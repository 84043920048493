import { ISpeciality } from "./appointment";
import { IPagination } from "./common";
import { Course } from "./courses";
import { IWorkingTimeInterval } from "./schedule";

export interface IReduxUser {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}

export interface IUser {
  id: number;
  email: string;
  role: "user" | "expert";
  profile: IProfile;
  user_must_repeat_surveys: boolean;
  working_time_intervals?: IWorkingTimeInterval["data"][];
  specialities: ISpeciality["data"][];
  survey_ids: number[];
  dental_survey_ids: number[];
  urogynecological_survey_ids: number[];
}

export interface IFlatUser {
  id: number;
  email: string;
  role: string;
}

export interface IUsers extends IPagination {
  data: IUser[];
}

export interface IProfile {
  id: number;
  first_name: string;
  last_name: string;
  avatar?: string | Blob;
  phone_number: string;
  pesel?: string;
  street_address?: string;
  city?: string;
  zip?: string;
  about_me?: string;
  interests?: string;
  courses: Course[];
}

export type TUserSelectOptions = "alphabethic" | "newest";

export const USER_SELECT_OPTIONS = [
  { label: "Alfabetycznie", value: "alphabethic" },
  { label: "Od najnowszych", value: "newest" },
];

import { rgba } from "utilities/rgba";

export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

const primaryColor = "#302664";
const secondaryColor = "#6077EE";
const accentColor = "#76C9DC";

export const theme = {
  breakpoints: ["576px", "768px", "992px", "1200px"],
  palette: {
    primary: {
      main: primaryColor,
      dark: "#211a46",
      light: "#595183",
    },
    secondary: {
      main: secondaryColor,
      dark: "#4353a6",
      light: "#7f92f1",
    },
    accent: {
      main: accentColor,
      dark: "#528c9a",
      light: "#91d3e3",
    },
    disabled: "#CED7DC",
    success: { main: "#60C55E", dark: "#438941", light: "#7fd07e" },
    error: { main: "#FF819F", dark: "#b25a6f", light: "#ff9ab2" },
    neutral: {
      mainBlue: "#499CDD",
      dark: "#1D1A30",
      grey: "#6A7482",
      lightGrey: "#CED7DC",
      lightBlue: "#DFF2FB",
      veryLightBlue: "EAE9EE",
      light: "#EFEFEF",
      white: "#FFFFFF",
      veryLight: "#F4F9FD",
      medium: "#BEC9D5",
      funBlue: "#206B96",
      red: "#C30000",
      tuna: "#E591A1",
    },
    text: {
      primary: primaryColor,
      secondary: "#ffffff",
    },
    background: "#FAFAFE",
    navbar: "#F0F2FE",
  },
  textStyles: {
    body2: {
      fontSize: 12,
      lineHeight: "16px",
    },
    body: {
      fontSize: 14,
      lineHeight: "24px",
    },
    h4: {
      fontSize: 12,
      lineHeight: "20px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 28,
      lineHeight: "32px",
      fontWeight: 700,
    },
  },
};

import styled from "styled-components";
import { rgba } from "utilities/rgba";

export const Line = styled.span`
  ${({ theme }) => `
	    display: flex;
      background-color: ${rgba(theme.palette.primary.main, 0.25)};
      margin: 8px 0 16px;
      width: 100%;
      height: 1px;
  `};
`;

import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useFetch = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const tryCatchWrapper = async <T, P>(
    fetchHandler: (props?: P) => Promise<T>,
  ): Promise<T | undefined> => {
    setError("");
    setIsLoading(true);
    try {
      return await fetchHandler();
    } catch (err: any) {
      toast.error(err.error);
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  return { error, setError, isLoading, setIsLoading, tryCatchWrapper };
};

export type FetchHook = {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  tryCatchWrapper: <T, P>(
    fetchHandler: (props?: P) => Promise<T>,
  ) => Promise<T | undefined>;
};

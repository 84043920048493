import { Box, Flex } from "components";
import { P } from "components/Typography";
import { format } from "date-fns";
import styled from "styled-components";
import { TKind } from "types/appointment";

interface IAppointmentTile {
  date: Date;
  type: TKind;
  name: string;
}

const kindMap = {
  telephone: "Teleporada",
  online: "Online",
  in_person: "Stacjonarnie - ul. Antoniego Słonimskiego 26/U01, 50-304 Wrocław",
};

const StyledBox = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  border-radius: 5px;
  width: 100%;
  min-height: 60px;
  gap: 10px;
`;

const DateBox = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 10px 15px;
  min-width: 110px;
`;

export const AppointmentTile = ({ date, type, name }: IAppointmentTile) => {
  return (
    <StyledBox>
      <DateBox>
        <P variant="body" color="primary" fontWeight={700}>
          {format(date, "dd.MM.yyyy")}
        </P>
        <P variant="body" color="primary">
          {format(date, "HH:mm")}
        </P>
      </DateBox>
      <Flex flexDirection="column" width="100%" justifyContent="center">
        <P variant="body" color="primary" fontWeight={700}>
          {name}
        </P>
        <P variant="body2" color="grey">
          {kindMap[type]}
        </P>
      </Flex>
    </StyledBox>
  );
};

import { IRegulation, IRegulationRes } from "types/regulations";
import request from "./index";

export const getRegulations = () =>
  request<IRegulationRes>({
    options: {
      url: "/regulations",
      method: "get",
    },
  });

export const getRegulation = (id: string) =>
  request<{ data: IRegulation }>({
    options: {
      url: `/regulations/${id}`,
      method: "get",
    },
  });

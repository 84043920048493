import { Flex } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const Wrapper = styled.main`
  min-height: 100vh;
  ${({ theme }) => `
    background-color: ${theme.palette.navbar};
    color: ${theme.palette.text.primary};
  `};
`;

export const ContentWrapper = styled(Flex)`
  width: 100%;
  min-height: calc(100vh - 90px);
`;

export const GuestContentWrapper = styled(ContentWrapper)`
  padding-block: 60px;
  @media only screen and (max-width: 1250px) {
    padding-block: 50px;
  }

  @media only screen and (max-width: 991px) {
    padding-block: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding-block: 30px;
  }

  @media only screen and (max-width: 480px) {
    padding-block: 20px;
  }
  @media ${device.tablet} {
    padding-inline: 24px;
  }
  @media ${device.mobile} {
    padding-inline: 16px;
  }
`;

export const Content = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.background};
  `};
  width: 100%;
  border-radius: 30px 0px 0px 0px;
  padding: 32px;
  @media ${device.tablet} {
    padding-inline: 24px;
  }
  @media ${device.mobile} {
    padding-inline: 16px;
  }
`;

import { http } from "utilities/http";
import {
  QUESTIONNAIRE,
  QUESTIONNAIRES,
  QUESTIONNAIRES_DENTAL,
  QUESTIONNAIRES_URO,
  QUESTIONNAIRE_DENTAL,
  QUESTIONNAIRE_URO,
} from "constants/paths";
import { IQuestionnaire } from "types/questionnaire";
import { serialize } from "object-to-formdata";
import {
  ICreateQuestionnaire,
  ICreateQuestionnaireDental,
  ICreateQuestionnaireUro,
  IGetQuestionnaire,
  IGetQuestionnaireDental,
  IGetQuestionnaireUro,
  IUpdateQuestionnaire,
  IUpdateQuestionnaireDental,
} from "types/forms/questionnaire";

export const createQuestionnaire = (questionnaire: ICreateQuestionnaire) =>
  http<IQuestionnaire>(QUESTIONNAIRES, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const updateQuestionnaire = (
  questionnaire: IUpdateQuestionnaire,
  id: string | number,
) =>
  http<IQuestionnaire>(QUESTIONNAIRE(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const getQuestionnaire = (id: string | number) =>
  http<IGetQuestionnaire>(QUESTIONNAIRE(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

export const createUroQuestionnaire = (
  questionnaire: ICreateQuestionnaireUro,
) =>
  http<IQuestionnaire>(QUESTIONNAIRES_URO, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const updateUroQuestionnaire = (
  questionnaire: IUpdateQuestionnaireDental,
  id: string | number,
) =>
  http<IQuestionnaire>(QUESTIONNAIRE_URO(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const getUroQuestionnaire = (id: string | number) =>
  http<IGetQuestionnaireUro>(QUESTIONNAIRE_URO(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

export const createDentalQuestionnaire = (
  questionnaire: ICreateQuestionnaireDental,
) =>
  http<IQuestionnaire>(QUESTIONNAIRES_DENTAL, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const updateDentalQuestionnaire = (
  questionnaire: IUpdateQuestionnaireDental,
  id: string | number,
) =>
  http<IQuestionnaire>(QUESTIONNAIRE_DENTAL(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(questionnaire),
  });

export const getDentalQuestionnaire = (id: string | number) =>
  http<IGetQuestionnaireDental>(QUESTIONNAIRE_DENTAL(id), {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

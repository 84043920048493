import { Box, Card, Flex } from "components";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import BirdLogo from "assets/images/logo_bird.svg";
import { device } from "styles/theme";

export const StyledCard = styled(Card)`
  width: 600px;
  height: fit-content;
  position: relative;
  overflow: visible;
  padding: 80px;
  @media ${device.tablet} {
    padding-inline: 60px;
  }
  @media ${device.tablet} {
    padding-inline: 40px;
  }
  @media ${device.mobile} {
    padding-inline: 20px;
  }
  margin-top: 60px;
`;

export const Logo = styled(Box)`
  position: absolute;
  width: 120px;
  height: 120px;
  left: calc(50% - 60px);
  top: -60px;
  border-radius: 15px;
  ${({ theme }) => `
    background-color: ${theme.palette.background};
  background-image: url("${BirdLogo}");
  `}
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  ${({ theme }) =>
    `box-shadow: 0px 4px 10px ${rgba(theme.palette.primary.dark, 0.25)};`}
`;

export const InfoCard = styled(Flex)`
  width: fit-content;
  flex-flow: column wrap;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.navbar};
`;

export const LinkA = styled.a`
  text-decoration: underline;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.text.primary};
`;

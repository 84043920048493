import { yupResolver } from "@hookform/resolvers/yup";
import { CalendarDatePicker, Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H2 } from "components/Typography";
import { Button } from "components/_form";
import { IOption } from "components/_form/Select/Select";
import { format, getDay } from "date-fns";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateAppointment } from "services/appointment";
import { IAppointmentExtended } from "types/appointment";
import { IUpdateAppointment } from "types/forms/appointment";
import { IService } from "types/service";
import { IUser } from "types/user";
import { getUserFullName } from "utilities/appointment";
import { updateAppointmentSchema } from "validation/appointment";
import { HoursSelect } from "views/AppointmentFormView/AppointmentForm/components";
import { hours } from "views/AppointmentFormView/AppointmentForm/components/HoursSelect";
import { ExpertSelect } from "./ExpertSelect";

interface IAppointmentEditModal extends Omit<IModal, "styles" | "children"> {
  appointment?: IAppointmentExtended["data"];
}
interface IExpert extends IOption {
  data: IUser;
}

interface IServiceChoose extends IOption {
  data: IService["data"];
}
interface IEditForm {
  first_visit: boolean;
  week_id: number;
  expert: IExpert;
  serviceType: IServiceChoose;
  startDate: Date;
  startHour: string;
}

export const AppointmentEditModal = ({
  isOpen,
  onCancelClick,
  appointment,
}: IAppointmentEditModal) => {
  const navigate = useNavigate();
  const visitDate = new Date(appointment?.date_and_time_of_appointment ?? "");
  const defaultValues = {
    startDate: visitDate,
    startHour: format(visitDate, "H:mm"),
  };
  const methods = useForm<IEditForm>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(updateAppointmentSchema),
  });

  useEffect(() => {
    if (!appointment) return;
    methods.setValue("expert", {
      label: getUserFullName(appointment.expert),
      value: String(appointment.expert.id),
      data: appointment.expert,
    });
    methods.setValue("serviceType", {
      label: appointment.service.name,
      value: String(appointment.service.id),
      data: appointment.service,
    });
    methods.setValue("first_visit", appointment.first_visit);
  }, [appointment]);

  const onSubmit = methods.handleSubmit(async (data) => {
    if (!appointment) return;

    const weekDay =
      getDay(data.startDate) === 0 ? 6 : getDay(data.startDate) - 1;

    const limit = data.first_visit
      ? data.serviceType?.data.first_time_slots
      : data.serviceType?.data.slots;
    let slots: string = `${weekDay}`;
    for (let i = 0; i < limit; i++) {
      slots += `_${hours.indexOf(data.startHour) + i}`;
    }

    const prepData: IUpdateAppointment = {
      appointment: {
        expert_id: appointment.expert.id,
        week_id: data.week_id,
        slot_ref: slots,
      },
    };
    try {
      const response = await updateAppointment(prepData, appointment.id);
      if (!response) return;
      navigate("/app/appointments");
      toast.success("Wizyta została zaktualizowana");
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      if (onCancelClick) onCancelClick();
    }
  });

  return (
    <Modal isOpen={isOpen} disableBackdropClick>
      <Flex width="100%" flexDirection="column" gap="20px" alignItems="center">
        <H2 variant="h2" textAlign="center" color="primary">
          Zmień termin wizyty
        </H2>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} style={{ width: "100%" }}>
            <Flex width="100%">
              <Flex
                width="100%"
                flexDirection="column"
                alignContent="center"
                justifyContent="center"
                gap="20px"
              >
                <ExpertSelect />
                <CalendarDatePicker />
              </Flex>
              <Flex width="100%" alignItems="center" justifyContent="center">
                <HoursSelect />
              </Flex>
            </Flex>

            <Flex mt={4} justifyContent="center" gap="10px">
              <Button
                variant="primary"
                bordered
                label="Anuluj"
                type="button"
                onClick={onCancelClick}
              />
              <Button
                variant="accent"
                label="Zapisz"
                type="submit"
                disabled={!methods.formState.isValid}
              />
            </Flex>
          </form>
        </FormProvider>
      </Flex>
    </Modal>
  );
};

import axios from "axios";
import { Card } from "components";
import { H2, H3 } from "components/Typography";
import { Select } from "components/_form";
import { IOption } from "components/_form/Select/Select";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getBlogs } from "services/knowledge";

interface IBlogsSelector {
  userBlogs: IOption[];
  setUserBlogs: Dispatch<SetStateAction<IOption[]>>;
}

export const BlogsSelector = ({ userBlogs, setUserBlogs }: IBlogsSelector) => {
  const [allBlogs, setAllBlogs] = useState<IOption[]>([]);
  const [dictionaryBlogs, setDictionaryBlogs] = useState<IOption[]>([]);

  const fetchBlogs = async () => {
    try {
      const response = await getBlogs();
      if (!response) return;
      const options: IOption[] = response.data.map((e) => {
        return { label: e.title, value: String(e.id) };
      });
      setAllBlogs(options);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const filteredData = allBlogs.filter((el) => {
      return userBlogs.indexOf(el) == -1;
    });
    setDictionaryBlogs(filteredData);
  }, [allBlogs, userBlogs]);

  return (
    <>
      <H3 variant="h3" mb={2}>
        Artykuły z bazy wiedzy
      </H3>
      <Select
        isMulti={true}
        onChange={(val) => {
          setUserBlogs(val as IOption[]);
        }}
        selectedOptions={userBlogs}
        options={dictionaryBlogs}
      />
    </>
  );
};

import { useState, useEffect } from "react";

interface IUseWindowWidth {
  windowWidth: number;
  isMobile: boolean;
}

export function useWindowWidth(): IUseWindowWidth {
  const isScreenSize =
    typeof document.documentElement.clientWidth !== "undefined";
  const [windowWidth, setWindowWidth] = useState(1200);
  const [isMobile, setIsMobile] = useState(false);

  function changeWindowWidth() {
    setWindowWidth(isScreenSize ? document.documentElement.clientWidth : 1200);
    setIsMobile(document.documentElement.clientWidth < 768);
  }

  useEffect(() => {
    changeWindowWidth();
    window.addEventListener("resize", changeWindowWidth);

    return () => {
      window.removeEventListener("resize", changeWindowWidth);
    };
  }, []);

  return { windowWidth, isMobile };
}

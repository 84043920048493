import { Flex } from "components/Flex";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

interface IListItem {
  active?: boolean;
  variant?: "sidebar" | "navbar";
}

export const ListItem = styled(Flex)<IListItem>`
  text-transform: capitalize;
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  gap: 10px;

  ${({ theme, active, variant }) => `
    ${
      variant === "sidebar"
        ? "border-radius: 30px; padding: 15px 20px;"
        : " padding: 4px 8px;"
    }
    height: ${variant == "sidebar" ? "80px" : "43px"};
    background-color: ${
      active &&
      (variant == "sidebar" ? theme.palette.secondary.main : "transparent")
    };    
    color: ${
      variant == "sidebar"
        ? active
          ? theme.palette.neutral.white
          : theme.palette.primary.main
        : theme.palette.primary.main
    };
    &:hover {    
      background-color: ${
        variant == "sidebar"
          ? !active && rgba(theme.palette.secondary.light, 0.2)
          : "transparent"
      };        
      color: ${
        variant == "sidebar"
          ? !active && theme.palette.primary.main
          : theme.palette.secondary.main
      }; 
    }
${active && "font-weight: bold;"}
  `};
  transition: 150ms all linear;
`;

import { FC, useEffect, useState } from "react";
import { Box, Card, Flex, Spinner } from "components";
import { ServiceTile } from "components/ServiceTile";
import { getServices } from "services/service";
import { toast } from "react-toastify";
import { IService } from "types/service";
import { SectionHeader } from "components/SectionHeader";
import styled from "styled-components";
import { device } from "styles/theme";

const GridBox = styled(Box)`
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  grid-auto-rows: auto;
`;

export const ServicesView: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [services, setServices] = useState<IService["data"][]>([]);

  const fetchServieces = async () => {
    setIsLoading(true);
    try {
      const response = await getServices();
      setServices(response.data);
    } catch (err: any) {
      toast.error(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServieces();
  }, []);

  return (
    <Flex width="100%" flexDirection="column">
      <SectionHeader text="Usługi" />
      {isLoading && <Spinner />}
      <GridBox>
        {services.map((service) => (
          <ServiceTile key={service.id} service={service} />
        ))}
      </GridBox>
    </Flex>
  );
};

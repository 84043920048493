import { getUser, getMe } from "services/user";
import { IAppointmentExtended } from "types/appointment";
import { IUser } from "types/user";

export const fetchExpertData = async (
  appointment: IAppointmentExtended["data"],
  currentUser: IUser,
) => {
  if (currentUser.role === "expert") {
    return currentUser;
  } else {
    const { data: user } = await getUser(appointment.expert.id);
    return user;
  }
};

export const fetchPatientData = async (
  appointment: IAppointmentExtended["data"],
  currentUser: IUser,
) => {
  if (currentUser.role === "user") {
    return currentUser;
  } else {
    const { data: user } = await getUser(appointment.patient.id);
    return user;
  }
};

export const addExpertAndPatientData = async (
  appointment: IAppointmentExtended["data"],
  currentUser: IUser,
) => {
  const expert = await fetchExpertData(appointment, currentUser);
  const patient = await fetchPatientData(appointment, currentUser);
  return {
    ...appointment,
    ...(expert && { expert }),
    ...(patient && { patient }),
  };
};

export const getExtendedAppointments = async (
  appointments: IAppointmentExtended["data"][],
) => {
  const { data: fetchedCurrentUser } = await getMe();
  return Promise.all(
    appointments.map((appointment) =>
      addExpertAndPatientData(appointment, fetchedCurrentUser),
    ),
  );
};

import { IBlogs } from "types/knowledge";
import { Flex, Spinner } from "components";
import { Button } from "components/_form";
import { P } from "components/Typography";
import { ReactComponent as ScrollIcon } from "assets/icons/scroll.svg";
import { useTranslation } from "react-i18next";
import { VideoCard } from "./VideoCard";

export const VideoList = ({
  blogs,
  isLoading,
  showMore,
  handleLoadMoreOffers,
}: {
  blogs?: IBlogs;
  isLoading: boolean;
  showMore: boolean;
  handleLoadMoreOffers: () => void;
}) => {
  const { t } = useTranslation();

  const List = () => {
    if (isLoading) return <Spinner />;
    if (!blogs || blogs.data.length == 0) return <>{t("noInfo")}</>;
    return (
      <>
        {blogs.data.map((blog) => {
          return <VideoCard key={blog.id} blog={blog} />;
        })}
      </>
    );
  };

  const canShowMore = blogs?.data?.length != 0 && !isLoading && showMore;

  return (
    <>
      <List />
      {canShowMore && (
        <Flex justifyContent="center" width="100%">
          <Button variant="transparent" onClick={handleLoadMoreOffers}>
            <ScrollIcon />
            <P variant="body">{t("loadMore")}</P>
          </Button>
        </Flex>
      )}
    </>
  );
};

import { PAYMENT } from "constants/paths";
import { IPaymentReturn } from "types/payment";
import { http } from "utilities/http";

export const getPayment = async (id: string | number) => {
  return await http<IPaymentReturn>(PAYMENT(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

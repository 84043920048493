import { FC, useEffect } from "react";
import { confirmEmail } from "api/authApi";
import { toast } from "react-toastify";
import { useQuery } from "hooks/useQuery";
import { useNavigate } from "react-router-dom";

export const ConfirmEmailView: FC = () => {
  let query = useQuery();
  const navigate = useNavigate();

  const handleConfirmEmail = async () => {
    const token = query.get("token");
    if (token) {
      try {
        const response = await confirmEmail(token);
        if (!response) return;
        toast.success("Konto zostało poprawnie potwierdzone");
        navigate("/login");
      } catch (e) {
        toast.error("Potwierdzenie nie powiodło się");
        navigate("/");
      }
    } else {
      toast.error("Brak tokenu autoryzacyjnego");
      navigate("/");
    }
  };

  useEffect(() => {
    handleConfirmEmail();
  }, []);

  return <></>;
};

import React, { useEffect, useRef, useState } from "react";
import { Flex, Avatar, Box } from "components";
import { ReactComponent as ChatIcon } from "assets/icons/ico-chat.svg";
import { P } from "../Typography/Typography";
import { TextareaField } from "../_form";
import chatWS from "./chatWS";
import { useMyAccount } from "hooks/useMyAccount";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  ChatHeader,
  Content,
  DotOnline,
  Message,
  MessageInputWrapper,
  SendIconStyled,
  SpecialistData,
  StyledFlex,
} from "./Chat.styled";

type IMessage = {
  id: number;
  isOwn: boolean;
  message: string;
};

interface IChat {
  patient: IChatUser;
  expert: IChatUser;
}

interface IChatUser {
  id: number;
  name: string;
  avatar?: string | Blob;
}

export const Chat = ({ expert, patient }: IChat) => {
  const [consultationId, setConsultationId] = useState<number>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [message, setMessage] = useState("");
  const { myAccountState } = useMyAccount();
  const { user } = myAccountState;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: containerRef.current.clientHeight });
  };

  const chatIdentifier = JSON.stringify({
    channel: "ConsultationChannel",
    patient_id: patient.id,
    expert_id: expert.id,
  });

  useEffect(() => {
    chatWS.send(
      JSON.stringify({
        command: "subscribe",
        identifier: chatIdentifier,
      }),
    );
  }, []);

  useEffect(() => {
    return () => {
      chatWS.send(
        JSON.stringify({
          command: "unsubscribe",
          identifier: chatIdentifier,
        }),
      );
    };
  }, []);

  const getHistoricalMessages = () => {
    setLoading(true);
    chatWS.send(
      JSON.stringify({
        command: "message",
        identifier: chatIdentifier,
        data: JSON.stringify({
          action: "pull_messages",
          sender_identifier: user?.id,
          consultation_id: consultationId,
          per_page: 10,
          page: page,
        }),
      }),
    );
  };

  const handleSendMessage = () => {
    if (!!message)
      chatWS.send(
        JSON.stringify({
          command: "message",
          identifier: chatIdentifier,
          data: JSON.stringify({
            action: "receive",
            sender_identifier: user?.id,
            content: message,
          }),
        }),
      );
    setMessage("");
  };

  useEffect(() => {
    if (!!consultationId && !!user) {
      getHistoricalMessages();
    }
  }, [consultationId, user]);

  chatWS.onmessage = (e) => {
    const data = JSON.parse(e.data);
    if (data.hasOwnProperty("type")) {
      if (data.type === "confirm_subscription") {
        setConsultationId(data.consultation_id);
        return;
      }
    } else {
      const message = data.message;
      if (message.hasOwnProperty("json")) {
        const result = JSON.parse(message.json);
        setTotal(result.pagination.count);
        let array: IMessage[] = [];
        result.data.map((e) => {
          array.push({
            id: e.id,
            message: e.content,
            isOwn: e.sender_id === user?.id,
          });
        });
        let mergeArray = [
          ...messages,
          ...array.filter((e) => !messages.includes(e)),
        ];
        setMessages(
          mergeArray.sort(function (a, b) {
            return b.id - a.id;
          }),
        );
        setPage((prev) => prev + 1);
        setLoading(false);
      } else {
        scrollToBottom();
        setMessages((oldMess) => [
          {
            id: message.id,
            message: message.content,
            isOwn: message.sender_id === user?.id,
          },
          ...oldMess,
        ]);
      }
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: total > messages.length,
    onLoadMore: getHistoricalMessages,
  });

  return (
    <StyledFlex
      alignItems="stretch"
      justifyContent="center"
      flexDirection="column"
    >
      <ChatHeader>
        <ChatIcon />
        <P variant="body">
          {user?.role === "expert" ? "Czat z pacjentem" : "Chat ze specjalistą"}
        </P>
      </ChatHeader>
      <SpecialistData>
        <Avatar
          size="medium"
          color="light"
          url={user?.role === user ? patient.avatar : expert.avatar}
        />
        <Flex flexDirection="column" justifyContent="center" ml={3}>
          <Flex alignItems="center">
            <DotOnline />
            <P variant="body2" color="white">
              online
            </P>
          </Flex>
          <P variant="h3" color="white">
            {user?.role === "expert" ? patient.name : expert.name}
          </P>
        </Flex>
      </SpecialistData>
      <Content ref={containerRef}>
        {messages.length > 0 ? (
          <>
            {messages.map((el) => (
              <Message key={el.id} own={el.isOwn}>
                {el.message}
              </Message>
            ))}
            {(loading || total > messages.length) && (
              <P ref={sentryRef} variant="body2" color="grey" my={2}>
                Loading...
              </P>
            )}
          </>
        ) : !!consultationId ? (
          <P variant="body2" color="grey" my={4}>
            Napisz coś, aby rozpocząć rozmowę
          </P>
        ) : (
          <P variant="body2" color="grey" my={4}>
            Przepraszamy. Chat niedostępny
          </P>
        )}
      </Content>
      <MessageInputWrapper>
        <Box py={20} px={15}>
          <TextareaField
            placeholder="Wiadomość..."
            rows={message.length > 0 ? 5 : 1}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            disabled={!consultationId}
            hideError
          />
        </Box>
        <SendIconStyled onClick={handleSendMessage} />
      </MessageInputWrapper>
    </StyledFlex>
  );
};

import { Flex } from "components";
import { FC } from "react";
import Calendar from "react-calendar";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export const StyledCalendar = styled(Calendar)`
  background-color: transparent;
  border: none;
  margin: 5px;
  width: 240px;

  .react-calendar__month-view__weekdays {
    color: ${({ theme }) => theme.palette.neutral.lightGrey};
    border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lightGrey};
    margin-bottom: 5px;
  }

  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button {
    :enabled:hover,
    :enabled:focus {
      background-color: ${({ theme }) => theme.palette.accent.light};
      border-radius: 15px;
      color: white;
    }
  }

  .react-calendar__navigation button:disabled {
    background-color: transparent;
  }

  .react-calendar__month-view__weekdays__weekday {
    border: none;

    abbr[title] {
      border-bottom: none !important;
      cursor: inherit !important;
      text-decoration: none !important;
    }
  }

  .react-calendar__navigation {
    position: relative;

    & > :first-child {
      display: none;
    }
    & > :nth-child(2) {
      position: absolute;
      right: 45px;
      height: 44px;
    }
    & > :nth-child(4) {
      position: absolute;
      right: 0;
      height: 44px;
    }
    & > :nth-child(5) {
      display: none;
    }
  }

  .react-calendar__tile {
    border-radius: 20px;
    color: ${({ theme }) => theme.palette.primary.main};

    :hover:not(:disabled) {
      background-color: ${({ theme }) =>
        rgba(theme.palette.secondary.main, 0.5)} !important;
    }
  }

  .react-calendar__tile--now {
    background-color: ${({ theme }) =>
      rgba(theme.palette.accent.main, 0.5)} !important;
    color: ${({ theme }) => theme.palette.neutral.white};
  }

  .react-calendar__tile--active {
    background-color: ${({ theme }) => theme.palette.secondary.main} !important;
    color: ${({ theme }) => theme.palette.neutral.white};
    font-weight: bold;
  }

  .react-calendar__tile:disabled {
    background-color: ${({ theme }) => theme.palette.neutral.white};
    color: ${({ theme }) => theme.palette.neutral.lightGrey};
  }

  .react-calendar__navigation__label {
    flex-grow: 0 !important;
  }

  .react-calendar__navigation__label__labelText {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }
`;

interface Props {
  fieldName?: string;
}

export const CalendarDatePicker: FC<Props> = ({ fieldName = "startDate" }) => {
  const { control, formState } = useFormContext();
  
  return (
    <Flex width="100%" justifyContent="center">
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { value, onChange } }) => (
          <StyledCalendar
            minDate={new Date()}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Flex>
  );
};

import { Flex } from "components/Flex";
import { t } from "i18next";
import styled from "styled-components";
import { TPaymentStatus } from "types/payment";

const Wrapper = styled(Flex)<{
  paymentStatus?: boolean;
  small?: boolean;
  compactSize?: boolean;
}>`
  border-radius: 30px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  ${({ theme, paymentStatus, small, compactSize }) => `
    padding: ${small ? "2px 12px" : "8px 25px"};
    height: ${small ? "25px" : "35px"};
    width: ${compactSize ? "fit-content" : "100%"};
    background-color: ${
      paymentStatus
        ? theme.palette.success.main
        : theme.palette.neutral.veryLight
    };
    color: ${
      paymentStatus
        ? theme.palette.neutral.white
        : theme.palette.neutral.lightGrey
    };
  `}
`;
export const PaymentStatus = ({
  paymentStatus,
  small,
  compactSize,
}: {
  paymentStatus: TPaymentStatus;
  small?: boolean;
  compactSize?: boolean;
}) => {
  return (
    <Wrapper
      paymentStatus={paymentStatus === "completed"}
      small={small}
      compactSize={compactSize}
    >
      {paymentStatus === "completed" ? "Opłacona" : "Nieopłacona"}
    </Wrapper>
  );
};

import React, { useEffect } from "react";
import styled from "styled-components";

export interface IHamburgerMenuProps {
  isOpen: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

interface MenuProps {
  isVisible: boolean;
}

const Menu = styled.div<MenuProps>`
  touch-action: none;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  transform: ${({ isVisible }) =>
    isVisible ? "translateX(0)" : "translateX(100%)"};
  background-color: ${({ theme }) => theme.palette.navbar};
  transition: all ease-in-out 0.2s;
  z-index: 15;
  padding-top: 60px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: inherit;
  justify-content: center;
  gap: 60px;
  width: 100%;
  align-items: center;
  overflow: scroll;
  padding-block: 40px;
`;

const HamburgerMenu = ({ isOpen, children, onClick }: IHamburgerMenuProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);
  return (
    <Menu isVisible={isOpen}>
      <Container onClick={onClick}>{children}</Container>
    </Menu>
  );
};

export default HamburgerMenu;

import { getAppointment } from "services/appointment";
import { getBlogs } from "services/knowledge";
import { getUser } from "services/user";

export async function fetchAppointments(id: string) {
  const appointmentResponse = await getAppointment(id);
  if (!appointmentResponse) {
    return {};
  }

  const expertResponse = await getUser(appointmentResponse.data.expert.id);
  const patientResponse = await getUser(appointmentResponse.data.patient.id);

  const extendedAppointmentData = {
    ...appointmentResponse.data,
    expert: expertResponse.data,
    patient: patientResponse.data,
  };

  const recommendedBlogsResponse = await getBlogs({
    user_id: extendedAppointmentData.patient.id,
  });

  const recommendedBlogs = recommendedBlogsResponse.data;

  return { appointment: extendedAppointmentData, recommendedBlogs };
}

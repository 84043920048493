import React, { Dispatch, SetStateAction } from "react";
import moment from "moment";
import "moment/locale/en-gb";
import {
  momentLocalizer,
  Formats,
  Components,
  EventPropGetter,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ReactComponent as PreviousIcon } from "assets/icons/ico-previous.svg";
import { ReactComponent as NextIcon } from "assets/icons/ico-next.svg";
import { StyledCalendar, StyledToolbar } from "./Calendar.styled";
import { MyEventType } from "types/calendar";
import { MyWeekHeader, MyEvent, eventStyleGetter } from "./Calendar.functions";
import "moment/locale/pl";

const localizer = momentLocalizer(moment);

const formats: Formats = {
  timeGutterFormat: (date: Date) => moment(date).format("HH:mm"),
};

interface IBigCalendar {
  currentWeek: moment.Moment;
  setCurrentWeek: Dispatch<SetStateAction<moment.Moment>>;
  setCurrentDay: Dispatch<SetStateAction<moment.Moment>>;
  events: any[];
}

export const BigCalendar = ({
  currentWeek,
  setCurrentWeek,
  setCurrentDay,
  events,
}: IBigCalendar) => {
  const Toolbar = () => (
    <StyledToolbar>
      <button
        onClick={() => setCurrentWeek(moment(currentWeek).subtract(1, "week"))}
      >
        <PreviousIcon />
      </button>
      <button
        onClick={() => setCurrentWeek(moment(currentWeek).add(1, "week"))}
      >
        <NextIcon />
      </button>
      <span>
        {currentWeek.format("MMMM")} | {currentWeek.format("YYYY")}
      </span>
    </StyledToolbar>
  );

  const components: Components<MyEventType> = {
    toolbar: Toolbar,
    week: {
      event: MyEvent,
      header: MyWeekHeader,
    },
  };

  function handleNavigate(newDate: Date, newView: string) {
    setCurrentDay(moment(newDate));
    if (moment(newDate).isSame(currentWeek, "week")) return;
    setCurrentWeek(moment(newDate).startOf("week"));
  }

  return (
    <StyledCalendar
      localizer={localizer}
      events={events}
      views={["week"]}
      defaultView="week"
      showMultiDayTimes={false}
      min={new Date(2023, 1, 1, 7)}
      max={new Date(2023, 1, 1, 22)}
      step={30}
      formats={formats}
      components={components as Components<object>}
      onNavigate={handleNavigate}
      date={currentWeek.toDate()}
      eventPropGetter={eventStyleGetter as EventPropGetter<object>}
    />
  );
};

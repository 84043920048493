import { Flex, Box } from "components";
import { P } from "components/Typography";
import { Button } from "components/_form";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { PaymentStatus } from "components/PaymentStatus";
import { StyledSpan } from "../AppointmentView.styled";
import { IAppointmentExtended } from "types/appointment";
import { getUserFullName } from "utilities/appointment";
import { useTranslation } from "react-i18next";
import { createPayment } from "services/pay";
import { IPayment } from "types/pay";
import { format } from "date-fns";
import { StyledJitsi } from "components/AppointmentTile/AppointmentTile";
import { createRoom, updateRoom } from "services/room";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

interface IAppointmentDetails {
  appointment: IAppointmentExtended["data"];
  old: boolean;
}

export const AppointmentDetailsMainPart = ({
  appointment,
  old,
}: IAppointmentDetails) => {
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const { t } = useTranslation();
  const visitDate = new Date(appointment.date_and_time_of_appointment);
  const date = format(visitDate, "dd.MM.yyyy - HH:mm");
  const navigate = useNavigate();
  const [meetActive, setMeetActive] = useState<boolean>(
    appointment.room ? appointment.room.isActive : false,
  );

  const handlePay = async () => {
    const payData: IPayment = {
      appointment_id: appointment.id,
      unit_price: appointment.first_visit
        ? appointment.service.first_time_price
        : appointment.service.price,
      user_id: appointment.patient.id,
      description: appointment.service.name,
    };
    const response = await createPayment(payData);

    if (response.status.statusCode === "SUCCESS") {
      localStorage.setItem("orderId", response.orderId);
      window.location.replace(response.redirectUri);
    }
  };

  const handleCreateRoom = async () => {
    const roomName = uuidv4();
    try {
      const response = await createRoom({
        room: {
          appointment_id: appointment.id,
          name: roomName,
          isActive: true,
        },
      });
      if (!response) return;
      setMeetActive(response.data.isActive);
    } catch (err: any) {}
  };

  const handleUpdateRoom = async () => {
    const updatedRoom = {
      room: {
        appointment_id: appointment.id,
        name: appointment.room.name,
        isActive: !meetActive,
      },
    };

    try {
      const response = await updateRoom(updatedRoom, appointment.room.id);
      if (!response) return;
      setMeetActive(response.data.isActive);
    } catch (err: any) {}
  };

  const handleRoom = () => {
    if (!appointment.room) handleCreateRoom();
    else handleUpdateRoom();
  };

  return (
    <>
      <P variant="h3" fontWeight={400} my={3} color="primary">
        {role === "expert" ? (
          <Flex flexDirection="column">
            <Box>
              {t("patient")}:{" "}
              <StyledSpan
                blue
                onClick={() => {
                  navigate(`/app/patients/${appointment.expert.id}`);
                }}
              >
                {appointment.patient_first_name +
                  " " +
                  appointment.patient_last_name}
              </StyledSpan>
            </Box>
            <P variant="body2">
              Umówiony przez: {getUserFullName(appointment.patient)}
            </P>
          </Flex>
        ) : (
          <>
            {t("expert")}:{" "}
            <StyledSpan
              blue
              onClick={() => {
                navigate(`/app/experts/${appointment.expert.id}`);
              }}
            >
              {getUserFullName(appointment.expert)}
            </StyledSpan>
          </>
        )}
      </P>
      <P variant="h3" fontWeight={400} color="primary">
        {t("appointmentType")}:{" "}
        <StyledSpan>
          {
            {
              online: "Online",
              in_person:
                "Stacjonarnie - ul. Antoniego Słonimskiego 26/U01, 50-304 Wrocław",
              telephone: "Telefoniczna",
            }[appointment.kind]
          }
        </StyledSpan>
      </P>
      {appointment.kind === "online" && (
        <Flex gap="10px" alignItems="center" flexWrap="wrap">
          {role === "expert" && (
            <>
              <StyledJitsi />
              <Button
                label={
                  !meetActive ? "Aktywuj spotkanie" : "Dezaktywuj spotkanie"
                }
                height="30px"
                variant={!meetActive ? "green" : "red"}
                onClick={handleRoom}
                disabled={appointment.canceled}
              />
            </>
          )}
          <Button
            label="Dołącz do spotkania"
            height="30px"
            variant="secondary"
            disabled={!meetActive || appointment.canceled}
            onClick={() => {
              navigate(`/jitsi-meeting/${appointment.room.id}`);
            }}
          />
        </Flex>
      )}
      <Flex my={3} alignItems="center" gap="10px">
        <P variant="h3" fontWeight={400} color="primary">
          {t("appointmentTime")}: <StyledSpan>{date}</StyledSpan>
        </P>
      </Flex>
      <Flex alignItems="center" gap="10px">
        <P variant="h3" fontWeight={400} color="primary">
          {t("appointmentPaymentStatus")}:
        </P>

        <PaymentStatus
          small
          paymentStatus={appointment.payment?.status ?? null}
          compactSize
        />
        {appointment.payment?.status !== "completed" && !old && (
          <Box mr={3}>
            <Button
              height="24px"
              onClick={handlePay}
              disabled={appointment.canceled}
            >
              {t("payNow")}
            </Button>
          </Box>
        )}
      </Flex>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Flex, Box, Menu, Avatar } from "components";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { useNavigate } from "react-router";
import { device } from "styles/theme";
import { navItems } from "constants/navItems";
import { useTranslation } from "react-i18next";
import BigLogo from "assets/images/logo_fizjo_horizontal.svg";
import EUFlag from "assets/images/eu.png";
import { ReactComponent as LoginIcon } from "assets/icons/ico-login.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/ico-logout.svg";
import { Button } from "components/_form";
import { logout } from "api/authApi";
import { ListItem } from "components/Menu/ListItem";
import { Link, useLocation } from "react-router-dom";
import { getUserFullName } from "utilities/appointment";
import { useWindowWidth } from "hooks/useWindowWidth";
import { Twirl as Hamburger } from "hamburger-react";
import HamburgerMenu from "./HamburgerMenu";
import { SideBar } from "./Sidebar";

export interface INavbar {
  onMenuIconClick: () => void;
  isSidebarOpen: boolean;
}

const Logo = styled.img`
  cursor: pointer;
`;

const EuLogo = styled.img`
  margin-left: 16px;
  cursor: pointer;
`;

const Wrapper = styled(Flex)`
  position: relative;
  z-index: 16;
  width: 100%;
  max-height: 90px;
  ${({ theme }) => `
    border-bottom: 0.5px solid ${theme.palette.neutral.light};
    background-color: ${theme.palette.navbar};
  `}
  padding-inline: 48px;

  @media ${device.laptop} {
    padding-inline: 32px;
  }

  @media ${device.tablet} {
    padding-inline: 24px;
  }
  @media ${device.mobile} {
    padding-inline: 16px;
  }
`;

const Languages = styled(Flex)`
  align-items: center;

  span {
    &[data-val="true"] {
      border-bottom: 1px solid black;
    }

    cursor: pointer;
    margin: 0 10px;
  }
`;

export const Navbar: React.FC<INavbar> = ({ onMenuIconClick }) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const isLoggedIn: boolean = !!currentUser.id;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isZoomed, setIsZoomed] = useState(
    localStorage.getItem("isZoomed") === "1",
  );

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await logout();
      localStorage.clear();
      navigate("/");
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  };

  const { isMobile } = useWindowWidth();
  const [isOpenHamburger, setIsOpenHamburger] = useState(false);

  useEffect(() => {
    // @ts-ignore
    document.body.style.zoom = isZoomed ? 1.25 : 1;
    localStorage.setItem("isZoomed", isZoomed ? "1" : "0");
  }, [isZoomed]);

  const ZoomButton = (
    <Button
      variant="secondary"
      fitContent
      bordered
      label={isZoomed ? t("buttons.zoomOut") : t("buttons.zoomIn")}
      onClick={() => setIsZoomed((prev) => !prev)}
    />
  );

  const unloggedBttns = (
    <>
      {ZoomButton}
      <Button
        variant="secondary"
        bordered
        label={t("buttons.singUp")}
        onClick={() => navigate("/register")}
      />
      <Button
        variant="secondary"
        onClick={() => navigate("/login")}
        label={t("buttons.singIn")}
        icon={<LoginIcon />}
      />
    </>
  );

  const loggedBttns = (
    <>
      <ListItem
        px={3}
        onClick={() => navigate("/app/my-account")}
        variant="navbar"
        active={location.pathname === "/app/my-account"}
      >
        <Box mr={1}>{`Moje konto (${getUserFullName(currentUser)})`}</Box>
        <Avatar color="dark" url={currentUser?.profile?.avatar} />
      </ListItem>
      {ZoomButton}
      <Button
        variant="secondary"
        bordered
        label={t("buttons.logOut")}
        icon={<LogoutIcon />}
        onClick={handleLogout}
      />
    </>
  );

  return (
    <>
      <Wrapper width="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" height={["60px", "60px", "105px"]}>
          <Logo
            height={isMobile ? "40px" : "60px"}
            onClick={() => navigate("/")}
            src={BigLogo}
            alt="specialist"
          />
          <EuLogo
            height={isMobile ? "16px" : "24px"}
            onClick={() => navigate("/projects")}
            src={EUFlag}
            alt="specialist"
          />
        </Flex>

        <Flex>
          {isMobile ? (
            <Hamburger
              size={28}
              toggled={isOpenHamburger}
              toggle={setIsOpenHamburger}
              rounded
            />
          ) : (
            <Flex justifyContent="flex-end" flexWrap="wrap" alignItems="center">
              <Menu
                view="vertical"
                onMenuIconClick={onMenuIconClick}
                items={navItems}
              />
              <Flex justifyContent="flex-end" style={{ gap: "10px" }} ml="10px">
                {isLoggedIn ? loggedBttns : unloggedBttns}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Wrapper>
      {isMobile && (
        <HamburgerMenu
          isOpen={isOpenHamburger}
          onClick={() => {
            setIsOpenHamburger(false);
          }}
        >
          <Menu
            view="horizontal"
            onMenuIconClick={onMenuIconClick}
            items={navItems}
          />
          {isLoggedIn && <SideBar stillExpand />}
          <Flex flexDirection="column" gap="10px">
            {isLoggedIn ? loggedBttns : unloggedBttns}
          </Flex>
        </HamburgerMenu>
      )}
    </>
  );
};

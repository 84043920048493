import { ICD10_CODES } from "constants/paths";
import qs from "qs";
import { IPagination } from "types/common";
import { IIcdCodes } from "types/researchCard";
import { http } from "utilities/http";

export const getIcdCodes = async (filters?: {
  page?: string | number;
  per_page?: string | number;
  disease?: string;
  icd10_code?: string;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<{
    data: IIcdCodes[];
    pagination: IPagination["pagination"];
  }>(`${ICD10_CODES}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

import { Box, Card, Flex } from "components";
import { useTranslation } from "react-i18next";
import {
  PatientDataInputs,
  ExpertSelects,
  HoursSelect,
  AppointmentTypeButtons,
  SummaryCard,
} from "./components";
import { Dispatch, SetStateAction } from "react";
import { CalendarDatePicker } from "components/CalendarDatePicker/CalendarDatePicker";
import styled from "styled-components";
import { H2 } from "components/Typography";
import { device } from "styles/theme";

export const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  gap: 15px;
  grid-auto-rows: 1fr;
  justify-items: center;
`;

interface IAppointmentForm {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  setInstantPayment: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  serviceTypeParam?: string;
  expertParam?: string;
}

export const AppointmentForm = ({
  onSubmit,
  isLoading,
  setInstantPayment,
  serviceTypeParam,
  expertParam,
}: IAppointmentForm) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      <GridBox>
        <PatientDataInputs />
        <Card height="100%">
          <Flex flexDirection="column">
            <ExpertSelects
              serviceTypeParam={serviceTypeParam}
              expertParam={expertParam}
            />
            <CalendarDatePicker />
          </Flex>
        </Card>
        <Card height="100%">
          <H2 variant="h2" mb="10px" textAlign="center" color="primary">
            Wybierz dostępną godzinę:
          </H2>
          <HoursSelect />
        </Card>
      </GridBox>
      <AppointmentTypeButtons />
      <SummaryCard
        isLoading={isLoading}
        setInstantPayment={setInstantPayment}
      />
    </form>
  );
};

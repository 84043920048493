import React, { FC } from "react";
import { Logo, StyledCard } from "./auth/components/Auth.Styled";
import { Box, Flex } from "../components";
import { H1, P } from "../components/Typography";
import EULogo from "assets/images/eu_logo.jpg";
import { EULink, LogoEU } from "views/HomeView/HomeView.styled";
import styled from "styled-components";

const StyledP = styled(P)`
  align-self: flex-start;
  font-size: 18px;
  margin: 4px 0;
  > span {
    font-weight: bold;
  }
`

export const ProjectsView: FC = () => {
  return <Flex justifyContent="center" alignItems="center" width="80%" flexDirection="column" mx="auto">
    <Box mb={3}>
      <LogoEU src={EULogo} alt="EU-logo" />
    </Box>
    <Box mb={3}>
      <H1 variant="h1" style={{textAlign: "center"}}>CHERRY OLGA JANKOWSKA<br/>
        realizuje projekt dofinansowany z Funduszy Europejskich pt.
        „Zwiększenie konkurencyjności firmy Cherry Olga Jankowska poprzez wdrożenie aplikacji mobilnej i webowej do
        fizjoterapii online.”
      </H1>
    </Box>
    <StyledP variant="body">
      <span>Celem projektu</span> jest wzrost konkurencyjności firmy poprzez wdrożenie specjalistycznego oprogramowania.
    </StyledP>
    <StyledP variant="body">
      <span>Wartość projektu:</span> 279 632,33 PLN
    </StyledP>
    <StyledP variant="body">
      <span>Dofinansowanie projektu z UE:</span> 237 687,48 PLN
    </StyledP>
    <StyledP variant="body">
      <span>Sfinansowano w ramach reakcji Unii na pandemię Covid-19</span>
    </StyledP>
    <StyledP variant="body">
      Projekt ma na celu wzrost konkurencyjności firmy poprzez wdrożenie specjalistycznego oprogramowania aplikacji mobilnej i webowej do fizjoterapii online, które pozwoli na świadczenie usług również w formie zdalnej
    </StyledP>
    <StyledP variant="body">
      Realizacja projektu i wdrożenie rozwiązań przełoży się na wzrost konkurencyjności i przychodowości firmy oraz pomoże w rozwiązaniu problemu - utrudniony dostęp do usług medycznych, fizjoterapeutycznych podczas pandemii.
    </StyledP>
    <StyledP variant="body">
       Rozwiązanie umożliwi przeprowadzenie terapii dla osób z problemem logistycznym, przebywających na kwarantannie.
    </StyledP>
  </Flex>;
};

import { EXAMINATION, EXAMINATIONS } from "constants/paths";
import { serialize } from "object-to-formdata";
import qs from "qs";
import {
  ICreateExamination,
  IExamination,
  IExaminations,
} from "types/examination";
import { http } from "utilities/http";

export interface IGetExaminationsFilters {
  page?: string | number;
  per_page?: string | number;
  user_id?: string | number;
}

export const getExaminations = async (filters?: IGetExaminationsFilters) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IExaminations>(`${EXAMINATIONS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getExamination = async (id: string | number) => {
  return await http<{ data: IExamination }>(EXAMINATION(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createExamination = (examination: ICreateExamination) =>
  http<{ data: IExamination }>(EXAMINATIONS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(examination),
  });

export const deleteExamination = (id: string | number) =>
  http<any>(EXAMINATION(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

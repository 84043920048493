import { ITab, TabPanel } from "components/TabPanel";
import { H4 } from "components/Typography";
import { isFuture, isPast } from "date-fns";
import { useEffect, useState } from "react";
import { IAppointmentExtended } from "types/appointment";
import { GappedFlex } from "../Components.styled";
import { AppointmentTile } from "./AppointmentTile";

const tabs: ITab[] = [
  { name: "appointmentsHistory" },
  { name: "plannedAppointments" },
];

interface IAppointmentsCard {
  appointments: IAppointmentExtended["data"][];
}

export const AppointmentsCard = ({ appointments }: IAppointmentsCard) => {
  const [currentTab, setCurrentTab] = useState<ITab>(tabs[0]);
  const [elementsToDisplay, setElementsToDisplay] = useState<
    IAppointmentExtended["data"][]
  >([]);

  const historical = appointments.filter((e) =>
    isPast(new Date(e.date_and_time_of_appointment)),
  );
  const planned = appointments.filter((e) =>
    isFuture(new Date(e.date_and_time_of_appointment)),
  );

  useEffect(() => {
    setElementsToDisplay(
      currentTab.name === "appointmentsHistory" ? historical : planned,
    );
  }, [currentTab]);

  return (
    <TabPanel tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}>
      <GappedFlex mt={3}>
        {elementsToDisplay.length > 0 ? (
          <>
            {elementsToDisplay.map((e, i) => {
              return (
                <AppointmentTile
                  key={i}
                  date={new Date(e.date_and_time_of_appointment)}
                  type={e.kind}
                  name={e.service.name}
                />
              );
            })}
          </>
        ) : (
          <H4 variant="h4" color="primary" textAlign="center">
            Brak wizyt do wyświetlenia
          </H4>
        )}
      </GappedFlex>
    </TabPanel>
  );
};

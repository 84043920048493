import styled from "styled-components";

export const SmallButton = styled.div<{ variant?: "primary" | "secondary" }>`
  ${({ theme, variant = "primary" }) => `    
    color: ${
      variant == "primary"
        ? theme.palette.secondary.main
        : theme.palette.error.main
    };
  `}
  width: 37px;
  height: 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
`;

export const Header = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.secondary.main};
  `}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 5px;
  height: 30px;
`;

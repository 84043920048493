import { FC, useState } from "react";
import { Box, Flex, Spinner } from "components";
import { Button, Checkbox, TextField, FieldLabel } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { H1, P } from "components/Typography/Typography";
import Calendar from "react-calendar";

import { DotLine } from "components/DotLine";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { CustomDatePicker } from "components/CustomDatePicker";
import { IHarmonogramForm } from "types/forms/schedule";
import { device } from "styles/theme";

export const ResponsiveBox = styled(Box)`
  width: 49%;
  padding: 5px;

  @media ${device.tablet} {
    padding: 0;
    width: 100%;
  }
`;

export const WeekDayButton = styled.button.attrs({ type: "button" })<{
  active?: boolean;
}>`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  margin-right: 12px;
  ${({ theme, active }) => `
    color: ${active ? theme.palette.neutral.white : theme.palette.primary.main};
    background-color: ${
      active ? theme.palette.primary.main : theme.palette.neutral.white
    };
    border: 1px solid ${theme.palette.neutral.lightGrey};
  `};
`;

import { FC, useState } from "react";
import { Box, Flex, Spinner } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IChangePassword } from "types/forms/auth";
import { setNewPassword } from "api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H1, P } from "components/Typography/Typography";
import { DotLine } from "components/DotLine";

const Wrapper = styled(Flex)`
  box-shadow: 0px 10px 40px rgba(29, 26, 48, 0.25);
  margin: 0 auto;
  border-radius: 10px;

  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
`}
`;

export const NewPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IChangePassword["user"]>({
    mode: "onChange",
    defaultValues: {
      password: "",
      password_confirmation: "",
      reset_password_token: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await setNewPassword({ user: data });
      if (!response) return;
      navigate("/app");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper flexDirection="column" width={["100%", "45%"]} p={4}>
      <H1 m={2} variant="h1" textAlign="center">
        Nowe hasło
      </H1>
      <DotLine my={3} />
      <form onSubmit={onSubmit}>
        <TextField
          label="Hasło: *"
          placeholder="Wpisz hasło..."
          transparent
          {...register("password")}
          error={t(errors.password?.message || "")}
        />
        <TextField
          label="Powtórz hasło: *"
          placeholder="Wpisz ponownie hasło..."
          transparent
          {...register("password_confirmation")}
          error={t(errors.password_confirmation?.message || "")}
        />
        <Flex justifyContent="center" width="100%">
          <Box my={4}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Button variant="red" type="submit" disabled={!formState.isValid}>
                {t("setNewPassword")}
              </Button>
            )}
          </Box>
        </Flex>
      </form>
    </Wrapper>
  );
};

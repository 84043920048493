import { Box, CalendarDatePicker, Card, Flex } from "components";
import { P } from "components/Typography";
import { Hour } from "components/Hour";
import { BigCalendar } from "./components/Calendar/Calendar";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { SectionHeader } from "components/SectionHeader";
import { useWindowWidth } from "hooks/useWindowWidth";
import styled from "styled-components";
import { device } from "styles/theme";

const Grid = styled(Box)`
  display: grid;
  gap: 20px;
  grid-template-columns: minmax(250px, max-content) 1fr;
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const ExpertCalendarView = () => {
  const { isMobile } = useWindowWidth();
  const methods = useForm({
    defaultValues: {
      currentDay: moment().startOf("day").toDate(),
    },
  });

  const setCurrentDay = (newCurrentDay: moment.Moment) => {
    methods.setValue("currentDay", newCurrentDay.toDate());
  };

  const onSubmit = methods.handleSubmit(() => {});

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between">
        <SectionHeader text="Kalendarz specjalistów" />
        <Flex alignItems="center">
          <P mr={2} variant="body2" color="grey">
            godzina:{" "}
          </P>
          <Hour />
        </Flex>
      </Flex>

      <FormProvider {...methods}>
        <form
          onSubmit={onSubmit}
          style={{ width: "100%", boxSizing: "border-box" }}
        >
          <Grid>
            <Card maxWidth={["", "", "330px"]} height="fit-content">
              <CalendarDatePicker fieldName="currentDay" />
            </Card>
            <Card width="100%">
              <BigCalendar
                currentDay={moment(methods.watch("currentDay"))}
                setCurrentDay={setCurrentDay}
              />
            </Card>
          </Grid>
        </form>
      </FormProvider>
    </Flex>
  );
};

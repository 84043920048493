import { FC, useEffect, useState } from "react";
import { Card, Flex, Spinner } from "components";
import { ExpertTile } from "./ExpertTile";
import { useExperts } from "hooks/useExperts";
import { Select, TextField } from "components/_form";
import { theme } from "styles";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { H4, P } from "components/Typography";
import { Pagination } from "views/AppointmentsView/Pagination";
import { TUserSelectOptions, USER_SELECT_OPTIONS } from "types/user";
import { IOption } from "components/_form/Select/Select";
import { SectionHeader } from "components/SectionHeader";
import { useWindowWidth } from "hooks/useWindowWidth";

export const ExpertsView: FC = () => {
  const {
    experts,
    isLoading,
    pagination,
    setOption,
    option,
    searchExperts,
    fetchExperts,
  } = useExperts();

  const { isMobile } = useWindowWidth();

  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (!searchText) {
      pagination.setPage(1);
      fetchExperts();
    }
  }, [searchText]);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex>
          <SectionHeader text="Specjaliści" />
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap="20px"
          width="fit-content"
          flexGrow="1"
          mb="20px"
          flexWrap="wrap"
        >
          <Flex alignItems="center" gap="10px" flexShrink={[1, 1, 0]}>
            <P fontWeight={700} variant="body">
              Sortuj według:
            </P>
            <Select
              selectedOptions={USER_SELECT_OPTIONS.find(
                (el) => el.value === option,
              )}
              options={USER_SELECT_OPTIONS}
              onChange={(e: IOption) => {
                setOption(e.value as TUserSelectOptions);
              }}
            />
          </Flex>
          <TextField
            width={isMobile ? "100%" : "400px"}
            hideError
            placeholder="Wyszukaj specjalistę po: imię, nazwisko, telefon"
            iconBackgroundColor={theme.palette.primary.main}
            icon={<SearchIcon />}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onIconClick={() => searchExperts(searchText)}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="10px">
        {isLoading ? (
          <Spinner />
        ) : experts.length > 0 ? (
          experts.map((expert) => (
            <ExpertTile key={expert.id} expert={expert} />
          ))
        ) : (
          <H4 variant="h4" color="primary" textAlign="center">
            Brak specjalistów do wyświetlenia
          </H4>
        )}
        {experts.length > 10 && <Pagination paginationHook={pagination} />}
      </Flex>
    </Flex>
  );
};

import { Flex } from "components";
import { FC } from "react";
import { HarmonogramForm } from "./components/HarmonogramForm";

export const ForDoctorView: FC = () => {
  return (
    <Flex flexDirection="row" gap="20px" width="100%">
      <HarmonogramForm />
    </Flex>
  );
};

import { RESEARCH_CARD, RESEARCH_CARDS } from "constants/paths";
import { serialize } from "object-to-formdata";
import {
  ICreateResearchCard,
  IResearchCardResponse,
  IUpdateReaserchCard,
} from "types/researchCard";
import { http } from "utilities/http";

export const getResearchCard = async (id: string | number) => {
  return await http<{ data: IResearchCardResponse }>(RESEARCH_CARD(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createResearchCard = (researchCard: ICreateResearchCard) =>
  http<{ data: IResearchCardResponse }>(RESEARCH_CARDS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ individual_medical_record: researchCard }),
  });

export const updateResearchCard = (
  id: string | number,
  researchCard: IUpdateReaserchCard,
) =>
  http<{ data: IResearchCardResponse }>(RESEARCH_CARD(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ individual_medical_record: researchCard }),
  });

import React, { useState, useEffect } from "react";
import { Flex, Box } from "components";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItem } from "./ListItem";
import { IModalHook } from "types/common";

export interface IMenuItem {
  path?: string;
  label: string;
  access?: string[];
  type?: string;
  icon?: React.ReactNode;
  modalHook?: IModalHook;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick?: () => void;
  view?: "horizontal" | "vertical";
  variant?: "sidebar" | "navbar";
  isExpanded?: boolean;
}

const NavigationLink = styled(NavLink)`
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.primary};
    text-decoration: none;
  `};
`;

const Icon = styled(Box)<{ active: boolean }>`
  svg {
    height: 40px;
    width: auto;
  }
  ${({ active, theme }) => `
    > :first-child {
      border-color: ${active ? "white" : theme.palette.primary.main};
      path {
        fill: ${active ? "white" : theme.palette.primary.main};
      }
    }
  `};
`;

interface IPreparedListItem {
  active: boolean;
  onClick: () => void;
}

export const Menu = ({
  items,
  onMenuIconClick,
  view = "horizontal",
  variant = "navbar",
  isExpanded,
}: IMenu) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [active, setIsActive] = useState<string>(pathname || "");
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const userRole = !!role ? role : "guest";

  const handleOnClick = (path?: string) => {
    onMenuIconClick && onMenuIconClick();
  };

  useEffect(() => {
    setIsActive(pathname);
  }, [pathname]);

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label, access, icon, modalHook }) => {
      if (!access?.includes(userRole)) return;
      const PreparedListItem = ({ active, onClick }: IPreparedListItem) => {
        return (
          <ListItem active={active} onClick={onClick} variant={variant}>
            {icon && (
              <Icon id="icon" active={active}>
                {icon}
              </Icon>
            )}
            {(isExpanded || variant === "navbar") && label}
          </ListItem>
        );
      };

      if (modalHook) {
        const { setIsOpen } = modalHook;
        return (
          <PreparedListItem
            active={false}
            onClick={() => setIsOpen(true)}
            key={label}
          />
        );
      }
      if (path) {
        const isActive = active === path;
        return (
          <NavigationLink to={path} key={label}>
            <PreparedListItem
              active={isActive}
              onClick={() => handleOnClick(path)}
            />
          </NavigationLink>
        );
      }
    });
  };

  return (
    <Flex
      flexDirection={view === "horizontal" ? "column" : "row"}
      justifyContent={view === "horizontal" ? "center" : ""}
      paddingX={variant === "sidebar" ? "10px" : ""}
      gap="5px"
      width={variant === "sidebar" ? "100%" : ""}
    >
      {renderItems()}
    </Flex>
  );
};

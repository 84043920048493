import axios from "axios";
import React, { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import {
  createResearchCard,
  getResearchCard,
  updateResearchCard,
} from "services/researchCard";
import { IIcdCodes, IResearchCardForm } from "types/researchCard";

export interface IResearchCardContext {
  formValues: IResearchCardForm;
  selectedIds: IIcdCodes[];
  setSelectedIds: Dispatch<SetStateAction<IIcdCodes[]>>;
  createdCardId: string | number;
  isLoading: boolean;
  isSaving: boolean;
  setValue: ({ key, value }: ISetValue) => void;
  fetchData: (id: string | number) => Promise<void>;
  createCard: (appId: string | number) => Promise<void>;
  updateCard: (id: string | number, appId: string | number) => Promise<void>;
}

const defaultValues: IResearchCardContext = {
  isLoading: false,
  selectedIds: [],
  isSaving: false,
  createdCardId: "",
  setSelectedIds: () => {},
  setValue: () => {},
  fetchData: async () => {},
  createCard: async () => {},
  updateCard: async () => {},
  formValues: {
    recognition_main_symptoms: "",
    recognition_patient_symptoms: "",
    recognition_note: "",
    interview_operations: "",
    interview_injuries: "",
    interview_drugs: "",
    interview_diseases: "",
    interview_weight: "",
    interview_note: "",
    examination_functional_status: "",
    examination_icf_code: "",
    detailed_examination_description_of_disturbed_function_structures: "",
    detailed_examination_structural_tests: "",
    detailed_examination_contraindications: "",
    initial_diagnosis: "",
    therapy_plan: "",
    treatment_summary: "",
  },
};

export const ResearchCardContext =
  React.createContext<IResearchCardContext>(defaultValues);

interface ISetValue {
  key: keyof IResearchCardForm;
  value: string;
}

interface IProvider {
  children: React.ReactNode;
}

const ReaserchCardProvider = ({ children }: IProvider) => {
  const [formValues, setFormValues] = React.useState<IResearchCardForm>(
    defaultValues.formValues,
  );
  const [selectedIds, setSelectedIds] = React.useState<IIcdCodes[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(
    defaultValues.isLoading,
  );
  const [isSaving, setIsSaving] = React.useState<boolean>(
    defaultValues.isSaving,
  );
  const [createdCardId, setCreatedCardId] = React.useState<number | string>(
    defaultValues.createdCardId,
  );

  const setValue = ({ key, value }: ISetValue) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const fetchData = async (id: string | number) => {
    setIsLoading(true);
    try {
      const result = await getResearchCard(id);
      if (!result) return;
      setFormValues({
        recognition_main_symptoms: result.data.recognition_main_symptoms_html,
        recognition_patient_symptoms:
          result.data.recognition_patient_symptoms_html,
        recognition_note: result.data.recognition_note_html,
        interview_operations: result.data.interview_operations_html,
        interview_injuries: result.data.interview_injuries_html,
        interview_drugs: result.data.interview_drugs_html,
        interview_diseases: result.data.interview_diseases_html,
        interview_weight: result.data.interview_weight_html,
        interview_note: result.data.interview_note_html,
        examination_functional_status:
          result.data.examination_functional_status_html,
        examination_icf_code: result.data.examination_icf_code_html,
        detailed_examination_description_of_disturbed_function_structures:
          result.data
            .detailed_examination_description_of_disturbed_function_structures_html,
        detailed_examination_structural_tests:
          result.data.detailed_examination_structural_tests_html,
        detailed_examination_contraindications:
          result.data.detailed_examination_contraindications_html,
        initial_diagnosis: result.data.initial_diagnosis_html,
        therapy_plan: result.data.therapy_plan_html,
        treatment_summary: result.data.treatment_summary_html,
      });
      setSelectedIds(result.data.icd10_codes);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createCard = async (appId: string | number) => {
    setIsSaving(true);
    let ids: number[] = [];

    selectedIds.map((el) => {
      return ids.push(Number(el.id));
    });
    try {
      const result = await createResearchCard({
        ...formValues,
        appointment_id: appId,
        icd10_code_ids: ids,
      });
      if (!result) return;
      setCreatedCardId(result.data.id);
      toast.success("Karta zapisana poprawnie");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  const updateCard = async (id: string | number, appId: string | number) => {
    setIsSaving(true);
    let ids: number[] = [];

    selectedIds.map((el) => {
      return ids.push(Number(el.id));
    });

    try {
      const result = await updateResearchCard(id, {
        ...formValues,
        appointment_id: appId,
        icd10_code_ids: ids,
      });
      if (!result) return;
      toast.success("Karta zapisana poprawnie");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ResearchCardContext.Provider
      value={{
        formValues,
        setValue,
        fetchData,
        createCard,
        updateCard,
        isLoading,
        isSaving,
        selectedIds,
        setSelectedIds,
        createdCardId,
      }}
    >
      {children}
    </ResearchCardContext.Provider>
  );
};

export default ReaserchCardProvider;

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import "moment/locale/en-gb";
import { Formats, Components } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import { StyledCalendar } from "./Calendar.styled";
import { IAvailabilityEvent } from "types/calendar";
import {
  MyWeekHeader,
  AvailabilityEvent,
  Toolbar,
  handleNavigate,
  timeGutterFormat,
  defaultProps,
  resizeEvent,
  handleSelectSlot,
  moveEvent,
  availabilityEventStyleGetter,
  mergeOverlappingEvents,
  fetchInitialWeeks,
} from "./Calendar.functions";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Flex } from "components/Flex";
import { Button } from "components/_form";
import { Box } from "components/Box";
import { Modal } from "components/Modal";
import { P } from "components/Typography";
import { IWeek } from "types/week";
import { parseEventsToWeeks, parseWeeksToEvents } from "utilities/week";
import { getWeeks } from "services/week";

const DragAndDropCalendar = withDragAndDrop(StyledCalendar);

const mockWeeks: IWeek["data"][] = [
  {
    id: 2,
    year: "2023",
    week_number: 8,
    mon: "22222222222222222222222",
    tue: "22222222222221111222222",
    wed: "22222222222222222222222",
    thu: "22222222200002222222222",
    fri: "22222222222222222222222",
    sat: "22222222222222222222222",
    sun: "22222222222222222222222",
    user: {},
    appointments: [],
  },
];

const mockEvents: IAvailabilityEvent[] = [
  {
    id: "1",
    start: new Date("2023-02-21T08:00:00"),
    end: new Date("2023-02-21T17:00:00"),
  },

  {
    id: "3",
    start: new Date("2023-02-20T18:00:00"),
    end: new Date("2023-02-20T20:00:00"),
  },
];

export const AvailabilityCalendar = () => {
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));
  const [events, setEvents] = useState<IAvailabilityEvent[]>([]);
  const [eventToDelete, setEventToDelete] =
    useState<IAvailabilityEvent | null>();
  const [initialEvents, setInitialEvents] = useState<IAvailabilityEvent[]>([]);
  const [initialWeeks, setInitialWeeks] = useState<IWeek["data"][]>(mockWeeks);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<
    "cancel" | "save" | "delete" | null
  >(null);

  const clickRef = useRef<number | null>(null);

  const { id } = JSON.parse(localStorage.getItem("currentUser") || "{}");

  useEffect(() => {
    fetchInitialWeeks(id).then((response) => {
      if (!response) return;
      setInitialWeeks(response);
      setEvents(parseWeeksToEvents(response));
      setInitialEvents(parseWeeksToEvents(response));
    });
  }, []);

  useEffect(() => {
    return () => {
      if (clickRef.current !== null) {
        window.clearTimeout(clickRef.current);
      }
    };
  }, []);

  const onDoubleClickEvent = useCallback((calEvent: IAvailabilityEvent) => {
    if (clickRef.current !== null) {
      window.clearTimeout(clickRef.current);
    }
    clickRef.current = window.setTimeout(() => {
      setEventToDelete(calEvent);
      setModalType("delete");
      setShowModal(true);
    }, 250);
  }, []);

  const components: Components<IAvailabilityEvent> = {
    toolbar: () => (
      <Toolbar currentWeek={currentWeek} setCurrentWeek={setCurrentWeek} />
    ),
    week: {
      event: AvailabilityEvent,
      header: MyWeekHeader,
    },
  };

  const formats: Formats = {
    timeGutterFormat,
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "cancel":
        return (
          <Flex p={15} flexDirection="column">
            <P variant="body">Czy na pewno chcesz cofnąć wszystkie zmiany?</P>
            <Flex justifyContent="end" alignItems="center" mt={30}>
              <Box mr={15}>
                <Button
                  onClick={() => {
                    setShowModal(false);
                    setModalType(null);
                  }}
                >
                  Nie
                </Button>
              </Box>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setModalType(null);
                  setEvents(initialEvents);
                }}
              >
                Tak
              </Button>
            </Flex>
          </Flex>
        );
      case "delete":
        return (
          <Flex p={15} flexDirection="column">
            <P variant="body">Usunąć interwał?</P>
            <Flex justifyContent="end" alignItems="center" mt={30}>
              <Box mr={15}>
                <Button
                  onClick={() => {
                    setShowModal(false);
                    setModalType(null);
                  }}
                >
                  Nie
                </Button>
              </Box>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setModalType(null);
                  if (!eventToDelete) return;
                  const filteredEvents = events.filter(
                    (event) => event.id !== eventToDelete.id,
                  );
                  setEvents(filteredEvents);
                  setEventToDelete(null);
                }}
              >
                Tak
              </Button>
            </Flex>
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      <DragAndDropCalendar
        {...defaultProps}
        events={events}
        formats={formats}
        components={components}
        onNavigate={handleNavigate(setCurrentWeek)}
        date={currentWeek.toDate()}
        resizable
        onEventResize={resizeEvent(setEvents)}
        onSelectSlot={handleSelectSlot(setEvents)}
        selectable
        onEventDrop={moveEvent(setEvents)}
        eventPropGetter={availabilityEventStyleGetter}
        onDoubleClickEvent={onDoubleClickEvent}
      />
      <Flex justifyContent="end" alignItems="center" mt={30}>
        <Box mr={15}>
          <Button
            onClick={() => {
              setModalType("cancel");
              setShowModal(true);
            }}
          >
            Cofnij wszystkie zmiany
          </Button>
        </Box>

        <Button onClick={() => {}}>Zapisz</Button>
      </Flex>
      <Modal isOpen={showModal}>{renderModalContent()}</Modal>
    </Flex>
  );
};

import {
  MEDICAL_RECOMMENDATION,
  MEDICAL_RECOMMENDATIONS,
} from "constants/paths";
import { IRecommendation } from "types/recommendation";
import { http } from "utilities/http";
import { serialize } from "object-to-formdata";

export const updateRecommendation = (
  medical_recommendation: Omit<IRecommendation, "id">,
  id: string | number,
) =>
  http<{ data: IRecommendation }>(MEDICAL_RECOMMENDATION(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ medical_recommendation: medical_recommendation }),
  });

export const createRecomendation = (
  medical_recommendation: Omit<IRecommendation, "id">,
) =>
  http<{ data: IRecommendation }>(MEDICAL_RECOMMENDATIONS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ medical_recommendation: medical_recommendation }),
  });

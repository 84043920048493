import axios from "axios";
import { Card, Flex, Line, Spinner } from "components";
import { DotLine } from "components/DotLine";
import { H1 } from "components/Typography";
import { Button } from "components/_form";
import { IOption } from "components/_form/Select/Select";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAppointment } from "services/appointment";
import { getBlogs } from "services/knowledge";
import {
  createRecomendation,
  updateRecommendation,
} from "services/recommendation";
import { updateUser } from "services/user";
import { IBlog } from "types/knowledge";
import { IRecommendation, Recommendation } from "types/recommendation";
import { SLine } from "./AppointmentResearchCardView.styled";
import { BlogsSelector } from "./components/BlogsSelector";
import { MedicalRecommendation } from "./components/MedicalRecommendation";
import { Section } from "./components/Section";
import { ResearchCardContext } from "./context/ResearchCardContext";
import {
  DetailedExaminationForm,
  ExaminationForm,
  InitialDiagnosisForm,
  InterviewForm,
  RecognitionForm,
  TherapyPlanForm,
  TreatmentSummaryForm,
} from "./data/SectionsData";

export const AppointmentResearchCardView = () => {
  const {
    fetchData,
    updateCard,
    createCard,
    isLoading,
    isSaving,
    createdCardId,
  } = useContext(ResearchCardContext);
  const { appId } = useParams();
  const [cardId, setCardId] = useState<string | number>("");
  const [userId, setUserId] = useState<string | number>("");
  const [userBlogs, setUserBlogs] = useState<IOption[]>([]);
  const [recommendation, setRecommendation] = useState<
    Omit<IRecommendation, "appointment_id">
  >({ id: "", body: "" });

  const fetchAppointment = async () => {
    if (!appId) return;
    try {
      const response = await getAppointment(appId);
      if (!response) return;
      if (!!response.data.individual_medical_record)
        setCardId(response.data.individual_medical_record.id);
      if (!!response.data.medical_recommendation)
        setRecommendation(response.data.medical_recommendation);
      setUserId(response.data.patient.id);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  const fetchUserBlogs = async () => {
    try {
      const response = await getBlogs({ user_id: userId });
      if (!response) return;
      const options: IOption[] = response.data.map((e) => {
        return { label: e.title, value: String(e.id) };
      });
      setUserBlogs(options);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    fetchAppointment();
  }, []);

  useEffect(() => {
    if (!!cardId) fetchData(cardId);
  }, [cardId]);

  useEffect(() => {
    if (!!userId) fetchUserBlogs();
  }, [userId]);

  const updateBlogs = async () => {
    const ids: string[] = userBlogs.map((e) => {
      return e.value;
    });
    const userData = {
      user: {
        blog_ids: ids,
      },
    };
    if (!ids.length) return;
    try {
      const response = updateUser(Number(userId), userData);
      if (!response) return;
    } catch (e) {
      toast.error(e.message);
    }
  };

  const addRecommendation = async () => {
    if (!appId) return;
    try {
      const response = await createRecomendation({
        appointment_id: appId,
        body: recommendation.body,
      });
      if (!response) return;
      setRecommendation((prev) => {
        return { id: response.data.id, body: prev.body };
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const editRecommendation = async () => {
    if (!appId) return;
    try {
      const response = await updateRecommendation(
        { appointment_id: appId, body: recommendation.body },
        recommendation.id,
      );
      if (!response) return;
      setRecommendation((prev) => {
        return { id: response.data.id, body: prev.body };
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleClick = () => {
    if (cardId) updateCard(cardId, appId ?? "");
    else {
      if (!!createdCardId) updateCard(createdCardId, appId ?? "");
      else createCard(appId ?? "");
    }
    updateBlogs();
    if (!!recommendation.id) editRecommendation();
    else addRecommendation();
  };

  return (
    <Flex flexDirection="column" width="100%">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <H1 variant="h1">Karta Badania</H1>
          <SLine />
          <Section data={RecognitionForm} isCodeSelectorVisible />
          <SLine />
          <Section data={InterviewForm} />
          <SLine />
          <Section data={ExaminationForm} />
          <SLine />
          <Section data={DetailedExaminationForm} />
          <SLine />
          <Section data={InitialDiagnosisForm} />
          <SLine />
          <Section data={TherapyPlanForm} />
          <SLine />
          <Section data={TreatmentSummaryForm} />
          <SLine />
          <Card overflow="visible">
            <MedicalRecommendation
              value={recommendation.body}
              setRecommendation={setRecommendation}
            />
            <BlogsSelector userBlogs={userBlogs} setUserBlogs={setUserBlogs} />
          </Card>
          <Flex flexDirection="row" width="100%" my={3} justifyContent="center">
            <Button
              label={isSaving ? "Ładowanie" : "Zapisz"}
              variant="accent"
              onClick={handleClick}
              disabled={isSaving}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

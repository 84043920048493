import { FC, useState } from "react";
import { Box, Flex, Spinner } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation/authSchemas";
import { ILogin } from "types/forms/auth";
import { login } from "api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H1, P } from "components/Typography/Typography";
import { Logo, StyledCard } from "./components/Auth.Styled";

const LinkItem = styled(P)`
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
    text-decoration-line: underline;
  `}
`;

const defaultValues = {
  email: "",
  password: "",
};

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm<ILogin>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setError("");
    setIsLoading(true);

    try {
      const response = await login(data);
      if (!response) return;
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      if (
        (!response.data.profile.pesel ||
          !response.data.profile.street_address ||
          !response.data.profile.zip ||
          !response.data.profile.city) &&
        response.data.role === "user"
      ) {
        localStorage.setItem("showModal", "true");
      }
      navigate("/app/appointments");
    } catch (err: any) {
      if (err && err.message) {
        toast.error(err.message);
        setError(err.message);
        reset(defaultValues);
      }
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Flex justifyContent="center" alignItems="center" width="100%">
      <StyledCard>
        <Logo />
        <Flex flexDirection="column" width="100%" gap="20px">
          <Flex flexDirection="column" width="100%">
            <H1 variant="h1" textAlign="center" color="primary">
              Logowanie
            </H1>
            <P variant="body" color="primary" textAlign="center">
              Zaloguj się, abyś mógł sprawdzić nadchodzące wizyty.
            </P>
          </Flex>
          <form onSubmit={onSubmit}>
            <Flex flexDirection="column" width="100%" gap="20px">
              <Box width="100%">
                <TextField
                  required
                  label={t("eMail") + ":"}
                  {...register("email")}
                  error={t(errors.email?.message || "")}
                />
                <TextField
                  required
                  type="password"
                  label={t("password") + ":"}
                  {...register("password")}
                  error={t(errors.password?.message || "")}
                />
                <Flex justifyContent="space-between" flexWrap="wrap" gap="5px">
                  <Button
                    onClick={() => navigate("/register")}
                    bordered
                    textOnly
                    variant="accent"
                    label={`${t("buttons.dontHaveAccount")} ${t(
                      "buttons.singUp",
                    )}`}
                  />
                  <Button
                    onClick={() => navigate("/password-reset")}
                    bordered
                    textOnly
                    label={t("buttons.resetingPassword")}
                  />
                </Flex>
              </Box>

              <Flex justifyContent="center">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    variant="secondary"
                    type="submit"
                    disabled={!formState.isValid}
                    label={t("buttons.singIn")}
                  />
                )}
              </Flex>
            </Flex>
          </form>
        </Flex>
      </StyledCard>
    </Flex>
  );
};

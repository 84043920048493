import styled from "styled-components";
import { H1, H2 } from "components/Typography";
import { ReactComponent as GoodIcon } from "assets/icons/ico-good.svg";
import { ReactComponent as InfoIcon } from "assets/icons/ico-info.svg";
import { ReactComponent as WrongIcon } from "assets/icons/ico-wrong.svg";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import { TPaymentStatus } from "types/payment";
import { Flex } from "components";

const MediumH1 = styled(H1)`
  font-size: 32px;
`;

interface IResponseInfo {
  response: TPaymentStatus | "none";
  retryPayment: () => Promise<void>;
  navigateToSurvey: () => void;
  showSurveyButton: boolean;
}

export const ResponseInfo = ({
  response,
  retryPayment,
  navigateToSurvey,
  showSurveyButton,
}: IResponseInfo) => {
  const navigate = useNavigate();

  return (
    <>
      {response &&
        {
          completed: <GoodIcon />,
          canceled: <WrongIcon />,
          pending: <InfoIcon />,
          waiting_for_confirmation: <InfoIcon />,
          none: null,
        }[response]}
      <MediumH1 variant="h1" mt={20}>
        {response &&
          {
            completed: "Dziękujemy. Płatność zakończona sukcesem.",
            canceled: "Przepraszamy. Płatność nie powiodła się.",
            pending: "Dziękujemy. Twoja płatność jest przetwarzana.",
            waiting_for_confirmation:
              "Dziękujemy. Twoja płatność jest przetwarzana.",
            none: "Strona, której szukasz, nie istnieje.",
          }[response]}
      </MediumH1>
      <H2 variant="h2" fontWeight={400} mt={20} mb={50}>
        {response &&
          {
            completed:
              " Potwierdzenie Twojej wizyty wysłaliśmy na Twój adres e-mail.",
            canceled: null,
            pending:
              " Potwierdzenie Twojej wizyty wysłaliśmy na Twój adres e-mail.",
            waiting_for_confirmation:
              " Potwierdzenie Twojej wizyty wysłaliśmy na Twój adres e-mail.",
            none: null,
          }[response]}
      </H2>
      <Flex gap="10px" justifyContent="center">
        {showSurveyButton && (
          <Button
            variant="primary"
            bordered
            label="Wypełnij ankietę"
            onClick={() => navigateToSurvey()}
          />
        )}
        <Button
          variant="primary"
          onClick={() =>
            response === "canceled"
              ? retryPayment()
              : navigate("/app/appointments")
          }
        >
          {response === "canceled" ? "Ponów próbę" : "Wróć do listy wizyt"}
        </Button>
      </Flex>
    </>
  );
};

import { IBox } from "components/Box/Box";
import styled, { css } from "styled-components";
import { theme } from "styles/theme";
import { flexbox, space, layout } from "styled-system";
import { rgba } from "utilities/rgba";

const { palette } = theme;

const backgroundColorVariantsMap = {
  primary: palette.neutral.white,
  light: palette.neutral.veryLight,
  coloured: palette.primary.main,
  disabled: rgba("#C9C8D3", 0.75),
};

const colorVariantsMap = {
  primary: palette.text.primary,
  light: palette.text.primary,
  coloured: palette.neutral.white,
  disabled: palette.neutral.white,
};

interface IColouredBox extends IBox {
  variant?: "primary" | "light" | "coloured" | "disabled";
  shadow?: boolean;
}

export const ColouredBox = styled.div<IColouredBox>(
  ({ variant = "primary", shadow }) => css`
    color: ${colorVariantsMap[variant]};
    background-color: ${backgroundColorVariantsMap[variant]};
    border-radius: 15px;
    ${shadow && `box-shadow: 0px 5px 55px rgba(41, 35, 79, 0.15);`}
  `,
  space,
  flexbox,
  layout,
);

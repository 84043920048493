import { Flex } from "components/Flex";
import { H2, H3, P } from "components/Typography/Typography";
import { Button } from "components/_form";
import styled from "styled-components";
import { Box } from "components/Box";
import { useNavigate } from "react-router-dom";
import { getUserFullName } from "utilities/appointment";
import { Avatar } from "components";
import { format, isToday } from "date-fns";
import { VerticalLine } from "components/Line";
import { IUser } from "types/user";

export const StyledHour = styled.button<{ active?: boolean }>`
  border-radius: 15px;
  padding: 4px 7px 5px;
  width: 50px;
  height: 26px;
  text-align: center;
  cursor: default;
  margin-left: 5px;
  ${({ theme, active }) => `
    color: ${active ? theme.palette.neutral.white : theme.palette.neutral.dark};
    background-color: ${
      active ? theme.palette.primary.main : theme.palette.neutral.white
    };
    border: 1px solid ${theme.palette.neutral.lightGrey};
  `};
`;

const Wrapper = styled(Flex)`
  min-height: 120px;
  border-radius: 15px;
  box-shadow: 0px 5px 55px rgba(41, 35, 79, 0.15);
`;

interface IExpertTile {
  expert: IUser;
}

interface IDayTab {
  date: Date;
}

const DayTab = ({ date }: IDayTab) => {
  return (
    <StyledHour active={isToday(date)}>
      <P variant="body2" fontWeight={600}>
        {format(date, "dd.MM")}
      </P>
    </StyledHour>
  );
};

export const ExpertTile = ({ expert }: IExpertTile) => {
  const navigate = useNavigate();

  return (
    <Wrapper
      width="100%"
      justifyContent="space-between"
      flexWrap="wrap"
      p={3}
      gap="5px"
    >
      <Flex alignItems="center">
        <Flex alignItems="center" justifyContent="center" mx={2}>
          <Avatar size="medium" url={expert.profile.avatar} />
        </Flex>
        <VerticalLine />

        <Flex ml={2} flexDirection="column" flexWrap="wrap">
          <H2 variant="h2" color="coloured">
            {getUserFullName(expert)}
          </H2>
          <Flex gap="5px">
            <P variant="body" color="grey">
              Tel.:
            </P>
            <P variant="body" color="primary" mr={1}>
              {expert.profile.phone_number}
            </P>
          </Flex>

          {expert.specialities.length ? (
            <Flex gap="5px">
              <P variant="body">zawód:</P>
              <P variant="body" fontWeight={700}>
                {expert.specialities
                  .map((speciality) => speciality.name)
                  .join(", ")}
              </P>
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
      <Flex
        width={["100%", "auto"]}
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Box>
          <Button
            onClick={() => {
              navigate(`/app/experts/${expert.id}`);
            }}
          >
            Szczegóły
          </Button>
        </Box>
      </Flex>
    </Wrapper>
  );
};

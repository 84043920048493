import { Flex } from "components";
import styled from "styled-components";

export const StyledTab = styled.button`
  border-radius: 15px;
  padding: 4px 15px 5px;

  min-height: 26px;
  max-height: fit-content;
  text-align: center;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 5px;
  ${({ theme }) => `
    color: ${theme.palette.neutral.white};
    background-color: ${theme.palette.primary.main};
  `};
`;

export const Wrapper = styled.div`
  justify-content: space-between;

  & > ${Flex} {
    & > div:first-child {
      flex: 3;
    }

    & > div:last-child {
      flex: 1;
    }
  }
`;

export const StyledSpan = styled.span<{ blue?: boolean }>`
  ${({ theme, blue }) => `
    color: ${blue && theme.palette.accent.main};
    cursor: pointer;
  `};
  font-weight: bold;
`;

export const SurveyButton = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: 10px 12px;
  gap: 7px;
  border-radius: 15px;
  width: fit-content;
  ${({ theme }) =>
    `border: 1px solid ${theme.palette.neutral.lightGrey}; color: ${theme.palette.primary.main};`}
  svg {
    width: 25px;
    height: auto;
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;

import { useState, useEffect } from "react";
import { createNote, getNote, updateNote } from "services/note";
import { INote } from "types/appointment";
import { IUpdateNote } from "types/forms/note";

interface INotes {
  noteId?: number;
  appointmentId: number;
}

export const useNotes = ({ noteId, appointmentId }: INotes) => {
  const [note, setNote] = useState<INote["data"]>();
  const [content, setContent] = useState("");

  const preparePayload = (content: string): IUpdateNote => {
    return {
      note: {
        content,
        appointment_id: appointmentId,
      },
    };
  };

  const handleUpdateNote = async (content: string) => {
    try {
      const payload = preparePayload(content);
      const response = noteId
        ? await updateNote(payload, noteId)
        : await createNote(payload);
      if (!response) return;
      setNote(response.data);
    } catch (err: any) {
      console.error(err);
    }
  };

  const fetchNote = async () => {
    try {
      if (!noteId) return;
      const response = await getNote(noteId);
      setNote(response?.data);
    } catch (err: any) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchNote();
  }, []);

  useEffect(() => {
    setContent(note?.content ?? "");
  }, [note]);

  return {
    note,
    setContent,
    content,
    handleUpdateNote,
  };
};

import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { DefaultTFuncReturn } from "i18next";

interface IFieldLabel {
  error?: string | DefaultTFuncReturn;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
}

export const FieldLabel = styled.label<IFieldLabel>`
  cursor: pointer;
  text-transform: capitalize;
  ${({ theme, disabled }) => `
    display: inline-block;
    width: 95%;    
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: ${theme.palette.text.primary};
    ${disabled && `color: ${rgba(theme.palette.text.primary, 0.4)}`};
    span:nth-child(1) {
      color: ${theme.palette.error.main};
    }
  `}
`;

import { Card, Flex } from "components";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
  margin: 0 auto;
  flex-flow: column nowrap;
  gap: 60px;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-inline: 120px;

  @media only screen and (max-width: 1250px) {
    padding-inline: 90px;
  }

  @media only screen and (max-width: 991px) {
    padding-inline: 60px;
  }

  @media only screen and (max-width: 768px) {
    padding-inline: 30px;
  }

  @media only screen and (max-width: 480px) {
    padding-inline: 10px;
  }

  > ${Flex}:first-child {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  > ${Flex}:last-child {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

export const ColorCardWrapper = styled(Flex)`
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const HomeImages = styled.img`
  border-radius: 15px;
  width: 100%;
`;

export const SideCard = styled(Flex)<{ img: string }>`
  justify-content: center;
  width: 100%;
  height: min-content;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ img }) => img});
  border-radius: 15px;
`;

export const MainText = styled.span`
  width: 100%;
  font-size: 20px;
  line-height: 32px;
`;

export const AccentColorCard = styled(Card)`
  ${({ theme }) =>
    `background-color: ${theme.palette.secondary.main}; color:${theme.palette.text.secondary};`}
  font-weight: 600;
  text-align: center;
  max-width: 300px;
  width: 100%;
`;

export const EULink = styled.a`
  all: unset;
  cursor: pointer;
`;

export const LogoEU = styled.img`
  max-width: 400px;
  width: 100%;
  border-radius: 15px;
`;

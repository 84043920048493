import { Card, Flex, Chat, Box, Line, Spinner } from "components";
import { H1 } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./AppointmentView.styled";
import { AppointmentDetailsMainPart } from "./AppointmentDetails/AppointmentDetailsMainPart";
import { useAppointmentView } from "hooks/useAppointmentView";
import { useNavigate, useParams } from "react-router-dom";
import { AppointmentDetailsSecondPart } from "./AppointmentDetails/AppointmentDetailsSecondPart";
import { Notes } from "components/Notes/Notes";
import moment from "moment";
import { Button } from "components/_form";
import { useState } from "react";
import { AppointmentCancelModal } from "./AppointmentDetails/AppointmentCancelModal";
import { AppointmentEditModal } from "./AppointmentDetails/AppointmentEditModal";
import { useWindowWidth } from "hooks/useWindowWidth";

export const AppointmentView = () => {
  const { isMobile } = useWindowWidth();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id = "" } = useParams<{ id: string }>();
  const {
    isLoading,
    appointment,
    recommendedBlogs,
    dictionaryBlogs,
    formHook: {
      control,
      formState: { errors },
      register,
    },
    old,
    conditions,
  } = useAppointmentView(id);

  if (!appointment) return <></>;

  const { shouldShowNotes, shouldShowChat } = conditions;
  const appointmentId = appointment.id;
  const noteId = appointment.note?.id;
  const today = moment(new Date());
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");

  return (
    <Wrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <AppointmentCancelModal
            onCancelClick={() => setOpenCancelModal(false)}
            isOpen={openCancelModal}
            appointmentId={appointment.id}
          />
          <AppointmentEditModal
            appointment={appointment}
            onCancelClick={() => setOpenEditModal(false)}
            isOpen={openEditModal}
          />
          <Flex flexDirection={["column", "column", "row"]} gap="30px">
            <Card>
              <Flex justifyContent="space-between" flexWrap="wrap" gap="10px">
                <H1 variant="h1" color="primary">
                  {!!appointment.service && appointment.service.name}
                </H1>
                {role === "expert" ? (
                  <Button
                    variant="accent"
                    disabled={appointment.canceled}
                    label="Wypełnij kartę badań podczas wizyty"
                    onClick={() => {
                      navigate(
                        `/app/appointments/${appointment.id}/research-card`,
                      );
                    }}
                  />
                ) : (
                  moment
                    .duration(
                      moment(appointment.date_and_time_of_appointment).diff(
                        today,
                      ),
                    )
                    .asHours() > 1 && (
                    <Flex gap="10px">
                      <Button
                        disabled={appointment.canceled}
                        variant="accent"
                        bordered
                        label="Zmień termin wizyty"
                        onClick={() => setOpenEditModal(true)}
                      />
                      <Button
                        disabled={appointment.canceled}
                        variant="red"
                        label="Odwołaj wizytę"
                        onClick={() => setOpenCancelModal(true)}
                      />
                    </Flex>
                  )
                )}
              </Flex>
              <Line />
              <AppointmentDetailsMainPart appointment={appointment} old={old} />
              <Line />
              <AppointmentDetailsSecondPart
                old={old}
                recommendedBlogs={recommendedBlogs}
                patient={appointment.patient}
                surveyType={appointment.service.survey_type}
                appointmentId={appointment.id}
                recommendations={appointment.medical_recommendation}
              />
            </Card>
            <Box>
              {shouldShowChat && (
                <Chat
                  expert={{
                    id: appointment.expert.id,
                    name:
                      appointment.expert.profile.first_name +
                      " " +
                      appointment.expert.profile.last_name,
                    avatar: appointment.expert.profile.avatar,
                  }}
                  patient={{
                    id: appointment.patient.id,
                    name:
                      appointment.patient.profile.first_name +
                      " " +
                      appointment.patient.profile.last_name,
                    avatar: appointment.patient.profile.avatar,
                  }}
                />
              )}
              {shouldShowNotes && (
                <Notes appointmentId={appointmentId} noteId={noteId} />
              )}
            </Box>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

import { P, H2 } from "components/Typography/Typography";
import { IBlog, IBlogs } from "types/knowledge";
import { useNavigate } from "react-router-dom";
import { StyledTab, SurveyButton } from "../AppointmentView.styled";
import { useTranslation } from "react-i18next";
import { TSurvey } from "types/service";
import { ReactComponent as SurveyIcon } from "assets/icons/ico-surveys.svg";
import { IRecommendation } from "types/recommendation";
import { Flex } from "components";
import { IUser } from "types/user";
import { getSurveyArray } from "utilities/getSurveyArray";

interface IAppointmentDetailsSecondPart {
  recommendedBlogs: IBlogs["data"];
  old: boolean;
  surveyType: TSurvey;
  patient: IUser;
  appointmentId: string | number;
  recommendations: IRecommendation;
}

interface BlogTab {
  blog: IBlog["data"];
}

const BlogTab = ({ blog }: BlogTab) => {
  const navigate = useNavigate();
  return (
    <StyledTab onClick={() => navigate(`/app/knowledge-base/${blog.slug}`)}>
      {blog.title}
    </StyledTab>
  );
};

export const AppointmentDetailsSecondPart = ({
  recommendedBlogs,
  old,
  patient,
  surveyType,
  recommendations,
}: IAppointmentDetailsSecondPart) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const survey = getSurveyArray(patient, surveyType);

  const handleNavigate = () => {
    if (!!survey.length) {
      navigate(`/app/survey/${surveyType}/${survey[survey.length - 1]}`);
    } else {
      navigate(`/app/survey/${surveyType}`);
    }
  };
  return (
    <>
      <Flex flexDirection="row" gap="10px" alignItems="center">
        <H2 variant="h2">{t("questionnaireView.header")}:</H2>
        {!survey.length || patient.user_must_repeat_surveys ? (
          <SurveyButton onClick={handleNavigate}>
            <SurveyIcon />
            <P fontWeight={600} variant="body">
              Wypełnij ankietę
            </P>
          </SurveyButton>
        ) : role === "user" ? (
          <SurveyButton onClick={handleNavigate}>
            <SurveyIcon />
            <P fontWeight={600} variant="body">
              Ankieta - {survey[survey.length - 1]}
            </P>
          </SurveyButton>
        ) : (
          <P variant="body">Brak ankiety</P>
        )}
      </Flex>
      <H2 variant="h2" mt={3}>
        {t("recommendations")}:
      </H2>
      <P
        variant="body"
        fontWeight={400}
        mb={3}
        color={old ? "dark" : "grey"}
        style={{ whiteSpace: "pre" }}
      >
        {!!recommendations
          ? recommendations.body
          : "Rekomendacje pojawią się, gdy lekarz je udostępni"}
      </P>
      <H2 variant="h2" mb={3}>
        {t("additionalKnowledgeBaseAcces")}:
      </H2>
      {recommendedBlogs.map((blog) => (
        <BlogTab blog={blog} key={blog.id} />
      ))}
    </>
  );
};

import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { AppLayout } from "layouts/AppLayout";
import { GuestLayout } from "layouts/GuestLayout";
import { Outlet, RouteObject } from "react-router-dom";
import { RegisterView } from "./views/auth/RegisterView";
import { LoginView } from "./views/auth/LoginView";
import { ResetPasswordView } from "./views/auth/ResetPasswordView";
import { AppointmentFormView } from "views/AppointmentFormView/AppointmentFormView";
import { TherapyView } from "views/TherapyView";
import { TestsView } from "views/TestsView/TestsView";
import { ForDoctorView } from "views/ForDoctor/ForDoctorView";
import { KnowledgeBaseView } from "views/KnowledgeBaseView/KnowledgeBaseView";
import { KnowledgeView } from "views/KnowledgeView";
import { PrivacyPolicyView } from "views/info/PrivacyPolicyView";
import { UsageRulesView } from "views/info/UsageRulesView";
import { RulesView } from "views/info/RulesView";
import { MyAccountView } from "views/MyAccountView/MyAccountView";
import { ConfirmEmailView } from "views/auth/ConfirmEmailView";
import { CustomView } from "views/CustomView";
import { AppointmentsView } from "views/AppointmentsView/AppointmentsView";
import { AppointmentView } from "views/AppointmentView/AppointmentView";
import { ContactView } from "views/info/ContactView";
import { JitsiMeetingView } from "views/JitsiMeeting/JitsiMeetingView";
import { FaqView } from "views/info/FaqView/FaqView";
import { HomeView } from "views/HomeView/HomeView";
import { NewPasswordView } from "views/auth/NewPasswordView";
import { ExpertsView } from "views/ExpertsView/ExpertsView";
import { PaymentSummaryView } from "views/PaymentSummaryView";
import { ExpertCalendarView } from "views/ExpertCalendarView/ExpertCalendarView";
import { AboutUsView } from "views/info/AboutUsView";
import { QuestionnaireView } from "views/SurveyView/QuestionnaireView";
import { ServicesView } from "views/ServicesView/ServicesView";
import { PatientsView } from "views/PatientsView/PatientsView";
import { PatientCardView } from "views/PatientCardView/PatientCardView";
import { ExpertView } from "views/ExpertView/ExpertView";
import { AppointmentResearchCardView } from "views/AppointmentResearchCardView/AppointmentResearchCardView";
import ReaserchCardProvider from "views/AppointmentResearchCardView/context/ResearchCardContext";
import { InfoLayout } from "layouts/InfoLayout";
import { AvailabilityCalendar } from "views/AppointmentsView/components/Calendar/AvailabilityCalendar";
import { ProjectsView } from "./views/ProjectsView";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <GuestLayout>
        <Outlet />
      </GuestLayout>
    ),
    children: [
      {
        index: true,
        element: <HomeView />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute isAuth>
        <GuestLayout>
          <Outlet />
        </GuestLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/register",
        element: <RegisterView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
      {
        path: "/new-password",
        element: <NewPasswordView />,
      },
      {
        path: "/login-confirm",
        element: <ConfirmEmailView />,
      },
      {
        path: "/projects",
        element: <ProjectsView />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "appointments",
        element: <AppointmentsView />,
      },
      {
        path: "appointments/:id",
        element: <AppointmentView />,
      },
      {
        path: "appointments/:appId/research-card",
        element: (
          <ReaserchCardProvider>
            <AppointmentResearchCardView />
          </ReaserchCardProvider>
        ),
      },
      {
        path: "add-appointment",
        element: <AppointmentFormView />,
      },
      {
        path: "add-appointment/:serviceType",
        element: <AppointmentFormView />,
      },
      {
        path: "add-appointment/:serviceType/:expert",
        element: <AppointmentFormView />,
      },
      {
        path: "experts",
        element: <ExpertsView />,
      },
      {
        path: "experts/:id",
        element: <ExpertView />,
      },
      {
        path: "recommendations",
        element: <TherapyView />,
      },
      {
        path: "tests",
        element: <TestsView />,
      },
      {
        path: "for-doctor",
        element: <ForDoctorView />,
      },
      {
        path: "my-account",
        element: <MyAccountView />,
      },
      {
        path: "expert-calendar",
        element: <ExpertCalendarView />,
      },
      {
        path: "expert-calendar/availability",
        element: <AvailabilityCalendar />,
      },
      {
        path: "survey/:type",
        element: <QuestionnaireView />,
      },
      {
        path: "survey/:type/:surveyId",
        element: <QuestionnaireView />,
      },
      {
        path: "patients",
        element: <PatientsView />,
      },
      {
        path: "patients/:id",
        element: <PatientCardView />,
      },
      { path: "payment-summary", element: <PaymentSummaryView /> },
      { path: "services", element: <ServicesView /> },
      {
        path: "knowledge-base",
        element: <KnowledgeBaseView />,
      },
      {
        path: "knowledge-base/:slug",
        element: <KnowledgeView />,
      },
    ],
  },
  {
    path: "/info",
    element: (
      <InfoLayout>
        <Outlet />
      </InfoLayout>
    ),
    children: [
      {
        path: "privacy-policy",
        element: <PrivacyPolicyView />,
      },
      {
        path: "usage-rules",
        element: <UsageRulesView />,
      },
      {
        path: "rules",
        element: <RulesView />,
      },
      {
        path: "help",
        element: <FaqView />,
      },
      {
        path: "kontakt",
        element: <ContactView />,
      },
      {
        path: "about-us",
        element: <AboutUsView />,
      },
      {
        path: ":slug",
        element: <CustomView />,
      },
      {
        path: "experts",
        element: <ExpertsView />,
      },
      {
        path: "experts/:id",
        element: <ExpertView />,
      },
      { path: "services", element: <ServicesView /> },
      {
        path: "knowledge-base",
        element: <KnowledgeBaseView />,
      },
      {
        path: "knowledge-base/:slug",
        element: <KnowledgeView />,
      },
    ],
  },
  {
    path: "/jitsi-meeting/:id",
    element: <JitsiMeetingView />,
  },
];

import { FC, useEffect, useState } from "react";
import { Box, Flex, Line, Link, Spinner } from "components";
import { Button, Checkbox, Radio, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "validation/authSchemas";
import { IRegister } from "types/forms/auth";
import { register as registerRec } from "api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getRegulations } from "api/regulations";
import { IRegulation } from "types/regulations";
import { Flatten } from "types/helper-types";
import { H1, H3, P } from "components/Typography/Typography";
import { InfoCard, LinkA, Logo, StyledCard } from "./components/Auth.Styled";
import { ConfirmRegistrationModal } from "./components/ConfirmRegistrationModal";
import { contactData } from "constants/contactData";
import { useWindowWidth } from "hooks/useWindowWidth";

export const RegisterView: FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regulations, setRegulations] = useState<IRegulation[]>();
  const [requiredRegulationIds, setRequiredRegulationIds] = useState<number[]>(
    [],
  );
  const [acceptedCommonRegulations, setAcceptedCommonRegulations] = useState<
    number[]
  >([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    formState: { errors },
  } = useForm<Flatten<IRegister>>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
      first_name: "",
      last_name: "",
      regulation_ids: [],
      role: "user",
      phone_number: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const handleCommonRegulations = (id: number) => {
    setAcceptedCommonRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const { first_name, last_name, phone_number, ...rest } = data;
    const payload = {
      ...rest,
      profile_attributes: { first_name, last_name, phone_number },
    };
    payload.regulation_ids = [
      ...data.regulation_ids,
      ...acceptedCommonRegulations,
    ].filter((v, i, a) => a.indexOf(v) === i);

    try {
      const response = await registerRec(payload);
      if (!response) return;
      setShowConfirmModal(true);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data.filter((v) => v.variant === "registration"));
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  useEffect(() => {
    if (!regulations) return;
    let temp: number[] = [];
    regulations.map((e) => {
      if (e.required) temp.push(e.id);
    });
    setRequiredRegulationIds(temp);
  }, [regulations]);

  const { isMobile } = useWindowWidth();

  return (
    <>
      <ConfirmRegistrationModal
        isOpen={showConfirmModal}
        onCancelClick={() => setShowConfirmModal(false)}
      />

      <Flex justifyContent="center" alignItems="center" width="100%">
        <StyledCard>
          <Logo />
          <Flex
            flexDirection="column"
            width="100%"
            gap="20px"
            alignItems="center"
          >
            <Flex flexDirection="column" width="100%">
              <H1 variant="h1" textAlign="center" color="primary">
                Rejestracja
              </H1>
              <P variant="body" color="primary" textAlign="center">
                Załóż konto, a będziesz mógł w pełni korzystać z funkcjonalności
                naszego portalu.
              </P>
            </Flex>
            <form onSubmit={onSubmit}>
              <Flex flexDirection="column" width="100%" gap="20px">
                <Box width="100%">
                  <TextField
                    required
                    label={t("eMail") + ":"}
                    placeholder={t("emailPlaceholder") + "..."}
                    {...register("email")}
                    error={t(errors.email?.message || "")}
                  />
                  <TextField
                    required
                    type="password"
                    label={t("password") + ":"}
                    placeholder={t("passwordPlaceholder") + "..."}
                    {...register("password")}
                    error={t(errors.password?.message || "")}
                  />
                  <TextField
                    required
                    type="password"
                    label={t("password_confirmation") + ":"}
                    placeholder={t("passwordConfirmationPlaceholder") + "..."}
                    {...register("password_confirmation")}
                    error={t(errors.password_confirmation?.message || "")}
                  />
                  <Flex gap="10px" flexDirection={["column", "column", "row"]}>
                    <TextField
                      required
                      label={t("first_name") + ":"}
                      placeholder={t("firstNamePlaceholder") + "..."}
                      {...register("first_name")}
                      error={t(errors.first_name?.message || "")}
                    />
                    <TextField
                      required
                      label={t("last_name") + ":"}
                      placeholder={t("lastNamePlaceholder") + "..."}
                      {...register("last_name")}
                      error={t(errors.last_name?.message || "")}
                    />
                  </Flex>
                  <TextField
                    required
                    label={t("phone") + ":"}
                    placeholder={t("phonePlaceholder") + "..."}
                    {...register("phone_number")}
                    error={t(errors.phone_number?.message || "")}
                  />
                </Box>

                <Flex flexDirection="column" width="100%">
                  {regulations?.map((regulation, index) =>
                    regulation.required ? (
                      <Controller
                        key={regulation.id}
                        control={control}
                        name={`regulation_ids.${index}`}
                        render={({ field: { onChange, value } }) => (
                          <Box
                            display="flex"
                            justifyContent="start"
                            mb={4}
                            alignItems="center"
                          >
                            <Checkbox
                              onChange={(val) => {
                                onChange(
                                  val.target.checked ? regulation.id : false,
                                );
                              }}
                              checked={!!value}
                            />
                            <Link
                              to={regulation.link && `/info${regulation.link}`}
                              target={regulation.link && "_blank"}
                            >
                              <Flex gap="5px">
                                {regulation.name}
                                {regulation.required && (
                                  <P color="red" variant="body">
                                    *
                                  </P>
                                )}
                              </Flex>
                            </Link>
                          </Box>
                        )}
                      />
                    ) : (
                      <Box
                        key={regulation.id}
                        display="flex"
                        justifyContent="start"
                        mb={4}
                        alignItems="center"
                      >
                        <Checkbox
                          onChange={() =>
                            handleCommonRegulations(regulation.id)
                          }
                          checked={
                            !!acceptedCommonRegulations.find(
                              (el) => el === regulation.id,
                            )
                          }
                        />
                        <Link
                          to={regulation.link && `/info${regulation.link}`}
                          target={regulation.link && "_blank"}
                        >
                          {regulation.name}
                        </Link>
                      </Box>
                    ),
                  )}
                </Flex>
                <Flex justifyContent="center">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={
                        !(
                          formState.isValid &&
                          requiredRegulationIds.join("") ===
                            watch("regulation_ids").join("")
                        ) || isLoading
                      }
                      label={t("buttons.createAccount")}
                    />
                  )}
                </Flex>
              </Flex>
            </form>
            <InfoCard>
              <P variant="body" textAlign="center">
                Nie chcesz się rejestrować?
              </P>
              <Flex
                flexDirection="row"
                gap="5px"
                flexWrap="wrap"
                justifyContent="center"
              >
                <P variant="body" textAlign="center">
                  Umów wizytę telefonicznie:
                </P>
                <LinkA href={`tel:${contactData.phoneNo}`}>
                  {contactData.phoneNo}
                </LinkA>
              </Flex>
            </InfoCard>
          </Flex>
        </StyledCard>
      </Flex>
    </>
  );
};

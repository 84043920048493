import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FieldLabel } from "components/_form";
import { ResponsiveBox } from "./HarmonogramForm.styled";
import styled from "styled-components";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import { rgba } from "../../../utilities/rgba";
import pl from "date-fns/locale/pl"; // the locale you want
registerLocale("pl", pl); // register it with the name you want

interface IDatePickerField extends ReactDatePickerProps {
  label: string;
  onChange: (val?: Date | null) => void;
}

export const StyledDatePicker = styled(DatePicker)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.medium};

    &:focus {
      border-color: ${theme.palette.primary.main};
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};
    }
  `}

  border-radius: 30px;
  outline: none;
  display: block;
  width: 100%;
  height: 39px;
  padding: 0 12px;
  font-size: 13px;
  text-align: center;
`;

export const DatePickerField: FC<IDatePickerField> = ({
  label,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ResponsiveBox>
      <FieldLabel>{t(label)}</FieldLabel>
      <StyledDatePicker
        onChange={(date) => {
          onChange(date instanceof Date ? date : null);
        }}
        dateFormat="dd-MM-yyyy"
        {...props}
        locale="pl"
        showWeekNumbers
      />
    </ResponsiveBox>
  );
};

import styled from "styled-components";

export const VerticalLine = styled.span`
  ${({ theme }) => `
	    display: flex;
      background-color: ${theme.palette.neutral.light};
      margin: 0 16px;
      width: 1px;
      height: 100%;
  `};
`;

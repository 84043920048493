import { Flex, Spinner } from "components";
import { H1, H4, P } from "components/Typography";
import { Select, TextField } from "components/_form";
import { theme } from "styles";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { PatientTile } from "./PatientTile";
import { getUsers } from "services/user";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IUser, TUserSelectOptions, USER_SELECT_OPTIONS } from "types/user";
import { getUserFullName } from "utilities/appointment";
import styled from "styled-components";
import { usePagination } from "hooks/usePagination";
import { Pagination } from "views/AppointmentsView/Pagination";
import { IOption } from "components/_form/Select/Select";
import { SectionHeader } from "components/SectionHeader";
import { useWindowWidth } from "hooks/useWindowWidth";

const GappedFlex = styled(Flex)`
  width: 100%;
  flex-flow: column nowrap;
  gap: 20px;
`;

export const PatientsView = () => {
  const [patients, setPatients] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [option, setOption] = useState<TUserSelectOptions>("alphabethic");
  const [searchText, setSearchText] = useState<string>("");
  const pagination = usePagination();
  const { isMobile } = useWindowWidth();

  const fetchPatients = async () => {
    setIsLoading(true);
    try {
      const filters = {
        role: "user",
        per_page: pagination.perPage,
        page: pagination.page,
        ...(option === "alphabethic"
          ? { alphabetically_direction: "ASC" }
          : { latest_direction: "DESC" }),
      };
      const response = await getUsers(filters);
      if (!response.data.length) return;
      setPatients(response.data);
      pagination.setPages(response.pagination?.pages ?? 1);
    } catch (err: any) {
      toast.error(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchPatients = async () => {
    setIsLoading(true);
    try {
      const response = await getUsers({
        role: "user",
        first_name: searchText,
      });
      const response2 = await getUsers({
        role: "user",
        last_name: searchText,
      });
      if (!response.data || !response2.data) return;
      setPatients([...response.data, ...response2.data]);
    } catch (e: any) {
      toast.error(e.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!searchText) {
      pagination.setPage(1);
      fetchPatients();
    }
  }, [searchText]);

  useEffect(() => {
    fetchPatients();
  }, [pagination.page, option]);

  useEffect(() => {
    if (!isLoading) window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isLoading]);

  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex width="fit-content">
          <SectionHeader text="Pacjenci" />
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap="20px"
          width="100%"
          flexGrow="1"
          mb="20px"
          flexWrap="wrap"
        >
          <Flex alignItems="center" gap="10px" flexShrink={[1, 1, 0]}>
            <P fontWeight={700} variant="body">
              Sortuj według:
            </P>
            <Select
              selectedOptions={USER_SELECT_OPTIONS.find(
                (el) => el.value === option,
              )}
              options={USER_SELECT_OPTIONS}
              onChange={(e: IOption) => {
                setOption(e.value as TUserSelectOptions);
              }}
            />
          </Flex>
          <TextField
            width={isMobile ? "100%" : "400px"}
            hideError
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Wyszukaj pacjenta po: imię, nazwisko"
            iconBackgroundColor={theme.palette.primary.main}
            icon={<SearchIcon />}
            onIconClick={() => searchPatients()}
          />
        </Flex>
      </Flex>
      {isLoading ? (
        <Spinner />
      ) : (
        <GappedFlex mb={3}>
          {patients.length > 0 ? (
            patients.map((e) => {
              return (
                <PatientTile
                  key={e.id}
                  fullName={getUserFullName(e)}
                  pesel={e.profile.pesel ?? "-"}
                  id={e.id}
                />
              );
            })
          ) : (
            <H4 variant="h4" color="primary" textAlign="center">
              Brak pacjentów do wyświetlenia
            </H4>
          )}
        </GappedFlex>
      )}
      {patients.length > 10 && <Pagination paginationHook={pagination} />}
    </Flex>
  );
};

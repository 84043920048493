import { Box, Flex } from "components";
import styled from "styled-components";
import { IBlog } from "types/knowledge";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import { P, H2 } from "components/Typography/Typography";
import { rgba } from "utilities/rgba";
import { separator } from "utilities/functions";
import { extractContent, truncate } from "utilities/functions";
import { useTranslation } from "react-i18next";
import tmpServiceImg from "assets/images/serviceTmp.png";
import { API_PHOTO_URL } from "constants/paths";
import { useWindowWidth } from "hooks/useWindowWidth";

const StyledP = styled(P)`
  font-size: 12px;
  line-height: 14px;
  ${({ theme }) => `
    color: ${rgba(theme.palette.primary.main, 0.5)}
  `};
`;

const StyledImg = styled.div<{ img?: string }>`
  min-width: 200px;
  width: 100%;
  max-width: 340px;
  aspect-ratio: 4/3;

  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ img }) => img && API_PHOTO_URL + img});
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main}
`};
`;

interface IBlogCard {
  blog: IBlog["data"];
}

export const BlogCard = ({ blog }: IBlogCard) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`${blog.slug}`);
  };

  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      mb={4}
      gap="20px 10px"
      width="100%"
    >
      <StyledImg img={blog.thumbnail} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        gap="10px"
        width="100%"
      >
        <Flex flexDirection="column" width="100%">
          <StyledP variant="body">
            {blog.topics.map(
              (topic, index, array) =>
                topic.name + separator(index, array, ", "),
            )}
          </StyledP>
          <H2 variant="h2">{blog.title}</H2>
          <P variant="body">{truncate(extractContent(blog.content), 100)}</P>
        </Flex>
        <Box>
          <Button bordered variant="red" onClick={handleOnClick}>
            {t("readMore") + " >>"}
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

import styled, { css } from "styled-components";
import {
  space,
  SpaceProps,
  LayoutProps,
  layout,
  flexbox,
  gridColumnGap,
} from "styled-system";
import { rgba } from "utilities/rgba";

interface ICard extends SpaceProps, LayoutProps {}

export const Card = styled.div<ICard>(
  () =>
    css`
      ${({ theme }) => `
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    background-color: ${theme.palette.neutral.white};
    box-shadow: 0px 4px 10px ${rgba(theme.palette.primary.dark, 0.25)};
    overflow: hidden;
  `};
    `,
  space,
  flexbox,
  gridColumnGap,
  layout,
);

import React, { FC, Dispatch, SetStateAction } from "react";
import { Flex } from "components";
import { ITag } from "types/knowledge";
import { P, H3 } from "components/Typography/Typography";
import styled from "styled-components";
import { separator } from "utilities/functions";
import { IBlogFilter } from "types/knowledge";
import { useTranslation } from "react-i18next";

const Tag = styled(P)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Separator = styled(P)`
  cursor: default;
`;

interface ITagSelectMenu {
  filters: IBlogFilter;
  setFilters: Dispatch<SetStateAction<IBlogFilter>>;
  tags: ITag[];
}

export const TagSelectMenu: FC<ITagSelectMenu> = ({
  filters,
  setFilters,
  tags,
}) => {
  const { t } = useTranslation();
  const isTagActive = (tagId: number) =>
    filters.tag_ids?.find((id) => id == tagId);

  const handleOnClick = (id: number) => {
    if (isTagActive(id)) {
      setFilters((prevState) => {
        return {
          ...prevState,
          tag_ids: prevState.tag_ids?.filter((prevId) => prevId != id),
          page: 1,
        };
      });
    } else {
      setFilters((prevState) => {
        if (prevState.tag_ids)
          return {
            ...prevState,
            tag_ids: [...prevState?.tag_ids, id],
            page: 1,
          };
        return {
          ...prevState,
          tag_ids: [id],
          page: 1,
        };
      });
    }
  };

  return (
    <Flex flexDirection="column" mt={3}>
      <H3 variant="h3" color="coloured" mb={3}>
        {t("filterByTag")}:
      </H3>
      <Flex flexDirection="row" flexWrap="wrap">
        {tags.map(({ id, name }, index, array) => {
          return (
            <React.Fragment key={id}>
              <Tag
                fontWeight={isTagActive(id) ? 700 : 400}
                variant="body2"
                onClick={() => handleOnClick(id)}
                mb={1}
              >
                {name}
              </Tag>
              <Separator mx={2} variant="body2" mb={1}>
                {separator(index, array)}
              </Separator>
            </React.Fragment>
          );
        })}
      </Flex>
    </Flex>
  );
};

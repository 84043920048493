import { format } from "date-fns";

//converts Date to DD.MM.YYYY string
export function dateToDateString(date: Date, separator: string = ".") {
  return format(date, `dd${separator}MM${separator}y`);
}

//converts Date to HH:MM string
export function dateToTimeString(date: Date, separator: string = ":") {
  return format(date, `HH${separator}mm`);
}

//merges Date and HH:MM string to new Date
export function mergeDateAndHours(date: Date, hour: string): string {
  const hours = hour.split(":");
  const preparedDate = new Date(date.setHours(+hours[0], +hours[1]));
  return preparedDate.toJSON();
}

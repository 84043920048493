import { Flex } from "components/Flex";
import { H1 } from "components/Typography";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from "assets/icons/arrow-left.svg";
import styled from "styled-components";

interface ISectionHeader {
  text: string;
}

const BackButton = styled(Flex)`
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
`;

export const SectionHeader = ({ text }: ISectionHeader) => {
  const navigate = useNavigate();
  return (
    <Flex width="100%" flexDirection="column" mb="20px">
      <Flex alignItems="center">
        <BackButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <Back />
        </BackButton>

        <H1 variant="h1" color="primary">
          {text}
        </H1>
      </Flex>
    </Flex>
  );
};

import { theme as themeConfig } from "styles/theme";
import React from "react";
import styled, { css } from "styled-components";
import { space, SpaceProps, variant } from "styled-system";

export type TypographyType = {
  variant: keyof typeof themeConfig.textStyles;
  children: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "coloured"
    | "white"
    | "dark"
    | "grey"
    | "red";
  fontWeight?: 400 | 500 | 600 | 700;
  textAlign?: "center" | "start" | "end";
} & SpaceProps;

const p = styled.p<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);
const h1 = styled.h1<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);
const h2 = styled.h2<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);
const h3 = styled.h3<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);
const h4 = styled.h4<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);
const h6 = styled.h6<TypographyType>(
  variant({
    scale: "textStyles",
  }),
  ({ theme, color, fontWeight, textAlign }) => css`
    ${color === "primary" ? `color: ${theme.palette.text.primary};` : null}
    ${color === "secondary" ? `color: ${theme.palette.text.secondary};` : null}
    ${color === "coloured" ? `color: ${theme.palette.secondary.main};` : null}
    ${color === "white" ? `color: ${theme.palette.neutral.white};` : null}
    ${color === "dark" ? `color: ${theme.palette.neutral.dark};` : null}
    ${color === "grey" ? `color: ${theme.palette.neutral.grey};` : null}
    ${color === "red" ? `color: ${theme.palette.error.main};` : null}
    ${fontWeight ? `font-weight: ${fontWeight};` : ""}
    ${textAlign ? `text-align: ${textAlign};` : ""}
  `,
  space,
);

export { p as P, h1 as H1, h2 as H2, h3 as H3, h4 as H4, h6 as H6 };

import { Dispatch, SetStateAction } from "react";
import moment from "moment";
import "moment/locale/en-gb";
import {
  HeaderProps,
  EventProps,
  EventPropGetter,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { rgba } from "utilities/rgba";
import { MyEventType, IAvailabilityEvent } from "types/calendar";
import { StyledCalendar, StyledToolbar } from "./Calendar.styled";
import { ReactComponent as PreviousIcon } from "assets/icons/ico-previous.svg";
import { ReactComponent as NextIcon } from "assets/icons/ico-next.svg";
import { CalendarProps } from "react-big-calendar";
import { getWeeks } from "services/week";
import { IWeek } from "types/week";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

type SetEventsType = Dispatch<SetStateAction<IAvailabilityEvent[]>>;

export const fetchInitialWeeks = async (
  owner_id: string,
): Promise<IWeek["data"][]> => {
  const currentYear = new Date().getFullYear();
  const currentWeekNumber = moment(new Date()).isoWeek();

  const lastWeeks: IWeek["data"][] = await Promise.all(
    Array.from({ length: 4 }, async (_, i) => {
      const response = await getWeeks({
        owner_id,
        year: currentYear.toString(),
        week_number: (currentWeekNumber - 4 + i).toString(),
      });
      return response["data"][0];
    }),
  );

  const futureWeeks: IWeek["data"][] = await Promise.all(
    Array.from({ length: 8 }, async (_, i) => {
      const response = await getWeeks({
        owner_id,
        year: currentYear.toString(),
        week_number: (currentWeekNumber + i + 1).toString(),
      });
      return response["data"][0];
    }),
  );

  return [...lastWeeks, ...futureWeeks];
};

export function mergeOverlappingEvents(
  events: IAvailabilityEvent[],
): IAvailabilityEvent[] {
  if (events.length <= 1) {
    return events;
  }

  // Sort events by start date
  const sortedEvents = events.sort(
    (a, b) => a.start.getTime() - b.start.getTime(),
  );

  const mergedEvents: IAvailabilityEvent[] = [sortedEvents[0]];

  for (let i = 1; i < sortedEvents.length; i++) {
    const currentEvent = sortedEvents[i];
    const lastMergedEvent = mergedEvents[mergedEvents.length - 1];

    // If the current event overlaps with the last merged event, merge them
    if (currentEvent.start <= lastMergedEvent.end) {
      mergedEvents[mergedEvents.length - 1] = {
        id: lastMergedEvent.id,
        start: lastMergedEvent.start,
        end:
          currentEvent.end > lastMergedEvent.end
            ? currentEvent.end
            : lastMergedEvent.end,
      };
    } else {
      // Otherwise, add the current event to the merged events list
      mergedEvents.push(currentEvent);
    }
  }

  return mergedEvents;
}

const isPastDate = (date: Date) => moment(date).isBefore(moment().endOf("day"));

export const eventStyleGetter: EventPropGetter<MyEventType> = ({ end }) => {
  if (moment(end).isBefore(moment())) {
    return {
      className: "past-event",
      style: {
        backgroundColor: rgba("#C9C8D3", 0.85),
        border: "none",
      },
    };
  }
  return {
    className: "",
    style: {},
  };
};

export const availabilityEventStyleGetter: EventPropGetter<MyEventType> = ({
  end,
}) => {
  if (moment(end).isBefore(moment())) {
    return {
      className: "past-event",
      style: {
        backgroundColor: rgba("#C9C8D3", 0.85),
        border: rgba("#C9C8D3", 0.85),
        cursor: "default",
        pointerEvents: "none",
      },
    };
  }
  return {
    className: "",
    style: {},
  };
};

export const handleSelectSlot =
  (setEvents: SetEventsType) =>
  ({ start, end }: { start: Date; end: Date }) => {
    if (isPastDate(start) || isPastDate(end)) {
      return; // Don't create events in the past
    }
    const newEvent = {
      start,
      end,
      id: `${Date.now()}`,
    };
    setEvents((prev) => mergeOverlappingEvents([...prev, newEvent]));
  };

export const moveEvent =
  (setEvents: SetEventsType) =>
  ({
    event,
    start,
    end,
  }: {
    event: IAvailabilityEvent;
    start: Date;
    end: Date;
  }) => {
    if (isPastDate(start) || isPastDate(end)) {
      return; // Don't create events in the past
    }
    const { id } = event;
    setEvents((prev) => {
      const existing = prev.find((ev) => ev.id === id) ?? {};
      const filtered = prev.filter((ev) => ev.id !== id);
      const newEvents = [...filtered, { ...existing, start, end, id }];
      return mergeOverlappingEvents(newEvents);
    });
  };

export const resizeEvent =
  (setEvents: SetEventsType) =>
  ({
    event,
    start,
    end,
  }: {
    event: IAvailabilityEvent;
    start: Date;
    end: Date;
  }) => {
    if (isPastDate(start) || isPastDate(end)) {
      return; // Don't create events in the past
    }
    const { id } = event;
    setEvents((prev) => {
      const existing = prev.find((ev) => ev.id === event.id) ?? {};
      const filtered = prev.filter((ev) => ev.id !== event.id);
      const newEvents = [...filtered, { ...existing, start, end, id }];
      return mergeOverlappingEvents(newEvents);
    });
  };

export function MyEvent(props: EventProps<MyEventType>) {
  const { event } = props;
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`/app/appointments/${event.id}`)}>
      <div>{event.patient}</div>
      <div>{event.service}</div>
    </div>
  );
}

export function AvailabilityEvent(props: EventProps<IAvailabilityEvent>) {
  return <div></div>;
}

export function MyWeekHeader({ date }: HeaderProps) {
  const weekday = moment(date).format("ddd");
  const dayOfMonth = moment(date).format("DD");

  return (
    <div>
      <p>{weekday}</p>
      <p>{dayOfMonth}</p>
    </div>
  );
}

export const timeGutterFormat = (date: Date) => moment(date).format("HH:mm");

export const handleNavigate =
  (setCurrentWeek: Dispatch<SetStateAction<moment.Moment>>) =>
  (newDate: Date) => {
    setCurrentWeek(moment(newDate).startOf("week"));
  };

export const Toolbar = ({
  currentWeek,
  setCurrentWeek,
}: {
  currentWeek: moment.Moment;
  setCurrentWeek: Dispatch<SetStateAction<moment.Moment>>;
}) => (
  <StyledToolbar>
    <button
      onClick={() => setCurrentWeek(moment(currentWeek).subtract(1, "week"))}
    >
      <PreviousIcon />
    </button>
    <button onClick={() => setCurrentWeek(moment(currentWeek).add(1, "week"))}>
      <NextIcon />
    </button>
    <span>
      {currentWeek.format("MMMM")} | {currentWeek.format("YYYY")}
    </span>
  </StyledToolbar>
);

export const defaultProps: any = {
  views: ["week"],
  defaultView: "week",
  showMultiDayTimes: false,
  min: new Date(2023, 1, 1, 8),
  max: new Date(2023, 1, 1, 20),
  step: 30,
  eventStyleGetter,
  localizer,
};

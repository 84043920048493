import { Flex } from "components";
import { H1, H2, H3 } from "components/Typography";
import { FC } from "react";
import styled from "styled-components";

const Li = styled.li`
  margin-inline-start: 30px;
`;

export const RulesView: FC = () => {
  return (
    <Flex
      width={["100%", "60%"]}
      flexDirection="column"
      gap="10px"
      margin="0 auto"
    >
      <H1 variant="h1" textAlign="center">
        Regulamin świadczenia usług drogą elektroniczną przez fizjo24.com
      </H1>
      <H2 variant="h2" textAlign="center">
        Regulamin stosowany od dnia 01.05.2023 r.
      </H2>
      <H3 variant="h3">§1. Postanowienia ogólne</H3>
      <ol type="1">
        <Li>
          Definicje:
          <ol type="a">
            <Li>
              Usługodawca lub Cherry Olga Jankowska z siedzibą w Wrocławiu
              (50-304) przy ul. Antoniego Słonimskiego 26/U01, NIP 9161347825,
              REGON 367884842.
            </Li>
            <Li>
              Serwis – serwis internetowy prowadzony przez Usługodawcę pod
              adresem: https://www.fizjo24.com
            </Li>
            <Li>
              Regulamin – niniejszy regulamin świadczenia usług drogą
              elektroniczną przez Usługodawcę.
            </Li>
            <Li>
              Użytkownik – osoba fizyczna, posiadająca pełną zdolność do
              czynności prawnych, która (i) dokonała Rejestracji w Serwisie, lub
              (ii) dokonała rejestracji w Serwisie z wykorzystaniem serwisu
              Facebook, lub (iii) dokonała rezerwacji terminu u SpecjaListy za
              pomocą Kalendarza Wizyt.
            </Li>
            <Li>
              SpecjaLista – korzystający z serwisu, będący lekarzem, lekarzem
              dentystą, psychologiem, rehabiLitantem, położną, dietetykiem,
              terapeutą, a także weterynarzem lub inną osobą niewymienioną
              powyżej wykonującą czynności w zakresie świadczeń zdrowotnych,
              świadczącą usługi zdrowotne, medyczne lub paramedyczne.
            </Li>
            <Li>
              Placówka – miejsce wykonywania czynności w zakresie świadczeń
              zdrowotnych, usług zdrowotnych, medycznych lub paramedycznych.
            </Li>
            <Li>
              Właściciel – korzystający z serwisu, który jest osobą upoważnioną
              do zarządzania informacjami o Placówce. Uprawnienia te są
              weryfikowane przez Usługodawcę.
            </Li>
            <Li>
              Konto – wpis w bazie danych Serwisu, dotyczący Użytkowników lub
              Specjalistów, z którego wynika możliwość dostępu do określonych
              Usług.
            </Li>
            <Li>
              Usługa – usługa świadczona drogą elektroniczną polegająca na
              wysyłaniu i odbieraniu danych za pomocą publicznych systemów
              teleinformatycznych na indywidualne żądanie usługobiorcy –
              Użytkownika, bez jednoczesnej fizycznej obecności stron.
            </Li>
            <Li>
              Kalendarz Wizyt – program komputerowy będący narzędziem do
              zarządzania grafikiem pracy Specjalisty, umożliwiający
              Użytkownikowi dokonanie rezerwacji terminu u Specjalisty przez
              Internet oraz samodzielne dodawanie terminów dostępnych do
              zarezerwowania przez Specjalistę.
            </Li>
          </ol>
        </Li>
        <Li>
          Usługodawca świadczy Usługi na podstawie niniejszego Regulaminu.
        </Li>
      </ol>
      <H3 variant="h3">§2. Rodzaje i zakres Usług</H3>
      <ol type="1">
        <Li>
          Usługodawca świadczy między innymi następujące Usługi:
          <ol type="a">
            <Li>udostępnia wyszukiwarkę Specjalistów i Usług;</Li>
            <Li>powiadamia o nowych informacjach i opiniach;</Li>
            <Li>umożliwia Użytkownikom zadawanie pytań Specjalistom;</Li>
            <Li>umożliwia rezerwację terminu u Specjalistów.</Li>
          </ol>
        </Li>
        <Li>
          Fizjo24 świadczy usługi polegające na informowaniu Użytkowników
          Internetu o praktyce zawodowej Specjalistów poprzez umieszczanie
          informacji o takiej praktyce w Internecie.
        </Li>
        <Li>
          W celu świadczenia usług, o których mowa w ust. 2 Fizjo24 może
          nawiązywać z podmiotami trzecimi stosunki umowne, które są niezbędne
          dla świadczenia tych usług. W szczególności Usługodawca może zawierać
          umowy, których celem będzie m.in. zapewnienie wysokiej pozycji w
          wynikach wyszukiwania wyszukiwarek internetowych.
        </Li>
        <Li>
          Usługodawca zastrzega sobie prawo do wprowadzenia opłat od dowolnych
          usług świadczonych w ramach Serwisu i jednocześnie zobowiązuje się
          poinformować o zmianach z odpowiednim wyprzedzeniem.
        </Li>
      </ol>
      <H3 variant="h3">§3. Warunki świadczenia usług </H3>
      <ol type="1">
        <Li>
          W celu korzystania z Usług należy spełnić poniższe warunki techniczne:
          <ol type="a">
            <Li>posiadać dostęp do sieci Internet;</Li>
            <Li>
              posiadać przeglądarkę internetową (jedną z następujących):
              Firefox, Chrome, Safari, IE, Opera, zaktualizowaną do najnowszej
              wersji.
            </Li>
          </ol>
        </Li>
        <Li>
          Każdy korzystający z Internetu może zapoznawać się z informacjami i
          opiniami o Centrum, Specjalistach oraz blogu i innych ogólno
          dostępnych informacjach.
        </Li>
        <Li>
          Każdy Użytkownik może zarezerwować termin u Specjalisty za pomocą
          Kalendarza Wizyt, pod warunkiem zweryfikowania numeru telefonu
          komórkowego oraz adresu e-mail. W niektórych przypadkach rezerwacja
          terminu u Specjalisty może wymagać podania nr PESEL. Podanie nr PESEL
          jest uzasadnione koniecznością identyfikacji Użytkownika przez
          Specjalistę lub Placówkę.
        </Li>
        <Li>
          Zarezerwowanie wizyty u Specjalisty jest możliwe wyłącznie w przypadku
          wyrażenia przez Użytkownika wyraźnej zgody na przetwarzanie przez
          Usługodawcę danych osobowych Użytkownika o stanie zdrowia (art. 9 ust.
          2 lit. a Rozporządzenia 679/2016, tzw. RODO) oraz udostępnienia tych
          danych wybranemu Specjaliście. Usługodawca informuje, że brak
          wyrażenia zgody na przetwarzanie danych osobowych Użytkownika o stanie
          zdrowia uniemożliwi zarezerwowanie wizyty. W trakcie umawiania wizyty
          przez Serwis Użytkownik może zdecydować się na wypełnienie ankiety
          dotyczącej jego stanu zdrowia. Wyniki tej ankiety zostaną przekazane
          do wybranego przez Użytkownika Specjalisty, u którego Użytkownik
          umawia wizytę. Przekazanie takich danych ma pomóc Specjaliście w
          przygotowaniu się do wizyty.
        </Li>
        <Li>
          Użytkownik może również:
          <ol type="a">
            <Li>wystawiać opinie o Specjaliście;</Li>
            <Li>dodawać i poprawiać informacje o Placówkach;</Li>
            <Li>zadawać pytania zweryfikowanym Specjaliście;</Li>
            <Li>zgłaszać nadużycia.</Li>
          </ol>
        </Li>
        <Li>
          Zadanie pytania zweryfikowanemu Specjaliście jest możliwe wyłącznie w
          przypadku wyrażenia przez Użytkownika wyraźnej zgody na przetwarzanie
          przez Usługodawcę danych osobowych Użytkownika o stanie zdrowia (art.
          9 ust. 2 lit. a Rozporządzenia 679/2016, tzw. RODO) oraz udostępnienia
          tych danych wybranemu Specjaliście. Usługodawca informuje, że brak
          wyrażenia zgody na przetwarzanie danych osobowych Użytkownika o stanie
          zdrowia uniemożliwi zadanie pytania.
        </Li>
        <Li>
          Usługodawca zastrzega sobie prawo do czasowego zawieszenia świadczenia
          Usług w związku z modernizacją lub przebudową Serwisu albo z pracami
          konserwatorskimi systemu teleinformatycznego. Usługodawca dołoży
          starań, aby przerwy w świadczeniu Usług nie były uciążliwe dla
          Użytkowników.
        </Li>
        <Li>
          Usługodawca nie odpowiada za przerwy w świadczeniu Usług wynikające z
          przyczyn od niego niezależnych, w szczególności stanowiących siłę
          wyższą (pożary, powodzie, klęski żywiołowe lub meteorologiczne itp.).
        </Li>
      </ol>
      <H3 variant="h3">
        §4. Dodawanie opinii i informacji, zadawanie pytań Specjalistom
      </H3>
      <ol type="1">
        <Li>
          Opinie i informacje o Specjalistach mogą dotyczyć wyłącznie
          działalności zawodowej Specjalistach.
        </Li>
        <Li>
          Opinie i informacje o Specjalistach mogą opierać się jedynie na
          osobistych doświadczeniach Użytkownika dotyczących danego Specjalisty
          i muszą one dotyczyć konsultacji medycznej lub wizyty, która miała
          miejsce.
        </Li>
        <Li>
          Jeden Użytkownik może zamieścić tylko jedną opinię. Opinia może
          dotyczyć kilku zdarzeń medycznych (wizyt, badań, zabiegów).
        </Li>
        <Li>
          Niedozwolone jest zamieszczanie w opiniach treści reklamowych,
          marketingowych lub odnoszących się do tzw. lokowania produktu.
          Niedozwolone jest również umieszczanie w opiniach linków do stron
          internetowych.
        </Li>
        <Li>
          Niedozwolone jest umieszczanie opinii przez Specjalistów na własnych
          Profilach oraz jakiekolwiek próby manipulacji opiniami, informacjami i
          ocenami.
        </Li>
        <Li>
          Niedozwolone jest umieszczanie opinii i komentarzy, które nie są
          zgodne z prawdą.
        </Li>
        <Li>
          Informacje, opinie i komentarze nie mogą naruszać prawa, dóbr
          osobistych, ani zasad współżycia społecznego. W szczególności
          niedozwolone jest używanie w opiniach wyrażeń wulgarnych,
          niecenzuralnych i obraźliwych, stosowanie przezwisk, odnoszenie się do
          sfery prywatnej i rodzinnej, oskarżanie o popełnienie przestępstwa,
          wzywanie do stosowania przemocy lub do nienawiści rasowej,
          wyznaniowej, etnicznej itp. Niedozwolone jest również dodawanie
          opinii, które mogą zostać uznane za pomówienie lub które kwestionują
          treści umieszczone przez Specjalistę w Serwisie lub kwestionują
          kwalifikacje zawodowe Specjalisty.
        </Li>
        <Li>
          Użytkownik zamieszczając opinię w Serwisie oświadcza, że wystawiona
          przez niego opinia lub komentarz jest prawdziwy i zgodny z
          rzeczywistym stanem rzeczy.
        </Li>
        <Li>
          Usługodawca ma prawo odmówić opublikowania opinii, komentarzy i
          informacji, a już opublikowane opinie, komentarze i informacje usunąć,
          jeżeli są sprzeczne z niniejszym Regulaminem lub przepisami prawa. W
          przypadku usunięcia opinii, komentarzy lub informacji Użytkownikowi w
          stosunku do Usługodawcy nie przysługuje jakiekolwiek roszczenie.
        </Li>
        <Li>
          Usługodawca nie ponosi odpowiedzialności za treść informacji, opinii i
          komentarzy przekazywanych przez Użytkowników lub Specjalistów.
        </Li>
        <Li>
          Zadając pytania Specjaliście, Użytkownik udziela Usługodawcy
          niewyłącznej, nieograniczonej w czasie, nieodpłatnej licencji na
          korzystanie z treści pytania lub jej części na terytorium całego
          świata, na polach eksploatacji takich jak: zwielokrotnianie techniką
          analogową i cyfrową, wprowadzanie do pamięci komputera, wprowadzanie
          do sieci komputerowej lub multimedialnej, wprowadzanie do obrotu,
          najem, dzierżawa, publiczne udostępnianie w miejscu i czasie wybranym
          przez Usługodawcę, wyświetlanie, nadawanie, reemitowanie, odtwarzanie,
          umieszczanie pytania w bazach danych, wykorzystania pytania w celu
          promocji Usługodawcy i publicznego prezentowania pytania w Serwisie.
          Usunięcie Konta przez Użytkownika nie ma wpływu na obowiązywanie
          powyższej licencji.
        </Li>
      </ol>
      <H3 variant="h3">
        §5. Warunki zawierania i rozwiązywania umów o świadczenie Usług.
        Rejestracja i logowanie.
      </H3>
      <ol type="1">
        <Li>
          Zawarcie umowy o świadczenie Usług następuje poprzez (i) rejestrację w
          Serwisie, lub (ii) rejestrację w Serwisie za pomocą serwisu Facebook,
          lub (iii) zarezerwowanie terminu u Specjalisty za pomocą Kalendarza
          Wizyt, lub (iv) zadanie pytania zweryfikowanemu Specjaliście.
        </Li>
        <Li>
          Zawarcie umowy o świadczenie Usług wiąże się z wyrażeniem zgody na
          wszystkie warunki niniejszego Regulaminu bez konieczności składania w
          tym celu dodatkowych oświadczeń. Użytkownik zobowiązuje się do
          przestrzegania Regulaminu.
        </Li>
        <Li>
          Zawierając umowę o świadczenie Usług Użytkownik, Specjalista lub
          Właściciel: potwierdza autentyczność podanych przez siebie danych.
        </Li>
        <Li>
          Zawierając umowę o świadczenie Usług Użytkownik, Specjalista lub
          Właściciel przyjmuje do wiadomości, że jego dane osobowe będą
          publicznie prezentowane w Internecie.
        </Li>
        <Li>Umowa o świadczenie Usług zostaje zawarta na czas nieokreślony.</Li>
        <Li>
          W celu Rejestracji Użytkownik wypełnia formularz zamieszczony na
          stronie https://www.fizjo24.com. Rejestracja jest również możliwa w
          procesie rezerwacji terminu u Specjalisty za pomocą Kalendarza Wizyt.
        </Li>
        <Li>
          Rejestracja i logowanie odbywa się za pomocą funkcjonalności
          udostępnionych przez Usługodawcę.
        </Li>
        <Li>
          W przypadku cofnięcia zgody na którykolwiek z warunków Regulaminu
          Użytkownik zobowiązany jest do natychmiastowego usunięcia swojego
          Konta lub zawiadomienia Usługodawcy o cofnięciu zgody.
        </Li>
        <Li>
          Użytkownikowi będącemu konsumentem w rozumieniu właściwych przepisów,
          przysługuje prawo do odstąpienia od umowy, w terminie 14 dni od dnia
          jej zawarcia, bez podania przyczyny. Odstąpienie od umowy następuje
          przez złożenie oświadczenia o odstąpieniu od umowy. Oświadczenie o
          odstąpieniu należy przesłać drogą elektroniczną na adres
          kontakt@centrumcryo.pl. Do zachowania terminu do odstąpienia od umowy
          wystarczy wysłanie oświadczenia przed jego upływem.
        </Li>
        <Li>
          W przypadku pojawienia się uzasadnionych wątpliwości co do
          autentyczności wprowadzonych przez Użytkownika danych, Usługodawca
          może zażądać przedstawienia stosownych dokumentów potwierdzających te
          dane.
        </Li>
        <Li>
          Niedopuszczalne jest posiadanie przez jednego Użytkownika więcej niż
          jednego Konta.
        </Li>
        <Li>Zabrania się udostępniania swojego Konta osobom trzecim.</Li>
        <Li>Zabrania się korzystania z Kont należących do osób trzecich.</Li>
        <Li>
          Użytkownik może rozwiązać umowę o świadczenie Usług poprzez skasowanie
          swojego Konta.
        </Li>
        <Li>
          Specjalista może rozwiązać umowę o świadczenie Usług poprzez
          skasowanie swojego Konta
        </Li>
      </ol>
      <H3 variant="h3">§6. Odpowiedzialność</H3>
      <ol type="1">
        <Li>
          Użytkownik i Specjalista są odpowiedzialni za bezpieczeństwo i
          zachowanie poufności swojego hasła do Profilu lub Konta. Usługodawca
          nie ponosi odpowiedzialności za nieuprawniony dostęp do Profilu lub
          Konta na skutek uzyskania dostępu do hasła przez osoby trzecie. W
          przypadku podejrzenia możliwości ujawnienia hasła osobom trzecim,
          Użytkownik i Specjalista zobowiązuje się niezwłocznie zmienić hasło
          lub skontaktować się w tym celu z Usługodawcą.
        </Li>
        <Li>
          Usługodawca nie ponosi odpowiedzialności za treść i formę materiałów i
          informacji umieszczonych w Serwisie przez Użytkownika, Placówkę lub
          Specjalistę. Usługodawca nie odpowiada za roszczenia Użytkowników,
          Placówek Specjalistów oraz osób trzecich, których prawa i uzasadnione
          interesy zostały naruszone poprzez te materiały. Użytkownik przyjmuje
          do wiadomości i zgadza się, że Usługodawca nie jest zobowiązany do
          sprawdzenia lub weryfikacji materiałów lub informacji zamieszczanych
          przez Użytkowników.
        </Li>
        <Li>
          Użytkownik, Placówka lub Specjalista ponoszą pełną odpowiedzialność za
          złamanie prawa bądź szkodę wywołaną ich działaniami w Serwisie, w
          szczególności podaniem nieprawdziwych danych, ujawnieniem tajemnicy
          zawodowej, innej informacji poufnej lub tajemnicy przedsiębiorstwa,
          naruszeniem dóbr osobistych lub praw autorskich oraz praw pokrewnych.
        </Li>
        <Li>
          Usługodawca wyraźnie zastrzega, iż korzystanie z Serwisu i
          udostępnianych przez niego Usług odbywa się na wyłączne ryzyko
          Użytkownika lub Specjalisty. Wszelkie umieszczone w Serwisie
          informacje i materiały, a także dostarczane za pośrednictwem Serwisu
          usługi nie są objęte gwarancją co do ich wartości, przydatności,
          zupełności, kompletności czy użyteczności.
        </Li>
        <Li>
          Usługodawca nie odpowiada za wady fizyczne i prawne oferowanych Usług
          bezpłatnych oraz za należytą jakość towarów i usług bezpłatnych.
          Usługodawca nie odpowiada także za skutki niewykonania lub
          nienależytego wykonania zaciągniętych przez kogokolwiek, za
          pośrednictwem Serwisu, zobowiązań oraz zdolności tych osób do
          zaciągania zobowiązań.
        </Li>
        <Li>
          Odpowiedzialność Usługodawcy za należytą jakość towarów i usług
          odpłatnych określają przepisy szczególne, stanowiące podstawę
          świadczeń odpłatnych.
        </Li>
        <Li>
          Usługodawca nie udziela żadnej gwarancji prawidłowego działania
          Serwisu w całości albo w części.
        </Li>
        <Li>
          W przypadku otrzymania przez Usługodawcę urzędowego zawiadomienia lub
          uzyskania wiarygodnej wiadomości o bezprawnym charakterze
          przechowywanych danych dostarczonych przez Użytkownika lub Specjalistę
          i uniemożliwienia dostępu do tych danych, Usługodawca nie ponosi
          odpowiedzialności względem tego Użytkownika lub Specjalisty za szkodę
          powstałą w wyniku uniemożliwienia dostępu do tych danych.
        </Li>
        <Li>
          Fizjo24 wskazuje, że wyniki ankiety wypełnianej przez Użytkownika w
          trakcie umawiania wizyty u wybranego Specjalisty nie zawierają
          diagnozy medycznej, a w konsekwencji jej prowadzenie nie może być
          kwalifikowana jako wykonywanie przez Fizjo24 świadczeń zdrowotnych.
          Ankieta oraz jej wynik mają za zadanie jedynie pomóc Specjaliście w
          przygotowaniu się do wizyty. Wynik ankiety ma jedynie charakter
          statystyczny, oparty o ogólnodostępne źródła wiedzy.
        </Li>
      </ol>
      <H3 variant="h3">
        §9. Tryb zgłaszania zastrzeżeń i postępowania reklamacyjnego
      </H3>
      <ol type="1">
        <Li>
          Usługodawca dołoży wszelkich starań w celu zapewnienia prawidłowego
          działania Serwisu oraz udzieli pomocy w rozwiązywaniu problemów
          związanych z jego funkcjonowaniem.
        </Li>
        <Li>
          Zastrzeżenia, a także uwagi, sugestie i błędy strony można zgłaszać,
          pisząc na adres e-mail: kontakt@centrumcryo.pl.
        </Li>
        <Li>
          Odpowiedzi na zgłoszenia będą udzielane na adres poczty elektronicznej
          Użytkownika lub Specjalisty.
        </Li>
        <Li>
          Zakłócenia w funkcjonowaniu Serwisu, problemy i uwagi związane ze
          świadczonymi usługami w ramach Serwisu mogą być reklamowane przez
          Użytkownika, Placówkę lub Specjalistę w terminie 21 dni od daty
          zdarzenia, poprzez zgłoszenie na adres: kontakt@centrumcryo.pl.
        </Li>
        <Li>
          Reklamacja rozpatrywana jest w terminie 21 dni od dnia jej wniesienia.
          Jeżeli termin ten nie będzie mógł być dotrzymany, Usługodawca
          powiadomi przed jego upływem o przyczynach opóźnienia i wyznaczy
          kolejny termin.
        </Li>
        <Li>
          Usługodawca zastrzega sobie prawo do pozostawienia reklamacji bez
          rozpoznania, jeżeli wynikać ona będzie z nieznajomości niniejszego
          Regulaminu, jego załączników lub przepisów prawa. Reklamacje
          zawierające treści wulgarne lub obraźliwe wobec Usługodawcy nie będą
          rozpatrywane.
        </Li>
      </ol>
      <H3 variant="h3">§10. Postanowienia końcowe</H3>
      <ol type="1">
        <Li>
          Usługodawca zastrzega sobie prawo do jednostronnej zmiany Regulaminu w
          ważnych przypadkach, w szczególności w przypadku zmiany
          funkcjonalności Serwisu, zmiany profilu działalności Usługodawcy lub
          zmiany przepisów prawa.
        </Li>
        <Li>
          O dokonanej zmianie Regulaminu Usługodawca poinformuje Użytkownika
          drogą poczty elektronicznej na wskazany adres e-mail lub w drodze
          korespondencji wewnętrznej kierowanej do Użytkownika przez mechanizm
          informatyczny na Konto Użytkownika, na 14 dni przed ich wejściem w
          życie.
        </Li>
        <Li>
          Użytkownik ponosi odpowiedzialność za podanie adresu poczty
          elektronicznej, do którego nie posiada dostępu, w szczególności adresu
          nieprawidłowego lub należącego do innego podmiotu, oraz wynikające z
          tego faktu skutki w postaci nieotrzymania powiadomienia określonego w
          ust. 2.
        </Li>
        <Li>
          Prawem właściwym dla niniejszego Regulaminu oraz dla umowy o
          świadczenie Usług jest prawo polskie.
        </Li>
        <Li>
          W sprawach nieuregulowanych w Regulaminie stosuje się przepisy ustawy
          o świadczeniu usług drogą elektroniczną, ustawy o prawie autorskim i
          prawach pokrewnych oraz Kodeksu cywilnego.
        </Li>
        <Li>
          Właściwym do rozstrzygania sporów wynikających ze świadczenia Usług, w
          przypadku sporów z Użytkownikami niebędącymi konsumentami jest sąd
          właściwy dla siedziby Usługodawcy. Informacje o sposobie dostępu do
          ww. trybu i procedur rozstrzygania sporów znajdują się pod
          następującym adresem: www.uokik.gov.pl oraz pod adresem:
          https://konsument.gov.pl/. Użytkownik będący konsumentem ma również
          możliwość skorzystania z unijnej platformy internetowej ODR, dostępnej
          pod adresem: http://ec.europa.eu/consumers/odr/.
        </Li>
      </ol>
    </Flex>
  );
};

import { Flex } from "components";
import "moment/locale/en-gb";
import { Calendar } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";

export const StyledCalendar = styled(Calendar)`
  width: 100%;

  .rbc-row .rbc-header,
  .rbc-time-view,
  .rbc-time-gutter > .rbc-timeslot-group,
  .rbc-time-header-content,
  .rbc-calendar,
  .rbc-time-content {
    border: none;
  }

  & .rbc-time-gutter.rbc-time-column {
    width: 60px;
  }
  & .rbc-day-slot.rbc-time-column {
    border-top: 1px solid #ddd;
  }

  & .rbc-allday-cell {
    display: none;
  }

  & .rbc-time-content {
    border-right: 1px solid #ddd;
    border-radius: 10px;
  }

  & .rbc-time-content .rbc-time-column:nth-child(2) {
    border-radius: 10px 0px 0px 10px;

    .rbc-events-container {
      border-radius: 10px 0px 0px 10px;
    }

    & > :nth-last-child(2) {
      border-bottom-left-radius: 10px;
    }

    & > :first-child {
      border-top-left-radius: 10px;
    }
  }

  .rbc-header {
    text-align: initial;
  }

  .rbc-header.rbc-today {
    border-radius: 15px 15px 0px 0px;
    background: #f83788;
    color: #ffffff;
    .rbc-button-link > :first-child p {
      font-weight: 700;
    }
  }

  .rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
    pointer-events: none;
  }

  .rbc-header {
    padding: 10px;
    .rbc-button-link > :first-child {
      text-align: left;
      font-weight: 400;
      & > :first-child {
        font-size: 20px;
        line-height: 24px;
      }
      & > :nth-child(2) {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  .rbc-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #abb7be;
    padding: 0px 12px;
  }

  .rbc-event-label {
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
  }

  .rbc-event-content > :first-child {
    & > :first-child {
      font-weight: 600;
      font-size: 9px;
      line-height: 10px;
    }
    & > :nth-child(2) {
      font-weight: 700;
      font-size: 9px;
      line-height: 10px;
    }
  }

  .rbc-event {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border: none;
    flex-flow: column nowrap;
  }
`;

export const StyledToolbar = styled(Flex)`
  button {
    background: none;
  }
  padding: 4px 15px;
  border-radius: 40px;
  margin-left: 70px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.primary.secondary};
  color: ${({ theme }) => theme.palette.neutral.white};
`;

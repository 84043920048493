import { Flex, Spinner } from "components";
import { ServiceTile } from "components/ServiceTile";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getServices } from "services/service";
import { IService } from "types/service";

interface IServicesAndPriceList {
  userId?: number;
}

export const ServicesAndPriceList = ({ userId }: IServicesAndPriceList) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [services, setServices] = useState<IService["data"][]>([]);
  const { role, id } = JSON.parse(localStorage.getItem("currentUser") || "{}");

  const fetchServieces = async () => {
    setIsLoading(true);
    console.log(id);
    try {
      const response = await getServices({
        user_ids: [userId, role === "expert" && id],
      });
      setServices(response.data);
    } catch (err: any) {
      toast.error(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchServieces();
  }, []);

  return (
    <>
      {isLoading && <Spinner />}
      <Flex flexDirection="column" gap="20px">
        {services.map((service) => (
          <ServiceTile key={service.id} service={service} userId={userId} />
        ))}
      </Flex>
    </>
  );
};

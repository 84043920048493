import { http } from "utilities/http";
import qs from "qs";
import { NOTE, NOTES } from "constants/paths";
import { INote } from "types/appointment";
import { ICreateNote, IUpdateNote } from "types/forms/note";
import { serialize } from "object-to-formdata";

export const getNote = async (id: string | number) => {
  return await http<INote>(NOTE(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const updateNote = (note: IUpdateNote, id: string | number) =>
  http<INote>(NOTE(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(note),
  });

export const createNote = (note: ICreateNote) =>
  http<INote>(NOTES, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(note),
  });

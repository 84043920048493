import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H2, H3 } from "components/Typography";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "assets/icons/ico-wrong.svg";
import { toast } from "react-toastify";
import { cancelAppointment } from "services/appointment";

interface IAppointmentCancelModal extends Omit<IModal, "styles" | "children"> {
  appointmentId?: number | string;
}

export const AppointmentCancelModal = ({
  isOpen,
  onCancelClick,
  appointmentId,
}: IAppointmentCancelModal) => {
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!appointmentId) return;
    try {
      const response = await cancelAppointment(appointmentId);
      if (!response) return;
      toast.success("Wizyta została anulowana");
    } catch (e) {
      toast.error(e.message);
    } finally {
      navigate("/app/appointments");
      if (onCancelClick) onCancelClick();
    }
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick} disableBackdropClick>
      <Flex width="100%" flexDirection="column" gap="20px" alignItems="center">
        <InfoIcon />
        <H2 variant="h2" textAlign="center" color="primary">
          Czy na pewno chcesz odwołać wizytę?
        </H2>
        <H3 variant="h3" textAlign="center" color="primary">
          Czynność ta jest nieodwracalna.
        </H3>
        <Flex mt={2} justifyContent="center" gap="10px">
          <Button
            variant="primary"
            bordered
            label="Nie"
            onClick={onCancelClick}
          />
          <Button variant="red" label="Tak" onClick={() => handleClick()} />
        </Flex>
      </Flex>
    </Modal>
  );
};

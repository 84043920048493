import { FC, useEffect, useState } from "react";
import { H1 } from "components/Typography/Typography";
import { Box, Flex } from "components";
import { useTranslation } from "react-i18next";
import { TabPanel } from "components/TabPanel";
import { getUser } from "services/user";
import { IUser } from "types/user";
import { useParams } from "react-router-dom";
import { AboutMe } from "components/Profile/Tabs/AboutMe";
import { Courses } from "components/Profile/Tabs/Courses/Courses";
import { ServicesAndPriceList } from "components/Profile/Tabs/ServicesAndPriceList";

const tabs = [
  { name: "aboutMe" },
  { name: "courses" },
  { name: "servicesAndPriceList" },
];

export const ExpertView: FC = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [expertData, setExpertData] = useState<IUser>();

  const { t } = useTranslation();
  const { id } = useParams();

  const fetchUserData = async () => {
    if (!id) return;
    try {
      const response = await getUser(id);
      if (!response) return;
      setExpertData(response.data);
    } catch {
    } finally {
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  if (!expertData) return <></>;

  const currentView = () => {
    switch (currentTab.name) {
      case "aboutMe":
        return <AboutMe userData={expertData} />;
      case "courses":
        return <Courses userData={expertData} />;
      case "servicesAndPriceList":
        return <ServicesAndPriceList userId={expertData.id} />;
    }
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex alignItems="center">
        <H1 mb={1} variant="h1" color="primary">
          {expertData?.profile?.first_name} {expertData?.profile?.last_name}
        </H1>
      </Flex>

      <Box my={3}>
        <TabPanel
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        >
          {currentView()}
        </TabPanel>
      </Box>
    </Flex>
  );
};

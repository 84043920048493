export const RecognitionForm = {
  title: "Rozpoznanie",
  fields: [
    {
      title: "Główne symptomy:",
      key: "recognition_main_symptoms",
    },
    {
      title: "Objawy pacjenta:",
      key: "recognition_patient_symptoms",
    },
    {
      title: "Notatki:",
      key: "recognition_note",
    },
  ],
};

export const InterviewForm = {
  title: "Wywiad",
  fields: [
    {
      title: "Operacje:",
      key: "interview_operations",
    },
    {
      title: "Urazy, poprzednie epizody bólowe:",
      key: "interview_injuries",
    },
    {
      title: "Leki i ich działanie:",
      key: "interview_drugs",
    },
    {
      title: "Choroby:",
      key: "interview_diseases",
    },
    {
      title: "Stabilność masy ciała:",
      key: "interview_weight",
    },
    {
      title: "Notatki:",
      key: "interview_note",
    },
  ],
};

export const ExaminationForm = {
  title: "Badania",
  fields: [
    {
      title: "Badanie stanu funkcjonowania:",
      key: "examination_functional_status",
    },
    {
      title: "Kod ICF:",
      key: "examination_icf_code",
    },
  ],
};

export const DetailedExaminationForm = {
  title: "Badania szczegółowe",
  fields: [
    {
      title: "Opis zaburzonych struktur funkcji:",
      key: "detailed_examination_description_of_disturbed_function_structures",
    },
    {
      title: "Testy strukturalne:",
      key: "detailed_examination_structural_tests",
    },
    {
      title: "Przeciwwskazania - żółte / czerwone flagi:",
      key: "detailed_examination_contraindications",
    },
  ],
};

export const InitialDiagnosisForm = {
  title: "Wstępna diagnoza",
  fields: [
    {
      key: "initial_diagnosis",
    },
  ],
};

export const TherapyPlanForm = {
  title: "Plan terapii",
  fields: [
    {
      key: "therapy_plan",
    },
  ],
};

export const TreatmentSummaryForm = {
  title: "Podsumowanie leczenia",
  fields: [
    {
      key: "treatment_summary",
    },
  ],
};

import { Box, Flex } from "components";
import styled from "styled-components";

export const Inline = styled(Box)`
  width: 100%;
  display: inline-flex;
  gap: 5px;
`;

export const GappedFlex = styled(Flex)`
  gap: 10px;
  flex-flow: column nowrap;
  max-height: 600px;
  overflow-y: auto;
`;

import { Flex } from "components";
import styled from "styled-components";
import { IBlog } from "types/knowledge";
import { useNavigate } from "react-router-dom";
import { P, H2 } from "components/Typography/Typography";
import { rgba } from "utilities/rgba";
import { separator } from "utilities/functions";
import { useTranslation } from "react-i18next";
import { API_PHOTO_URL } from "constants/paths";

const StyledP = styled(P)`
  font-size: 12px;
  line-height: 14px;
  ${({ theme }) => `
    color: ${rgba(theme.palette.primary.main, 0.5)}
  `};
`;

const Wrapper = styled(Flex)`
  width: 410px;
  cursor: pointer;
`;

const StyledImg = styled.div<{ img?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;

  width: 410px;
  height: 250px;

  border-radius: 5px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ img }) => img && API_PHOTO_URL + img});

  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `};
`;

interface IBlogCard {
  blog: IBlog["data"];
}

export const VideoCard = ({ blog }: IBlogCard) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`${blog.slug}`);
  };

  return (
    <Wrapper mb={4} mr={4} flexDirection="column" onClick={handleOnClick}>
      <StyledImg img={blog.thumbnail} />
      <StyledP variant="body" mt={3}>
        {blog.topics.map(
          (topic, index, array) => topic.name + separator(index, array, ", "),
        )}
      </StyledP>
      <H2 variant="h2" mt={2} onClick={handleOnClick}>
        {blog.title}
      </H2>
    </Wrapper>
  );
};

import { PAY } from "constants/paths";
import { serialize } from "object-to-formdata";
import { IPayment, IPaymentResponse } from "types/pay";
import { http } from "utilities/http";

export const createPayment = (payment: IPayment) =>
  http<IPaymentResponse>(PAY, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({
      payment: { ...payment, currency_code: "PLN", quantity: 1 },
    }),
  });

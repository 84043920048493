import { Box, Card, Flex } from "components";
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getIcdCodes } from "services/icdCodes";
import styled from "styled-components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { IIcdCodes } from "types/researchCard";
import { rgba } from "utilities/rgba";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { ResearchCardContext } from "../context/ResearchCardContext";
import { ReactComponent as XIcon } from "assets/icons/bx-x.svg";
import { P } from "components/Typography";

const Root = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  ${({ theme }) => `border: 1px solid ${theme.palette.neutral.grey}`}
`;

const StyledCard = styled(Card)`
  z-index: 2;
  min-height: 50px;
  position: absolute;
  top: 100%;
  margin-block: 10px;
  max-height: 300px;
  overflow: auto;
`;

const CodeTile = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.accent.main};
  color: ${({ theme }) => theme.palette.neutral.white};
`;

const XButton = styled(Box)`
  padding: 0px 10px;

  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.neutral.white};
    }
  }
`;

const Element = styled(Box)`
  padding: 5px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => rgba(theme.palette.accent.main, 0.4)};
  }
`;

export const CodesSelector = () => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [options, setOptions] = useState<IIcdCodes[]>([]);

  const { selectedIds, setSelectedIds } = useContext(ResearchCardContext);

  const fetchOptions = async () => {
    setLoading(true);
    try {
      const response = await getIcdCodes({
        disease: !searchValue ? undefined : searchValue,
        per_page: !searchValue ? 30 : undefined,
        page: !searchValue ? page : undefined,
      });
      if (!response) return;

      if (!searchValue) {
        setPage((prev) => prev + 1);
        setTotal(Number(response.pagination?.count));
        setOptions((prev) => {
          if (page === 1) return response.data;
          else return [...prev, ...response.data];
        });
      } else {
        if (page != 1) {
          setPage(1);
        }
        setTotal(Number(response.pagination?.count));
        setOptions(response.data);
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showOptions) fetchOptions();
    if (!searchValue) setOptions([]);
  }, [searchValue]);

  useEffect(() => {
    if (showOptions) {
      fetchOptions();
    } else {
      setPage(1);
      setOptions([]);
    }
  }, [showOptions]);

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: total > options.length,
    onLoadMore: fetchOptions,
  });

  const rootRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(rootRef, () => {
    setShowOptions(false);
  });

  const removeElement = (id: number | string) => {
    const tmp = selectedIds.filter((e) => e.id !== id);
    setSelectedIds(tmp);
  };

  const addElement = (el: IIcdCodes) => {
    if (!selectedIds.find((e) => e.id === el.id))
      setSelectedIds((prev) => [...prev, el]);
  };

  return (
    <Root ref={rootRef}>
      <StyledInput
        placeholder="Wybierz kody ICD10"
        onFocus={() => {
          setShowOptions(true);
        }}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />
      {!!selectedIds.length && (
        <Flex flexDirection="row" gap="10px" flexWrap="wrap">
          {selectedIds.map((el) => {
            return (
              <CodeTile key={el.id}>
                <P variant="body" fontWeight={600}>
                  {el.code} - {el.disease}
                </P>
                <XButton onClick={() => removeElement(el.id)}>
                  <XIcon />
                </XButton>
              </CodeTile>
            );
          })}
        </Flex>
      )}
      {showOptions && (
        <StyledCard>
          <Flex flexDirection="column">
            {!!options.length
              ? options.map((el) => {
                  return (
                    <Element
                      key={el.id}
                      onClick={() => {
                        addElement(el);
                      }}
                    >
                      {el.code} - {el.disease}
                    </Element>
                  );
                })
              : loading
              ? "Ładowanie..."
              : "Brak danych"}
            {total > options.length && (
              <Box ref={sentryRef} width="100%">
                {loading ? "Ładowanie..." : "Wczytaj więcej..."}
              </Box>
            )}
          </Flex>
        </StyledCard>
      )}
    </Root>
  );
};

import { Box, Flex, Spinner } from "components";
import { DotLine } from "components/DotLine";
import { H1, H2, P } from "components/Typography";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { IUser } from "types/user";
import { ProfileCard } from "./components/ProfileCard";
import { InterviewCard } from "./components/InterviewCard";
import { AppointmentsCard } from "./components/AppointmentsCard/AppointmentsCard";
import { Inline } from "./components/Components.styled";
import { getAppointments } from "services/appointment";
import { getUser } from "services/user";
import { getUserFullName } from "utilities/appointment";
import { IAppointmentExtended } from "types/appointment";
import { getExaminations } from "services/examination";
import { IExamination } from "types/examination";
import { toast } from "react-toastify";
import { device } from "styles/theme";

const Grid = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  gap: 30px 30px;
`;

export const PatientCardView = () => {
  const [patientData, setPatientData] = useState<IUser>();
  const [appointments, setAppointments] =
    useState<IAppointmentExtended["data"][]>();
  const [examinations, setExaminations] = useState<IExamination[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id } = useParams();

  const fetchTestsData = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const result = await getExaminations({ user_id: Number(id) });
      if (!result) return;
      setExaminations(result.data);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAppointmentsData = async () => {
    if (!id) return;
    setIsLoading(true);
    try {
      const response = await getAppointments({ patient_id: Number(id) });
      if (!response) return;
      setAppointments(response.data);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async () => {
    if (!id) return;
    try {
      const response = await getUser(id);
      if (!response) return;
      setPatientData(response.data);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchAppointmentsData();
    fetchTestsData();
  }, []);

  return (
    <Flex width="100%" flexDirection="column">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex flexDirection="column" mb="20px">
            <H1 mb={1} variant="h1" color="primary">
              Karta pacjenta
            </H1>
            <H2 variant="h2" color="coloured">
              {patientData && getUserFullName(patientData)}
            </H2>
            <Inline>
              <P variant="body" color="grey">
                PESEL:
              </P>
              <P variant="body" color="primary">
                {patientData?.profile.pesel ?? "-"}
              </P>
            </Inline>
          </Flex>
          <Flex flexDirection="row" flexWrap="nowrap">
            <Grid>
              <Flex
                flexDirection="column"
                width="100%"
                flexWrap="nowrap"
                gap="30px"
              >
                {patientData && <ProfileCard patientData={patientData} />}
                {examinations && (
                  <InterviewCard
                    tests={examinations}
                    userId={patientData?.id}
                    fetchTestAgain={() => fetchTestsData()}
                  />
                )}
              </Flex>
              {appointments && <AppointmentsCard appointments={appointments} />}
            </Grid>
          </Flex>
        </>
      )}
    </Flex>
  );
};

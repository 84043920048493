import { Select } from "components/_form";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IOption } from "components/_form/Select/Select";
import { Box } from "components";
import { getUserFullName } from "utilities/appointment";
import { getUsers } from "services/user";
import { FC, useEffect, useState } from "react";
import { getServices } from "services/service";
import { serialize } from "v8";

interface Props {
  serviceTypeParam?: string;
  expertParam?: string;
}

export const ExpertSelects: FC<Props> = ({ serviceTypeParam, expertParam }) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();
  const [expertOptions, setExpertOptions] = useState<IOption[]>([]);
  const [serviceOptions, setServiceOptions] = useState<IOption[]>([]);
  const [initialLoading, setInitialLoading] = useState({
    expert: false,
    serviceType: false,
  });
  const serviceType = watch("serviceType");
  const expert = watch("expert");

  const fetchExperts = async () => {
    try {
      const queryFilters = {
        role: "expert",
        ...(serviceType && { service_ids: [serviceType.value] }),
      };
      const response = await getUsers(queryFilters);
      if (!response) return;
      const options = response.data.map((expert) => {
        return {
          value: expert.id.toString(),
          label: getUserFullName(expert),
          data: expert,
        };
      });
      setExpertOptions(options);

      if (expertParam && !initialLoading.expert) {
        const foundExpertOption = options.find(
          (option) => option.value === expertParam,
        );
        if (foundExpertOption) setValue("expert", foundExpertOption);
        setInitialLoading((curr) => {
          return {
            expert: true,
            serviceType: curr.serviceType,
          };
        });
      }
    } catch (err: any) {
    } finally {
    }
  };

  const fetchServices = async () => {
    try {
      const queryFilters = {
        ...(expert && { user_ids: [expert.value] }),
      };
      const response = await getServices(queryFilters);
      if (!response) return;
      const options = response.data.map((service) => {
        return {
          value: service.id.toString(),
          label: service.name,
          data: service,
        };
      });
      setServiceOptions(options);

      if (serviceTypeParam && !initialLoading.serviceType) {
        const foundServiceOption = options.find(
          (serviceOption) => serviceOption.value === serviceTypeParam,
        );
        if (foundServiceOption) setValue("serviceType", foundServiceOption);
        setInitialLoading((curr) => {
          return {
            expert: curr.expert,
            serviceType: true,
          };
        });
      }
    } catch (err: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchExperts();
    fetchServices();
  }, [serviceType, expert]);

  return (
    <>
      <Box mb={3}>
        <Controller
          control={control}
          name="serviceType"
          render={({ field: { value, onChange } }) => (
            <Select
              label="Rodzaj usługi"
              placeholder="Rodzaj usługi"
              options={serviceOptions}
              isMulti={false}
              selectedOptions={value}
              onChange={(val) => onChange(val)}
              isClearable
            />
          )}
        />
      </Box>
      <Box mb={3}>
        <Controller
          control={control}
          name="expert"
          render={({ field: { value, onChange } }) => (
            <Select
              label="Specjalista"
              placeholder="Imię i Nazwisko (specjalizacja)"
              options={expertOptions}
              isMulti={false}
              selectedOptions={value}
              onChange={(val) => onChange(val)}
              isClearable
            />
          )}
        />
      </Box>
    </>
  );
};

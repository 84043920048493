import { Flex, Line } from "components";
import styled from "styled-components";

export const InputWrapper = styled(Flex)`
  flex-flow: column nowrap;
  width: 100%;
  gap: 30px;
`;

export const SLine = styled(Line)`
  margin: 20px 0px;
`;

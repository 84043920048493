import { Box, Card, Flex, Modal } from "components";
import { H2, P } from "components/Typography";
import { ReactComponent as XIcon } from "assets/icons/bx-x.svg";
import styled from "styled-components";
import { Button } from "components/_form";
import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg";
import { theme } from "styles";
import { useState } from "react";
import { TestDeleteModal } from "./TestDeleteModal";
import { API_PHOTO_URL } from "constants/paths";

const StyledCard = styled(Card)`
  position: relative;
`;

const XButtonWrapper = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const StyledAttachment = styled.a`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 8px;
  padding: 5px 8px;
  display: flex;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 14px;
`;

interface Props {
  test: {
    name: string;
    date: string;
    description: string;
    id: string | number;
    exam_file: string;
  };
  handleDelete: (id: number | string) => Promise<void>;
}

export const Test = ({ test, handleDelete }: Props) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const fileName = !!test.exam_file
    ? test.exam_file.substring(test.exam_file.lastIndexOf("/") + 1)
    : "";

  const fileLink = `${API_PHOTO_URL}${test.exam_file}`;

  return (
    <>
      <TestDeleteModal
        isOpen={showPopup}
        onCancelClick={() => {
          setShowPopup(false);
        }}
        examinationId={test.id}
        handleDelete={handleDelete}
      />
      <StyledCard height="fit-content">
        <XButtonWrapper
          onClick={() => {
            setShowPopup(true);
          }}
        >
          <Button textOnly variant="transparent" icon={<XIcon />} />
        </XButtonWrapper>
        {!!test.exam_file && (
          <StyledAttachment href={fileLink} target="_blank">
            <Flex alignItems="center" gap="10px">
              <AttachmentIcon fill={theme.palette.primary.main} />
              {fileName}
            </Flex>
          </StyledAttachment>
        )}
        <H2 variant="h2" color="primary">
          {test.name}
        </H2>
        <P variant="body2" color="grey">
          Data badania: {test.date}
        </P>

        <P variant="body" fontWeight={700} color="primary">
          Opis:
        </P>
        <P variant="body" color="primary">
          {test.description}
        </P>
      </StyledCard>
    </>
  );
};

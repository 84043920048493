import { useEffect, useState } from "react";
import { IUser } from "types/user";
import { IAppointmentExtended } from "types/appointment";
import { Dispatch, SetStateAction } from "react";
import { IUserForm } from "types/forms/user";
import { useForm, UseFormReturn } from "react-hook-form";
import { FetchHook, useFetch } from "hooks/useFetch";
import { getMyAccountHandlers } from "./useMyAccount.handlers";

export const useMyAccountState = (): MyAccountState => {
  const [user, setUser] = useState<IUser>();
  const [appointments, setAppointments] = useState<
    IAppointmentExtended["data"][]
  >([]);
  const [isSettingsMode, setIsSettingsMode] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  return {
    user,
    setUser,
    appointments,
    setAppointments,
    isSettingsMode,
    setIsSettingsMode,
    uploadedFile,
    setUploadedFile,
  };
};

export const useMyAccount = (): MyAccountHook => {
  const formHook = useForm<IUserForm>({
    mode: "onChange",
  });
  const fetchHook = useFetch();
  const myAccountState = useMyAccountState();
  const myAccountHandlers = getMyAccountHandlers({
    formHook,
    fetchHook,
    myAccountState,
  });

  const { handleUpdateAvatar, fetchUser, fetchAppointments } =
    myAccountHandlers;
  const { uploadedFile, isSettingsMode, user } = myAccountState;

  useEffect(() => {
    handleUpdateAvatar();
  }, [uploadedFile]);

  useEffect(() => {
    fetchUser();
  }, [isSettingsMode, uploadedFile]);

  useEffect(() => {
    fetchAppointments();
  }, []);

  return {
    formHook,
    fetchHook,
    myAccountState,
    myAccountHandlers,
  };
};

export type MyAccountHook = {
  fetchHook: FetchHook;
  myAccountState: MyAccountState;
  formHook: UseFormReturn<IUserForm>;
  myAccountHandlers: any;
};

export type MyAccountState = {
  user: IUser | undefined;
  setUser: Dispatch<SetStateAction<IUser | undefined>>;
  appointments: IAppointmentExtended["data"][];
  setAppointments: Dispatch<SetStateAction<IAppointmentExtended["data"][]>>;
  isSettingsMode: boolean;
  setIsSettingsMode: Dispatch<SetStateAction<boolean>>;
  uploadedFile: File | undefined;
  setUploadedFile: Dispatch<SetStateAction<File | undefined>>;
};

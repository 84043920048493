import { COURSES } from "constants/paths";
import { serialize } from "object-to-formdata";
import { ICourse } from "types/courses";
import { http } from "utilities/http";

interface ICreateCourse {
  title: string;
  description: string;
  receipt_date: any;
  profile_id: number;
}

export const createCourse = (course: ICreateCourse) =>
  http<{ data: ICourse }>(COURSES, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ course }),
  });

export const deleteCourse = (id: number) =>
  http(`${COURSES}/${id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

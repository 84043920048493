import { ReactComponent as PreviousIcon } from "assets/icons/ico-previous.svg";
import { ReactComponent as NextIcon } from "assets/icons/ico-next.svg";
import styled from "styled-components";
import { ITodayAppointments } from "./TodayAppointments";
import moment from "moment";

const StyledBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  background: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 15px;

  svg {
    fill: white;
    cursor: pointer;
  }
`;

const Date = styled.div`
  color: white;
  text-align: center;
  text-transform: capitalize;
  & > :first-child {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`;

interface IDateNavigationBox extends Omit<ITodayAppointments, "appointments"> {}

export const DateNavigationBox = ({
  currentDay,
  setCurrentDay,
}: IDateNavigationBox) => {
  return (
    <StyledBox>
      <PreviousIcon
        onClick={() => setCurrentDay(moment(currentDay).subtract(1, "day"))}
      />
      <Date>
        <p>{moment(currentDay).format("dddd")}</p>
        <p>{moment(currentDay).format("D MMMM YYYY")}</p>
      </Date>
      <NextIcon
        onClick={() => setCurrentDay(moment(currentDay).add(1, "day"))}
      />
    </StyledBox>
  );
};

import { FC, useEffect, useState } from "react";
import { Flex, Spinner } from "components";
import styled from "styled-components";
import { IBlogs, IBlogFilter, ITopic, ITag } from "types/knowledge";
import { getBlogs, getTags } from "services/knowledge";
import { getTopics } from "services/knowledge";
import {
  TagSelectMenu,
  TopicSelectMenu,
  BlogList,
  VideoList,
  SearchField,
} from "./components";
import { TabPanel } from "components/TabPanel";
import { SectionHeader } from "components/SectionHeader";

export const VerticalLine = styled.span`
  ${({ theme }) => `
    display: flex;
    background-color: ${theme.palette.neutral.lightGrey};
    margin-right: 30px;
    //margin-left: 30px;
    width: 1px;
`};
`;

const initialFilters: IBlogFilter = {
  topic_ids: [],
  tag_ids: [],
  page: 1,
  per_page: 20,
  with_video: false,
};

const tabs = [{ name: "Artykuły" }, { name: "Filmy i nagrania" }];

export const KnowledgeBaseView: FC<{ isPublic?: boolean }> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [filters, setFilters] = useState<IBlogFilter>(initialFilters);
  const [blogs, setBlogs] = useState<IBlogs | null>(null);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    setBlogs(null);
    if (currentTab === tabs[1]) {
      setFilters((prevState) => {
        return { ...prevState, with_video: true, page: 1 };
      });
    } else {
      setFilters((prevState) => {
        return { ...prevState, with_video: false, page: 1 };
      });
    }
  }, [currentTab]);

  const handleGetBlogs = async () => {
    setIsLoading(true);
    const queryFilters = {
      ...filters,
    };

    getBlogs(queryFilters)
      .then((res) => {
        if (!res) return;
        setShowMore(!!res.pagination?.next);
        setBlogs((prevState) => {
          if (filters.page == 1) return res;
          const blogsCombined = {
            ...res,
            data: [...(prevState?.data || []), ...res.data],
          };
          return blogsCombined;
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleLoadMoreOffers = async () => {
    setFilters((prevState) => {
      if (!prevState.page) return prevState;
      return { ...prevState, page: prevState.page + 1 };
    });
  };

  const initialFetch = () =>
    getTopics().then((response) => {
      if (response.data.length > 0) {
        setTopics(response.data);
        setFilters((prevState) => {
          return { ...prevState, topic_ids: [response.data[0].id], page: 1 };
        });
      }
      getTags().then((response) => {
        if (!response) return;
        setTags(response.data);
      });
    });

  useEffect(() => {
    setIsLoading(true);
    handleGetBlogs().then(() => {
      setIsLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    setIsLoading(true);
    initialFetch().then(() => setIsLoading(false));
  }, []);

  const currentView = () => {
    switch (currentTab.name) {
      case "Artykuły":
        return (
          <BlogList
            blogs={blogs}
            isLoading={isLoading}
            showMore={showMore}
            handleLoadMoreOffers={handleLoadMoreOffers}
          />
        );
      case "Filmy i nagrania":
        return (
          <VideoList
            blogs={blogs}
            isLoading={isLoading}
            showMore={showMore}
            handleLoadMoreOffers={handleLoadMoreOffers}
          />
        );
    }
  };
  return (
    <Flex flexDirection="column">
      <SectionHeader text="Baza wiedzy" />
      <TabPanel
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        <Flex
          width="100%"
          flexDirection={["column", "column", "row"]}
          justifyContent="space-between"
          gap="5px"
        >
          <Flex flexDirection="column" flexGrow={2}>
            {isLoading ? <Spinner /> : currentView()}
          </Flex>
          <Flex flexDirection="column">
            <SearchField setFilters={setFilters} />
            <Flex flexDirection="row">
              <Flex flexDirection="column">
                <TopicSelectMenu
                  filters={filters}
                  setFilters={setFilters}
                  topics={topics}
                />
                <TagSelectMenu
                  filters={filters}
                  setFilters={setFilters}
                  tags={tags}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </TabPanel>
    </Flex>
  );
};

export function separator(
  index: number,
  array: any,
  separator: string = " | ",
): string {
  return ++index == array.length ? "" : separator;
}

export function extractContent(s: string) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function truncate(str: string, n: number) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1);
  return subString.slice(0, subString.lastIndexOf(" ")) + "...";
}

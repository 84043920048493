import moment from "moment";
import { IAvailabilityEvent } from "types/calendar";
import { IWeek, IWeekDays } from "types/week";
import { mergeOverlappingEvents } from "views/AppointmentsView/components/Calendar/Calendar.functions";

export function getDateFromWeek(
  week: IWeek["data"],
  day: keyof IWeekDays,
): Date {
  const year = parseInt(week.year);
  const month = 0;
  const weekNumber = week.week_number;
  const days = Object.keys({} as IWeekDays) as Array<keyof IWeekDays>;
  const dayOfWeek = days.indexOf(day) + 1;
  const date = new Date(year, month, (weekNumber - 1) * 7 + dayOfWeek);

  date.setHours(0, 0, 0, 0);

  return date;
}

export function parseWeeksToEvents(
  weeks: IWeek["data"][],
): IAvailabilityEvent[] {
  const events: IAvailabilityEvent[] = [];
  weeks.forEach((week) => {
    const { id, year, week_number, mon, tue, wed, thu, fri, sat, sun } = week;
    const weekdays = [mon, tue, wed, thu, fri, sat, sun];
    weekdays.forEach((day, dayIndex) => {
      for (let i = 0; i <= 23; i++) {
        if (day[i] !== "2") {
          const startHour = 8 + Math.floor(i / 2);
          const startMinute = i % 2 === 0 ? 0 : 30;
          const start = moment(
            `${year}-W${week_number}-${
              dayIndex + 1
            }T${startHour}:${startMinute}:00`,
            "YYYY-[W]WW-E[T]HH:mm:ss",
          ).toDate();
          const endHour = 8 + Math.floor((i + 1) / 2);
          const endMinute = (i + 1) % 2 === 0 ? 0 : 30;
          const end = moment(
            `${year}-W${week_number}-${
              dayIndex + 1
            }T${endHour}:${endMinute}:00`,
            "YYYY-[W]WW-E[T]HH:mm:ss",
          ).toDate();
          events.push({ id: `${id}-${dayIndex}-${i}`, start, end });
        }
      }
    });
  });

  return mergeOverlappingEvents(events);
}

function getDaySlots(start: Date, end: Date): number[] {
  const daySlots = [];
  const startHour = start.getHours();
  const startMinute = start.getMinutes();
  const endHour = end.getHours();
  const endMinute = end.getMinutes();

  for (let i = startHour * 2; i < endHour * 2; i++) {
    if (i < 16 || i >= 44) {
      // Ignore slots outside of business hours
      continue;
    }

    const slot = i - 16;

    if (i === startHour * 2 && startMinute >= 30) {
      // Start time is on or after the half-hour mark
      daySlots.push(slot + 1);
    } else if (i === endHour * 2 - 1 && endMinute > 0) {
      // If the event ends on a half hour, include the next time slot
      daySlots.push(slot + 1);
    } else {
      daySlots.push(slot);
    }
  }

  return daySlots;
}

function updateDaySlots(day: string, slots: number[]): string {
  const dayArray = day.split("");

  slots.forEach((slot) => {
    dayArray[slot] = "0";
  });

  return dayArray.join("");
}

export function parseEventsToWeeks(
  events: IAvailabilityEvent[],
): IWeek["data"][] {
  const weeks: { [key: string]: IWeek["data"] } = {};

  // Group events by week and day of the week
  events.forEach((event) => {
    const weekNumber = moment(event.start).isoWeek();
    const year = moment(event.start).year();
    const dayOfWeek = moment(event.start).day();
    const weekDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    const dayKey = weekDays[dayOfWeek - 1] as keyof IWeekDays;
    const weekKey = `${year}-${weekNumber}`;
    const daySlots = getDaySlots(event.start, event.end);

    if (!weeks[weekKey]) {
      // Create a new week object if it doesn't exist
      weeks[weekKey] = {
        id: 0,
        year: year.toString(),
        week_number: weekNumber,
        mon: "222222222222222222222222",
        tue: "222222222222222222222222",
        wed: "222222222222222222222222",
        thu: "222222222222222222222222",
        fri: "222222222222222222222222",
        sat: "222222222222222222222222",
        sun: "222222222222222222222222",
        user: {},
        appointments: [],
      };
    }

    // Update the day slots in the week data
    weeks[weekKey][dayKey] = updateDaySlots(weeks[weekKey][dayKey], daySlots);
  });

  return Object.values(weeks);
}

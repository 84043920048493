import { getRegulations } from "api/regulations";
import { Box, Flex, Link } from "components";
import { Checkbox } from "components/_form";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import React from "react";
import { IRegulation } from "types/regulations";
import { H3, P } from "components/Typography";

export const RegulationCheckboxes = () => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const [regulations, setRegulations] = useState<IRegulation[]>();
  const [acceptedCommonRegulations, setAcceptedCommonRegulations] = useState<
    number[]
  >([]);
  const [acceptedRequiredRegulations, setAcceptedRequiredRegulations] =
    useState<number[]>([]);
  const [requiredRegulationIds, setRequiredRegulationIds] = useState<number[]>(
    [],
  );

  const handleCommonRegulations = (id: number) => {
    setAcceptedCommonRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const handleRequiredRegulations = (id: number) => {
    setAcceptedRequiredRegulations((prevState) => {
      return prevState.includes(id)
        ? prevState.filter((el) => el !== id)
        : [...prevState, id];
    });
  };

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data.filter((v) => v.variant === "appointment"));
  };

  useEffect(() => {
    setValue("regulation_ids", acceptedRequiredRegulations);
  }, [acceptedRequiredRegulations]);

  useEffect(() => {
    loadRegulations();
  }, []);

  useEffect(() => {
    if (!regulations) return;
    let temp: number[] = [];
    regulations.map((e) => {
      if (e.required) temp.push(e.id);
    });
    setRequiredRegulationIds(temp);
  }, [regulations]);

  useEffect(() => {
    if (
      requiredRegulationIds.toString() ===
      acceptedRequiredRegulations.toString()
    ) {
      setValue("regulationsAccepted", true, { shouldValidate: true });
    } else {
      setValue("regulationsAccepted", false, { shouldValidate: true });
    }
  }, [requiredRegulationIds, acceptedRequiredRegulations]);

  return (
    <Flex flexDirection="column" gap="10px" width="100%">
      <Controller
        control={control}
        name={`first_visit`}
        render={({ field: { onChange } }) => (
          <Box display="flex" justifyContent="start" mb={1} alignItems="center">
            <Checkbox
              label="Pierwsza wizyta"
              onChange={(val) => {
                onChange(val.target.checked);
              }}
            />
          </Box>
        )}
      />
      <H3 variant="h3">Zgody:</H3>
      <Flex flexDirection="column">
        {regulations?.map((regulation) => (
          <Box
            key={regulation.id}
            display="flex"
            justifyContent="start"
            mb={1}
            alignItems="center"
          >
            <Checkbox
              onChange={() => {
                !!regulation.required
                  ? handleRequiredRegulations(regulation.id)
                  : handleCommonRegulations(regulation.id);
              }}
              checked={
                !!regulation.required
                  ? !!acceptedRequiredRegulations.find(
                      (el) => el === regulation.id,
                    )
                  : !!acceptedCommonRegulations.find(
                      (el) => el === regulation.id,
                    )
              }
            />
            <Link
              to={regulation.link && `/info${regulation.link}`}
              target={regulation.link && "_blank"}
            >
              <Flex gap="5px">
                {regulation.name}
                {regulation.required && (
                  <P color="red" variant="body">
                    *
                  </P>
                )}
              </Flex>
            </Link>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

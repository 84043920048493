import { Flex, Spinner } from "components";
import { TabPanel, ITab } from "components/TabPanel";
import { useEffect, useState } from "react";
import { AppointmentTile } from "components/AppointmentTile";
import { Pagination } from "./Pagination";
import { useAppointmentsView } from "hooks/useAppointmentsView/useAppointmentsView";
import { H3 } from "components/Typography";
import { SectionHeader } from "components/SectionHeader";

const tabs: ITab[] = [
  { name: "appointmentsHistory" },
  { name: "plannedAppointments" },
];
export const AppointmentsUserView = () => {
  const [currentTab, setCurrentTab] = useState<ITab>(tabs[0]);
  const { appointments, paginationHook, isLoading, setTabsFilter } =
    useAppointmentsView();

  useEffect(() => {
    if (currentTab === tabs[0]) setTabsFilter({ archived: true });
    else setTabsFilter({ archived: false });
  }, [currentTab]);

  return (
    <Flex flexDirection="column">
      <SectionHeader text="Wizyty" />
      <TabPanel
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        <>
          <Flex flexDirection="column">
            {appointments.length > 0 ? (
              <>
                {appointments.map((e) => {
                  return (
                    <AppointmentTile
                      key={e.id}
                      appointment={e}
                      mode={
                        currentTab.name === "appointmentsHistory"
                          ? "historical"
                          : "planned"
                      }
                    />
                  );
                })}
                <Pagination paginationHook={paginationHook} />
              </>
            ) : (
              <H3 variant="h3" color="primary" textAlign="center">
                {!isLoading ? "Brak wizyt do wyświetlenia" : <Spinner />}
              </H3>
            )}
          </Flex>
        </>
      </TabPanel>
    </Flex>
  );
};

import { Box, Card, Flex } from "components";
import { H2, H3, P } from "components/Typography";
import styled from "styled-components";
import { Button } from "components/_form";
import tmpServiceImg from "assets/images/serviceTmp.png";
import { IService } from "types/service";
import { useNavigate } from "react-router-dom";
import { API_PHOTO_URL } from "constants/paths";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)`
  position: relative;
  padding: 0;
`;

const ReadMore = styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
  :hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  cursor: pointer;
  transition: 150ms linear all;
`;

const StyledImg = styled(Box)<{ img?: string }>`
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ img }) =>
    !img ? tmpServiceImg : API_PHOTO_URL + img});
  display: flex;
  flex-flow: column nowrap;
`;

interface Props {
  service: IService["data"];
  userId?: number;
}

export const ServiceTile = ({ service, userId }: Props) => {
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleMakeAppointment = () => {
    navigate(
      `/app/add-appointment/${service.id}${!!userId ? "/" + userId : ""}`,
    );
  };

  return (
    <StyledCard>
      <Flex alignItems="center">
        <StyledImg img={service.thumbnail} />
        <Flex flexDirection="column" flexGrow={1} p={3}>
          <H2 variant="h2" color="coloured">
            {service.name}
          </H2>
          <Flex>
            <H3 variant="h3" mr="10px">
              Czas trwania:
            </H3>
            <H3 variant="h3" color="coloured">
              {`${service.first_time_slots * 10} min`}
              {" | "}
              {`${service.slots * 10} min`}
            </H3>
          </Flex>

          <Flex>
            <H3 variant="h3" mr="10px">
              Cena:
            </H3>
            <H3 variant="h3" color="red">
              {`${Number(service.first_time_price).toFixed(2)} zł`}
              {" | "}
              {`${Number(service.price).toFixed(2)} zł`}
            </H3>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDirection="column" p={3}>
        <P variant="body" color="primary">
          {isReadMore
            ? service.description.slice(0, 200) + "..."
            : service.description}{" "}
          <ReadMore onClick={toggleReadMore}>
            {isReadMore ? "Pokaż więcej" : "Pokaż mniej"}
          </ReadMore>
        </P>

        {role !== "expert" && (
          <Flex justifyContent="end" width="100%" pt={3}>
            <Box>
              <Button
                variant="secondary"
                onClick={handleMakeAppointment}
                label={t("buttons.scheduleApp")}
              >
                ▼
              </Button>
            </Box>
          </Flex>
        )}
      </Flex>
    </StyledCard>
  );
};

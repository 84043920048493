import * as yup from "yup";

export const appointmentSchema = yup.object().shape({
  serviceType: yup.object({
    value: yup.string().required(),
    label: yup.string(),
  }),
  expert: yup.object({
    value: yup.string().required(),
    label: yup.string(),
  }),
  name: yup.string().required(),
  surname: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required("phoneNumberRequired")
    .matches(
      /^(?:(?:\+|00)?48)?([0-9]{4})[-. ]?([0-9]{5})$/,
      "badPhoneFormat",
    ),
  startDate: yup.date().required(),
  startHour: yup.string().required(),
  kind: yup.string().required(),
  regulationsAccepted: yup.bool().required().oneOf([true]),
});

export const updateAppointmentSchema = yup.object().shape({
  serviceType: yup.object({
    value: yup.string().required(),
    label: yup.string(),
  }),
  expert: yup.object({
    value: yup.string().required(),
    label: yup.string(),
  }),
  startDate: yup.date().required(),
  startHour: yup.string().required(),
});

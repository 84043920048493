import { FC } from "react";
import { Flex } from "components";
import styled from "styled-components";
import { ColouredBox } from "components/ColouredBox";
import { Button } from "components/_form";
import { P, H3 } from "components/Typography";
import { IAppointmentExtended } from "types/appointment";
import { getUserFullName } from "utilities/appointment";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const StyledH3 = styled(H3)`
  font-size: 20px;
`;

const StyledP = styled(P)`
  font-size: 12px;
`;

const StyledColouredBox = styled(ColouredBox)`
  p,
  h3 {
    margin-bottom: 5px;
  }
  button {
    padding: 7px 10px;
    font-size: 12px;
  }
`;

interface IAppointmentTile {
  variant?: "light" | "disabled";
  data: IAppointmentExtended["data"];
}

export const AppointmentTile: FC<IAppointmentTile> = ({
  variant = "light",
  data,
}) => {
  const navigate = useNavigate();
  return (
    <StyledColouredBox width="100%" variant={variant} p={10} mt={20}>
      <StyledH3 variant="h3">
        {moment(data.date_and_time_of_appointment).format("HH:mm")} -{" "}
        {moment(data.date_and_time_of_appointment)
          .add(
            data.first_visit
              ? data.service.first_time_slots * 10
              : data.service.slots * 10,
            "minutes",
          )
          .format("HH:mm")}
      </StyledH3>
      <P variant="body2">
        {data.patient_first_name} {data.patient_last_name}
      </P>
      <P variant="body2" fontWeight={700}>
        {data.service.name}
      </P>
      <StyledP variant="body2">
        {
          {
            online: "Online",
            telephone: "Telefoniczna",
            in_person:
              "Stacjonarnie - ul. Antoniego Słonimskiego 26/U01, 50-304 Wrocław",
          }[data.kind]
        }
      </StyledP>
      <Flex justifyContent="end" alignItems="end">
        <Button
          height="30px"
          onClick={() => {
            navigate(`/app/appointments/${data.id}`);
          }}
        >
          Szczegóły wizyty
        </Button>
      </Flex>
    </StyledColouredBox>
  );
};

import { FC, useEffect, useState } from "react";
import { Flex } from "components";
import styled from "styled-components";
import { ResponseInfo } from "./PaymentSummaryInfo";
import { getPayment } from "services/payment";
import { IPaymentReturn } from "types/payment";
import { IPayment } from "types/pay";
import { createPayment } from "services/pay";
import { useNavigate } from "react-router-dom";
import { getSurveyArray } from "utilities/getSurveyArray";
import { IUser } from "types/user";
import { toast } from "react-toastify";

export const PaymentSummaryView: FC = () => {
  const navigate = useNavigate();
  const orderId = localStorage.getItem("orderId");
  const currentUser: IUser = JSON.parse(
    localStorage.getItem("currentUser") || "{}",
  );
  const [data, setData] = useState<IPaymentReturn["data"]>();
  const [showSurveyButton, setShowSurveyButton] = useState<boolean>(false);

  const getPaymentStatus = async () => {
    if (!!orderId) {
      try {
        const response = await getPayment(orderId);
        if (!response) return;
        setData(response.data);
        const survey = getSurveyArray(
          currentUser,
          response.data.service.survey_type,
        );
        setShowSurveyButton(
          !survey.length || currentUser.user_must_repeat_surveys,
        );
      } catch (e: any) {
        toast.error(e.error);
      }
    }
  };

  useEffect(() => {
    getPaymentStatus();

    return () => {
      localStorage.removeItem("orderId");
    };
  }, []);

  const retryPayment = async () => {
    if (data) {
      const payData: IPayment = {
        appointment_id: data.appointment.id,
        unit_price: data.unit_price,
        user_id: data.user.id,
        description: data.description,
      };

      const response = await createPayment(payData);

      if (response.status.statusCode === "SUCCESS") {
        localStorage.setItem("orderId", response.orderId);
        window.location.replace(response.redirectUri);
      }
    }
  };

  const navigateToSurvey = () => {
    if (data && data.service) {
      navigate(`/app/survey/${data.service.survey_type}`);
    }
  };

  return (
    <Flex
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <ResponseInfo
        showSurveyButton={showSurveyButton}
        response={data?.status ?? "none"}
        retryPayment={() => retryPayment()}
        navigateToSurvey={() => navigateToSurvey()}
      />
    </Flex>
  );
};

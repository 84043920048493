export const API_URL = process.env.REACT_APP_API_URL;
export const API_PHOTO_URL = process.env.REACT_APP_BLOB_URL;

export const ME = `${API_URL}/users/me`;
export const USERS = `${API_URL}/users`;
export const USER = (id: string | number) => `${API_URL}/users/${id}`;
export const PROFILE = `${API_URL}/profile`;

export const BLOGS = `${API_URL}/blogs`;
export const BLOG = (slug: string) => `${API_URL}/blogs/${slug}`;
export const TOPICS = `${API_URL}/topics`;
export const TAGS = `${API_URL}/tags`;

export const BLOG_USER = `${API_URL}/blogs_users`;

export const APPOINTMENT = (id: string | number) =>
  `${API_URL}/appointments/${id}`;
export const CANCEL_APPOINTMENT = (id: string | number) =>
  `${API_URL}/appointments/${id}/cancel`;
export const APPOINTMENTS = `${API_URL}/appointments`;
export const SPECIALITY = (id: string | number) =>
  `${API_URL}/specialities/${id}`;
export const SPECIALITIES = `${API_URL}/specialities`;

export const NOTE = (id: string | number) => `${API_URL}/notes/${id}`;
export const NOTES = `${API_URL}/notes/`;

export const SCHEDULE = (id: string | number) => `${API_URL}/schedules/${id}`;
export const SCHEDULES = `${API_URL}/appointments`;
export const INTERVAL = (id: string | number) =>
  `${API_URL}/working_time_intervals/${id}`;
export const INTERVALS = `${API_URL}/working_time_intervals`;

export const FAQS = `${API_URL}/helps`;

export const QUESTIONNAIRE = (id: string | number) =>
  `${API_URL}/surveys/${id}`;
export const QUESTIONNAIRES = `${API_URL}/surveys`;
export const QUESTIONNAIRE_URO = (id: string | number) =>
  `${API_URL}/urogynecological_surveys/${id}`;
export const QUESTIONNAIRES_URO = `${API_URL}/urogynecological_surveys`;
export const QUESTIONNAIRE_DENTAL = (id: string | number) =>
  `${API_URL}/dental_surveys/${id}`;
export const QUESTIONNAIRES_DENTAL = `${API_URL}/dental_surveys`;

export const WEEK = (id: string | number) => `${API_URL}/weeks/${id}`;
export const WEEKS = `${API_URL}/weeks/available`;
export const EXPERT_WEEKS = `${API_URL}/weeks`;

export const SERVICES = `${API_URL}/services`;

export const PAY = `${API_URL}/pay`;

export const PAYMENT = (id: string | number) => `${API_URL}/payments/${id}`;

export const PAYMENTS = `${API_URL}/payments`;

export const ROOMS = `${API_URL}/rooms`;
export const ROOM = (id: string | number) => `${API_URL}/rooms/${id}`;

export const EXAMINATIONS = `${API_URL}/examinations`;
export const EXAMINATION = (id: string | number) =>
  `${API_URL}/examinations/${id}`;

export const COURSES = `${API_URL}/courses`;

export const RESEARCH_CARD = (id: string | number) =>
  `${API_URL}/individual_medical_records/${id}`;
export const RESEARCH_CARDS = `${API_URL}/individual_medical_records`;

export const ICD10_CODES = `${API_URL}/icd10_codes`;

export const MEDICAL_RECOMMENDATIONS = `${API_URL}/medical_recommendations`;

export const MEDICAL_RECOMMENDATION = (id: string | number) =>
  `${API_URL}/medical_recommendations/${id}`;

import { Flex } from "components/Flex";
import { H2, P } from "components/Typography/Typography";
import { Button } from "components/_form";
import styled from "styled-components";
import { IAppointmentExtended } from "types/appointment";
import { Box } from "components/Box";
import { useNavigate } from "react-router-dom";
import { getUserFullName } from "utilities/appointment";
import { PaymentStatus } from "components/PaymentStatus";
import { format } from "date-fns";
import { VerticalLine } from "components/Line";
import { ReactComponent as Survey } from "assets/icons/ico-surveys.svg";
import { ReactComponent as Jitsi } from "assets/icons/logo-jitsi.svg";
import { getSurveyArray } from "utilities/getSurveyArray";
import { useWindowWidth } from "hooks/useWindowWidth";

const Wrapper = styled(Box)<{ canceled: boolean }>`
  min-height: 160px;
  border-radius: 15px;
  box-shadow: 0px 5px 55px rgba(41, 35, 79, 0.15);
  display: grid;
  grid-template-columns: minmax(min-content, 220px) 1fr;
  position: relative;
  ${({ canceled }) =>
    canceled &&
    `opacity: 0.5; ::after { content: 'Anulowana'; position: absolute; font-size: 30px; line-height:30px; font-weight: 600; position: absolute;
margin-left: auto;
margin-right: auto;
top:calc(50% - 15px);
left: 0;
right: 0;
text-align: center; }`};
`;

const TimeWrapper = styled(Flex)`
  border-radius: 15px 0 0 15px;
  width: 100%;
  max-width: 220px;
  ${({ theme }) => `
      background-color: ${theme.palette.navbar};
  `}
`;

export const StyledJitsi = styled(Jitsi)`
  width: 25px;
  height: auto;
`;

export type TMode = "historical" | "planned";

interface IAppointmentTile {
  appointment: IAppointmentExtended["data"];
  mode: TMode;
}

export const AppointmentTile = ({ appointment, mode }: IAppointmentTile) => {
  const navigate = useNavigate();
  const visitDate = new Date(appointment.date_and_time_of_appointment);
  const { isMobile } = useWindowWidth();

  const date = format(visitDate, "dd.MM.yyyy");
  const startAt = format(visitDate, "HH:mm");

  const survey = getSurveyArray(
    appointment.patient,
    appointment.service.survey_type,
  );

  return (
    <fieldset disabled={appointment.canceled}>
      <Wrapper width="100%" my={2} canceled={appointment.canceled}>
        <TimeWrapper
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <H2 textAlign="center" variant="h2" mb={2} color="primary">
            {date}
          </H2>
          <H2 textAlign="center" fontWeight={400} variant="h2" color="primary">
            {startAt}
          </H2>
        </TimeWrapper>
        <Flex
          justifyContent={["center", "center", "space-between"]}
          flexGrow={1}
          flexWrap="wrap"
          flexDirection="row"
          p={2}
        >
          <Flex
            px={[2, 4]}
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Flex flexWrap="wrap">
              <H2 variant="h2" mr={1} color="primary">
                {appointment.service.name} |
              </H2>
              <H2 variant="h2" color="coloured">
                {getUserFullName(appointment.expert)}
              </H2>
            </Flex>
            <Flex flexWrap="wrap">
              {
                {
                  online: (
                    <Flex m={1} flexWrap="wrap" gap="5px" alignItems="center">
                      <P textAlign="center" variant="body2" color="grey">
                        Online
                      </P>
                      <StyledJitsi />
                      <Button
                        onClick={() => {
                          navigate(`/jitsi-meeting/${appointment.room.id}`);
                        }}
                        variant="green"
                        height="25px"
                        disabled={
                          !appointment.room || !appointment.room.isActive
                        }
                      >
                        Dołącz w Jitsi Meet
                      </Button>
                    </Flex>
                  ),
                  in_person: (
                    <P textAlign="center" variant="body2" color="grey">
                      Stacjonarnie - ul. Antoniego Słonimskiego 26/U01, 50-304
                      Wrocław
                    </P>
                  ),
                  telephone: (
                    <P textAlign="center" variant="body2" color="grey">
                      Telefoniczna
                    </P>
                  ),
                }[appointment.kind]
              }
            </Flex>
          </Flex>

          <Flex
            alignItems="center"
            justifyContent="center"
            px={[2, 4]}
            py={[4, 0]}
            flexWrap="wrap"
          >
            {mode === "planned" && (
              <>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  gap="10px"
                  width="150px"
                >
                  <P variant="body2">Status płatności:</P>
                  <PaymentStatus
                    paymentStatus={appointment.payment?.status ?? null}
                  />
                  {!(appointment.payment?.status === "completed") && (
                    <Button variant="red" height="35px">
                      Opłać teraz
                    </Button>
                  )}
                </Flex>
                <Flex height="100%" py={4}>
                  <VerticalLine />
                </Flex>
              </>
            )}

            <Flex
              gap="10px"
              flexDirection="column"
              minWidth={[null, null, "200px"]}
              justifySelf="end"
            >
              <Button
                onClick={() => {
                  navigate(`/app/appointments/${appointment.id}`);
                }}
              >
                Szczegóły wizyty
              </Button>
              <Flex justifyContent={["center", "center", "end"]}>
                {
                  {
                    historical: (
                      <Button
                        onClick={() => {
                          navigate(
                            `/app/add-appointment/${appointment.service.id}/${appointment.expert.id}`,
                          );
                        }}
                        variant="secondary"
                        height="35px"
                      >
                        Umów ponownie
                      </Button>
                    ),
                    planned:
                      !survey.length ||
                      appointment.patient.user_must_repeat_surveys ? (
                        <Button
                          onClick={() => {
                            navigate(
                              `/app/survey/${appointment.service.survey_type}`,
                            );
                          }}
                          variant="secondary"
                          height="35px"
                          icon={<Survey />}
                        >
                          Uzupełnij ankietę
                        </Button>
                      ) : (
                        <></>
                      ),
                  }[mode]
                }
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </fieldset>
  );
};

import { useEffect, useState } from "react";
import { getAppointments } from "services/appointment";
import { IAppointmentExtended } from "types/appointment";
import { PaginationHook, usePagination } from "../usePagination";
import { FetchHook, useFetch } from "../useFetch";
import { getExtendedAppointments } from "./useAppointmentsView.handlers";

const today = new Date();

interface AppointmentsViewHook {
  today: Date;
  appointments: IAppointmentExtended["data"][];
  setAppointments: React.Dispatch<
    React.SetStateAction<IAppointmentExtended["data"][]>
  >;
  dateFilter: TDateFilter;
  setDateFilter: React.Dispatch<React.SetStateAction<TDateFilter>>;
  setTabsFilter: React.Dispatch<React.SetStateAction<TTabsFilter>>;
  fetchHook: FetchHook;
  paginationHook: PaginationHook;
  isLoading: boolean;
}

type TDateFilter = {
  week_number?: number;
};

type TTabsFilter = {
  archived?: boolean;
};

export const useAppointmentsView = (): AppointmentsViewHook => {
  const [appointments, setAppointments] = useState<
    IAppointmentExtended["data"][]
  >([]);
  const [dateFilter, setDateFilter] = useState<TDateFilter>({});
  const [tabsFilter, setTabsFilter] = useState<TTabsFilter>({ archived: true });
  const { role, id } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const fetchHook = useFetch();
  const paginationHook = usePagination();
  const { page, perPage, pages, setPages, setPage } = paginationHook;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAppointments = async () => {
    setAppointments([]);
    if (!role || !id) return;
    const filters = {
      ...(role != "expert" && tabsFilter),
      ...dateFilter,
      ...(role == "expert"
        ? { expert_id: id }
        : { patient_id: id, page, per_page: perPage }),
    };
    const response = await getAppointments(filters);
    setPages(response.pagination?.pages ?? pages);
    setAppointments(
      await getExtendedAppointments(response.data ? response.data : []),
    );
  };

  useEffect(() => {
    setIsLoading(fetchHook.isLoading);
  }, [fetchHook.isLoading]);

  useEffect(() => {
    fetchHook.tryCatchWrapper(fetchAppointments);
  }, [page, perPage]);

  useEffect(() => {
    setPage(1);
    fetchHook.tryCatchWrapper(fetchAppointments);
  }, [dateFilter, tabsFilter]);

  return {
    today,
    appointments,
    setAppointments,
    dateFilter,
    setDateFilter,
    setTabsFilter,
    fetchHook,
    paginationHook,
    isLoading,
  };
};

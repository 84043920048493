import { FC, useState, useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import { useParams } from "react-router-dom";
import { getBlog, getTags, getTopics } from "services/knowledge";
import { Flex } from "../components";
import {
  SearchField,
  TagSelectMenu,
  TopicSelectMenu,
} from "./KnowledgeBaseView/components";
import { VerticalLine } from "./KnowledgeBaseView/KnowledgeBaseView";
import {
  IBlog,
  IBlogFilter,
  IBlogVideos,
  ITag,
  ITopic,
} from "../types/knowledge";
import ReactPlayer from "react-player/lazy";
import { H1, H2, H3 } from "components/Typography";
import { API_PHOTO_URL } from "constants/paths";
import styled from "styled-components";

const sanitize = (dirty: string) => ({
  __html: sanitizeHtml(dirty),
});

const SanitizeHTML = ({ html }: { html: string }) => (
  <div dangerouslySetInnerHTML={sanitize(html)} />
);

const initialFilters: IBlogFilter = {
  topic_ids: [],
  tag_ids: [],
  page: 1,
  per_page: 20,
};

const Photo = styled.img`
  max-width: 250px;
  border-radius: 10px;
`;

export const KnowledgeView: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [page, setPage] = useState<IBlog["data"]>();
  const [videos, setVideos] = useState<IBlogVideos[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<IBlogFilter>(initialFilters);
  const [topics, setTopics] = useState<ITopic[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);

  const initialFetch = () =>
    getTopics().then((response) => {
      if (response) {
        setTopics(response.data);
      }
      getTags().then((response) => {
        if (!response) return;
        setTags(response.data);
      });
    });

  useEffect(() => {
    setIsLoading(true);
    initialFetch().then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!slug) return;
    getBlog(slug).then((response) => {
      if (!response?.data?.content) return;
      setPage(response.data);
      setVideos(response.data.blog_videos);
    });
  }, [slug]);

  if (!page) return <></>;
  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      justifyContent="space-between"
    >
      <Flex
        width="100%"
        flexDirection="column"
        gap="20px"
        alignItems="center"
        flexGrow={2}
      >
        <Flex
          alignItems="center"
          justifyContent="space-around"
          gap="20px"
          width="80%"
          flexWrap={["wrap-reverse", "wrap-reverse", "nowrap"]}
        >
          <H1 variant="h1">{page.title}</H1>
          <Photo src={API_PHOTO_URL + page.thumbnail} />
        </Flex>

        <SanitizeHTML html={page.content} />
        {videos?.map((e) => {
          return (
            <Flex
              key={e.id}
              flexDirection="column"
              gap="5px"
              width="fit-content"
            >
              <H3 variant="h3">{e.title}</H3>
              <ReactPlayer
                width="100%"
                height="auto"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
                controls
                url={process.env.REACT_APP_BLOB_URL + e.video}
              />
            </Flex>
          );
        })}
      </Flex>
      <Flex flexDirection="column">
        <SearchField setFilters={setFilters} />
        <Flex flexDirection="row">
          <VerticalLine />
          <Flex flexDirection="column">
            <TopicSelectMenu
              filters={filters}
              setFilters={setFilters}
              topics={topics}
            />
            <TagSelectMenu
              filters={filters}
              setFilters={setFilters}
              tags={tags}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

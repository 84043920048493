import { FC, useEffect, useState } from "react";
import { H4 } from "components/Typography/Typography";
import { Flex, Card, Spinner } from "components";
import { TestsForm } from "./components/TestsForm";
import { Test } from "./components/Test";
import {
  createExamination,
  deleteExamination,
  getExaminations,
} from "services/examination";
import {
  ICreateExamination,
  ICreateExaminationForm,
  IExamination,
} from "types/examination";
import { toast } from "react-toastify";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { examinationSchema } from "validation/examination";
import { TestAddWithoutFileModal } from "./components/TestAddWithoutFileModal";
import { SectionHeader } from "components/SectionHeader";
import { useWindowWidth } from "hooks/useWindowWidth";

export const TestsView: FC = () => {
  const [examinations, setExaminations] = useState<IExamination[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddWithoutFileModalOpen, setIsAddWithoutFileModalOpen] =
    useState(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");

  const { isMobile } = useWindowWidth();

  const methods = useForm<ICreateExaminationForm>({
    mode: "onChange",
    resolver: yupResolver(examinationSchema),
  });
  const { handleSubmit } = methods;

  const handleExaminations = async () => {
    setIsLoading(true);
    try {
      const result = await getExaminations();
      if (!result) return;
      setExaminations(result.data);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdd = async (data: ICreateExaminationForm) => {
    if (!data.exam_file && !isAddWithoutFileModalOpen) {
      setIsAddWithoutFileModalOpen(true);
      return;
    }

    const dataToSend: ICreateExamination = {
      examination: {
        name: data.name,
        description: data.description,
        exam_file: data.exam_file,
        date: data.date,
        user_id: currentUser.id,
      },
    };

    try {
      const result = await createExamination(dataToSend);
      if (!result) return;
      methods.reset();
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      handleExaminations();
    }
  };

  const handleDelete = async (id: number | string) => {
    try {
      const response = await deleteExamination(id);
      if (!response) return;
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      handleExaminations();
    }
  };

  const onSubmit = handleSubmit((data) => handleAdd(data));

  useEffect(() => {
    handleExaminations();
  }, []);

  return (
    <Flex width="100%" flexDirection="column">
      <SectionHeader text="Moje Badania" />
      <Flex
        justifyContent="space-between"
        gap="30px"
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <Card height="fit-content" width="100%">
          <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
              <TestsForm />
            </form>
          </FormProvider>
        </Card>

        <Flex flexDirection="column" gap="10px" width="100%">
          {isLoading && <Spinner />}
          {!isLoading && examinations?.length > 0 ? (
            examinations.map((test) => (
              <Test key={test.id} test={test} handleDelete={handleDelete} />
            ))
          ) : (
            <H4 variant="h4" color="primary" textAlign="center">
              Brak badań do wyświetlenia
            </H4>
          )}
        </Flex>
      </Flex>

      <TestAddWithoutFileModal
        isOpen={isAddWithoutFileModalOpen}
        onCancelClick={() => {
          setIsAddWithoutFileModalOpen(false);
        }}
        handleSubmit={onSubmit}
      />
    </Flex>
  );
};

import { Flex } from "components";
import styled from "styled-components";
import { Menu } from "components/Menu/Menu";
import { sidebarItems } from "constants/sidebarItems";
import { useState } from "react";
import { device } from "styles/theme";

const Wrapper = styled(Flex)<{ isExpanded?: boolean }>`
  ${({ theme, isExpanded }) => `
    background-color: ${theme.palette.navbar};
    width: ${isExpanded ? "280px" : "100px"};
  `}
  transition: linear 0.2s width;
  flex-shrink: 0;

  @media ${device.tablet} {
    display: none;
  }
`;

interface ISidebar {
  stillExpand?: boolean;
}

export const SideBar = ({ stillExpand }: ISidebar) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(stillExpand ?? false);
  return (
    <Wrapper
      alignItems="center"
      flexDirection="column"
      onMouseEnter={() => {
        !stillExpand && setIsExpanded(true);
      }}
      onMouseLeave={() => {
        !stillExpand && setIsExpanded(false);
      }}
      isExpanded={isExpanded}
    >
      <Menu variant="sidebar" items={sidebarItems} isExpanded={isExpanded} />
    </Wrapper>
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Line, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H2, H3 } from "components/Typography";
import { Button, TextareaField, TextField } from "components/_form";
import { FileField } from "components/_form/FileField";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createExamination } from "services/examination";
import { ICreateExamination, ICreateExaminationForm } from "types/examination";
import { examinationSchema } from "validation/examination";
import { TestAddWithoutFileModal } from "views/TestsView/components/TestAddWithoutFileModal";

interface IAddTestModal extends Pick<IModal, "isOpen" | "onCancelClick"> {
  userId?: number | string;
  fetchTestAgain: () => void;
}

export const AddTestModal = ({
  isOpen,
  onCancelClick,
  userId,
  fetchTestAgain,
}: IAddTestModal) => {
  const [isAddWithoutFileModalOpen, setIsAddWithoutFileModalOpen] =
    useState(false);

  const methods = useForm<ICreateExaminationForm>({
    mode: "onChange",
    resolver: yupResolver(examinationSchema),
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { isValid },
  } = methods;

  const handleAdd = async (data: ICreateExaminationForm) => {
    if (!data.exam_file && !isAddWithoutFileModalOpen) {
      setIsAddWithoutFileModalOpen(true);
      return;
    }

    const dataToSend: ICreateExamination = {
      examination: {
        name: data.name,
        description: data.description,
        exam_file: data.exam_file,
        date: data.date,
        user_id: userId ?? "",
      },
    };

    try {
      const result = await createExamination(dataToSend);
      if (!result) return;
      toast.success("Badanie zostało dodane");
      if (onCancelClick) onCancelClick();
    } catch (err: any) {
      toast.error(err.error);
    } finally {
      reset();
      fetchTestAgain();
    }
  };

  const onSubmit = handleSubmit((data) => handleAdd(data));
  return (
    <>
      <TestAddWithoutFileModal
        isOpen={isAddWithoutFileModalOpen}
        onCancelClick={() => {
          setIsAddWithoutFileModalOpen(false);
        }}
        handleSubmit={onSubmit}
      />
      <Modal isOpen={isOpen} disableBackdropClick>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <H2 variant="h2" color="coloured">
              Dodaj badanie:
            </H2>
            <Line />
            <TextField label="Nazwa badania:" {...register("name")} required />
            <TextField
              label="Data badania:"
              type="date"
              {...register("date")}
              required
            />
            <TextareaField label="Opis" {...register("description")} rows={4} />
            <FileField label="Załącznik:" name="exam_file" />
            <Flex mt={2} justifyContent="center" gap="20px">
              <Button
                variant="red"
                bordered
                label="Anuluj"
                onClick={onCancelClick}
              />
              <Button
                variant="accent"
                label="Zapisz"
                type="submit"
                disabled={!isValid}
                onClick={onSubmit}
              />
            </Flex>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

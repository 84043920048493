import { Flex } from "components";
import { MyAccountState } from "hooks/useMyAccount";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { IUserForm } from "types/forms/user";
import { IUser } from "types/user";
import { AccountDetails } from "../AccountDetails";
import { AccountDetailsSettings } from "../AccountDetailsSettings";
import { ProfessionalInterestsScope } from "../ProfessionalInterestsScope";
import { ProfileCard } from "../ProfileCard";

type AccountFormHooks = UseFormReturn<IUserForm>;

interface IAboutMe {
  myAccountState?: MyAccountState;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  formHook?: AccountFormHooks;
  userData?: IUser;
}

export const AboutMe: FC<IAboutMe> = ({
  myAccountState,
  formHook,
  onSubmit,
  userData,
}) => {
  const user = myAccountState?.user || userData;

  return (
    <Flex
      flexDirection={["column", "column", "row"]}
      justifyContent="space-between"
      gap="20px"
    >
      <ProfileCard
        myAccountState={myAccountState}
        userData={userData}
        formHooks={formHook}
        onSubmit={onSubmit}
      />
      <Flex width="100%" flexDirection="column" gap="20px">
        {myAccountState?.isSettingsMode && formHook && onSubmit ? (
          <AccountDetailsSettings
            myAccountState={myAccountState}
            formHooks={formHook}
            onSubmit={onSubmit}
          />
        ) : (
          <AccountDetails myAccountState={myAccountState} userData={userData} />
        )}

        {user?.role === "expert" && (
          <ProfessionalInterestsScope
            myAccountState={myAccountState}
            userData={userData}
            formHooks={formHook}
            onSubmit={onSubmit}
          />
        )}
      </Flex>

      {/* <PlannedAppointmentsList myAccountState={myAccountState} /> */}
    </Flex>
  );
};

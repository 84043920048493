import { http } from "utilities/http";
import qs from "qs";
import { WEEKS, WEEK, EXPERT_WEEKS } from "constants/paths";
import { IWeek } from "types/week";
import { serialize } from "object-to-formdata";
import { ICreateWeek, IUpdateWeek } from "types/forms/week";

export const getWeeks = async (filters?: {
  page?: string | number;
  per_page?: string | number;
  week_id: string | number;
  service_id?: string | number;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IWeek["data"]>(`${WEEKS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getExpertWeeks = async (filters?: {
  page?: string | number;
  per_page?: string | number;
  owner_id?: string | number;
  week_number?: number;
  year?: string;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IWeek["data"]>(`${EXPERT_WEEKS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createWeek = (week: ICreateWeek) =>
  http<IWeek>(EXPERT_WEEKS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(week),
  });

export const updateWeek = (week: IUpdateWeek, id: string | number) =>
  http<IWeek>(WEEK(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(week),
  });

export const deleteWeek = (id: string | number) =>
  http<IWeek>(WEEK(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

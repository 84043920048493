import { http } from "utilities/http";
import { IUser, IUsers } from "types/user";
import { ME, PROFILE, USERS, USER } from "constants/paths";
import { serialize } from "object-to-formdata";
import qs from "qs";
import { IUpdateProfile, IUpdateUser } from "types/forms/user";

export const getMe = () =>
  http<{ data: IUser }>(ME, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

export const updateUser = async (id: number, user: IUpdateUser) => {
  const res = await http<{ data: IUser }>(`${USERS}/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(user),
  });

  return res.data;
};

export const updateProfile = async (profile: IUpdateProfile) => {
  const res = await http<{ data: IUser }>(PROFILE, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(profile),
  });

  return res.data;
};

export const getUsers = async (filters?: {
  page?: string | number;
  per_page?: string | number;
  role: string;
  service_ids?: number[];
  alphabetically_direction?: string;
  latest_direction?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IUsers>(`${USERS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getUser = async (id: string | number) => {
  return await http<{ data: IUser }>(USER(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H3 } from "components/Typography";
import { Button } from "components/_form";

interface ICourseDeleteModal extends Omit<IModal, "styles" | "children"> {
  handleDelete: () => Promise<void>;
}

export const DeleteCourseModal = ({
  isOpen,
  onCancelClick,
  handleDelete,
}: ICourseDeleteModal) => {
  const onClickHandle = () => {
    handleDelete();
    if (onCancelClick) onCancelClick();
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <Flex p={3} width="100%" flexDirection="column">
        <H3 variant="h3" textAlign="center" color="primary">
          Czy na pewno chcesz usunąć kurs?
        </H3>
        <Flex mt={2} justifyContent="center" gap="20px">
          <Button
            variant="secondary"
            bordered
            label="Nie"
            onClick={onCancelClick}
          />
          <Button variant="red" label="Tak" onClick={onClickHandle} />
        </Flex>
      </Flex>
    </Modal>
  );
};

import { FC, useEffect, useMemo, useState } from "react";
import { Box, Card, Flex } from "components";
import { Button } from "components/_form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { H2, P } from "components/Typography/Typography";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import {
  IHarmonogramForm,
  IHarmonogramWeek,
  Interval,
  WeekDay,
  WeekDayState,
} from "types/forms/schedule";
import { DatePickerField } from "./DatePickerField";
import { WeekDayPicker } from "./WeekDayPicker";
import { IntervalsHeader } from "./IntervalsHeader";
import {
  eachWeekOfInterval,
  getMinutes,
  getWeek,
  getYear,
  setDay,
  setHours,
  setMinutes,
  setWeek,
  setYear,
} from "date-fns";
import { TimePicker } from "../../../components/TimePicker";
import {
  compareDates,
  formatDate,
  getDaysOfWeekBetweenDates,
} from "../../../utilities/filterDates";
import {
  createWeek,
  deleteWeek,
  getExpertWeeks,
  getWeeks,
  updateWeek,
} from "../../../services/week";
import { ICreateWeek, IUpdateWeek } from "../../../types/forms/week";
import { toast } from "react-toastify";
import { IWeek, IWeeks } from "../../../types/week";
import { da } from "date-fns/locale";
import { SectionHeader } from "components/SectionHeader";

interface IEmptyWeek {
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  sun: string;
}

const weekDayLabels = {
  mon: "monday",
  tue: "tuesday",
  wed: "wednesday",
  thu: "thursday",
  fri: "friday",
  sat: "saturday",
  sun: "sunday",
};
const emptyWeek: IEmptyWeek = {
  mon: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  tue: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  wed: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  thu: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  fri: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  sat: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
  sun: "00000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
};
const weekDays: WeekDay[] = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
const weekDaysAlt: WeekDay[] = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
];
const timeValues: string[] = [
  "07:00",
  "07:10",
  "07:20",
  "07:30",
  "07:40",
  "07:50",
  "08:00",
  "08:10",
  "08:20",
  "08:30",
  "08:40",
  "08:50",
  "09:00",
  "09:10",
  "09:20",
  "09:30",
  "09:40",
  "09:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
];

const initWeekDayState: WeekDayState = {
  mon: {
    sub: [],
    value: [],
  },
  tue: {
    sub: [],
    value: [],
  },
  wed: {
    sub: [],
    value: [],
  },
  thu: {
    sub: [],
    value: [],
  },
  fri: {
    sub: [],
    value: [],
  },
  sat: {
    sub: [],
    value: [],
  },
  sun: {
    sub: [],
    value: [],
  },
};

export const HarmonogramForm: FC = () => {
  const { t } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const [isLoading, setIsLoading] = useState(false);
  const [weeks, setWeeks] = useState<IWeeks>();
  const [weekDaysInRange, setWeekDaysInRange] = useState<string[]>([]);
  const [weekDayState, setWeekDayState] =
    useState<WeekDayState>(initWeekDayState);

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IHarmonogramForm>({
    mode: "onChange",
  });
  const watcher = watch();

  const fetchWeeks = (refreshState?: boolean) => {
    currentUser.id &&
      getExpertWeeks({
        owner_id: currentUser.id.toString(),
      })
        .then((res) => {
          setWeeks(res);
        })
        .catch((err) => {
          if (err && err.error) {
            toast.error(err.error);
          }
        });
    if (refreshState) {
      setValue("date_from", null);
      setValue("date_to", null);
      setWeekDaysInRange([]);
      setWeekDayState(initWeekDayState);
    }
  };

  useEffect(() => {
    if (watcher.date_from && watcher.date_to) {
      setWeekDayState(initWeekDayState);
      const daysInRange = getDaysOfWeekBetweenDates(
        watcher.date_from,
        watcher.date_to,
      );
      let filteredWeekDays = weekDaysAlt.filter((el, index) => {
        if (daysInRange.includes(index)) {
          return true;
        }
        setWeekDayState((prevState) => {
          const copy = { ...prevState };
          copy[el] = {
            sub: [],
            value: [],
          };
          return copy;
        });
        return false;
      });
      if (filteredWeekDays.length > 1 && filteredWeekDays[0] === "sun") {
        // @ts-ignore
        filteredWeekDays.push(filteredWeekDays.shift());
      }
      setWeekDaysInRange(filteredWeekDays);
    }
  }, [watcher.date_to, watcher.date_from]);

  const handleSelectTime = (
    weekDay: WeekDay,
    subIndex: number,
    date: Date,
    isTo: boolean,
  ) => {
    setWeekDayState((prevState) => {
      const copy = { ...prevState };
      copy[weekDay].sub[subIndex][+isTo] = date;
      return copy;
    });
  };

  const handleAddTimeRange = (weekDay: WeekDay) => {
    setWeekDayState((prevState) => {
      const copy = { ...prevState };
      copy[weekDay]?.sub.push([null, null]);
      return copy;
    });
  };

  const handleRemoveTimeRange = (weekDay: WeekDay, index: number) => {
    setWeekDayState((prevState) => {
      const copy = { ...prevState };
      copy[weekDay].sub = copy[weekDay]?.sub.filter((el, i) => i !== index);
      return copy;
    });
  };

  const parseHours = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return (
      (hours < 10 ? `0${hours}` : hours) +
      ":" +
      (minutes === 0 ? "00" : minutes)
    );
  };

  const getArrayValuesFromTimeRange = (from: string, to: string) => {
    const indexArray: number[] = [];
    let push = false;
    timeValues.forEach((val, index) => {
      if (val === from) push = true;
      if (val === to) push = false;
      if (push) indexArray.push(index);
    });
    return indexArray;
  };

  const setDayValue = (dayValue: number[], valueToSet: number[]) =>
    dayValue
      .map((el, index) => {
        return valueToSet.includes(index) ? 0 : 1;
      })
      .join("");

  const onSubmit = handleSubmit(async (data) => {
    if (!data.date_from || !data.date_to) return;
    const from = new Date(
      new Date(data.date_from).setDate(data.date_from.getDate()),
    );
    const to = new Date(data.date_to);

    // let payload: (ICreateWeek["week"] & { id?: string | number })[] = [];
    const weekPayload: { [key: string]: string } = {};

    Object.keys(weekDayState).forEach((key) => {
      const day = weekDayState?.[key as keyof WeekDayState];
      let dayTimeIndexes: number[] = [];
      if (day?.value?.length > 0) {
        day?.sub.forEach((range) => {
          const timeIndexes = getArrayValuesFromTimeRange(
            parseHours(range[0] || new Date()),
            parseHours(range[1] || new Date()),
          );
          console.log("timeIndexes", timeIndexes);
          dayTimeIndexes = [...dayTimeIndexes, ...timeIndexes];
        });
      }
      dayTimeIndexes = dayTimeIndexes.filter(
        (value, index, array) => array.indexOf(value) === index,
      );
      console.log("dayTimeIndexes", dayTimeIndexes);
      dayTimeIndexes.length > 0 &&
        (weekPayload[key] = setDayValue(day.value, dayTimeIndexes));
    });

    console.log("weekPayload", weekPayload);

    const weeksByNumber: { [key: number]: any } = {};
    for (
      from;
      from.getTime() <= to.getTime();
      from.setDate(from.getDate() + 1)
    ) {
      const weekNumber = getWeek(from, { weekStartsOn: 1 }) - 1;
      const currentWeek = weeksByNumber[weekNumber]
        ? weeksByNumber[weekNumber]
        : weeks?.data.find((el) => el.week_number === weekNumber) || {
            user_id: currentUser.id,
            year: from.getFullYear(),
            week_number: weekNumber,
            ...emptyWeek,
          };

      console.log("currentWeek", currentWeek);

      const dayIndex = weekDaysAlt.findIndex(
        (weekDay, index) => index === from.getDay(),
      );
      const dayName = weekDaysAlt[dayIndex];
      if (weekPayload[dayName]) {
        currentWeek[dayName] = weekPayload[dayName];
        weeksByNumber[weekNumber] = currentWeek;
      }
    }

    const promises = Object.keys(weeksByNumber).map((weekNumber) => {
      const el = weeksByNumber[weekNumber as unknown as number];
      return el.id
        ? updateWeek(
            {
              week: el,
            },
            el.id,
          )
        : createWeek({
            week: el,
          });
    });
    Promise.all(promises)
      .then(() => {
        fetchWeeks(true);
      })
      .catch((err) => {
        if (err && err.error) {
          toast.error(err.error);
        }
      });
  });

  const prepareExistedDays = (data: IWeek["data"]) => {
    const daysIndexes: number[] = [];
    Object.keys(emptyWeek).map((dayName, index) => {
      if (
        data[dayName as keyof IWeek["data"]] !==
        emptyWeek[dayName as keyof IEmptyWeek]
      ) {
        daysIndexes.push(index + 1);
      }
    });

    const days: Date[] = [];
    daysIndexes.forEach((el) => {
      days.push(
        setDay(
          setWeek(setYear(new Date(), +data.year), +data.week_number),
          el === 7 ? 0 : el,
          { weekStartsOn: 1 },
        ),
      );
    });
    return days;
  };

  const existedDays = useMemo(() => {
    return weeks?.data?.reduce((acc: Date[], el) => {
      return [...acc, ...prepareExistedDays(el)];
    }, []);
  }, [weeks?.data]);

  const selectedDayFromExisted = useMemo(() => {
    const res =
      existedDays &&
      watcher.date_from &&
      // @ts-ignore
      existedDays.find((el) => compareDates(el, watcher.date_from));
    if (res) {
      const weekNumber = getWeek(res, { weekStartsOn: 1 }) - 1;
      const week = weeks?.data.find((el) => el.week_number === weekNumber);
      const dayName = res.getDay() - 1 === -1 ? 6 : res.getDay() - 1;

      // @ts-ignore
      const actualValue = week[weekDays[dayName]];
      // @ts-ignore
      const ranges: {
        val: string;
        index: number;
      }[] = actualValue
        .split("")
        .map((el, index) =>
          !+el
            ? {
                val: timeValues[index],
                index,
              }
            : false,
        )
        .filter((el) => !!el);

      // @ts-ignore
      let rangeValues: {
        val: string;
        index: number;
      }[][] = [];
      for (let i = 0; i < ranges.length; i++) {
        if (i === 0) {
          rangeValues.push([ranges[i]]);
          continue;
        }
        const firstLevelLength = rangeValues.length - 1;
        if (
          rangeValues[firstLevelLength][
            rangeValues[firstLevelLength].length - 1
          ].index +
            1 ===
          ranges[i].index
        ) {
          rangeValues[firstLevelLength].push(ranges[i]);
        } else {
          rangeValues.push([ranges[i]]);
        }
      }

      const stringRangeValues = rangeValues.map((el) => {
        if (el.length === 1) {
          const index = el[0].index;
          return (
            el[0].val +
            " - " +
            (timeValues[index + 1] ? timeValues[index + 1] : "22:00")
          );
        }
        const index = el[el.length - 1].index;
        return (
          el[0].val +
          " - " +
          (timeValues[index + 1] ? timeValues[index + 1] : "22:00")
        );
      });

      setValue("date_to", null);
      setWeekDayState((prevState) => {
        const copy = { ...initWeekDayState };
        copy[weekDays[dayName] as keyof WeekDayState] = {
          sub: [[null, null]],
          value: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ],
        };
        return copy;
      });
      return {
        dayName: weekDays[dayName],
        day: res,
        ranges: stringRangeValues,
      };
    }
    return res;
  }, [watcher.date_from, existedDays]);

  const isSubmitButtonDisabled = useMemo(() => {
    let res = false;
    Object.keys(weekDayState).map((day) => {
      const key = day as keyof IHarmonogramWeek;
      if (weekDayState[key]?.sub?.length > 0) {
        weekDayState[key]?.sub.forEach((el) => {
          if (el[0] === null || el[1] === null) res = true;
        });
      }
    });

    return res;
  }, [weekDayState]);

  const onEditSubmit = handleSubmit(async () => {
    if (!selectedDayFromExisted) return;
    const weekNumber = getWeek(selectedDayFromExisted.day);
    const actualWeek = weeks?.data.find((el) => el.week_number === weekNumber);
    const actualWeekCopy = { ...actualWeek };
    console.log("actualWeek", actualWeek);
    const day = weekDayState?.[selectedDayFromExisted.dayName];
    console.log("day", day);
    let dayTimeIndexes: number[] = [];
    if (day?.value?.length > 0) {
      day?.sub.forEach((range) => {
        const timeIndexes = getArrayValuesFromTimeRange(
          parseHours(range[0] || new Date()),
          parseHours(range[1] || new Date()),
        );
        console.log("timeIndexes", timeIndexes);
        dayTimeIndexes = [...dayTimeIndexes, ...timeIndexes];
      });
    }

    dayTimeIndexes = dayTimeIndexes.filter(
      (value, index, array) => array.indexOf(value) === index,
    );
    console.log("dayTimeIndexes", dayTimeIndexes);
    actualWeekCopy[selectedDayFromExisted.dayName] = setDayValue(
      day.value,
      dayTimeIndexes,
    );

    actualWeekCopy.id &&
      updateWeek(
        { week: actualWeekCopy as IUpdateWeek["week"] },
        actualWeekCopy.id,
      )
        .then(() => {
          fetchWeeks(true);
        })
        .catch((err) => {
          if (err && err.error) {
            toast.error(err.error);
          }
        });
  });

  useEffect(() => {
    fetchWeeks();
  }, []);

  // useEffect(() => {
  //   [6].map(el => deleteWeek(el))
  // }, [])

  const dayIntervals = weekDays
    .filter((weekDay) => weekDayState[weekDay]?.value?.length > 0)
    .map((weekDay) =>
      weekDayState[weekDay]?.sub.map((el, index) => (
        <Flex
          mb={2}
          width="100%"
          flexWrap="wrap"
          key={`${weekDay}-${index}-interval`}
        >
          <Box width="20%" p={2} mt={1}>
            {index === 0 && <P variant="body">{t(weekDayLabels[weekDay])}</P>}
          </Box>
          <Box width="30%" p={2}>
            <TimePicker
              hideTime={{
                from: [7, 0],
                to: [22, 0],
              }}
              selected={el[0]}
              onChange={(date: Date) =>
                handleSelectTime(weekDay, index, date, false)
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption=""
              timeFormat="HH:mm"
              minTime={setHours(setMinutes(new Date(), 0), 7)}
              maxTime={
                el[1]
                  ? setMinutes(el[1], getMinutes(el[1]) - 10)
                  : setHours(setMinutes(new Date(), 0), 22)
              }
              dateFormat="HH:mm"
            />
          </Box>
          <Box width="30%" p={2}>
            <TimePicker
              hideTime={{
                from: [7, 0],
                to: [22, 0],
              }}
              selected={el[1]}
              disabled={!el[0]}
              onChange={(date: Date) =>
                handleSelectTime(weekDay, index, date, true)
              }
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption=""
              timeFormat="HH:mm"
              minTime={
                el[0]
                  ? setMinutes(el[0], getMinutes(el[0]) + 10)
                  : setHours(setMinutes(new Date(), 0), 7)
              }
              maxTime={setHours(setMinutes(new Date(), 0), 22)}
              dateFormat="HH:mm"
            />
          </Box>
          <Flex alignItems="center" width="20%" p={2} mt={1}>
            {index !== 0 && (
              <TrashIcon
                onClick={() => handleRemoveTimeRange(weekDay, index)}
              />
            )}
            {weekDayState[weekDay]?.sub.length - 1 === index && (
              <PlusIcon onClick={() => handleAddTimeRange(weekDay)} />
            )}
          </Flex>
        </Flex>
      )),
    );

  return (
    <Flex justifyContent="center" flexDirection="column" width="100%">
      <SectionHeader text="Moja dostępność" />
      <Card>
        <H2 variant="h2">Wybierz dni aby ustalić dostępność:</H2>
        <form onSubmit={onSubmit}>
          <Flex flexWrap="wrap" my={3}>
            <DatePickerField
              key={existedDays?.length}
              minDate={new Date()}
              name="date_from"
              label={t("inputs.dateFrom") + ":"}
              locale="en-GB"
              dayClassName={(date: Date) => {
                return existedDays &&
                  existedDays.find((el) => compareDates(el, date))
                  ? "date-exists"
                  : null;
              }}
              selected={watcher.date_from}
              onChange={(val) => setValue("date_from", val)}
            />
            <DatePickerField
              minDate={watcher.date_from}
              disabled={!watcher.date_from || !!selectedDayFromExisted}
              name="date_to"
              label={t("inputs.dateTo") + ":"}
              locale="en-GB"
              selected={watcher.date_to}
              onChange={(val) => setValue("date_to", val)}
            />
          </Flex>
          {watcher.date_from && watcher.date_to && (
            <>
              <WeekDayPicker
                weekDayState={weekDayState}
                setWeekDayState={setWeekDayState}
                weekDays={weekDaysInRange as WeekDay[]}
              />
              <Flex
                alignItems={["start", "center"]}
                flexDirection={["column", "column", "row"]}
                my={3}
                flexWrap="wrap"
              ></Flex>
              <Flex width="100%" flexWrap="wrap">
                <IntervalsHeader />
                {dayIntervals}
                <Button disabled={isSubmitButtonDisabled} onClick={onSubmit}>
                  {t("save")}
                </Button>
              </Flex>
            </>
          )}
          {selectedDayFromExisted && (
            <>
              <Box>
                Wprowadź nowe okresy dla{" "}
                {formatDate(selectedDayFromExisted.day)}
              </Box>
              <Box my={2}>
                Aktualne okresy{" "}
                {selectedDayFromExisted.ranges.map(
                  (el, index) =>
                    el +
                    (index + 1 < selectedDayFromExisted.ranges.length
                      ? ", "
                      : ""),
                )}
              </Box>
              {dayIntervals}
              <Button
                variant="secondary"
                disabled={isSubmitButtonDisabled}
                onClick={onEditSubmit}
              >
                {t("save")}
              </Button>
            </>
          )}
        </form>
      </Card>
    </Flex>
  );
};

import { ReactComponent as KnowledgeBaseIcon } from "assets/icons/menu-knowledge-base.svg";
import { ReactComponent as AppointmentsIcon } from "assets/icons/menu-appointments.svg";
import { ReactComponent as ExaminationsIcon } from "assets/icons/menu-examinations.svg";
import { ReactComponent as AddApointmentIcon } from "assets/icons/menu-add-appointment.svg";
import { ReactComponent as PatientsIcon } from "assets/icons/menu-patients.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/menu-calendar.svg";
import { IMenuItem } from "components/Menu/Menu";

export const sidebarItems: IMenuItem[] = [
  {
    icon: <AddApointmentIcon />,
    label: "Umów wizytę",
    path: "/app/add-appointment",
    access: ["user"],
  },
  {
    icon: <AppointmentsIcon />,
    label: "Wizyty",
    path: "/app/appointments",
    access: ["user", "expert"],
  },
  {
    icon: <CalendarIcon />,
    label: "Kalendarz specjalistów",
    path: "/app/expert-calendar",
    access: ["expert"],
  },
  {
    icon: <PatientsIcon />,
    label: "Pacjenci",
    path: "/app/patients",
    access: ["expert"],
  },
  {
    icon: <ExaminationsIcon />,
    label: "Moje Badania",
    path: "/app/tests",
    access: ["user"],
  },
  {
    icon: <KnowledgeBaseIcon />,
    label: "Baza wiedzy",
    path: "/app/knowledge-base",
    access: ["user", "expert"],
  },
];

import { useState } from "react";
import { Card, Flex } from "components/index";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "assets/icons/minus.svg";
import { H3, P } from "components/Typography";
import styled from "styled-components";

const StyledCard = styled(Card)<{ isDisabled?: boolean; isOpen?: boolean }>`
  ${({ isDisabled = false, isOpen, theme }) => `
    ${isDisabled && `cursor: default`};
    ${isOpen && `background-color: ${theme.palette.secondary.main}`};
    ${isOpen && `color: ${theme.palette.neutral.white}`};
  `}
  padding: 12px 20px;
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  user-select: none;
`;

export const FaqAccordion = ({
  question,
  answer,
}: {
  question: string;
  answer: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCard
      onClick={() => setIsOpen((prevState) => !prevState)}
      isOpen={isOpen}
    >
      <Flex flexDirection="row">
        <Flex alignItems="center" mr={3}>
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </Flex>
        <Flex flexDirection="column">
          <StyledFlex>
            <H3 variant="h3" color={isOpen ? "white" : "primary"}>
              {question}
            </H3>
          </StyledFlex>
          {isOpen && (
            <P variant="body2" mt={3}>
              {answer}
            </P>
          )}
        </Flex>{" "}
      </Flex>
    </StyledCard>
  );
};

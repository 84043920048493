import { FC, useEffect, useState } from "react";
import { Flex, Card, Box } from "components";
import { P } from "components/Typography";
import { ReactComponent as EditIcon } from "assets/icons/ico-notepad.svg";
import { TextareaField } from "components/_form";
import { useTranslation } from "react-i18next";
import { Header, SmallButton } from "./Notes.styled";
import { useNotes } from "hooks/useNotes";

interface INotes {
  noteId?: number;
  appointmentId: number;
}

export const Notes: FC<INotes> = ({ noteId, appointmentId }) => {
  const { t } = useTranslation();
  const { note, content, handleUpdateNote, setContent } = useNotes({
    noteId,
    appointmentId,
  });
  const [mode, setMode] = useState<"write" | "read">("read");

  const onSubmit = () => {
    handleUpdateNote(content);
  };

  useEffect(() => {
    setMode("read");
  }, [note]);

  return (
    <Card minWidth="300px" p={0} mt={20}>
      <Header>
        <EditIcon />
        <P variant="body2" fontWeight={700} color="white">
          {t("notes")}
        </P>
        <P variant="body2" color="white">
          ({t("visibleOnlyForYou")})
        </P>
      </Header>
      <Box p={10} height={mode === "read" ? "100%" : "350px"}>
        <TextareaField
          placeholder={t("startWriting") ?? ""}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          disabled={mode === "read"}
          hideError
          rows={8}
        />
      </Box>
      <Flex justifyContent="end" alignItems="center" px={15} pb={10}>
        {
          {
            write: (
              <>
                <SmallButton
                  variant="secondary"
                  onClick={() => setMode("read")}
                >
                  {t("buttons.cancel")}
                </SmallButton>
                <SmallButton onClick={onSubmit}>
                  {t("buttons.save")}
                </SmallButton>
              </>
            ),
            read: (
              <SmallButton onClick={() => setMode("write")}>
                {t("buttons.edit")}
              </SmallButton>
            ),
          }[mode]
        }
      </Flex>
    </Card>
  );
};

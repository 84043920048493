import { SERVICES } from "constants/paths";
import qs from "qs";
import { IServices } from "types/service";
import { http } from "utilities/http";

export const getServices = async (filters?: {
  page?: string | number;
  per_page?: string | number;
  user_ids?: number[];
}) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IServices>(`${SERVICES}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

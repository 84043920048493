import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { getUsers } from "services/user";
import { IUser, TUserSelectOptions } from "types/user";
import { getWeeks } from "services/week";
import { IWeekDays } from "types/week";
import { getISOWeek } from "date-fns";
import { useFetch } from "./useFetch";
import { getDateFromWeek } from "utilities/week";
import { PaginationHook, usePagination } from "./usePagination";
import { SetState } from "immer/dist/internal";

const date = new Date();

const year = date.getFullYear().toString();

const weekNumber = getISOWeek(date);

// const getAvailableDays = async (expert_id: number) => {
//   let availableDays: Date[] = [];
//   let weeksChecked = 0;

//   while (availableDays.length < 5 && weeksChecked < 5) {
//     const request_weeks_number = weekNumber + weeksChecked;
//     const response = await getWeeks({
//       owner_id: expert_id.toString(),
//       year,
//       week_number: request_weeks_number.toString(),
//     });

//     const { data: weeks } = response;

//     const days = Object.keys({} as IWeekDays) as Array<keyof IWeekDays>;

//     for (const week of weeks) {
//       for (const day of days) {
//         const dayData = week[day];
//         if (typeof dayData === "string" && dayData.includes("0")) {
//           const date = getDateFromWeek(week, day);
//           availableDays.push(date);
//           if (availableDays.length === 5) {
//             break;
//           }
//         }
//       }
//     }
//     weeksChecked++;
//   }
//   return availableDays;
// };

export const useExperts = (): ExpertsHook => {
  const { setIsLoading, setError, isLoading } = useFetch();
  const [option, setOption] = useState<TUserSelectOptions>("alphabethic");
  const [experts, setExperts] = useState<IUser[]>([]);
  const pagination = usePagination();

  const searchExperts = async (searchText: string) => {
    setIsLoading(true);
    try {
      const response = await getUsers({
        role: "expert",
        first_name: searchText,
      });
      const response2 = await getUsers({
        role: "expert",
        last_name: searchText,
      });
      const response3 = await getUsers({
        role: "expert",
        phone_number: searchText,
      });
      if (!response.data || !response2.data || !response3.data) return;
      setExperts([...response.data, ...response2.data, ...response3.data]);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExperts = async () => {
    setError("");
    setIsLoading(true);
    try {
      const response = await getUsers({
        role: "expert",
        per_page: pagination.perPage,
        page: pagination.page,
        ...(option === "alphabethic"
          ? { alphabetically_direction: "ASC" }
          : { latest_direction: "DESC" }),
      });
      if (!response.data.length) return;
      const expertsData = await Promise.all(
        response.data.map(async (expert) => {
          return {
            ...expert,
          };
        }),
      );
      setExperts(expertsData);
    } catch (err: any) {
      setError(err.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExperts();
  }, [option]);

  return {
    experts,
    isLoading,
    pagination,
    setOption,
    option,
    searchExperts,
    fetchExperts,
  };
};

interface ExpertsHook {
  experts: IUser[];
  isLoading: boolean;
  pagination: PaginationHook;
  option: TUserSelectOptions;
  setOption: Dispatch<SetStateAction<TUserSelectOptions>>;
  searchExperts: (searchText: string) => Promise<void>;
  fetchExperts: () => Promise<void>;
}

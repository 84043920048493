import { Card, Flex, Line } from "components";
import { H2, H3, P } from "components/Typography";
import { IUser } from "types/user";
import { Inline } from "./Components.styled";

interface IProfileView {
  patientData: IUser;
}

export const ProfileCard = ({ patientData }: IProfileView) => {
  return (
    <Card>
      <H2 variant="h2" color="coloured">
        Dane osobowe:
      </H2>
      <Line />
      <Flex gap="5px" width="100%" flexDirection="column">
        <Inline>
          <P variant="body" color="grey">
            Imię:
          </P>
          <H3 variant="h3" color="primary">
            {patientData?.profile.first_name}
          </H3>
        </Inline>
        <Inline>
          <P variant="body" color="grey">
            Nazwisko:
          </P>
          <H3 variant="h3" color="primary">
            {patientData?.profile.last_name}
          </H3>
        </Inline>
        <Inline>
          <P variant="body" color="grey">
            PESEL:
          </P>
          <H3 variant="h3" color="primary">
            {patientData?.profile.pesel ?? "-"}
          </H3>
        </Inline>
        <Inline>
          <P variant="body" color="grey">
            Numer telefonu:
          </P>
          <H3 variant="h3" color="primary">
            {patientData?.profile.phone_number ?? "-"}
          </H3>
        </Inline>
        <Inline>
          <P variant="body" color="grey">
            Email:
          </P>
          <H3 variant="h3" color="primary">
            {patientData?.email}
          </H3>
        </Inline>
        <Inline>
          <P variant="body" color="grey">
            Adres zamieszkania:
          </P>
          <H3 variant="h3" color="primary">
            {`${patientData?.profile.street_address ?? ""}, ${
              patientData?.profile.zip ?? ""
            } ${patientData?.profile.city ?? ""}`}
          </H3>
        </Inline>
      </Flex>
    </Card>
  );
};

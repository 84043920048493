import { useState, useEffect } from "react";
import { IAppointmentExtended } from "types/appointment";
import { IBlogs } from "types/knowledge";
import { fetchAppointments } from "./useAppointments.handlers";
import { useFetch, FetchHook } from "../useFetch";
import { isAppointmentOld } from "utilities/appointment";
import { useTranslation } from "react-i18next";

export interface UseAppointmentHook extends FetchHook {
  appointment?: IAppointmentExtended["data"];
  recommendedBlogs: IBlogs["data"];
  old: boolean;
  canceled: boolean;
}

export const useAppointment = (id: string): UseAppointmentHook => {
  const { t } = useTranslation();
  const fetchHook = useFetch();
  const { setError, setIsLoading } = fetchHook;
  const [appointment, setAppointment] =
    useState<IAppointmentExtended["data"]>();
  const [recommendedBlogs, setRecommendedBlogs] = useState<IBlogs["data"]>([]);
  const [old, setOld] = useState(false);
  const [canceled, setCanceled] = useState(false);
  useEffect(() => {
    let slots = appointment?.first_visit
      ? appointment.service.first_time_slots
      : appointment?.service.slots;
    setOld(
      !!appointment &&
        isAppointmentOld(appointment.date_and_time_of_appointment, slots ?? 0),
    );
    setCanceled(!!appointment && appointment.canceled);
  }, [appointment]);

  const fetchAppointmentData = async () => {
    setIsLoading(true);
    setError("");

    try {
      const { appointment, recommendedBlogs } = await fetchAppointments(id);

      setIsLoading(false);
      setAppointment(appointment);
      setRecommendedBlogs(recommendedBlogs ?? []);
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message || t("fetchingError"));
    }
  };

  useEffect(() => {
    fetchAppointmentData();
  }, []);

  return {
    ...fetchHook,
    appointment,
    recommendedBlogs,
    old,
    canceled,
  };
};

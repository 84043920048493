import { FC } from "react";
import { Flex } from "components";
import { WeekDay, WeekDayState } from "types/forms/schedule";
import { WeekDayButton } from "./HarmonogramForm.styled";
import { Dispatch, SetStateAction } from "react";

const weekDayShortLabels = {
  mon: "P",
  tue: "W",
  wed: "Ś",
  thu: "C",
  fri: "P",
  sat: "S",
  sun: "N"
};

type WeekDayPickerProps = {
  weekDayState: WeekDayState;
  setWeekDayState: Dispatch<SetStateAction<WeekDayState>>;
  weekDays: WeekDay[];
};

export const WeekDayPicker: FC<WeekDayPickerProps> = ({
                                                        weekDayState,
                                                        setWeekDayState,
                                                        weekDays
                                                      }) => (
  <Flex>
    {weekDays?.map((weekDay) => (
      <WeekDayButton
        active={weekDayState[weekDay]?.value?.length > 0}
        onClick={() =>
          setWeekDayState((prevState) => ({
            ...prevState,
            [weekDay]: prevState[weekDay]?.value?.length < 1 ? {
              sub: [[null, null]],
              value: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0
              ],
            } : {
              sub: [],
              value: []
            }
          }))
        }
        key={weekDay}
      >
        {weekDayShortLabels[weekDay]}
      </WeekDayButton>
    ))}
  </Flex>
);

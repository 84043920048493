import { FC } from "react";
import { H3 } from "components/Typography/Typography";
import { Flex, Box } from "components";
import { useTranslation } from "react-i18next";
import { ColouredBox } from "components/ColouredBox";
import { Button, TextareaField, TextField } from "components/_form";
import { IUserForm } from "types/forms/user";
import { UseFormReturn } from "react-hook-form";
import { MyAccountState } from "hooks/useMyAccount";
import { StyledLine } from "views/MyAccountView/MyAccountView.styled";

type AccountFormHooks = UseFormReturn<IUserForm>;

interface IAccountDetailsSettings {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  myAccountState: MyAccountState;
  formHooks: AccountFormHooks;
}

export const AccountDetailsSettings: FC<IAccountDetailsSettings> = ({
  myAccountState: { setIsSettingsMode, user },
  formHooks: {
    reset,
    register,
    formState: { errors },
  },
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <ColouredBox width="100%" shadow maxHeight="fit-content">
      <form onSubmit={onSubmit}>
        <Flex flexDirection="column" p={4}>
          {user?.role === "expert" ? (
            <>
              <H3 variant="h2" color="coloured" mb={2}>
                O mnie:
              </H3>
              <StyledLine my={3} />
              <TextareaField
                error={errors.about_me?.message}
                {...register("about_me")}
              />
            </>
          ) : (
            <>
              <H3 variant="h2" color="coloured" mb={2}>
                Moje dane:
              </H3>
              <StyledLine my={3} />
              <TextField
                label={t("inputs.email") + ":"}
                error={errors.email?.message}
                {...register("email")}
                disabled
              />
              <TextField
                label={t("inputs.phone") + ":"}
                error={errors.email?.message}
                {...register("phone_number")}
              />
              <TextField
                label={t("inputs.pesel") + ":"}
                error={errors.email?.message}
                {...register("pesel")}
              />
              <TextField
                label={t("inputs.address") + ":"}
                error={errors.email?.message}
                {...register("street_address")}
              />
              <Flex width="100%" gap="10px">
                <TextField
                  label={t("inputs.zip") + ":"}
                  error={errors.email?.message}
                  {...register("zip")}
                />
                <TextField
                  label={t("inputs.city") + ":"}
                  error={errors.email?.message}
                  {...register("city")}
                />
              </Flex>
            </>
          )}
          <Flex justifyContent="end" alignItems="end" width="100%">
            <Box py={3} mr={2}>
              <Button
                variant="red"
                bordered
                onClick={() => {
                  reset();
                  setIsSettingsMode(false);
                }}
              >
                Anuluj
              </Button>
            </Box>
            <Box py={3}>
              <Button type="submit" variant="accent">
                {t("save")}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </form>
    </ColouredBox>
  );
};

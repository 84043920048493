import styled from "styled-components";
import { H3 } from "components/Typography/Typography";
import { Box } from "components";
import { rgba } from "utilities/rgba";

export const StyledH3 = styled(H3)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 25px;
  font-size: 19px;
`;

export const StyledLine = styled(Box)`
  width: 100%;
  height: 2px;
  ${({ theme }) => `
    background-color: ${rgba(theme.palette.primary.main, 0.15)}
  `};
`;

import styled from "styled-components";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { rgba } from "utilities/rgba";

interface TimePickerProps extends ReactDatePickerProps {
  hideTime: {
    from: [number, number]
    to: [number, number]
  };
}

export const StyledTimePicker = styled(DatePicker)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
    color: ${theme.palette.text.primary};
    border: 1px solid ${theme.palette.neutral.medium};

    &:focus {
      border-radius: 4px;
      border-color: ${theme.palette.primary.main};
    }

    &:disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};
    }
  `}

  border-radius: 30px;
  outline: none;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 4px;
  font-size: 13px;
  text-align: center;
`;

export const TimePicker = (props: TimePickerProps) => {
  const handleColor = (time: Date) => {
    const hide = props.hideTime;
    return (
      (time.getHours() < hide.from[0] || (time.getHours() === hide.from[0] && time.getMinutes() < hide.from[1]))
      ||
      (time.getHours() > hide.to[0] || (time.getHours() === hide.to[0] && time.getMinutes() > hide.to[1])))
      ? "d-none"
      : null;
  };

  return (
    <>
      <StyledTimePicker
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeClassName={handleColor}
        dateFormat="hh:mm"
        {...props}
      />
    </>
  );

};

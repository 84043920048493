import {
  IAppointmentFormData,
  ICreateAppointment,
} from "types/forms/appointment";
import { format, getDay } from "date-fns";
import { IUser } from "types/user";
import { hours } from "views/AppointmentFormView/AppointmentForm/components/HoursSelect";
import moment from "moment";

const ONE_HOUR = 60 * 60 * 1000;
const HALF_HOUR = ONE_HOUR / 2;

export function addHalfHour(startDate: Date) {
  return new Date(startDate.getTime() + HALF_HOUR).toJSON();
}

export function preparePayload(
  data: IAppointmentFormData,
  patient_id: number,
): ICreateAppointment {
  const {
    startDate,
    startHour,
    kind,
    expert,
    week_id,
    serviceType,
    regulation_ids,
    first_visit,
  } = data;
  const weekDay = getDay(startDate) === 0 ? 6 : getDay(startDate) - 1;

  const limit = first_visit
    ? serviceType?.data.first_time_slots
    : serviceType?.data.slots;
  let slots: string = `${weekDay}_${hours.indexOf(data.startHour)}_${
    hours.indexOf(data.startHour) + (limit - 1)
  }`;

  return {
    appointment: {
      expert_id: +expert.value,
      patient_id,
      kind,
      week_id,
      slot_ref: slots,
      service_id: +serviceType.value,
      cabinet_id: null,
      patient_first_name: data.name,
      patient_last_name: data.surname,
      patient_email: data.email,
      patient_phone_number: data.phone,
      regulation_ids: regulation_ids,
      first_visit: first_visit,
    },
  };
}

export function getDateTime(JSONDate: string): string {
  const date = new Date(JSONDate);
  return format(date, "dd.MM.y - HH:mm");
}

export function getTime(JSONDate: string): string {
  const date = new Date(JSONDate);
  return format(date, "HH:mm");
}

export function getDate(JSONDate: string): string {
  const date = new Date(JSONDate);
  return format(date, "dd.MM.y");
}

export function isAppointmentOld(JSONDate: string, slots: number): boolean {
  const finish_at = moment(JSONDate)
    .add(10 * slots, "minutes")
    .toJSON();
  return new Date(finish_at).getTime() < new Date().getTime();
}

export function getUserFullName(user: IUser): string {
  return user?.profile?.first_name + " " + user?.profile?.last_name;
}

import { FC } from "react";
import { Flex } from "components";
import styled from "styled-components";
import { H1, H2, P } from "components/Typography";

const StyledFlex = styled(Flex)`
  li {
    margin-inline-start: 30px;
  }
  ul li {
    list-style: circle;
  }
`;

export const PrivacyPolicyView: FC = () => {
  return (
    <StyledFlex
      width={["100%", "60%"]}
      flexDirection="column"
      gap="20px"
      margin="0 auto"
    >
      <H1 variant="h1">
        Polityka Prywatności i informacja o ochronie danych osobowych Cherry
        Olga Jankowska
      </H1>
      <H2 variant="h2">Kim jesteśmy?</H2>
      <P variant="body">
        Cherry Olga Jankowska z siedzibą we Wrocławiu przy ul. Antoniego
        Słonimskiego 26/U01 (50-304). Prowadzimy platformę internetową
        www.fizjo24.pl oraz oferujemy aplikację mobilną Fizjo24 oraz świadczymy
        usługi stacjonarne we Wrocławiu pod marką Cryo Centrum Zdrowia i Urody.
        Dla celu stosowania przepisów o ochronie danych osobowych, w
        szczególności Ogólnego Rozporządzenia o Ochronie Danych Osobowych
        [(Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
        kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych)] .
      </P>
      <H2 variant="h2">Informacje dla użytkowników Fizjo24</H2>
      <H1 variant="h1">
        I. JEŻELI JESTEŚ PACJENTEM (KORZYSTASZ Z NASZYCH USŁUG DLA CELÓW INNE
        NIŻ ZAWODOWE)
      </H1>
      <H2 variant="h2">
        A) Jak pozyskujemy dane osobowe; dla jakich celów i w oparciu o jakie
        podstawy prawne je przetwarzamy?
      </H2>
      <H2 variant="h2">
        1. Zakładanie konta użytkownika (pacjenta) i korzystanie z naszych Usług
      </H2>
      <P variant="body">
        Pozyskujemy Twoje dane osobowe bezpośrednio od Ciebie w momencie kiedy
        zakładasz konto użytkownika lub korzystasz z naszych Usług. Kiedy
        rejestrujesz u nas konto użytkownika, prosimy Cię o podanie podstawowych
        informacji, takich jak adres e- mail. Możesz również założyć konto
        użytkownika z wykorzystaniem swoich kont w innych serwisach (np.
        Facebook, Google czy Apple). W takim przypadku prosimy obsługujące te
        konta firmy o podzielenie się z nami Twoimi danymi - zgodnie z
        informacją, którą prezentujemy przed założeniem konta użytkownika.
        Możesz korzystać z naszych Usług przez naszą platformę internetową oraz
        aplikację mobilną. Korzystając z naszych Usług, możesz również podać
        więcej danych osobowych, takich jak imię i nazwisko, płeć, numer
        telefonu. Będziemy przetwarzać te dane dla celów umożliwienia Ci
        korzystania z naszych Usług. Nasze Usługi pozwalają Ci, między innymi,
        na korzystanie z następujących funkcjonalności: rezerwowanie wizyt u
        specjalistów, przechowywanie informacji o Tobie na koncie użytkownika
        (pacjenta), rozmowa ze specjalistami z użyciem czatu, wysyłanie
        specjalistom i placówkom wiadomości, dodawanie opinii o swoich
        doświadczeniach ze specjalistami, dostęp do historii badań, dostęp do
        udostępnionych filmów oraz innych treści przez specjalistę. Możesz
        również sprawdzać historię swoich wizyt umówionych przez nasz serwis
        oraz zarządzać swoim kontem użytkownika (pacjenta). W momencie, kiedy
        zakładasz konto użytkownika (pacjenta), zawierasz z nami umowę o
        świadczenie usług drogą elektroniczną. Umowa ta stanowi podstawę prawną
        dla przetwarzania danych osobowych zgodnie z art. 6 ust. 1 lit. b RODO.
      </P>
      <H2 variant="h2">
        2. Umawianie wizyty u specjalisty lub umawianie się na badanie
      </H2>
      <P variant="body">
        Kiedy umawiasz wizytę u specjalisty lub umawiasz badanie z
        wykorzystaniem naszych Usług, otrzymujemy od Ciebie dodatkowe dane
        osobowe. Zakres tych danych może obejmować:
      </P>
      <ul>
        <li>Imię i nazwisko,</li>
        <li>Numer telefonu,</li>
        <li>Adres e-mail,</li>
        <li>Datę i godzinę wizyty lub badania,</li>
        <li>Powód Twojej wizyty lub badania,</li>
        <li>Typ wizyty lub badania,</li>
        <li>
          Numer PESEL (w niektórych przypadkach, gdy specjalista lub placówka
          oczekują pozyskania takiej danej osobowej przy umówieniu wizyty),
        </li>
        <li>
          Inne informacje jakie podasz podczas procesu umawiania wizyty lub
          badania (np. komentarze dla lekarza, inne informacje, które podasz).
        </li>
      </ul>
      <P variant="body">
        Będziemy przechowywać te dane osobowe i udostępnimy je specjaliście lub
        placówce, która zatrudnia specjalistę lub która będzie przeprowadzać
        badania. W momencie, gdy udostępniamy dane osobowe specjaliście lub
        placówce, staje się ona niezależnym od nas i samodzielnym
        administratorem danych osobowych, która będzie przetwarzać dane osobowe
        dla własnych celów (np. dla celów udzielania porad medycznych lub
        podobnych). Zachęcamy do zapoznania się z polityką prywatności
        specjalisty lub placówki w celu uzyskania dalszych informacji w tym
        zakresie.
      </P>
      <P variant="body">
        Jeżeli umawiasz wizytę u specjalisty lub badanie, korzystając z naszych
        Usług, możemy przetwarzać (przechowywać, udostępniać specjaliście lub
        placówce) dane osobowe, które mogą być uznane za dane o stanie zdrowia.
        W szczególności za takie dane mogą zostać uznane informacje o tym, jaki
        rodzaj wizyty jest umawiany, powód wizyty lub badania, historię
        umawianych wizyt lub badań. Z tego powodu będziemy prosić o wyrażenie
        przez Ciebie wyraźnej zgody na przetwarzanie danych o stanie zdrowia
        (zgodnie z art. 9 ust. 2 lit. a RODO). Zgoda jest nam potrzebna na
        przechowywanie tych danych, umożliwienie korzystania z naszych Usług
        oraz udostępnienie ich wybranemu specjaliście lub placówce. Bez takiej
        zgody nie będziemy mogli umówić wizyty lub badania, jak również nie
        będziemy mogli świadczyć wszystkich naszych Usług.
      </P>
      <P variant="body">
        Fizjo24 wysyła potwierdzenie umówienia wizyty jako SMS lub e-mail.
        Możemy również wysłać przypomnienie o wizycie lub badaniu, jeżeli ich
        termin się zbliża. Po wizycie lub badaniu możemy również poprosić o
        wystawienie opinii w naszym serwisie. Jeżeli placówka lub specjalista
        odwoła wizytę lub badanie, również prześlemy informacje na ten temat.
      </P>
      <P variant="body">
        Przed wizytą lub badaniem istnieje możliwość przesłania do specjalisty
        lub placówki dodatkowych danych (np. o alergiach, historii choroby
        itp.), które pozwolą lepiej przygotować się do wizyty. Specjalista lub
        placówka może również wysłać do pacjenta ankietę z prośbą o podanie
        informacji przed wizytą. Wypełnienie ankiety pozwoli specjaliście lub
        placówce lepiej przygotować się do wizyty. Ankieta jest wysłana przez
        specjalistę lub placówkę i to te podmioty są odpowiedzialne za jej
        treść. Odpowiedzi pacjenta trafiają do placówki lub specjalisty.
      </P>
      <H2 variant="h2">
        3. Przechowywanie danych na koncie użytkownika i dzielenie się nimi ze
        specjalistą
      </H2>
      <P variant="body">
        Jako część naszych Usług umożliwiamy naszym użytkownikom przechowywanie
        wybranych przez nich informacji i danych na koncie użytkownika
        (pacjenta). Ta funkcjonalność pozwala m.in. na:
      </P>
      <ol>
        <li>Przechowywanie danych osobowych w jednym miejscu,</li>
        <li>
          Łatwy dostęp do informacji o chorobach, alergiach, przyjmowanych
          lekach, przebytych badaniach lub odbytych wizytach itp. oraz dzielenie
          się tymi informacjami z specjalistami.
        </li>
      </ol>
      <P variant="body">
        Kategorie danych osobowych, które będziemy przetwarzać obejmują:
      </P>
      <ul>
        <li>
          Imię i nazwisko, adres email (niezbędne do skorzystania z tej
          funkcji),
        </li>
        <li>
          Dodatkowo, jeżeli zdecydujesz się dodać takie dane, będziemy
          przetwarzać: numer Twojego dokumentu tożsamości, numer telefonu, dane
          o ubezpieczeniu, historię wizyt, informacje o chorobach, informacje o
          alergiach, informacje o przyjmowanych lekach, dane dotyczące historii
          rodzinnej.
        </li>
      </ul>
      <P variant="body">
        Dane będą przetwarzane przez nas wyłącznie w celu przechowywania ich w
        jednym miejscu oraz w celu podzielenia się nimi z specjalistą i będą
        przetwarzane na podstawie zgody.
      </P>
      <P variant="body">
        Przed dodaniem informacji do konta użytkownika (pacjenta) prosimy o
        wyrażenie wyraźnej zgody na przetwarzanie danych osobowych dotyczących
        zdrowia. Zgoda zostaje udzielona w celu zapewnienia możliwości
        korzystania z funkcjonalności przechowywania danych. Fizjo24 nie będzie
        wykorzystywał danych do innych celów, jak również nie będzie ich
        sprzedawał. Zgoda na przetwarzanie danych osobowych jest dobrowolna i
        może zostać w każdym czasie wycofana. W przypadku wycofania zgody,
        przechowywane przez nas informacje o stanie zdrowia zostaną usunięte.
        Pozostałe dane osobowe zostaną zachowane do czasu upływu okresów
        przechowywania wskazanych poniżej (patrz Tabela).
      </P>
      <P variant="body">
        Masz możliwość podzielenia się wszystkimi lub wybranymi danymi
        zapisanymi na koncie użytkownika (pacjenta) z specjalistą. To Ty
        decydujesz, czy i jakimi danymi chce podzielić się ze specjalistą.
        Podzielenie się danymi jest opcjonalne. Przed podzieleniem się danymi,
        Fizjo24 poprosi o zgodę. W przypadku jej udzielenia, wybrane przez
        użytkownika dane osobowe zostaną udostępnione specjaliście. W momencie
        podzielenia się danymi, specjalista staje się ich samodzielnym
        administratorem i będzie je przetwarzał we własnych celach.
      </P>
      <H2 variant="h2">
        4. Czatowanie ze specjalistą oraz wysyłanie wiadomości do specjalistów i
        placówek
      </H2>
      <P variant="body">
        Korzystając z naszych Usług, istnieje możliwość czatowania (w czasie
        rzeczywistym) ze specjalistą lub wysłanie prywatnej bądź publicznej
        wiadomości do specjalisty. Użytkownicy mogą przeglądać historię swoich
        czatów oraz pytań zadanych specjaliście.
      </P>
      <H2 variant="h2">5. Marketing</H2>
      <P variant="body">
        Możemy również przetwarzać dane osobowe użytkowników w celach
        marketingowych. Dane, które możemy przetwarzać w tym celu obejmują adres
        e-mail, numer telefonu oraz imię i nazwisko. Każdy użytkownik może w
        każdym momencie zrezygnować z otrzymywania informacji o charakterze
        marketingowym, w szczególności klikając przypis “wypisz się” (lub
        podobny), znajdujący się w wiadomości, którą otrzymuje.
      </P>
      <H2 variant="h2">Inne cele</H2>
      <P variant="body">
        Fizjo24 może również uzyskać inne dane użytkowników związane z
        korzystaniem z naszych Usług. Te dane obejmują takie informacje jak:
        informacja o urządzeniu/przeglądarce, z którego korzysta użytkownik,
        adres IP, częstotliwość logowania lub korzystania z Usług, czas
        korzystania z Usług, czy strefa czasowa. W przypadku korzystania z Usług
        w aplikacji mobilnej możemy również pozyskać dane o lokalizacji na
        podstawie danych z GPS. Użytkownik może zawsze wyłączyć dane o
        lokalizacji.
      </P>
      <P variant="body">Możemy również przetwarzać dane osobowe w celu:</P>
      <ul>
        <li>
          Rozpatrywania skarg lub reklamacji związanych z naszymi Usługami,
        </li>
        <li>Dochodzenia lub obrony przed roszczeniami,</li>
        <li>Informowania o nowych funkcjonalnościach lub Usługach,</li>
        <li>
          Zarządzania i planowania naszej działalności gospodarczej, na przykład
          analizujemy w jaki sposób użytkownicy korzystają z naszych Usług, aby
          przewidzieć zapotrzebowanie na nasze Usługi w przyszłości i
          odpowiednio zaplanować nasze działania. Analizujemy również zmiany w
          czasie korzystania z naszych Usług, jak również analizujemy, czy nasz
          produkt odpowiada potrzebom użytkowników. Część informacji, które
          wykorzystujemy do zarządzania i planowania naszej działalności, jest
          zanonimizowana (np. sposób nawigowania po naszym serwisie), jednak
          wraz z innymi informacjami (np. jak długo użytkownik był zalogowany)
          może pozwolić to na odszyfrowanie tożsamości konkretnego użytkownika.
        </li>
      </ul>
      <P variant="body">
        Przetwarzamy te dane osobowe na podstawie naszych prawnie uzasadnionych
        interesów, co znajduje oparcie w art. 6 ust. 1 lit. f RODO.
      </P>
      <H2 variant="h2">B) Czy przetwarzamy dane o stanie zdrowia?</H2>
      <P variant="body">
        W przypadku umawiania wizyty u specjalisty lub umawiania badania,
        zapisania informacji o sobie na koncie użytkownika (pacjenta), dodania
        opinii lub zadania pytania specjaliście, Fizjo24 może przetwarzać dane
        osobowe o stanie zdrowia. Fizjo24 może również działać jako tzw. podmiot
        przetwarzający, któremu specjaliści i placówki powierzają przetwarzanie
        danych osobowych o stanie zdrowia.
      </P>
      <H2 variant="h2">
        C) Czy możemy przetwarzać dane innych osób niż nasi użytkownicy?
      </H2>
      <P variant="body">
        Jeżeli użytkownik umawia wizytę lub badanie dla kogoś innego (np. dla
        członka swojej rodziny), Fizjo24 może przetwarzać dane osobowe takiej
        osoby. Będziemy je przetwarzać dla tych samych celów, dla których
        przetwarzamy dane osobowe naszych użytkowników.
      </P>
      <H2 variant="h2">
        IV. INFORMACJE MAJĄCE ZASTOSOWANIE DO WSZYSTKICH KORZYSTAJĄCYCH Z
        NASZYCH USŁUG LUB NASZEGO PORTALU
      </H2>
      <H2 variant="h2">
        Czy dzielimy się Twoimi danymi z podmiotami trzecimi?
      </H2>
      <P variant="body">
        Jeżeli zdecydujesz się podzielić swoimi danymi ze specjalistą, u którego
        odbędziesz wizytę, za Twoją zgodą przekażemy mu wybrane przez Ciebie
        informacje.
      </P>
      <P variant="body">
        Możemy również przekazać Twoje dane podmiotom, które pomagają nam
        świadczyć Usługi lub pomagają nam prowadzić działalność. Celem
        przekazania danych osobowych jest świadczenie Usług. Większość z tych
        podmiotów działa jako tzw. podmioty przetwarzające (zgodnie z art. 28
        RODO), jednak część z nich może występować jako samodzielni
        administratorzy danych osobowych. Podmioty, którym możemy przekazywać
        dane osobowe, obejmują następujące kategorie:
      </P>
      <ul>
        <li>Firmy zapewniające usługi chmurowe oraz utrzymania serwerów,</li>
        <li>
          Firmy zapewniające usługi w zakresie bezpieczeństwa informatycznego,
        </li>
        <li>Firmy świadczące usługi telekomunikacyjne i podobne,</li>
        <li>Zewnętrzni doradcy prawni i audytorzy,</li>
        <li>Banki,</li>
        <li>Firmy ubezpieczeniowe,</li>
        <li>Dostawcy usług płatniczych,</li>
        <li>Firmy zajmujące się bezpieczeństwem operacji finansowych,</li>
        <li>Firmy informatyczne, które świadczą usługi informatyczne.</li>
      </ul>
      <P variant="body">
        Możemy również udostępnić dane osobowe w celu odpowiedzi na żądania
        zgłoszone w stosunku do Fizjo24 przez uprawnione organy państwowe i
        sądowe (np. prokuratury, sądy, policję, urzędy).
      </P>
      <P variant="body">
        Możemy również udostępnić dane osobowe w przypadku, gdy będziemy
        przechodzić reorganizację, będziemy brali udział w fuzji lub przejęciu.
      </P>
      <P variant="body">
        W innych przypadkach nie będziemy udostępniać Twoich danych podmiotom
        trzecim, chyba że udzielisz nam na to zgody lub będziemy mieli właściwą
        podstawę prawną do takiego działania.
      </P>
      <H2 variant="h2">Jak długo będziemy przetwarzać dane osobowe?</H2>
      <P variant="body">
        Będziemy przetwarzać dane osobowe przez okres niezbędny dla realizacji
        celów, o których mowa powyżej oraz do czasu wypełnienia obowiązków
        prawnych, które są na nas nałożone. Co do zasady, będziemy przetwarzać
        dane osobowe przez 6 lat od zakończenia obowiązywania stosunku prawnego,
        jaki łączy Fizjo24 z użytkownikami lub specjalistami. Okres ten jest
        uzasadniony obowiązującym w Polsce okresem przedawnienia roszczeń
        cywilnych i podatkowych.
      </P>
      <P variant="body">Przysługują Ci poniższe prawa określone w RODO:</P>
      <ul>
        <li>
          Masz prawo do otrzymania informacji odnośnie do przetwarzania danych
          osobowych (w szczególności informacji w jakim celu, jakie dane i przez
          kogo są przetwarzane oraz komu zostały udostępnione, masz również
          prawo żądać informacji jak długo Twoje dane będą przetwarzane).
          Niniejsza polityka prywatności stanowi realizację prawa do informacji.
        </li>
        <li>
          Masz prawo wnieść skargę do nas odnośnie do przetwarzania Twoich
          danych oraz możesz wnieść skargę do Urzędu Ochrony Danych Osobowych,
          ul. Stawki 2, Warszawa, (https://uodo.gov.pl/).
        </li>
        <li>Masz prawo otrzymać kopię Twoich danych, które przetwarzamy.</li>
        <li>
          Masz prawo żądać usunięcia danych osobowych (jeżeli uważasz, że nie
          mamy prawa ich przetwarzać) lub wnieść sprzeciw odnośnie do
          przetwarzania.
        </li>
        <li>
          Możesz również wnieść wniosek o ograniczenie przetwarzania Twoich
          danych osobowych.
        </li>
        <li>
          Masz prawo żądać sprostowania lub poprawiania Twoich danych osobowych.
        </li>
        <li>Masz również prawo do przenoszenia danych osobowych.</li>
      </ul>
      <P variant="body">
        W celu wykonania swoich praw możesz skontaktować się z nami
        bezpośrednio. Każde żądanie dostępu do danych powinno być wystosowane
        pisemnie lub pocztą elektroniczną. W przypadku, gdy pojawią się
        wątpliwości odnośnie do tożsamości osoby składającej żądanie, będziemy
        mogli prosić o uzupełnienia żądania, w szczególności o niezbędne dane
        identyfikacyjne.
      </P>
      <P variant="body">
        Będziemy starać się odpowiedzieć na każde żądanie w terminie 1 miesiąca.
        Jednak w przypadkach bardziej skomplikowanych lub w razie wielu zapytań
        złożonych w tym samym czasie, możemy przedłużyć termin odpowiedzi, o
        czym zostaniesz poinformowany. Zachowujemy prawo do pobierania
        uzasadnionej opłaty odpowiadającej ponoszonym przez nas kosztom
        sporządzenia odpowiedzi na żądania nieuzasadnione lub nadmiarowe. W
        takim przypadku zostaniesz poinformowany o swoich prawach oraz
        wytłumaczymy zaistniałą sytuację.
      </P>
      <P variant="body">
        Staramy się, aby informacje, które przetwarzamy, były poprawne i
        aktualne. Jednak w przypadku zmian tych informacji, prosimy Cię o
        poinformowanie Fizjo24, aby możliwe było uaktualnienie przetwarzanych
        danych. Zaktualizujemy dane, które są niepoprawne lub nieaktualne. Mamy
        na to 1 miesiąc od dnia przekazania nam prośby o zmianę danych.
      </P>
      <H2 variant="h2">Linki do innych stron internetowych</H2>
      <P variant="body">
        W naszym serwisie lub aplikacji mogą znajdować się linki do stron
        internetowych, w tym przyciski prowadzące do mediów społecznościowych.
        Staramy się, aby linki, które umieszczamy prowadziły do stron o wysokim
        standardzie ochrony danych osobowych. Nie jesteśmy jednak odpowiedzialni
        za sposób wykorzystania danych osobowych, bezpieczeństwo i zawartość
        tych stron internetowych. Prosimy o zapoznanie się z polityką
        prywatności tych stron oraz z ich regulaminami, ponieważ korzystanie z
        tych stron oznacza stosowanie się do zasad określonych przez ich
        właścicieli.
      </P>
      <H2 variant="h2">Jak chronimy Twoje dane?</H2>
      <P variant="body">
        Zapewniamy odpowiednie zabezpieczenia techniczne, fizyczne,
        elektroniczne i administracyjne w celu ochrony danych osobowych przed
        nieuprawnionym dostępem. Przestrzegamy ogólnie przyjętych standardów
        branżowych, aby chronić przekazywane nam dane osobowe, zarówno podczas
        transmisji, jak i po ich otrzymaniu. Niestety przekazywanie informacji
        przez Internet (w tym pocztą elektroniczną) nie jest całkowicie
        bezpieczne. Chociaż dołożymy wszelkich starań, aby chronić dane osobowe,
        nie możemy zagwarantować bezpieczeństwa danych przesyłanych do nas;
        każda transmisja odbywa się na własne ryzyko. Po otrzymaniu informacji
        stanowiących dane osobowe, zastosujemy odpowiednie procedury i zasady
        bezpieczeństwa, aby zapobiec nieautoryzowanemu dostępowi do tych danych.
      </P>
      <H2 variant="h2">Skontaktuj się z nami</H2>
      <P variant="body">
        Jeżeli masz jakiekolwiek pytania, skontaktuj się z nami:
      </P>
      <P variant="body">E-mail: kontakt@centrumcry.pl</P>
      <P variant="body">
        Adres korespondencyjny: Cherry Olga Jankowska, ul. Antoniego
        Słonimskiego 26/U01, 50-304 Wrocław
      </P>
    </StyledFlex>
  );
};

import { format, isFuture, isPast } from "date-fns";
import { da } from "date-fns/locale";

export const filterDates = (dates: Date[], mode: "past" | "future") => {
  return mode === "past"
    ? dates.filter(
        (date) =>
          isPast(date) &&
          format(date, "dd.MM.yyyy") !== format(new Date(), "dd.MM.yyyy"),
      )
    : [new Date(), ...dates.filter((date) => isFuture(date))];
};

export const getDaysOfWeekBetweenDates = (startDate: Date, endDate: Date) => {
  const days: number[] = []
  const currDate = new Date(startDate)
  while (currDate <= endDate) {
    const dayOfWeek = currDate.getDay();
    !days.includes(dayOfWeek) && days.push(dayOfWeek)
    currDate.setDate(currDate.getDate() + 1);
  }
  return days.sort();
};

type typeToCompare = '<' | '>' | '<='
export const compareDates = (date1: Date, date2: Date, type?: typeToCompare) => {
  if (type === '<') return date1.setHours(0, 0, 0, 0) < date2.setHours(0, 0, 0, 0)
  if (type === '>') return date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0)
  if (type === '<=') return date1.setHours(0, 0, 0, 0) <= date2.setHours(0, 0, 0, 0)
  return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0)
}

export const formatDate = (d: Date, char?: string) => d ? `${(d.getDate()) <= 9 ? "0" + (d.getDate()) : (d.getDate())}${char || '-'}${(d.getMonth() + 1) <= 9 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)}${char || '-'}${d.getFullYear()}` : '';

import { Flex } from "components/Flex";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React from "react";
import { Box } from "components/Box";

const Tabs = styled(Flex)`
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.navbar};
  box-shadow: 0px 20px 50px rgba(44, 38, 87, 0.1);
`;
const TabButton = styled(Flex)<{ isActive: boolean }>`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  ${({ isActive, theme }) => `
    ${
      isActive
        ? `background-color: ${theme.palette.neutral.white}; border-radius: 15px 15px 0px 0px; font-weight: 600;`
        : `background-color: ${theme.palette.neutral.navbar};
        :first-child {
    border-radius: 15px 0px 0px 0px;
  }
  :last-child {
    border-radius: 0px 15px 0px 0px;
  }`
    }
  color: ${theme.palette.primary.main}`};
`;
const TabContainer = styled(Box)`
  ${({ theme }) => `background-color: ${theme.palette.neutral.white};`}
  min-height: 100px;
  height: 100%;
  border-radius: 0px 0px 15px 15px;
  padding: 20px;
`;

export interface ITab {
  name: string;
}

interface Props {
  tabs: ITab[];
  currentTab: ITab;
  setCurrentTab: React.Dispatch<React.SetStateAction<ITab>>;
  children?: React.ReactNode;
}

export const TabPanel = ({
  tabs,
  currentTab,
  setCurrentTab,
  children,
}: Props) => {
  const handleTabClick = (name: string) => {
    const foundTab = tabs.find((tab) => tab.name === name);
    if (foundTab) setCurrentTab(foundTab);
  };
  const { t } = useTranslation();

  return (
    <Tabs>
      <Flex minHeight="fit-content" width="100%">
        {tabs.map(({ name }) => (
          <TabButton
            key={name}
            isActive={currentTab.name === name}
            onClick={() => {
              handleTabClick(name);
            }}
          >
            {t(name)}
          </TabButton>
        ))}
      </Flex>
      <TabContainer>{children}</TabContainer>
    </Tabs>
  );
};

import { FC, Dispatch, SetStateAction } from "react";
import { Box, Flex, Line } from "components";
import styled from "styled-components";
import { ITopic, IBlogFilter } from "types/knowledge";
import { P, H2 } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";

const CategoryButton = styled(Flex)`
  cursor: pointer;
`;

interface ITopicSelectMenu {
  filters: IBlogFilter;
  setFilters: Dispatch<SetStateAction<IBlogFilter>>;
  topics: ITopic[];
}

export const TopicSelectMenu: FC<ITopicSelectMenu> = ({
  filters,
  setFilters,
  topics,
}) => {
  const { t } = useTranslation();
  const isTopicActive = (topicId: number) =>
    filters.topic_ids?.find((id) => id == topicId);

  const changeTopicsActiveStatus = (id: number) =>
    setFilters((prevState) => {
      return { ...prevState, topic_ids: [id], page: 1 };
    });

  const handleOnClick = (id: number) => {
    if (isTopicActive(id)) return;
    changeTopicsActiveStatus(id);
  };

  return (
    <Flex flexDirection="column">
      <H2 variant="h2" color="coloured" mb={3}>
        {t("topics")}:
      </H2>
      {topics.map(({ id, name }) => {
        return (
          <CategoryButton key={id} flexDirection="column">
            <Box onClick={() => handleOnClick(id)}>
              {isTopicActive(id) ? (
                <P variant="body" fontWeight={700}>
                  {name}
                </P>
              ) : (
                <P variant="body2">{name}</P>
              )}
            </Box>
            <Line />
          </CategoryButton>
        );
      })}
    </Flex>
  );
};

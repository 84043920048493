import React, { FC } from "react";
import { Flex } from "components";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import styled from "styled-components";
import { PaginationHook } from "hooks/usePagination";

const PageBox = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 35px;
  height: 35px;

  border: 1px solid rgba(41, 35, 79, 0.1);
  border-radius: 10px;
`;

interface IPagination {
  paginationHook: PaginationHook;
}

export const Pagination: FC<IPagination> = ({
  paginationHook: { page, setPage, pages },
}) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <ArrowLeft
        strokeOpacity={page == 1 ? "0.1" : "1"}
        style={{ cursor: page == 1 ? "default" : "pointer" }}
        onClick={() => {
          if (page == 1) return;
          setPage((prevState) => --prevState);
        }}
      />
      <Flex mx={3} alignItems="center">
        <PageBox mr={2}>{page}</PageBox> z {pages}
      </Flex>
      <ArrowRight
        strokeOpacity={page == pages ? "0.1" : "1"}
        onClick={() => {
          if (page == pages) return;
          setPage((prevState) => ++prevState);
        }}
        style={{ cursor: page == pages ? "default" : "pointer" }}
      />
    </Flex>
  );
};

import { FC, useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/en-gb";
import {
  momentLocalizer,
  Formats,
  Components,
  EventPropGetter,
  Views,
  EventProps,
  ResourceHeaderProps,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ReactComponent as PreviousIcon } from "assets/icons/ico-previous.svg";
import { ReactComponent as NextIcon } from "assets/icons/ico-next.svg";
import { StyledCalendar, StyledToolbar } from "./Calendar.styled";
import { MyEventType } from "types/calendar";
import "moment/locale/pl";
import { Flex } from "components";
import { P } from "components/Typography";
import { theme } from "styles";
import { getUsers } from "services/user";
import { getUserFullName } from "utilities/appointment";
import { getAppointments } from "services/appointment";

interface Event {
  id: number;
  fullName: string;
  start: Date;
  end: Date;
  serviceType: string;
  resourceId: number;
}

interface Resource {
  name: string;
  resourceId: number;
}

const localizer = momentLocalizer(moment);

const formats: Formats = {
  timeGutterFormat: (date: Date) => moment(date).format("HH:mm"),
};

interface Props {
  currentDay: moment.Moment;
  setCurrentDay: (newCurrentDay: moment.Moment) => void;
}

export const BigCalendar: FC<Props> = ({ currentDay, setCurrentDay }) => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [events, setEvents] = useState<Event[]>([]);

  const fetchExperts = async () => {
    try {
      const result = await getUsers({ role: "expert" });
      if (!result) return;
      let tmp: Resource[] = [];
      result.data.map((e) => {
        tmp.push({ resourceId: e.id, name: getUserFullName(e) });
      });
      setResources(tmp);
    } catch (e: any) {}
  };

  const fetchAppoitments = () => {
    resources.forEach(async (e) => {
      try {
        const result = await getAppointments({
          expert_id: e.resourceId,
          appointment_date: currentDay.format("DD-MM-YYYY"),
        });
        if (!result) return;
        let tmp: Event[] = [];
        result.data.map((e) => {
          tmp.push({
            id: e.id,
            fullName: e.patient_first_name + " " + e.patient_last_name,
            serviceType: e.service.name,
            start: new Date(e.date_and_time_of_appointment),
            end: moment(new Date(e.date_and_time_of_appointment))
              .add(30, "minutes")
              .toDate(),
            resourceId: e.expert.id,
          });
          const merged = events.concat(tmp);
          setEvents(merged);
        });
      } catch (e: any) {}
    });
  };

  useEffect(() => {
    fetchExperts();
  }, []);

  useEffect(() => {
    if (resources.length > 0) fetchAppoitments();
  }, [resources]);

  useEffect(() => {
    setEvents([]);
    fetchAppoitments();
  }, [currentDay]);

  const Toolbar = () => (
    <StyledToolbar>
      <button
        onClick={() => setCurrentDay(moment(currentDay).subtract(1, "day"))}
      >
        <PreviousIcon fill="white" />
      </button>

      <Flex alignItems="center" flexDirection="column">
        <P variant="h2" fontWeight={700}>
          {currentDay.format("dddd")}
        </P>
        <P variant="body">{currentDay.format("DD MMMM YYYY")}</P>
      </Flex>

      <button onClick={() => setCurrentDay(moment(currentDay).add(1, "day"))}>
        <NextIcon fill="white" />
      </button>
    </StyledToolbar>
  );

  const resourceHeader = (props: ResourceHeaderProps<Resource>) => {
    const { resource } = props;
    return (
      <Flex alignItems="center">
        <P variant="body" color="primary">
          {resource.name}
        </P>
      </Flex>
    );
  };

  const MyEvent = (props: EventProps<Event>) => {
    const { event } = props;

    console.log(event);

    return (
      <div>
        <div>{event.fullName}</div>
        <div>{event.serviceType}</div>
      </div>
    );
  };

  const eventStyleGetter: EventPropGetter<MyEventType> = ({ end }) => {
    if (moment(end).isBefore(moment())) {
      return {
        className: "past-event",
        style: {
          backgroundColor: theme.palette.neutral.lightGrey,
          border: "none",
        },
      };
    }
    return {
      className: "",
      style: {},
    };
  };

  const components: Components<Event> = {
    toolbar: Toolbar,
    day: {
      event: MyEvent,
    },
    resourceHeader: resourceHeader as (
      props: ResourceHeaderProps<object>,
    ) => JSX.Element,
  };

  function handleNavigate(newDate: Date) {
    setCurrentDay(moment(newDate).startOf("day"));
  }

  return (
    <StyledCalendar
      localizer={localizer}
      events={events}
      defaultView={Views.DAY}
      showMultiDayTimes={true}
      min={new Date(2023, 1, 1, 7)}
      max={new Date(2023, 1, 1, 22)}
      step={30}
      formats={formats}
      components={components as Components<object>}
      onNavigate={handleNavigate}
      date={currentDay.toDate()}
      eventPropGetter={eventStyleGetter as EventPropGetter<object>}
      resources={resources}
      // @ts-ignore
      resourceIdAccessor="resourceId"
    />
  );
};

const buttons = {
  singUp: "Sing up",
  singIn: "Sing in",
  logOut: "Log out",
  createAccount: "Create account",
  resetingPassword: "Reseting aassword",
  resetPassword: "Reset aasswors",
  dontHaveAccount: "Don't have account",
  ifYouDontHaveAccount: "If you don't have an account, sign up now!",
  checkNow: "Check it out right now!",
  back: "Back",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  zoomIn: "Aa+",
  zoomOut: "Aa-",
  scheduleApp: "Make an appointment",
  clear: "Clear",
};

const homeView = {
  welcome: "Welcome to Fizjo24",
  thisIsAPlace:
    "This is a place created by Cryo Health and Beauty Center, where your health and well-being are our top priority!",
  ourCompany:
    "Our company specializes in physiotherapy that helps you get rid of pain and restore your body's fitness. What's more, as one of the few medical facilities in Poland, we also offer the possibility to use our services online.",
  whyFizjo: "Why should you choose Fizjo24? Here are a few reasons:",
  cards: {
    card1:
      "Experienced physiotherapists - our team are qualified specialists who know their job and can help even in the most difficult cases.",
    card2:
      "Modern therapy methods - we use the latest technology and therapy methods to help our patients quickly and effectively.",
    card3:
      "Individual approach - every patient is different, so we treat each case individually and tailor therapy to the patient's needs.",
    card4:
      "Quick response - we know that time is important, so we strive to provide a quick response to requests and quick appointments.",
  },
  whatMakesUsUnique: "What makes us different?",
  ourOnlineOffer:
    "Our online offering that allows you to receive physiotherapy services without leaving home! Thanks to a mobile app and specialized software, you can consult with a physiotherapist online and then carry out the entire therapy at home using our recorded exercise videos. In addition, we offer continuous contact with the client and constant monitoring of therapy progress.",
  dontDelay:
    "Don't delay! Take care of your health and well-being now. Use the services of Fizjo24 and feel the difference!",
};

const common = {
  privacyPolicy: "privacy policy",
  regulamin: "regulamin",
  first_name: "first name",
  last_name: "last name",
  phone: "phone number",
  paid: "paid",
  unpaid: "unpaid",
  send: "Send",
  save: "Zapisz",
  eMail: "Email",
  settings: "Ustawienia",
  search: "Search",
};

const auth = {
  login: "login",
  register: "register",
  password: "password",
  forgotPass: "Forgot password?",
  accountless: "Don't have account?",
  emailRequired: "Email is required",
  phoneNumberRequired: "Phone number is required",
  badEmailFormat: "Invalid email format",
  badPhoneFormat: "Invalid phone format",
  passwordRequired: "Password is required",
  firstNameRequired: "First Name is required",
  lastNameRequired: "Last Name is required",
  passMinVal: "The password should contain at least 6 characters",
  passMaxVal: "The password can be up to 18 characters long",
  passDiffErr: "The passwords do not match",
  acceptRegulamin: "Accept the terms and conditions",
  password_confirmation: "password confirmation",
  send_email: "Send e-mail",
};

const contact = {
  nameAndSurname: "Name and surname",
  messageContent: "Message content",
  yourEmail: "Your email",
  regulationConsent:
    "Wyrażam zgodę na przetwarzanie moich danych osobowych podanych formularzu w celu uzyskania od powiedzi na zapytanie. Moja zgoda jest dobrowolna, a jednocześnie niezbędna do uzyskania odpowiedzi.", //$
};

const knowledge = {
  noInfo: "No informations",
  loadMore: "Load more",
  topics: "Topics",
  filterByTag: "Tags",
  readMore: "Read more",
};

const myAccount = {
  firstName: "Imię",
  lastName: "Nazwisko",
  updatedAccountData: "Zmieniono dane konta",
  dataEditionError: "Nie udało się zaktualizować danych",
  aboutMe: "About me",
  courses: "Courses",
  servicesAndPriceList: "Services and price list",
};

const weekdays = {
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
};

const questionnaire = {
  // questions
  problem_area_common: "What area is affected? (mark on the drawing)",
  problem_duration: "How long does the problem exists?",
  pain_scale:
    "Please rate the degree of stress from 1 - 10, where 1 - very weak, 10 - very strong",
  work_style: "Please rate your work style",
  steps: "How many steps do you take during the day?",
  daily_activity: "What does your daily activity looks like?",
  exercise: "Do you exercise?",
  exercise_frequency: "How often?",
  stress_scale: "How would you rate your stress level on a scale of 0 to 10?",
  drugs: "Do you take any medications on a regular basis, if so, which ones?",
  // answers
  problem_area: "Problem area",
  sitting: "Sitting",
  standing: "Standing",
  mixed: "Mixed",
  little: "Little",
  average: "Average",
  lot: "Lot",
  no: "No",
  light: "Light",
  hard: "Hard",
  another: "Another",
  "1_to_2_times_a_week": "1 to 2 times a week",
  "3_to_4_times_a_week": "3 to 4 times a week",
  "5_to_7_times_a_week": "5 to 7 times a week",
  "1_to_4": "1 to 4",
  "5_to_7": "5 to 7",
  "8_to_10": "8 to 10",
  cardiovascular_diseases: "Cardiovascular diseases",
  autoimmune_diseases: "Autoimmune diseases",
};

const questionnaireDental = {
  // questions
  problem_area_dental:
    "What area is affected by the problem? (mark on face, you can select more than one area and add a note)",
  problem_length: "How long does the problem exists?",
  fatigue_scale:
    "Please rate the degree of stress from 1 - 10, where 1 - very weak, 10 - very strong",
  jaw_tension: "Do you feel tension in the jaw area?",
  grinding_teeth: "Do you grind your teeth?",
  stiffness_in_the_jaw:
    "How do you feel the morning stiffness in the jaw area?",
  tinnitus: "Do you have tinnitus?",
  headaches: "Do you have headaches, if so how often?",
  visual_impairment: "Do you have visual field disturbances?",
  chronic_diseases: "Do you have any chronic diseases?",
  permanent_drugs:
    "Do you take any medications on a regular basis, if so, which ones?",
  painkillers: "Do you take painkillers?",
};

const questionnaireUrogynecological = {
  // questions
  problem: "What problem are you reporting? (multiple options can be selected)",
  last_time_at_a_gynecologist:
    "When was the last time you visited a gynecologist?",
  last_period: "When did you have your last menstrual period?",
  liquids_during_the_day: "How much fluid do you take during the day?",
  how_often_in_the_toilet_urinate:
    "How often do you go to the toilet during the day to urinate?",
  how_often_in_the_toilet_urinate_daily:
    "How often do you urinate during the day (24h)?",
  urine_at_night: "Do you get up at night to urinate?",
  daily_activity: "What is your daily activity like?",
  exercises: "Do you exercise?",
  exercises_frequency: "How often?",
  stress_level: "How would you rate your stress level on a scale of 0 to 10?",
  chronic_diseases: "Do you have chronic diseases?",
  urine_loss: "Are you losing urine?",
  difficulties_in_defecation:
    "Do you find it difficult to empty your bladder or bowels completely?",
  soreness_at_sexual_intercourse: "Are sexual relations painful for you?",
  soreness_during_menstruation: "Are periods painful in the first days?",
  // answers
  preventive_visit: "Preventive visit",
  preparation_for_pregnancy: "Preparation for pregnancy",
  preparation_for_childbirth: "Preparation for childbirth",
  pregnancy_discomforts: "Pregnancy discomforts",
  painful_periods: "Painful periods",
  painful_ovulation: "Painful ovulation",
  painful_intercourse: "Painful intercourse",
  dilation_of_the_rectus_abdominis_muscles:
    "Dilation of the rectus abdominis muscles",
  urinary_incontinence: "Urinary incontinence",
  stool_and_gas_incontinence: "Stool and gas incontinence",
  constipation: "Constipation",
  pelvic_pain: "Pelvic pain",
  scar_therapy: "Scar therapy (cc, episiotomy-incision/perineine tear, etc.)",
  other_specify: "Other (please specify)",
  not_applicable: "Not applicable",
  less_than_a_liter: "Less than a liter",
  above_2l: "Above 2l",
  above_3l: "Above 3l",
  every_30_minutes: "Every 30 minutes",
  every_hour: "Every hour",
  every_2_hours: "Every 2 hours",
  every_3_hours_and_more: "Every 3 hours and more",
  other: "Other",
  less_than_6_times: "Less than 6 times",
  "6_-_8_times": "6 - 8 times",
  "9_-_12_times": "9 – 12 times",
  more_than_12_times: "More than 12 times",
  yes_how_many_times: "Yes (if yes, how many times)",
  no: "No",
  light: "Light",
  medium: "Medium",
  hard: "Hard",
  i_dont_exercise: "I don't exercise",
  i_do_light_exercise_regularly: "I do light exercise regularly",
  "i_regularly_do_medium-heavy_workouts":
    "I regularly do medium-heavy workouts",
  i_do_heavy_workouts_on_a_regular_basis:
    "I do heavy workouts on a regular basis",
  "1-2_times_a_week": "1 - 2 times a week",
  "3-4_times_a_week": "3 – 4 times a week",
  "5-7_times_a_week": "5 – 7 times a week",
  yes_cardiovascular_diseases: "Yes (cardiovascular diseases)",
  yes_autoimmune_diseases: "Yes (autoimmune diseases)",
  yes_other: "Yes (other)",
  yes: "Yes",
  yes_when_laughing_coughing_sneezing: "Yes, when laughing, coughing, sneezing",
  yes_when_jumping: "Yes, when jumping",
  yes_regardless_of_the_situation: "Yes, regardless of the situation",
  yes_when_I_feel_pressure_on_the_bladder:
    "Yes, when I feel pressure on the bladder",
  yes_at_the_beginning_at_the_entrance_to_the_vagina:
    "Yes, at the beginning, at the entrance to the vagina",
  yes_deeper: "Yes, deeper",
  i_dont_want_to_answer_that_question: "I don't want to answer that question",
  yes_always: "Yes, always",
  yes_for_some_time: "Yes, for some time",
  enter_value: "Enter value",
};

export default {
  ...common,
  ...auth,
  ...contact,
  ...knowledge,
  ...myAccount,
  ...weekdays,
  ...questionnaire,
  ...questionnaireDental,
  ...questionnaireUrogynecological,
  buttons,
  homeView,
};

import { IQuestionEntity } from "./QuestionField";
import {
  IQuestionnaireDentalForm,
  IQuestionnaireForm,
  IQuestionnaireUrogynecologicalForm,
} from "../../types/forms/questionnaire";

export const questionEntities: IQuestionEntity<IQuestionnaireForm>[] = [
  {
    index: 0,
    question: "problem_area",
    name: "problem_area",
    label: "problem_area_common",
    type: "schema",
  },
  {
    index: 1,
    question: "problem_duration",
    name: "problem_duration",
    label: "problem_duration",
    type: "input",
  },
  {
    index: 2,
    question: "pain_scale",
    name: "pain_scale",
    label: "pain_scale",
    type: "numeric",
    answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  {
    index: 3,
    question: "work_style",
    name: "work_style",
    label: "work_style",
    type: "choice",
    answers: ["sitting", "standing", "mixed"],
  },
  {
    index: 4,
    question: "steps",
    name: "steps",
    label: "steps",
    type: "choice",
    answers: ["little", "average", "lot"],
  },
  {
    index: 5,
    question: "daily_activity",
    name: "daily_activity",
    label: "daily_activity",
    type: "choice",
    answers: ["little", "average", "lot"],
  },
  {
    index: 6,
    question: "exercises",
    name: "exercises",
    label: "exercise",
    type: "choice",
    answers: ["no", "light", "average", "hard", "another"],
  },
  {
    index: 7,
    question: "exercises_frequency",
    name: "exercises_frequency",
    label: "exercise_frequency",
    type: "choice",
    answers: [
      "1_to_2_times_a_week",
      "3_to_4_times_a_week",
      "5_to_7_times_a_week",
    ],
  },
  {
    index: 8,
    question: "stress_scale",
    name: "stress_scale",
    label: "stress_scale",
    type: "choice",
    answers: ["1_to_4", "5_to_7", "8_to_10"],
  },
  {
    index: 9,
    question: "chronic_diseases",
    name: "chronic_diseases",
    label: "Czy ma Pani/Pan choroby przewlekłe?",
    type: "choice",
    answers: [
      "no",
      "cardiovascular_diseases",
      "autoimmune_diseases",
      "another",
    ],
  },
  {
    index: 10,
    question: "drugs",
    name: "drugs",
    label: "drugs",
    type: "precise",
  },
  {
    index: 11,
    question: "painkillers",
    name: "painkillers",
    label: "painkillers",
    type: "precise",
  },
];

export const dentalQuestionEntities: IQuestionEntity<IQuestionnaireDentalForm>[] =
  [
    {
      index: 0,
      question: "problem_area",
      name: "problem_area",
      label: "problem_area_dental",
      type: "schema",
    },
    {
      index: 1,
      question: "problem_length",
      name: "problem_length",
      label: "problem_length",
      type: "input",
    },
    {
      index: 2,
      question: "fatigue_scale",
      name: "fatigue_scale",
      label: "fatigue_scale",
      type: "numeric",
      answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    {
      index: 3,
      question: "jaw_tension",
      name: "jaw_tension",
      label: "jaw_tension",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 4,
      question: "grinding_teeth",
      name: "grinding_teeth",
      label: "grinding_teeth",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 5,
      question: "stiffness_in_the_jaw",
      name: "stiffness_in_the_jaw",
      label: "stiffness_in_the_jaw",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 6,
      question: "tinnitus",
      name: "tinnitus",
      label: "tinnitus",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 7,
      question: "headaches",
      name: "headachesequency",
      label: "headaches",
      type: "choice",
      answers: [
        "No",
        "1_to_2_times_a_week",
        "3_to_4_times_a_week",
        "5_to_7_times_a_week",
      ],
    },
    {
      index: 8,
      question: "visual_impairment",
      name: "visual_impairment",
      label: "visual_impairment",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 9,
      question: "chronic_diseases",
      name: "chronic_diseases",
      label: "chronic_diseases",
      type: "preciseDefiniteItem",
      definiteItem: "another",
      answers: [
        "no",
        "cardiovascular_diseases",
        "autoimmune_diseases",
        "another",
      ],
    },
    {
      index: 10,
      question: "permanent_drugs",
      name: "permanent_drugs",
      label: "permanent_drugs",
      type: "precise",
    },
    {
      index: 11,
      question: "painkillers",
      name: "painkillers",
      label: "painkillers",
      type: "precise",
    },
  ];

export const urogynecologicalQuestionEntities: IQuestionEntity<IQuestionnaireUrogynecologicalForm>[] =
  [
    {
      index: 0,
      question: "problem",
      name: "problem",
      label: "problem",
      type: "preciseDefiniteItem",
      definiteItem: "other_specify",
      answers: [
        "preventive_visit",
        "preparation_for_pregnancy",
        "preparation_for_childbirth",
        "pregnancy_discomforts",
        "painful_periods",
        "painful_ovulation",
        "painful_intercourse",
        "dilation_of_the_rectus_abdominis_muscles",
        "urinary_incontinence",
        "stool_and_gas_incontinence",
        "constipation",
        "pelvic_pain",
        "scar_therapy",
        "other_specify",
      ],
    },
    {
      index: 1,
      question: "last_time_at_a_gynecologist",
      name: "last_time_at_a_gynecologist",
      label: "last_time_at_a_gynecologist",
      type: "input",
    },
    {
      index: 2,
      question: "last_period",
      name: "last_period",
      label: "last_period",
      type: "preciseDefiniteItem",
      definiteItem: "enter_value",
      answers: ["not_applicable", "enter_value"],
    },
    {
      index: 3,
      question: "liquids_during_the_day",
      name: "liquids_during_the_day",
      label: "liquids_during_the_day",
      type: "choice",
      answers: [
        "less_than_a_liter",
        "1 - 1.5l",
        "1.5 - 2l",
        "above_2l",
        "above_3l",
      ],
    },
    {
      index: 4,
      question: "how_often_in_the_toilet_urinate",
      name: "how_often_in_the_toilet_urinate",
      label: "how_often_in_the_toilet_urinate",
      type: "choice",
      answers: [
        "every_30_minutes",
        "every_hour",
        "every_2_hours",
        "every_3_hours_and_more",
        "other",
      ],
    },
    {
      index: 5,
      question: "how_often_in_the_toilet_urinate_daily",
      name: "how_often_in_the_toilet_urinate_daily",
      label: "how_often_in_the_toilet_urinate_daily",
      type: "choice",
      answers: [
        "less_than_6_times",
        "6_-_8_times",
        "9_-_12_times",
        "more_than_12_times",
      ],
    },
    {
      index: 6,
      question: "urine_at_night",
      name: "urine_at_night",
      label: "urine_at_night",
      type: "preciseDefiniteItem",
      definiteItem: "yes_how_many_times",
      answers: ["no", "yes_how_many_times"],
    },
    {
      index: 7,
      question: "daily_activity",
      name: "daily_activity",
      label: "daily_activity",
      type: "choice",
      answers: ["light", "medium", "hard"],
    },
    {
      index: 8,
      question: "exercises",
      name: "exercises",
      label: "exercises",
      type: "choice",
      answers: [
        "i_dont_exercise",
        "i_do_light_exercise_regularly",
        "i_regularly_do_medium",
        "i_do_heavy_workouts_on_a_regular_basis",
        "other",
      ],
    },

    {
      index: 9,
      question: "exercises_frequency",
      name: "exercises_frequency",
      label: "exercises_frequency",
      type: "choice",
      answers: ["1-2_times_a_week", "3-4_times_a_week", "5-7_times_a_week"],
    },
    {
      index: 10,
      question: "stress_level",
      name: "stress_level",
      label: "stress_level",
      type: "choice",
      answers: ["1 – 4", "5 – 7", "8 – 10"],
    },
    {
      index: 11,
      question: "chronic_diseases",
      name: "chronic_diseases",
      label: "chronic_diseases",
      type: "choice",
      answers: [
        "no",
        "yes_cardiovascular_diseases",
        "yes_autoimmune_diseases",
        "yes_other",
      ],
    },
    {
      index: 12,
      question: "urine_loss",
      name: "urine_loss",
      label: "urine_loss",
      type: "choice",
      answers: [
        "yes",
        "yes_when_laughing_coughing_sneezing",
        "yes_when_jumping",
        "yes_regardless_of_the_situation",
        "yes_when_I_feel_pressure_on_the_bladder",
      ],
    },
    {
      index: 13,
      question: "difficulties_in_defecation",
      name: "difficulties_in_defecation",
      label: "difficulties_in_defecation",
      type: "choice",
      answers: ["yes", "no"],
    },
    {
      index: 14,
      question: "soreness_at_sexual_intercourse",
      name: "soreness_at_sexual_intercourse",
      label: "soreness_at_sexual_intercourse",
      type: "choice",
      answers: [
        "no",
        "yes_at_the_beginning_at_the_entrance_to_the_vagina",
        "yes_deeper",
        "i_dont_want_to_answer_that_question",
      ],
    },
    {
      index: 15,
      question: "soreness_during_menstruation",
      name: "soreness_during_menstruation",
      label: "soreness_during_menstruation",
      type: "choice",
      answers: ["no", "yes_always", "yes_for_some_time", "not_applicable"],
    },
  ];

import { Card, Flex, Line } from "components";
import { H2, H3, P } from "components/Typography";
import { IExamination } from "types/examination";
import { StyledAttachment } from "views/TestsView/components/Test";
import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { theme } from "styles";
import { API_PHOTO_URL } from "constants/paths";
import { Button } from "components/_form";
import { useEffect, useState } from "react";
import { AddTestModal } from "./AddTestModal";

interface IInterviewCard {
  tests: IExamination[];
  userId?: number | string;
  fetchTestAgain: () => void;
}

export const InterviewCard = ({
  tests,
  userId,
  fetchTestAgain,
}: IInterviewCard) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  return (
    <>
      <AddTestModal
        isOpen={showAddModal}
        onCancelClick={() => setShowAddModal(false)}
        fetchTestAgain={fetchTestAgain}
        userId={userId}
      />
      <Card>
        <Flex
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <H2 variant="h2" color="coloured">
            Badania pacjenta:
          </H2>
          {!!userId && (
            <Button
              variant="accent"
              bordered
              icon={<PlusIcon />}
              textOnly
              onClick={() => setShowAddModal(true)}
            />
          )}
        </Flex>
        <Line />
        {!!tests.length &&
          tests.map((e, k) => {
            return (
              <Card key={k}>
                <Flex flexDirection="column">
                  <StyledAttachment
                    href={`${API_PHOTO_URL}${e.exam_file}`}
                    target="_blank"
                  >
                    <Flex alignItems="center" gap="10px">
                      <AttachmentIcon fill={theme.palette.primary.main} />
                      {e.exam_file.substring(e.exam_file.lastIndexOf("/") + 1)}
                    </Flex>
                  </StyledAttachment>
                  <H3 variant="h3" color="primary">
                    {e.name}
                  </H3>
                  <P variant="body2" color="grey">
                    Data badania: {e.date}
                  </P>
                </Flex>
                <P variant="body" fontWeight={700} color="primary">
                  Opis:
                </P>
                <P variant="body" color="primary">
                  {e.description}
                </P>
              </Card>
            );
          })}
      </Card>
    </>
  );
};

import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { Button, TextareaField, TextField } from "components/_form";
import { MyAccountState } from "hooks/useMyAccount";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createCourse } from "services/courses";
import { ICourse } from "types/courses";
import { courseSchema } from "validation/course";

interface ICourseFormModal extends IModal {
  myAccountState: MyAccountState;
}

export const CourseFormModal: FC<ICourseFormModal> = ({
  isOpen,
  onCancelClick,
  myAccountState,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<ICourse, "id">>({ resolver: yupResolver(courseSchema) });

  const onSubmit = handleSubmit(async (data) => {
    if (myAccountState?.user) {
      try {
        const { data: course } = await createCourse({
          ...data,
          profile_id: myAccountState?.user.profile.id,
        });
        if (course)
          myAccountState.setUser(
            (prevUser) =>
              prevUser && {
                ...prevUser,
                profile: {
                  ...prevUser.profile,
                  courses: [...prevUser.profile.courses, course],
                },
              },
          );
        toast.success("Dodano kurs");
      } catch (e) {
        toast.error("Nie udało się dodać kursu");
      }
    }
    onCancelClick && onCancelClick();
  });

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick}>
      <form onSubmit={onSubmit}>
        <Flex p={3} flexDirection="column">
          <TextField
            label="Nazwa"
            {...register("title")}
            error={errors?.title?.message}
          />
          <TextField
            type="date"
            label="Data ukończenia"
            {...register("receipt_date")}
            error={errors?.receipt_date?.message}
          />
          <TextareaField
            label="Opis"
            {...register("description")}
            error={errors?.description?.message}
          />

          <Flex mt={3} gap="10px" justifyContent="end">
            <Button
              variant="red"
              bordered
              type="button"
              onClick={onCancelClick}
            >
              Anuluj
            </Button>
            <Button variant="accent" type="submit">
              Dodaj
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

import { Flex, Line } from "components";
import { H2 } from "components/Typography";
import { Button, TextareaField, TextField } from "components/_form";
import { FileField } from "components/_form/FileField";
import { useFormContext } from "react-hook-form";

export const TestsForm = () => {
  const {
    register,
    formState: { isValid },
  } = useFormContext();
  return (
    <>
      <H2 variant="h2" color="coloured">
        Dodaj badanie:
      </H2>
      <Line />
      <TextField label="Nazwa badania:" {...register("name")} required />
      <TextField
        label="Data badania:"
        type="date"
        {...register("date")}
        required
      />
      <TextareaField label="Opis" {...register("description")} rows={4} />
      <FileField label="Załącznik:" name="exam_file" />
      <Flex justifyContent="end">
        <Button disabled={!isValid} variant="red" type="submit">
          Dodaj badanie
        </Button>
      </Flex>
    </>
  );
};

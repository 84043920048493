import { JitsiMeeting } from "@jitsi/react-sdk";
import { useMyAccount } from "hooks/useMyAccount";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoom } from "services/room";
import { IRoom } from "types/room";

export const JitsiMeetingView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { myAccountState } = useMyAccount();
  const { user } = myAccountState;
  const [roomData, setRoomData] = useState<IRoom>();

  const handleGetRoom = async () => {
    if (id)
      try {
        const response = await getRoom(id);
        if (!response) return;
        setRoomData(response.data);
      } catch {}
  };

  useEffect(() => {
    handleGetRoom();
  }, []);

  return user && roomData ? (
    <JitsiMeeting
      domain={process.env.JITSI_SERVER_DOMAIN}
      roomName={roomData.name}
      userInfo={{
        email: user.email,
        displayName: user.profile.first_name + " " + user.profile.last_name,
      }}
      onReadyToClose={() => {
        navigate("/app/appointments");
      }}
      getIFrameRef={(iframeRef) => {
        iframeRef.style.height = "100vh";
      }}
    />
  ) : (
    <></>
  );
};

import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H2, H3 } from "components/Typography";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GoodIcon } from "assets/icons/ico-good.svg";

export const ConfirmResetPasswrdModal = ({
  isOpen,
  onCancelClick,
}: Omit<IModal, "styles" | "children">) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
    onCancelClick;
  };

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick} disableBackdropClick>
      <Flex width="100%" flexDirection="column" gap="20px" alignItems="center">
        <GoodIcon />
        <H2 variant="h2" textAlign="center" color="primary">
          Resetowanie hasła powiodło się.
        </H2>
        <H3 variant="h3" textAlign="center" color="primary">
          Na skrzynkę mailową otrzymasz mail z linkiem pozwalającym na zmianę
          hasła. Wejdź w niego i ustaw nowe hasło.
        </H3>
        <Flex mt={2} justifyContent="center">
          <Button
            variant="secondary"
            label="Przejdź do logowania"
            onClick={handleClick}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

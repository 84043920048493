import { Box } from "components/Box";
import { Flex } from "components/Flex";
// import { Tooltip } from "components/Tooltip";
import { theme } from "styles/theme";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "accent" | "red" | "green";
  disabled?: boolean;
  label?: string | null;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  fitContent?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
  height?: string;
  textOnly?: boolean;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  bordered?: ButtonI["bordered"];
  height?: ButtonI["height"];
  textOnly?: ButtonI["textOnly"];
  fitContent?: ButtonI["fitContent"];
}

const { palette, textStyles } = theme;

const colorVariantsMap = {
  primary: palette.text.secondary,
  secondary: palette.text.secondary,
  accent: palette.text.secondary,
  red: palette.text.secondary,
  green: palette.text.secondary,
};

const backgroundColorVariantsMap = {
  primary: palette.primary.main,
  secondary: palette.secondary.main,
  accent: palette.accent.main,
  red: palette.error.main,
  green: palette.success.main,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.primary.dark,
  secondary: palette.secondary.dark,
  accent: palette.accent.dark,
  red: palette.error.dark,
  green: palette.success.dark,
};

const backgroundColorActiveVariantsMap = {
  primary: palette.primary.light,
  secondary: palette.secondary.light,
  accent: palette.accent.light,
  red: palette.error.light,
  green: palette.success.light,
};

const StyledButton = styled.button<StyledButtonI>`
  font-family: "Open Sans", sans-serif;
  ${({ variant, textOnly, fullWidth, bordered, height, fitContent }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;    
    ${fullWidth ? `width: 100%;` : ""};
    min-width: ${textOnly || fitContent ? "fit-content" : "150px"};
    ${height ? `height: ${height};` : "min-height: 43px;"}
    border-radius: 15px;    
    font-weight: 600;
    font-size: ${textStyles.body2.fontSize}px;
    color: ${
      bordered ? backgroundColorVariantsMap[variant] : colorVariantsMap[variant]
    };
    background-color: ${
      bordered ? "transparent" : backgroundColorVariantsMap[variant]
    };
    ${
      bordered
        ? `
          box-shadow: inset 0px 0px 0px 1px ${backgroundColorVariantsMap[variant]}; 
          border: 0;
        `
        : "border: 0;"
    }
    cursor: pointer;

    ${
      textOnly &&
      `background-color: transparent; box-shadow:unset; font-size:${textStyles.body.fontSize}px;`
    };

    svg { 
      max-width: 15px;
      max-height: 15px;
      path {
      fill: ${
        bordered
          ? backgroundColorVariantsMap[variant]
          : colorVariantsMap[variant]
      };};};
    
    &:hover {
      color: ${bordered ? backgroundColorHoverVariantsMap[variant] : ""};
      background-color: ${
        bordered
          ? rgba(backgroundColorHoverVariantsMap[variant], 0.1)
          : backgroundColorHoverVariantsMap[variant]
      };
      ${
        bordered
          ? `
          box-shadow: inset 0px 0px 0px 1px ${backgroundColorHoverVariantsMap[variant]}; 
          border: 0;
        `
          : "border: 0;"
      }
      ${textOnly && "background-color: transparent; box-shadow:unset;"}
      svg > path {
      fill: ${bordered ? backgroundColorHoverVariantsMap[variant] : ""};
    }
    }

    &:active {
      color: ${bordered ? backgroundColorActiveVariantsMap[variant] : ""};
      background-color: ${
        bordered
          ? rgba(backgroundColorActiveVariantsMap[variant], 0.1)
          : backgroundColorActiveVariantsMap[variant]
      };
      ${
        bordered
          ? `
          box-shadow: inset 0px 0px 0px 1px ${backgroundColorActiveVariantsMap[variant]}; 
          border: 0;
        `
          : "border: 0;"
      }
      ${textOnly && "background-color: transparent; box-shadow:unset;"}
      svg > path {
      fill: ${bordered ? backgroundColorActiveVariantsMap[variant] : ""};
    }
    }
    &:disabled {
      pointer-events: none;
      background-color: ${theme.palette.disabled};
      ${
        bordered
          ? `
          color: ${theme.palette.disabled};
          background-color: transparent;
          box-shadow: inset 0px 0px 0px 1px ${theme.palette.disabled}; 
          border: 0;
        `
          : "border: 0;"
      }
    }

    :focus-visible {
      outline: 1px solid ${backgroundColorVariantsMap[variant]};
    }

    transition: all 150ms linear;
  `}
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  bordered,
  variant = "primary",
  type = "button",
  disabled = false,
  fullWidth,
  onClick,
  children,
  height,
  tooltipContent,
  textOnly,
  fitContent,
}) => (
  // <Tooltip fullWidth={fullWidth} content={tooltipContent}>
  <StyledButton
    type={type}
    icon={icon}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    bordered={bordered}
    height={height}
    onClick={onClick}
    textOnly={textOnly}
    fitContent={fitContent}
  >
    <Flex
      justifyContent="center"
      alignItems="center"
      px={textOnly ? "0px" : "15px"}
      gap="5px"
    >
      {label}
      {icon}
      {children && <Box>{children}</Box>}
    </Flex>
  </StyledButton>
  // </Tooltip>
);

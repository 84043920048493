import moment from "moment";
import * as yup from "yup";

export const courseSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  receipt_date: yup
    .string()
    .test(
      "if date is older than today",
      "Data musi być starsza od dzisiaj",
      (value) => {
        return value ? moment(value).toDate() < new Date() : false;
      },
    )
    .required(),
});

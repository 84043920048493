import { FC, useEffect, useState } from "react";
import { P } from "components/Typography/Typography";
import { Flex, Avatar } from "components";
import { useTranslation } from "react-i18next";
import { ColouredBox } from "components/ColouredBox";
import { useRef } from "react";
import { MyAccountState } from "hooks/useMyAccount";
import styled from "styled-components";
import { ReactComponent as MailIcon } from "assets/icons/ico-mail.svg";
import { ReactComponent as LocationIcon } from "assets/icons/ico-location.svg";
import { Select, TextField } from "components/_form";
import { StyledH3 } from "views/MyAccountView/MyAccountView.styled";
import { IUser } from "types/user";
import { Controller, UseFormReturn } from "react-hook-form";
import { IUserForm } from "types/forms/user";
import { getSpecialities } from "services/appointment";
import { IOption } from "components/_form/Select/Select";

const AvatarContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const DataContainer = styled(Flex)`
  width: 100%;
  height: fit-content;
  padding: 20px;
  flex-flow: column;
`;

const Icon = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.neutral.mainBlue};
  `}
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 5px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

type AccountFormHooks = UseFormReturn<IUserForm>;

interface IProfileCard {
  myAccountState?: MyAccountState;
  userData?: IUser;
  formHooks?: AccountFormHooks;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

const roleMap = {
  user: "Pacjent",
  expert: "Specjalista",
};

export const ProfileCard: FC<IProfileCard> = ({
  myAccountState,
  userData,
  onSubmit,
  formHooks,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [specialities, setSpecialities] = useState<IOption[]>();

  const handleFetchSpecialities = async () => {
    const res = await getSpecialities();
    setSpecialities(
      res.data.map(({ id, name }) => ({ value: String(id), label: name })),
    );
  };

  useEffect(() => {
    handleFetchSpecialities();
  }, []);

  const user = myAccountState?.user || userData;

  if (!user) return <></>;

  return (
    <ColouredBox minWidth="250px" height="fit-content" shadow>
      <Flex flexDirection="column" width="100%">
        <AvatarContainer>
          <Avatar
            size="large"
            editButton={myAccountState?.isSettingsMode}
            url={user.profile.avatar}
            onEdit={(event) => {
              fileInputRef.current?.click();
            }}
          />
        </AvatarContainer>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(event) => {
            if (event.target.files) {
              myAccountState?.setUploadedFile(event.target.files[0]);
            }
          }}
        />
        <DataContainer>
          <StyledH3 variant="body" fontWeight={600} my={2}>
            {user.profile.first_name + " " + user.profile.last_name}
          </StyledH3>
          <Flex flexDirection="column" width="100%">
            {user.role === "expert" ? (
              <>
                <form onSubmit={onSubmit}>
                  {myAccountState?.isSettingsMode ? (
                    <Flex mt={2} width="100%" flexDirection="column">
                      <Controller
                        control={formHooks?.control}
                        name="specialities"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label="Zawód"
                            options={specialities || []}
                            isMulti
                            onChange={(val) => onChange(val)}
                            selectedOptions={value}
                          />
                        )}
                      />
                    </Flex>
                  ) : (
                    <Flex>
                      <P variant="body" color="grey" mr={1}>
                        Zawód:
                      </P>
                      <P variant="body" color="coloured" fontWeight={600}>
                        {user.specialities.map(({ name }) => name + " | ")}
                      </P>
                    </Flex>
                  )}

                  <Flex my={2} gap="5px" alignItems="center">
                    <Icon>
                      <MailIcon />
                    </Icon>
                    <Flex width="100%" style={{ wordBreak: "break-all" }}>
                      <P variant="body" fontWeight={600}>
                        {user.email}
                      </P>
                    </Flex>
                  </Flex>

                  {myAccountState?.isSettingsMode ? (
                    <Flex my={2}>
                      <TextField
                        label="Adres"
                        {...formHooks?.register("street_address")}
                      />
                    </Flex>
                  ) : (
                    <Flex mt={2} gap="5px" alignItems="center">
                      <Icon>
                        <LocationIcon />
                      </Icon>
                      <P variant="body" fontWeight={600}>
                        {user.profile.street_address || "brak adresu"}
                      </P>
                    </Flex>
                  )}
                </form>
              </>
            ) : (
              <Flex>
                <P variant="body" color="grey" mr={1}>
                  Typ konta:
                </P>
                <P variant="body" color="coloured" fontWeight={600}>
                  {roleMap[user.role]}
                </P>
              </Flex>
            )}
          </Flex>
        </DataContainer>
      </Flex>
    </ColouredBox>
  );
};

import request from "./index";
import {
  ILogin,
  ILoginResponse,
  IRegister,
  IResetPassword,
  IChangePassword,
} from "types/forms/auth";

export const login = (data: ILogin) =>
  request<ILoginResponse>({
    options: {
      url: "/login",
      method: "post",
      data: { user: data },
    },
    handleSubmit: (response) => {
      if (response.headers.authorization && !localStorage.getItem("token")) {
        localStorage.setItem("token", response.headers.authorization);
      }
      return response.data;
    },
  });

export const logout = () =>
  request({
    options: {
      url: "/logout",
      method: "delete",
    },
  });

export const register = (data: IRegister) =>
  request<ILoginResponse>({
    options: {
      url: "/signup",
      method: "post",
      data: { user: data },
    },
    handleSubmit: (response) => {
      if (response.headers.authorization && !localStorage.getItem("token")) {
        localStorage.setItem("token", response.headers.authorization);
      }
      return response.data;
    },
  });

export const resetPassword = (data: IResetPassword) =>
  request({
    options: {
      url: "/password",
      method: "post",
      data,
    },
  });

export const setNewPassword = (data: IChangePassword) =>
  request({
    options: {
      url: "/password",
      method: "patch",
      data,
    },
  });

export const confirmEmail = (confirmation_token: string) =>
  request({
    options: {
      url: `/confirmation?confirmation_token=${confirmation_token}`,
      method: "get",
    },
  });

export const navItems = [
  {
    label: "Usługi",
    path: "/app/services",
    access: ["user"],
  },
  {
    label: "Specjaliści",
    path: "/app/experts",
    access: ["user"],
  },
  {
    label: "Usługi",
    path: "/info/services",
    access: ["guest"],
  },
  {
    label: "Specjaliści",
    path: "/info/experts",
    access: ["guest"],
  },
  {
    label: "Pomoc",
    path: "/info/help",
    access: ["user", "guest"],
  },
  {
    label: "Kontakt",
    path: "/info/kontakt",
    access: ["user", "guest"],
  },
  {
    label: "Baza wiedzy",
    path: "/info/knowledge-base",
    access: ["guest"],
  },
  {
    label: "Moja dostępność",
    path: "/app/for-doctor",
    access: ["expert"],
  },
];

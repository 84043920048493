import { Flex } from "components";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
`;

export const Wrapper = styled(Flex)`
  margin: 0 auto;
`;

export const StyledHour = styled.button<{ active?: boolean }>`
  border-radius: 30px;
  padding: 10px 15px;
  height: 40px;
  width: 100%;
  text-align: center;

  ${({ theme, active }) => `
    color: ${active ? theme.palette.neutral.white : theme.palette.neutral.dark};
    font-weight: ${active ? "bold" : "normal"};
    background-color: ${
      active ? theme.palette.accent.main : theme.palette.neutral.white
    };
    border: 1px solid ${theme.palette.neutral.lightGrey};
    :disabled {
    cursor: not-allowed;
    color:${theme.palette.neutral.lightGrey};
  }
  `};
`;

export const Line = styled.div`
  width: 1px;
  height: 30px;
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.lightGrey};
  `};
`;

import { Box, Flex } from "components";
import { H1, H2, P } from "components/Typography";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  AccentColorCard,
  ColorCardWrapper,
  HomeImages,
  LogoEU,
  MainText,
  Wrapper,
} from "./HomeView.styled";
import HomeUp from "assets/images/home-view-up.png";
import HomeDown from "assets/images/home-view-down.jpg";
import EULogo from "assets/images/eu_logo.jpg";

export const HomeView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const isLoggedIn: boolean = !!currentUser.id;

  const data = [
    t("homeView.cards.card1"),
    t("homeView.cards.card2"),
    t("homeView.cards.card3"),
    t("homeView.cards.card4"),
  ];

  return (
    <Wrapper>
      <Flex flexDirection="row" width="100%" gap="60px">
        <Flex
          flexDirection="column"
          width="100%"
          justifyContent="center"
          flexWrap="wrap"
          gap="20px"
        >
          <Link to="/projects" target="_blank">
            <Flex
              flexDirection="column"
              width="100%"
              maxWidth="800px"
              gap="10px"
              alignItems="center"
              mb="20px"
            >
              <P variant="body2" textAlign="center" color="primary">
                Sfinansowano w ramach reakcji Unii na pandemię COVID 19,
                realizujemy projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA
                6.2 WSPARCIE MŚP W OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W
                RAMACH PROGRAMU OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
              </P>
              <LogoEU src={EULogo} alt="EU-logo" />
            </Flex>
          </Link>
          <H1 variant="h1">{t("homeView.welcome")}</H1>
          <MainText>{t("homeView.thisIsAPlace")}</MainText>
          <MainText>{t("homeView.ourCompany")}</MainText>
        </Flex>
        <Flex width="100%" justifyContent="center" alignItems="center">
          <HomeImages src={HomeUp} alt="Home Image" />
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <H2 variant="h2" textAlign="center" mb="20px">
          {t("homeView.whyFizjo")}
        </H2>
        <Box>
          <ColorCardWrapper gap="15px">
            {data.map((e, k) => {
              return <AccentColorCard key={k}>{e}</AccentColorCard>;
            })}
          </ColorCardWrapper>
        </Box>
      </Flex>
      <Flex flexDirection="row" width="100%" gap="60px">
        <Flex width="100%" justifyContent="center" alignItems="center">
          <HomeImages src={HomeDown} alt="Home Image" />
        </Flex>
        <Flex
          flexDirection="column"
          width="100%"
          justifyContent="center"
          flexWrap="wrap"
          gap="20px"
        >
          <H1 variant="h1">{t("homeView.whatMakesUsUnique")}</H1>
          <MainText>{t("homeView.ourOnlineOffer")}</MainText>
          <MainText>{t("homeView.dontDelay")}</MainText>

          <Flex mx="auto" gap="20px" flexWrap="wrap">
            <Button
              variant="accent"
              label={isLoggedIn ? t("buttons.checkNow") : t("buttons.singIn")}
              onClick={() =>
                navigate(isLoggedIn ? "/app/appointments" : "/login")
              }
            />

            {!isLoggedIn && (
              <Button
                label={t("buttons.ifYouDontHaveAccount")}
                onClick={() => navigate("/register")}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

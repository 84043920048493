import { FC, useEffect, useState } from "react";
import { Card, Flex } from "components";
import { FaqAccordion } from "./FaqAccordion";
import { IFaqs } from "types/faq";
import { getFaqs } from "services/faq";
import { H2 } from "components/Typography";
import { SectionHeader } from "components/SectionHeader";

export const FaqView: FC = () => {
  const [faqs, setFaqs] = useState<IFaqs>();

  useEffect(() => {
    getFaqs().then((res) => setFaqs(res));
  }, []);
  return (
    <Flex flexDirection="column">
      <SectionHeader text="Pomoc" />
      <Card>
        <H2 variant="h2" textAlign="center">
          Najczęściej zadawane pytania:
        </H2>
        <Flex flexDirection="column" mt="20px" gap="20px">
          {faqs?.data.map((el) => (
            <FaqAccordion
              key={el.id}
              question={el.question}
              answer={el.answer}
            />
          ))}
        </Flex>
      </Card>
    </Flex>
  );
};

import { useEffect, useState } from "react";
import { IOption } from "components/_form/Select/Select";
import { useForm, UseFormReturn } from "react-hook-form";
import { AppointmentDetailsFormData } from "types/forms/appointment";
import { useAppointment, UseAppointmentHook } from "./useAppointment";
import { useDictionaryBlogs } from "hooks/useDictionaryBlogs";

export interface Conditions {
  shouldShowChat: boolean;
  shouldShowNotes: boolean;
  canceled: boolean;
}

interface AppointmentViewHook extends UseAppointmentHook {
  dictionaryBlogs: IOption[];
  formHook: UseFormReturn<AppointmentDetailsFormData>;
  conditions: Conditions;
}

export const useAppointmentView = (id: string): AppointmentViewHook => {
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const appointmentHook = useAppointment(id);
  const { dictionaryBlogs } = useDictionaryBlogs();
  const [shouldShowChat, setShouldShowChat] = useState(false);
  const [shouldShowNotes, setShouldShowNotes] = useState(false);
  const formHook = useForm<AppointmentDetailsFormData>({
    mode: "onChange",
  });

  const { recommendedBlogs, old, canceled } = appointmentHook;

  useEffect(() => {
    setShouldShowChat(old && !canceled);
    setShouldShowNotes(role === "expert");
  }, [role, old]);

  useEffect(() => {
    const recommendedBlogIds = recommendedBlogs.map((blog) =>
      blog.id.toString(),
    );
    const initialFormValues = {
      recommendations: "",
      recommendedBlogs: dictionaryBlogs.filter(
        (blog) => recommendedBlogIds.indexOf(blog.value) !== -1,
      ),
    };
    formHook.reset(initialFormValues);
  }, [recommendedBlogs]);

  return {
    dictionaryBlogs,
    formHook,
    conditions: {
      shouldShowChat,
      shouldShowNotes,
      canceled,
    },
    ...appointmentHook,
  };
};

import { Card, Flex } from "components";
import { Hour } from "components/Hour";
import { SectionHeader } from "components/SectionHeader";
import { P } from "components/Typography";
import { useAppointmentsView } from "hooks/useAppointmentsView/useAppointmentsView";
import moment from "moment";
import { useEffect, useState } from "react";
import { MyEventType } from "types/calendar";
import { BigCalendar } from "./components/Calendar/Calendar";
import { TodayAppointments } from "./components/TodayAppointments";
import { useWindowWidth } from "hooks/useWindowWidth";

export const AppointmentsExpertView = () => {
  const { appointments, setDateFilter } = useAppointmentsView();
  const [currentWeek, setCurrentWeek] = useState(moment().startOf("week"));
  const [currentDay, setCurrentDay] = useState(moment(new Date()));
  const [events, setEvents] = useState<MyEventType[]>([]);
  const { isMobile } = useWindowWidth();

  useEffect(() => {
    setDateFilter({ week_number: currentWeek.isoWeek() });
    setEvents([]);

    if (currentWeek.isSame(new Date(), "isoWeek")) {
      setCurrentDay(moment(new Date()));
      return;
    }
    if (!currentWeek.isSame(currentDay, "isoWeek")) {
      setCurrentDay(currentWeek.isoWeekday(1));
    }
  }, [currentWeek]);

  useEffect(() => {
    if (!currentDay.isSame(currentWeek, "isoWeek")) {
      setCurrentWeek(currentDay);
    }
  }, [currentDay]);

  const handleEvents = () => {
    const tmp: MyEventType[] = [];
    appointments.map((a) => {
      if (a.week.week_number === currentWeek.isoWeek())
        tmp.push({
          id: a.id,
          service: a.service.name,
          patient: `${a.patient_first_name} ${a.patient_last_name}`,
          start: moment(a.date_and_time_of_appointment).toDate(),
          end: moment(a.date_and_time_of_appointment)
            .add(
              a.first_visit
                ? a.service.first_time_slots * 10
                : a.service.slots * 10,
              "minutes",
            )
            .toDate(),
        });
    });

    console.log(events);

    if (!events.length) {
      setEvents(tmp);
    } else {
      const merged = events.concat(tmp.filter((item) => events.includes(item)));
      setEvents(merged);
    }
  };

  useEffect(() => {
    handleEvents();
  }, [appointments]);

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="space-between" mb="20px">
        <SectionHeader text="Kalendarz wizyt" />
        <Flex alignItems="center">
          <P mr={2} variant="body2" color="grey">
            godzina:{" "}
          </P>
          <Hour />
        </Flex>
      </Flex>
      <Flex flexDirection={["column", "column", "row"]} width="100%" gap="20px">
        <TodayAppointments
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          appointments={appointments}
        />
        <Card>
          <BigCalendar
            setCurrentDay={setCurrentDay}
            currentWeek={currentWeek}
            setCurrentWeek={setCurrentWeek}
            events={events}
          />
        </Card>
      </Flex>
    </Flex>
  );
};

import { FC } from "react";
import { P, H3 } from "components/Typography/Typography";
import { Flex } from "components";
import { useTranslation } from "react-i18next";
import { ColouredBox } from "components/ColouredBox";
import { StyledLine } from "../../views/MyAccountView/MyAccountView.styled";
import styled from "styled-components";
import { MyAccountState } from "hooks/useMyAccount";
import { IUser } from "types/user";

const StyledFlex = styled(Flex)`
  position: relative;
`;

interface IAccountDetails {
  myAccountState?: MyAccountState;
  userData?: IUser;
}

export const AccountDetails: FC<IAccountDetails> = ({
  myAccountState,
  userData,
}) => {
  const { t } = useTranslation();

  const user = myAccountState?.user || userData;

  if (!user) return <></>;

  return (
    <ColouredBox width="100%" maxHeight="fit-content" shadow>
      <StyledFlex flexDirection="column" p={4} mb={3}>
        <H3 variant="h2" color="coloured" mb={2}>
          {user?.role === "expert" ? "O mnie:" : "Moje dane:"}
        </H3>
        <StyledLine my={3} />

        {user?.role === "expert" ? (
          <>
            <P variant="body" mb={3}>
              {user.profile.about_me}
            </P>
          </>
        ) : (
          <>
            <P variant="body" mb={1} fontWeight={700}>
              {t("eMail")}:
            </P>
            <P variant="body" mb={3}>
              {user?.email}
            </P>
            <P variant="body" mb={1} fontWeight={700}>
              {t("password")}:
            </P>
            <P variant="body" mb={3}>
              ********
            </P>
            <P variant="body" mb={1} fontWeight={700}>
              {t("Numer telefonu")}:
            </P>
            <P variant="body" mb={3}>
              {user?.profile.phone_number}
            </P>
            <P variant="body" mb={1} fontWeight={700}>
              {t("PESEL")}:
            </P>
            <P variant="body" mb={3}>
              {user?.profile.pesel}
            </P>
            <P variant="body" mb={1} fontWeight={700}>
              {t("Adres")}:
            </P>
            <P variant="body" mb={3}>
              {`${user?.profile.street_address}, ${user?.profile.zip} ${user?.profile.city}`}
            </P>
          </>
        )}
      </StyledFlex>
    </ColouredBox>
  );
};

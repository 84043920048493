import { ROOMS, ROOM } from "constants/paths";
import { serialize } from "object-to-formdata";
import { IRooms, IRoom, ICreateRoom } from "types/room";
import { http } from "utilities/http";

export const getRooms = async () => {
  return await http<IRooms>(ROOMS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getRoom = async (id: string | number) => {
  return await http<{ data: IRoom }>(ROOM(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createRoom = (room: ICreateRoom) =>
  http<{ data: IRoom }>(ROOMS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(room),
  });

export const updateRoom = (room: ICreateRoom, id: string | number) =>
  http<{ data: IRoom }>(ROOM(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(room),
  });

export const deleteRoom = (id: string | number) =>
  http<any>(ROOM(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

import { Flex, Modal } from "components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as InfoIcon } from "assets/icons/ico-info.svg";
import { H2, H3 } from "components/Typography";
import { Button } from "components/_form";
import { useEffect, useState } from "react";

type TShowModal = "true" | "false";

export const CompleteDataModal = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<TShowModal>("false");

  useEffect(() => {
    setShowModal((localStorage.getItem("showModal") || "false") as TShowModal);
  }, []);

  const handleClick = () => {
    setShowModal("false");
    localStorage.setItem("showModal", "false");
  };

  return showModal === "true" ? (
    <Modal isOpen={showModal === "true"}>
      <Flex width="100%" flexDirection="column" gap="20px" alignItems="center">
        <InfoIcon />
        <H2 variant="h2" textAlign="center" color="primary">
          Masz nieuzupełnione dane personalne.
        </H2>
        <H3 variant="h3" textAlign="center" color="primary">
          Możesz je uzupełnić w zakłatce Moje Konto.
        </H3>
        <Flex mt={2} justifyContent="center" gap="10px">
          <Button
            variant="primary"
            bordered
            label="Anuluj"
            onClick={() => handleClick()}
          />
          <Button
            variant="accent"
            label="Przejdź do Moje Konto"
            onClick={() => {
              handleClick();
              navigate("/app/my-account");
            }}
          />
        </Flex>
      </Flex>
    </Modal>
  ) : (
    <></>
  );
};

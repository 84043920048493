import { FC } from "react";
import { P, H3 } from "components/Typography/Typography";
import { Flex } from "components";
import { ColouredBox } from "components/ColouredBox";
import styled from "styled-components";
import { MyAccountState } from "hooks/useMyAccount";
import { TextareaField } from "components/_form";
import { StyledLine } from "views/MyAccountView/MyAccountView.styled";
import { IUser } from "types/user";
import { UseFormReturn } from "react-hook-form";
import { IUserForm } from "types/forms/user";

const StyledFlex = styled(Flex)`
  position: relative;
`;

type AccountFormHooks = UseFormReturn<IUserForm>;

interface IAccountDetails {
  myAccountState?: MyAccountState;
  userData?: IUser;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  formHooks?: AccountFormHooks;
}

export const ProfessionalInterestsScope: FC<IAccountDetails> = ({
  myAccountState,
  userData,
  onSubmit,
  formHooks,
}) => {
  const user = myAccountState?.user || userData;

  if (!user) return <></>;

  return (
    <ColouredBox width="100%" maxHeight="fit-content" shadow>
      <StyledFlex flexDirection="column" p={4} mb={3}>
        <H3 variant="h2" color="coloured" mb={2}>
          Zakres zainteresowań zawodowych:
        </H3>
        <StyledLine my={3} />
        {myAccountState?.isSettingsMode && onSubmit && formHooks ? (
          <form onSubmit={onSubmit}>
            <TextareaField {...formHooks.register("interests")} />
          </form>
        ) : (
          <P variant="body" mb={3}>
            {user?.profile.interests}
          </P>
        )}
      </StyledFlex>
    </ColouredBox>
  );
};

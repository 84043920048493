import { AppointmentsExpertView } from "./AppointmentsExpertView";
import { AppointmentsUserView } from "./AppointmentsUserView";

export const AppointmentsView = () => {
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");

  return role === "expert" ? (
    <AppointmentsExpertView />
  ) : (
    <AppointmentsUserView />
  );
};

import { FC } from "react";
import { Flex } from "components";
import styled from "styled-components";

const Wrapper = styled(Flex)`
  margin: 0 auto;
`;

export const TherapyView: FC = () => {
  return (
    <Wrapper justifyContent="center" flexWrap={["wrap", "nowrap"]}>
      terapie
    </Wrapper>
  );
};

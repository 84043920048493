import { http } from "utilities/http";
import qs from "qs";

import { BLOGS, BLOG, TOPICS, TAGS, BLOG_USER } from "constants/paths";
import { IBlog, IBlogs, ITopics, ITags, IBlogFilter } from "types/knowledge";
import { IUpdateBlog } from "types/forms/knowledge";
import { serialize } from "object-to-formdata";

export const getBlogs = async (filters?: IBlogFilter) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IBlogs>(`${BLOGS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getBlog = async (slug: string) => {
  return await http<IBlog>(BLOG(slug), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getTopics = async () => {
  return await http<ITopics>(TOPICS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const updateBlog = (blog: IUpdateBlog, slug: string) =>
  http<IBlog>(BLOG(slug), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ blog }),
  });

export const getTags = async () => {
  return await http<ITags>(TAGS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const updateUserBlogs = (
  blogId: string | number,
  userId: string | number,
) => {
  http(BLOG_USER, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize({ blog_id: blogId, user_id: userId }),
  });
};

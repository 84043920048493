import { TSurvey } from "types/service";
import { IUser } from "types/user";

export const getSurveyArray = (user: IUser, serviceType: TSurvey) => {
  switch (serviceType) {
    case "common":
      return user.survey_ids;
    case "uro":
      return user.urogynecological_survey_ids;
    case "common":
      return user.dental_survey_ids;
  }
  return [];
};

import { Flex } from "components/Flex";
import styled from "styled-components";

const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `};
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  ${({ theme }) => `
    background-color: ${theme.palette.primary.main};
  `};
`;

export const DotLine = ({ ...props }) => (
  <Flex alignItems="center" {...props}>
    <StyledDot />
    <StyledLine />
    <StyledDot />
  </Flex>
);

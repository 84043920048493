import styled from "styled-components";
import { Button, TextField } from "components/_form";
import {
  UseFormRegister,
  FieldErrors,
  Controller,
  Control,
  UseFormSetValue,
  UseFormGetValues,
} from "react-hook-form";
import { QuestionUnion } from "types/forms/questionnaire";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "components";
import { useEffect, useState } from "react";
import { H3 } from "components/Typography";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ReactComponent as BodySchema } from "assets/images/body-schema.svg";
import { rgba } from "utilities/rgba";
import { set } from "date-fns";
import { useWindowWidth } from "hooks/useWindowWidth";

export interface IQuestionEntity<T> {
  index: number;
  label: string;
  question: QuestionUnion<T>;
  name: string;
  definiteItem?: string;
  type:
    | "schema"
    | "input"
    | "numeric"
    | "choice"
    | "precise"
    | "preciseDefiniteItem";
  answers?: string[] | number[];
}

interface IFormHandlers<T extends FieldValues> {
  errors: FieldErrors<T>;
  register: UseFormRegister<T>;
  control: Control<T, any>;
  setValue: UseFormSetValue<T>;
  getValues: UseFormGetValues<T>;
}

interface IQuestionField<R extends FieldValues> {
  questionEntity: IQuestionEntity<R>;
  formHandlers: IFormHandlers<R>;
  isReadOnly: boolean;
}

interface IPoint {
  x: number;
  y: number;
}

const SchemaBox = styled(Flex)`
  width: 100%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid;
  border-radius: 15px;
  position: relative;
  overflow-x: visible;
`;

const PointOuter = styled(Flex)<IPoint>`
  position: absolute;
  top: ${({ y }) => y - 15}px;
  left: ${({ x }) => x - 15}px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: ${({ theme }) => rgba(theme.palette.neutral.red, 0.1)};
  align-items: center;
  justify-content: center;
`;

const PointInner = styled(Box)`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.neutral.red};
`;

const StyledH3 = styled(H3)`
  font-size: 18px;
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
  `};
`;

const RadioLabel = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.palette.primary.main};
  `};
`;

export const RadioInput = styled.input.attrs({ type: "radio" })`
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
`;

export const NumberButton = styled.button.attrs({ type: "button" })<{
  active?: boolean;
}>`
  border-radius: 50%;
  height: 45px;
  width: 45px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  ${({ theme, active }) => `
    color: ${active ? theme.palette.neutral.white : theme.palette.primary.main};
    background-color: ${
      active ? theme.palette.primary.main : theme.palette.neutral.white
    };
    border: 1px solid ${theme.palette.neutral.lightGrey};
  `};
`;

export const QuestionField = <R extends FieldValues>({
  questionEntity: { question, type, answers, label, index, name, definiteItem },
  formHandlers: { errors, register, control, setValue, getValues },
  isReadOnly,
}: IQuestionField<R>) => {
  const { t } = useTranslation();
  const [isInputActive, setIsInputActive] = useState<boolean | undefined>(
    undefined,
  );
  const val = getValues(question) as string;
  const [points, setPoints] = useState<IPoint[]>([]);
  const { isMobile } = useWindowWidth();

  useEffect(() => {
    if (type === "schema" && val) setPoints(JSON.parse(val));
    if (type === "precise" && isReadOnly) {
      setIsInputActive(!!val);
    }
  }, [val]);

  const handleClick = (e) => {
    var rect = e.target.getBoundingClientRect();
    var rect = e.target.getBoundingClientRect();
    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;
    const newPoints = [...points, { x: x, y: y }];

    setPoints(newPoints);
    // @ts-ignore
    setValue(question, JSON.stringify(newPoints));
  };

  const handleClear = () => {
    setPoints([]);
    // @ts-ignore
    setValue(question, "");
  };

  const QuestionForm = () => {
    return {
      schema: (
        <SchemaBox>
          <Box
            width="fit-content"
            height="fit-content"
            style={{ position: "relative" }}
          >
            {points?.map((e, k) => {
              return (
                <PointOuter key={k} x={e.x} y={e.y}>
                  <PointInner />
                </PointOuter>
              );
            })}
            <BodySchema />
            <Box
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: "2",
              }}
              width="100%"
              height="100%"
              onClick={(e) => !isReadOnly && handleClick(e)}
            />
          </Box>

          <Box style={{ position: "absolute", top: 10, right: 10 }}>
            <Button
              bordered
              height="30px"
              fitContent
              label={t("buttons.clear")}
              onClick={() => handleClear()}
            />
          </Box>
        </SchemaBox>
      ),
      input: (
        <TextField
          {...register(question)}
          // @ts-ignore
          error={t(errors[question]?.message || "")}
        />
      ),
      numeric: (
        <Controller
          control={control}
          name={question}
          render={({ field: { onChange, value } }) => (
            <Flex
              justifyContent={["start", "start", "space-between"]}
              gap="10px"
              flexWrap={["wrap", "wrap", "nowrap"]}
            >
              {answers?.map((answer) => (
                <NumberButton
                  active={value == answer}
                  onClick={() => {
                    onChange(answer);
                  }}
                  key={answer}
                >
                  {answer}
                </NumberButton>
              ))}
            </Flex>
          )}
        />
      ),
      choice: (
        <>
          {answers?.map((answer) => {
            return (
              <Flex key={answer.toString() + name} mb={15}>
                <RadioInput
                  id={answer.toString() + name}
                  value={answer}
                  {...register(question)}
                  name={name}
                />
                <RadioLabel htmlFor={answer.toString() + name}>
                  {t(answer.toString())}
                </RadioLabel>
              </Flex>
            );
          })}
        </>
      ),
      precise: (
        <>
          <Flex mb={15}>
            <RadioInput
              name={name}
              id={question + "Yes"}
              value="yes"
              checked={!!isInputActive}
              onChange={(e) => {
                // @ts-ignore
                setValue(question, "");
                setIsInputActive(true);
              }}
            />
            <RadioLabel htmlFor={question + "Yes"}>{t("yes")}</RadioLabel>
          </Flex>
          {isInputActive && (
            <TextField
              {...register(question)}
              // @ts-ignore
              error={t(errors[question]?.message || "")}
            />
          )}
          <Flex mb={15}>
            <RadioInput
              id={question + "No"}
              value="no"
              name={name}
              checked={isInputActive === false}
              onChange={(e) => {
                // @ts-ignore
                setValue(question, null);
                setIsInputActive(false);
              }}
            />
            <RadioLabel htmlFor={question + "No"}>{t("no")}</RadioLabel>
          </Flex>
        </>
      ),
      preciseDefiniteItem: (
        <>
          {answers?.map((answer) => {
            return (
              <Flex key={answer.toString() + name} mb={15}>
                <RadioInput
                  id={answer.toString() + name}
                  value={answer}
                  {...register(question)}
                  name={name}
                  onChange={(e) => {
                    // @ts-ignore
                    setValue(question, answer);
                    setIsInputActive(answer.toString() === definiteItem);
                  }}
                />
                <RadioLabel htmlFor={answer.toString() + name}>
                  {t(answer.toString())}
                </RadioLabel>
              </Flex>
            );
          })}
          {isInputActive && (
            <TextField
              {...register(question)}
              // @ts-ignore
              error={t(errors[question]?.message || "")}
            />
          )}
        </>
      ),
    }[type];
  };

  return (
    <>
      <StyledH3 variant="h3" mt={30} mb={15}>
        {++index}. {t(label)}
      </StyledH3>
      <QuestionForm />
    </>
  );
};

import { FC } from "react";
import { Box, Flex } from "components";
import { P } from "components/Typography/Typography";

export const IntervalsHeader: FC = ({}) => (
  <Flex mb={2} width="100%">
    <Box width="20%" p={2}>
      <P variant="body">#</P>
    </Box>
    <Box width="30%" p={2}>
      <P variant="body">od</P>
    </Box>
    <Box width="30%" p={2}>
      <P variant="body">do</P>
    </Box>
    <Box width="20%" p={2}>
      <P variant="body">usuń/dodaj</P>
    </Box>
  </Flex>
);

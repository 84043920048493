import { Box, Card, Flex, Spinner } from "components";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { H2, P } from "components/Typography/Typography";
import { dateToDateString } from "utilities/convertDate";
import { Line } from "../../AppointmentFormView.styled";
import { useFormContext } from "react-hook-form";
import { Dispatch, SetStateAction } from "react";

interface ISummaryCard {
  isLoading: boolean;
  setInstantPayment: Dispatch<SetStateAction<boolean>>;
}

export const SummaryCard = ({ isLoading, setInstantPayment }: ISummaryCard) => {
  const { t } = useTranslation();
  const {
    watch,
    formState: { isValid },
  } = useFormContext();

  const serviceDetails = watch("serviceType");

  return (
    <Card width={["100%", "100%", "max-content"]} mt={3}>
      <Flex alignItems="center" flexWrap={"wrap"} flexDirection="row">
        <H2 variant="h2" mr={2} mb={1}>
          Podsumowanie:
        </H2>
        {(!!watch("serviceType")?.label || !!watch("expert")?.label) && (
          <P variant="body" mr={2} fontWeight={600}>
            {watch("serviceType")?.label} | {watch("expert")?.label}
          </P>
        )}
        <Line />
        <P variant="body" mx={2} fontWeight={600}>
          Data:
        </P>
        <P variant="body" mr={2} fontWeight={700}>
          {dateToDateString(watch("startDate"), "/")}
        </P>

        <Line />
        <P variant="body" mx={2} fontWeight={600}>
          Godzina:
        </P>
        <P variant="body" fontWeight={700} mr={2}>
          {watch("startHour")}
        </P>
        <Line />
        <P variant="body" mx={2} fontWeight={600}>
          Czas trwania:
        </P>
        {serviceDetails && (
          <P variant="body" fontWeight={700} mr={2}>
            {watch("first_visit")
              ? serviceDetails?.data.first_time_slots * 10
              : serviceDetails?.data.slots * 10}{" "}
            min
          </P>
        )}
        <Line />
        <P variant="body" mx={2} fontWeight={600}>
          Cena:
        </P>
        {serviceDetails && (
          <P variant="body" fontWeight={700}>
            {watch("first_visit")
              ? serviceDetails?.data.first_time_price
              : serviceDetails?.data.price}{" "}
            zł
          </P>
        )}
      </Flex>

      <Flex mt={4} flexDirection="row" flexWrap="wrap" gap="10px">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {watch("kind") === "in_person" && (
              <Button
                variant="accent"
                type="submit"
                disabled={!isValid}
                onClick={() => setInstantPayment(false)}
              >
                Zarezerwuj
              </Button>
            )}
            <Button
              variant="red"
              type="submit"
              disabled={!isValid}
              onClick={() => setInstantPayment(true)}
            >
              Zarezerwuj i zapłać*
            </Button>
          </>
        )}
      </Flex>
      <P variant="body" mt={2}>
        * w przypadku wizyt online i telefonicznych wizyta zostanie anulowana,
        jeśli nie zostanie opłacona w ciągu 30 min.
      </P>
    </Card>
  );
};

import { Flex } from "components";
import { Button } from "components/_form";
import { MyAccountState } from "hooks/useMyAccount";
import { FC, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { IUserForm } from "types/forms/user";
import { IUser } from "types/user";
import { Course } from "./components/Course";
import { CourseFormModal } from "./components/CourseForm";

type AccountFormHooks = UseFormReturn<IUserForm>;

interface ICourses {
  myAccountState?: MyAccountState;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  formHook?: AccountFormHooks;
  userData?: IUser;
}

export const Courses: FC<ICourses> = ({
  myAccountState,
  onSubmit,
  formHook,
  userData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = myAccountState?.user || userData;

  return (
    <>
      <Flex flexDirection="column" gap="20px">
        {myAccountState?.user && (
          <Flex justifyContent="end">
            <Button
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Dodaj
            </Button>
          </Flex>
        )}
        {user?.profile.courses?.map((course) => (
          <Course
            key={course.title}
            course={course}
            myAccountState={myAccountState}
          />
        ))}
      </Flex>
      {myAccountState && onSubmit && formHook && (
        <CourseFormModal
          isOpen={isModalOpen}
          onCancelClick={() => {
            setIsModalOpen(false);
          }}
          myAccountState={myAccountState}
        />
      )}
    </>
  );
};

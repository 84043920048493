import React from "react";
import styled from "styled-components";
import { Typography, Flex, Link, Box } from "components";
import { ReactComponent as PL } from "assets/flags/pl.svg";
import { ReactComponent as GB } from "assets/flags/gb.svg";
import { P } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";
import IOSLogo from "assets/images/ios.png";
import GoogleLogo from "assets/images/google-play.png";

const Logo = styled.img`
  cursor: pointer;
  height: 20px;
  margin-right: 8px;
`;

const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => `
     background-color: ${theme.palette.primary.main};
  `}
  a {
    text-decoration: none;
  }

  flex-direction: column;
`;

const VerticalLine = styled.div`
  height: 16px;
  width: 1px;
  ${({ theme }) => `background-color: ${theme.palette.neutral.white}`}
`;

const GappedFlex = styled(Flex)`
  flex-flow: row wrap;
  gap: 20px;
  align-items: center;
`;

const Flag = styled(Box)`
  cursor: pointer;
`;

const DownloadWrapper = styled.div``;

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const handleLangChange = (lang: string) => {
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };
  return (
    <Wrapper width="100%" height="100%" py={4} px={5} alignItems="start">
      <Flex
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        gap="30px"
      >
        <Flex flexDirection="column" gap="15px">
          <GappedFlex>
            <Link to="/info/privacy-policy">
              <Typography.P variant="body" color="white">
                Polityka Prywatności
              </Typography.P>
            </Link>

            <VerticalLine />

            <Link to="/info/rules">
              <Typography.P variant="body" color="white">
                Regulamin strony
              </Typography.P>
            </Link>
          </GappedFlex>

          <DownloadWrapper>
            <Typography.P variant="body" color="white" my={2}>
              Pobierz aplikację
            </Typography.P>
            <GappedFlex>
              <Link to="/Fizjo24 IOS.zip" target="_blank" download>
                <Flex alignItems="center">
                  <Logo src={IOSLogo} />
                  <Typography.P variant="body" color="white">
                    IOS
                  </Typography.P>
                </Flex>
              </Link>

              <VerticalLine />

              <Link to="/Fizjo24.apk" target="_blank" download>
                <Flex alignItems="center">
                  <Logo src={GoogleLogo} />
                  <Typography.P variant="body" color="white">
                    Android
                  </Typography.P>
                </Flex>
              </Link>
            </GappedFlex>
          </DownloadWrapper>
        </Flex>
        <Flex gap={"10px"} alignItems="center">
          <Flag>
            <PL
              height="30px"
              data-val={i18n.language === "pl"}
              onClick={() => handleLangChange("pl")}
            />
          </Flag>
          <VerticalLine />
          <Flag>
            <GB
              height="30px"
              data-val={i18n.language === "en"}
              onClick={() => handleLangChange("en")}
            />
          </Flag>
        </Flex>
      </Flex>
      <P variant="body" color="grey" mt={3}>
        © 2023 CRYO Centrum Zdrowia i Urody. Wszystkie prawa zastrzeżone.
      </P>
    </Wrapper>
  );
};

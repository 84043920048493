import { Box, Flex } from "components";
import { H2, P } from "components/Typography";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface IPatientTile {
  id: string | number;
  fullName: string;
  pesel: string;
}

const Wrapper = styled(Flex)`
  min-height: 100px;
  border-radius: 15px;
  box-shadow: 0px 5px 55px rgba(41, 35, 79, 0.15);
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 20px;
`;

const Inline = styled(Box)`
  display: inline-flex;
  gap: 5px;
`;

export const PatientTile = ({ id, fullName, pesel }: IPatientTile) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Flex flexDirection="column" width="fit-content">
        <H2 variant="h2" color="coloured">
          {fullName}
        </H2>
        <Inline>
          <P variant="body" color="grey">
            PESEL:
          </P>
          <P variant="body" color="primary">
            {pesel}
          </P>
        </Inline>
      </Flex>
      <Box>
        <Button
          label="Karta pacjenta"
          onClick={() => navigate(`/app/patients/${id}`)}
        />
      </Box>
    </Wrapper>
  );
};

import { FC } from "react";
import { Flex, Box, Card } from "components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import contactImage from "assets/images/contact-image.png";
import { ReactComponent as LocationIcon } from "assets/icons/ico-location.svg";
import { ReactComponent as MailIcon } from "assets/icons/ico-mail.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/ico-phone.svg";
import { GoogleMap } from "components/GoogleMap/GoogleMap";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { SectionHeader } from "components/SectionHeader";
import { contactData } from "constants/contactData";
import { device } from "styles/theme";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div role="alert">
      <p>Failed to load google map due to react strict mode</p>
      <a href="https://github.com/google-map-react/google-map-react/issues/1116#issuecomment-1150589667">
        More about issue
      </a>
    </div>
  );
}

const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  grid-auto-rows: 1fr;
`;

const StyledImg = styled.div`
  object-fit: contain;
  border-radius: 15px;
  width: 450px;
  height: 450px;
  background: url(${contactImage});
`;

const Icon = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.secondary.main};
  `}
  width: 45px;
  height: 45px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ContactView: FC = () => {
  const { t } = useTranslation();
  return (
    <Flex width="100%" flexDirection="column">
      <SectionHeader text="Kontakt" />
      <Card>
        <GridBox>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <LocationIcon />
              </Icon>
            </Box>
            {contactData.address}
          </Flex>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <MailIcon />
              </Icon>
            </Box>
            {contactData.email}
          </Flex>
          <Flex alignItems="center">
            <Box m={2}>
              <Icon>
                <PhoneIcon />
              </Icon>
            </Box>
            {contactData.phoneNo}
          </Flex>
        </GridBox>
        <Flex gap="30px" mt="30px" flexWrap="wrap">
          <StyledImg />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <GoogleMap lat={59.955413} lng={17.04415717116464} />
          </ErrorBoundary>
        </Flex>
      </Card>
    </Flex>
  );
};

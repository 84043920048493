import React from "react";
import { Footer } from "layouts/components/Footer";
import { Navbar } from "./components/Navbar";
import { ContentWrapper, Wrapper, Content } from "./components/Layout.styled";
import { CompleteDataModal } from "components/CompleteDataModal";

export const InfoLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <CompleteDataModal />
      <Wrapper>
        <Navbar isSidebarOpen={false} onMenuIconClick={() => {}} />
        <ContentWrapper>
          <Content>{children}</Content>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};

import { Flex } from "components/Flex";
import { useRef, useState } from "react";
import { Button } from "../Button";
import { FieldLabel } from "../FieldLabel";
import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg";
import { theme } from "styles";
import { useFormContext } from "react-hook-form";

interface Props {
  label: string;
  name: string;
}

export const FileField = ({ label, name }: Props) => {
  const { register, setValue, watch } = useFormContext();
  const { name: inputName } = register(name);
  const fileName = watch(inputName)?.name;
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Flex width="fit-content" flexDirection="column">
      {label && <FieldLabel>{label}</FieldLabel>}

      <input
        style={{ display: "none" }}
        ref={ref}
        name={inputName}
        type="file"
        onChange={(e) => {
          if (e.target.files) {
            setValue(inputName, e.target.files[0]);
          }
        }}
      />

      <Button
        bordered
        variant="primary"
        onClick={() => {
          ref.current?.click();
        }}
        icon={<AttachmentIcon />}
      >
        {!!fileName ? fileName : "Załącz plik"}
      </Button>
    </Flex>
  );
};

import { useEffect, useState } from "react";
import { IOption } from "components/_form/Select/Select";
import { getBlogs } from "services/knowledge";

interface DictionaryHook {
  dictionaryBlogs: IOption[];
  setDictionaryBlogs: React.Dispatch<React.SetStateAction<IOption[]>>;
}

export const useDictionaryBlogs = (): DictionaryHook => {
  const [dictionaryBlogs, setDictionaryBlogs] = useState<IOption[]>([]);

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const allBlogs = await getBlogs();
        setDictionaryBlogs(
          allBlogs.data.map((blog) => {
            return {
              value: blog.id.toString(),
              label: blog.title,
            };
          }),
        );
      } catch (error: any) {
        console.error(error);
      }
    };

    fetchDictionary();
  }, []);

  return {
    dictionaryBlogs,
    setDictionaryBlogs,
  };
};

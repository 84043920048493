import { useState, FC, useEffect } from "react";
import styled from "styled-components";
import { Button } from "components/_form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ICreateQuestionnaire,
  IQuestionnaireDentalForm,
  IQuestionnaireForm,
  IQuestionnaireUrogynecologicalForm,
} from "types/forms/questionnaire";
import {
  createDentalQuestionnaire,
  createQuestionnaire,
  createUroQuestionnaire,
  getDentalQuestionnaire,
  getQuestionnaire,
  getUroQuestionnaire,
} from "services/questionnaire";
import { H2, P } from "components/Typography";
import { Flex } from "components";
import { QuestionField } from "./QuestionField";
import {
  dentalQuestionEntities,
  questionEntities,
  urogynecologicalQuestionEntities,
} from "./Questions";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const StyledH2 = styled(H2)`
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
`;

const Header = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.secondary.main};
  `}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px 50px;
  height: 120px;
  border-radius: 30px;
`;

const Wrapper = styled(Flex)`
  margin: 0 auto;
`;

export const QuestionnaireView: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const { t } = useTranslation();
  const { type, surveyId } = useParams<{
    type: string;
    surveyId: string;
  }>();
  const navigate = useNavigate();
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  if (!type) navigate("/app/appointments");

  useEffect(() => {
    if (!!surveyId) setIsReadOnly(true);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm<IQuestionnaireForm>({
    mode: "onChange",
  });

  const {
    control: controlUro,
    register: registerUro,
    handleSubmit: handleSubmitUro,
    setValue: setValueUro,
    getValues: getValuesUro,
    reset: resetUro,
    formState: { errors: errorsUro, isValid: isValidUro },
  } = useForm<IQuestionnaireUrogynecologicalForm>({
    mode: "onChange",
  });

  const {
    control: controlDental,
    register: registerDental,
    handleSubmit: handleSubmitDental,
    setValue: setValueDental,
    getValues: getValuesDental,
    reset: resetDental,
    formState: { errors: errorsDental, isValid: isValidDental },
  } = useForm<IQuestionnaireDentalForm>({
    mode: "onChange",
  });

  const handleGetCommonSurvey = async () => {
    try {
      const response = await getQuestionnaire(surveyId ?? "");
      if (!response) return;
      reset(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  const handleGetUroSurvey = async () => {
    try {
      const response = await getUroQuestionnaire(surveyId ?? "");
      if (!response) return;
      resetUro(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  const handleGetDentalSurvey = async () => {
    try {
      const response = await getDentalQuestionnaire(surveyId ?? "");
      if (!response) return;
      resetDental(response.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (!surveyId) return;
    switch (type) {
      case "common":
        handleGetCommonSurvey();
        break;
      case "uro":
        handleGetUroSurvey();
        break;
      case "dental":
        handleGetDentalSurvey();
        break;
    }
  }, []);

  const preparePayload = (data: IQuestionnaireForm): ICreateQuestionnaire => {
    const copy = { ...data };
    if (copy.drugs === "no") copy.drugs = null;
    if (copy.painkillers === "no") copy.painkillers = null;
    return {
      survey: { ...copy, user_id: id },
    };
  };

  const submitLogic = async (
    data:
      | IQuestionnaireForm
      | IQuestionnaireUrogynecologicalForm
      | IQuestionnaireDentalForm,
  ) => {
    setIsLoading(true);
    try {
      if (type === "common") {
        const payload = preparePayload(data as IQuestionnaireForm);
        const response = await createQuestionnaire(payload);
        if (!response) return;
      }
      if (type === "uro") {
        const payload = {
          urogynecological_survey: {
            ...(data as IQuestionnaireUrogynecologicalForm),
            user_id: id,
          },
        };
        const response = await createUroQuestionnaire(payload);
        if (!response) return;
      }
      if (type === "dental") {
        const payload = {
          dental_survey: {
            ...(data as IQuestionnaireDentalForm),
            user_id: id,
          },
        };
        const response = await createDentalQuestionnaire(payload);
        if (!response) return;
      }
      toast.success(t("questionnaireView.questionnaireSaved"));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitVariants: { [key: string]: any } = {
    common: handleSubmit(submitLogic),
    uro: handleSubmitUro(submitLogic),
    dental: handleSubmitDental(submitLogic),
  };
  const onSubmit = submitVariants[type as string];

  const formHandlers = { errors, register, control, setValue, getValues };
  const formHandlersUro = {
    errors: errorsUro,
    register: registerUro,
    control: controlUro,
    setValue: setValueUro,
    getValues: getValuesUro,
  };
  const formHandlersDental = {
    errors: errorsDental,
    register: registerDental,
    control: controlDental,
    setValue: setValueDental,
    getValues: getValuesDental,
  };

  const backButton = (
    <Button variant="primary" bordered onClick={() => navigate(-1)}>
      {isReadOnly ? t("buttons.back") : t("buttons.cancel")}
    </Button>
  );

  return (
    <Wrapper justifyContent="center" flexDirection="column">
      <fieldset disabled={isReadOnly}>
        <Header>
          <Flex flexDirection="column" width="100%" gap="10px">
            <H2 variant="h2" color="white">
              {t("questionnaireView.subHeader")}
            </H2>
            <P variant="body2" color="white">
              Ankieta nie jest obowiązkowa
            </P>
          </Flex>
        </Header>
        <Flex flexDirection="column" py={30}>
          <StyledH2 variant="h2" color="coloured">
            Fizjoterapia
          </StyledH2>
          <form onSubmit={onSubmit}>
            {type === "common" &&
              questionEntities.map((questionEntity) => {
                return (
                  <QuestionField<IQuestionnaireForm>
                    key={questionEntity.index}
                    questionEntity={questionEntity}
                    formHandlers={formHandlers}
                    isReadOnly={isReadOnly}
                  />
                );
              })}
            {type === "uro" &&
              urogynecologicalQuestionEntities.map((questionEntity) => {
                return (
                  <QuestionField<IQuestionnaireUrogynecologicalForm>
                    key={questionEntity.index}
                    questionEntity={questionEntity}
                    formHandlers={formHandlersUro}
                    isReadOnly={isReadOnly}
                  />
                );
              })}
            {type === "dental" &&
              dentalQuestionEntities.map((questionEntity) => {
                return (
                  <QuestionField<IQuestionnaireDentalForm>
                    key={questionEntity.index}
                    questionEntity={questionEntity}
                    formHandlers={formHandlersDental}
                    isReadOnly={isReadOnly}
                  />
                );
              })}

            {!isReadOnly && (
              <Flex mt={30} justifyContent="center" gap="10px">
                {backButton}
                <Button type="submit" variant="accent">
                  {t("buttons.save")}
                </Button>
              </Flex>
            )}
          </form>
        </Flex>
      </fieldset>
      {isReadOnly && (
        <Flex mt={30} justifyContent="center" gap="10px">
          {backButton}
        </Flex>
      )}
    </Wrapper>
  );
};

import { Box } from "components";
import { Controller } from "react-hook-form";
import { StyledHour } from "../../AppointmentFormView.styled";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { getWeeks } from "services/week";
import getWeek from "date-fns/getWeek";
import { getDay } from "date-fns";
import { IWeek } from "types/week";
import styled from "styled-components";
import { P } from "components/Typography";

const GridBox = styled(Box)`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: 1fr;
  justify-items: center;
`;

export const hours = [
  "7:00",
  "7:10",
  "7:20",
  "7:30",
  "7:40",
  "7:50",
  "8:00",
  "8:10",
  "8:20",
  "8:30",
  "8:40",
  "8:50",
  "9:00",
  "9:10",
  "9:20",
  "9:30",
  "9:40",
  "9:50",
  "10:00",
  "10:10",
  "10:20",
  "10:30",
  "10:40",
  "10:50",
  "11:00",
  "11:10",
  "11:20",
  "11:30",
  "11:40",
  "11:50",
  "12:00",
  "12:10",
  "12:20",
  "12:30",
  "12:40",
  "12:50",
  "13:00",
  "13:10",
  "13:20",
  "13:30",
  "13:40",
  "13:50",
  "14:00",
  "14:10",
  "14:20",
  "14:30",
  "14:40",
  "14:50",
  "15:00",
  "15:10",
  "15:20",
  "15:30",
  "15:40",
  "15:50",
  "16:00",
  "16:10",
  "16:20",
  "16:30",
  "16:40",
  "16:50",
  "17:00",
  "17:10",
  "17:20",
  "17:30",
  "17:40",
  "17:50",
  "18:00",
  "18:10",
  "18:20",
  "18:30",
  "18:40",
  "18:50",
  "19:00",
  "19:10",
  "19:20",
  "19:30",
  "19:40",
  "19:50",
  "20:00",
  "20:10",
  "20:20",
  "20:30",
  "20:40",
  "20:50",
  "21:00",
  "21:10",
  "21:20",
  "21:30",
  "21:40",
  "21:50",
];

export const HoursSelect = () => {
  const { watch, control, setValue, register } = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [weekDay, setWeekDay] = useState<0 | 1 | 2 | 3 | 4 | 5 | 6>(0);
  const date = watch("startDate");
  const expert = watch("expert");
  const fV = watch("first_visit");
  const serviceType = watch("serviceType");
  const [initRun, setInitRun] = useState<boolean>(true);
  const [data, setData] = useState<IWeek["data"] | null>();
  const [dayMatrix, setDayMatrix] = useState<string>("");
  const [serviceSlots, setServiceSlots] = useState({
    firstTime: 0,
    standard: 0,
  });

  useEffect(() => {
    setServiceSlots({
      firstTime: serviceType?.data.first_time_slots,
      standard: serviceType?.data.slots,
    });
  }, [serviceType]);

  const fetchHours = async () => {
    setIsLoading(true);
    try {
      const foundWeekId = expert?.data.weeks?.find(
        (week: { week_number: number }) =>
          week.week_number === getWeek(new Date(date), { weekStartsOn: 1 }) - 1,
      )?.id;

      const queryFilters = {
        service_id: String(serviceType?.value),
        week_id: foundWeekId,
      };
      const response = await getWeeks(queryFilters);
      if (!response) return;
      setData(response);
    } catch (err: any) {
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!serviceType || !expert) return;
    setWeekDay(getDay(date));
    if (!initRun) {
      setValue("startHour", "");
    }
    fetchHours();
    setInitRun(false);
  }, [date, expert, serviceType]);

  useEffect(() => {
    if (!data) {
      setDayMatrix("");
      return;
    }
    setValue("week_id", data.id);
    switch (weekDay) {
      case 0:
        return setDayMatrix(data?.sun);
      case 1:
        return setDayMatrix(data?.mon);
      case 2:
        return setDayMatrix(data?.tue);
      case 3:
        return setDayMatrix(data?.wed);
      case 4:
        return setDayMatrix(data?.thu);
      case 5:
        return setDayMatrix(data?.fri);
      case 6:
        return setDayMatrix(data?.sat);
    }
  }, [data]);

  return !!dayMatrix ? (
    <GridBox>
      <input type="hidden" {...register("week_id")} />

      <Controller
        control={control}
        name="startHour"
        render={({ field: { onChange, value } }) => (
          <>
            {hours.map((hour, i) => {
              const slots = fV ? serviceSlots.firstTime : serviceSlots.standard;
              const tmp = dayMatrix.slice(i, i + slots);
              const array = tmp.split("");
              return array.length === slots && array.every((e) => e === "0") ? (
                <StyledHour
                  active={value === hour && Number(dayMatrix[i]) === 0}
                  onClick={() => {
                    onChange(hour);
                  }}
                  key={hour}
                  type="button"
                  disabled={Number(dayMatrix[i]) > 0 || !dayMatrix}
                >
                  {hour}
                </StyledHour>
              ) : null;
            })}
          </>
        )}
      />
    </GridBox>
  ) : (
    <P variant="body" textAlign="center">
      Brak dostępnych godzin do wyświetlenia
    </P>
  );
};

import { Flex } from "components";
import { P } from "components/Typography";
import styled from "styled-components";

const StyledWrapper = styled(Flex)`
  border-radius: 20px;
  padding: 4px 10px;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
`;

export const Hour = () => {
  return (
    <StyledWrapper>
      <P variant="h2" color="coloured" fontWeight={700}>
        {new Date().toLocaleTimeString("pl", {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </P>
    </StyledWrapper>
  );
};

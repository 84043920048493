import { useState } from "react";

export interface PaginationHook {
  page: number;
  perPage: number;
  pages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  setPages: (newPages: number) => void;
}

export const usePagination = (initialPerPage: number = 10): PaginationHook => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initialPerPage);
  const [pages, setPages] = useState(1);

  const handleSetPages = (newPages: number) => {
    if (newPages < pages) {
      setPage(1);
    }
    setPages(newPages);
  };

  return {
    page,
    perPage,
    pages,
    setPage,
    setPerPage,
    setPages: handleSetPages,
  };
};

import { ReactComponent as SendIcon } from "assets/icons/ico-send.svg";
import { Flex } from "components/Flex";
import styled, { css } from "styled-components";

export const StyledFlex = styled(Flex)`
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 5px 55px rgba(41, 35, 79, 0.15);
`;

export const SendIconStyled = styled(SendIcon)`
  cursor: pointer;
  margin-right: 20px;
`;

export const ChatHeader = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.secondary.main};
  `};
  justify-content: center;
  align-items: center;
  color: white;
  height: 30px;

  > svg {
    margin-right: 6px;
  }
`;

export const SpecialistData = styled(Flex)`
  ${({ theme }) => `
      background-color: ${theme.palette.primary.main};
    `};
  padding: 10px 20px;
`;

export const DotOnline = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.success.main};
  `};
  margin-right: 6px;
  height: 10px;
  width: 10px;
  border-radius: 50px;
`;

export const Content = styled(Flex)`
  ${({ theme }) => `
    background-color: ${theme.palette.neutral.white};
  `};
  max-height: 300px;
  flex-direction: column-reverse;
  align-items: center;
  overflow-y: auto;
  padding-block: 3px;
`;

export const MessageOwn = css`
  margin-left: auto;
  margin-right: 20px;
  color: white;
`;
export const MessageNotOwn = css`
  margin-right: auto;
  margin-left: 20px;
`;

export const Message = styled(Flex)<{ own?: boolean }>`
  margin-block: 2px;
  word-break: break-all;
  ${({ theme, own }) => `
      background-color: ${
        own ? theme.palette.secondary.main : theme.palette.neutral.light
      };
      ${own ? MessageOwn : MessageNotOwn};
  `};
  padding: 10px;
  border-radius: 20px;
  max-width: 80%;
`;

export const MessageInputWrapper = styled(Flex)`
  ${({ theme }) => `
      background-color: ${theme.palette.neutral.light};
  `};
  align-items: center;
  > :first-child {
    flex: 12;
  }
  > :last-child {
    flex: 1;
  }
`;

import { FC, useEffect, useState } from "react";
import { H1, P } from "components/Typography/Typography";
import { Box, Flex } from "components";
import { useTranslation } from "react-i18next";
import { useMyAccount } from "hooks/useMyAccount";
import { ReactComponent as EditIcon } from "assets/icons/ico-notes.svg";
import styled from "styled-components";
import { TabPanel } from "components/TabPanel";
import { AboutMe } from "components/Profile/Tabs/AboutMe";
import { Courses } from "components/Profile/Tabs/Courses/Courses";
import { ServicesAndPriceList } from "components/Profile/Tabs/ServicesAndPriceList";

const Edit = styled(Flex)`
  ${({ theme }) => `    
    background: ${theme.palette.error.main};
  `}
  width: 25px;
  height: 25px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
`;

const EditButton = styled(Flex)`
  gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
`;

const tabs = [
  { name: "aboutMe" },
  { name: "courses" },
  { name: "servicesAndPriceList" },
];

export const MyAccountView: FC = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const { t } = useTranslation();
  const {
    formHook,
    myAccountHandlers: { onSubmit },
    myAccountState,
  } = useMyAccount();

  const { isSettingsMode, user, setIsSettingsMode } = myAccountState;

  useEffect(() => {
    setIsSettingsMode(false);
  }, [currentTab]);

  if (!user) return <></>;

  const currentView = () => {
    switch (currentTab.name) {
      case "aboutMe":
        return (
          <AboutMe
            formHook={formHook}
            onSubmit={onSubmit}
            myAccountState={myAccountState}
          />
        );
      case "courses":
        return (
          <Courses
            formHook={formHook}
            onSubmit={onSubmit}
            myAccountState={myAccountState}
          />
        );
      case "servicesAndPriceList":
        return <ServicesAndPriceList />;
    }
  };

  return (
    <Flex width="100%" flexDirection="column">
      <Flex alignItems="center" mb="20px">
        <H1 mb={1} variant="h1" color="primary">
          {t("myAccountHeader")}
        </H1>
        {currentTab === tabs[0] && (
          <EditButton
            alignItems="center"
            onClick={() => setIsSettingsMode(true)}
          >
            <Edit>
              <EditIcon width="12px" height="12px" />
            </Edit>
            <P variant="body" fontWeight={700} color="red">
              Edytuj
            </P>
          </EditButton>
        )}
      </Flex>

      {user.role === "expert" ? (
        <Box my={3}>
          <TabPanel
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            {currentView()}
          </TabPanel>
        </Box>
      ) : (
        currentView()
      )}
    </Flex>
  );
};

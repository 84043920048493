import { http } from "utilities/http";
import qs from "qs";
import {
  SPECIALITIES,
  SPECIALITY,
  APPOINTMENTS,
  APPOINTMENT,
  NOTE,
  CANCEL_APPOINTMENT,
} from "constants/paths";
import {
  IAppointment,
  IAppointments,
  ISpeciality,
  ISpecialities,
  IAppointmentExtended,
} from "types/appointment";
import {
  IUpdateAppointment,
  ICreateAppointment,
} from "types/forms/appointment";
import { serialize } from "object-to-formdata";

export interface GetAppointmentsFilters {
  page?: string | number;
  per_page?: string | number;
  appointment_date?: string;
  expert_id?: number;
  patient_id?: number;
}

export const getAppointments = async (filters?: GetAppointmentsFilters) => {
  const query = qs.stringify(filters, { arrayFormat: "brackets" });
  return await http<IAppointments>(`${APPOINTMENTS}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getAppointment = async (id: string | number) => {
  return await http<IAppointmentExtended>(APPOINTMENT(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const createAppointment = (appointment: ICreateAppointment) =>
  http<IAppointmentExtended>(APPOINTMENTS, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: serialize(appointment),
  });

export const updateAppointment = (
  appointment: IUpdateAppointment,
  id: string | number,
) =>
  http<IAppointment>(APPOINTMENT(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
    body: serialize(appointment),
  });

export const cancelAppointment = (id: string | number) =>
  http<IAppointment>(CANCEL_APPOINTMENT(id), {
    method: "PATCH",
    headers: {
      Accept: "application/json",
    },
  });

export const deleteAppointment = (id: string | number) =>
  http<IAppointment>(APPOINTMENT(id), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
    },
  });

export const getSpecialities = async () => {
  return await http<ISpecialities>(SPECIALITIES, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const getSpieciality = async (id: string | number) => {
  return await http<ISpeciality>(SPECIALITY(id), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

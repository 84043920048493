import React, { FC, Dispatch, SetStateAction, useState } from "react";
import { Flex, Box } from "components";
import { IBlogFilter } from "types/knowledge";
import { useTranslation } from "react-i18next";
import { TextField } from "components/_form";
import { theme } from "styles/theme";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

interface ISearchField {
  setFilters: Dispatch<SetStateAction<IBlogFilter>>;
}

export const SearchField: FC<ISearchField> = ({ setFilters }) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>();

  const handleOnClick = () =>
    setFilters((prevState) => {
      return { ...prevState, title_text: searchText };
    });

  return (
    <Flex flexDirection="column" mt={3}>
      <TextField
        height="40px"
        placeholder={t("search") + "..."}
        onChange={(e) => setSearchText(e.target.value)}
        iconBackgroundColor={theme.palette.primary.main}
        icon={
          <Box>
            <SearchIcon />
          </Box>
        }
        onIconClick={handleOnClick}
      />
    </Flex>
  );
};

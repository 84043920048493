import { urogynecologicalQuestionEntities } from "../views/SurveyView/Questions";

const buttons = {
  singUp: "Zarejestruj się",
  singIn: "Zaloguj się",
  logOut: "Wyloguj się",
  createAccount: "Utwórz konto",
  resetingPassword: "Resetowanie hasła",
  resetPassword: "Resetuj hasło",
  dontHaveAccount: "Nie masz konta?",
  ifYouDontHaveAccount: "Jeśli nie masz konta, załóż je teraz!",
  checkNow: "Sprawdź już teraz!",
  back: "Wróć",
  save: "Zapisz",
  cancel: "Anuluj",
  edit: "Edytuj",
  zoomIn: "Aa+",
  zoomOut: "Aa-",
  scheduleApp: "Umów wizytę",
  clear: "Wyczyść",
};

const homeView = {
  welcome: "Witaj w Fizjo24",
  thisIsAPlace:
    "Jest to miejsce stworzone przez Cryo Centrum Zdrowia i Urody, w którym Twoje zdrowie i dobre samopoczucie są dla nas najważniejsze!",
  ourCompany:
    "Nasza firma specjalizuje się w fizjoterapii, która pomaga Ci pozbyć się bólu i przywrócić sprawność Twojego ciała. Co więcej, jako jedna z niewielu placówek medycznych w Polsce, oferujemy również możliwość skorzystania z naszych usług online.",
  whyFizjo: "Dlaczego warto wybrać Fizjo24? Oto kilka powodów:",
  cards: {
    card1:
      "Doświadczeni fizjoterapeuci – nasz zespół to wykwalifikowani specjaliści, którzy znają się na swojej pracy i potrafią pomóc nawet w najtrudniejszych przypadkach.",
    card2:
      "Nowoczesne metody terapii – stosujemy najnowocześniejsze technologie i metody terapii, aby szybko i skutecznie pomóc naszym pacjentom.",
    card3:
      "Indywidualne podejście – każdy pacjent jest inny, dlatego też każdy przypadek traktujemy indywidualnie i dostosowujemy terapię do potrzeb pacjenta.",
    card4:
      "Szybka reakcja – wiemy, że czas jest ważny, dlatego staramy się zapewnić szybką reakcję na zgłoszenia i szybkie terminy wizyt.",
  },
  whatMakesUsUnique: "Co nas wyróżnia?",
  ourOnlineOffer:
    "Nasza oferta online, dzięki której możesz skorzystać z usług fizjoterapeutycznych bez wychodzenia z domu! Dzięki aplikacji mobilnej i specjalistycznemu oprogramowaniu, możesz skonsultować się z fizjoterapeutą online, a następnie przeprowadzić całą terapię w domu, korzystając z nagranych przez nas filmów z ćwiczeniami. Dodatkowo, oferujemy ciągły kontakt z klientem oraz stały monitoring postępów terapii.",
  dontDelay:
    "Nie zwlekaj! Zadbaj o swoje zdrowie i dobre samopoczucie już teraz. Skorzystaj z usług Fizjo24 i poczuj różnicę!",
};

const common = {
  privacyPolicy: "polityka prywatności",
  regulamin: "regulamin",
  first_name: "imię",
  last_name: "nazwisko",
  phone: "numer telefonu",
  paid: "opłacona",
  unpaid: "nieopłacona",
  send: "Wyślij",
  save: "Zapisz",
  eMail: "Email",
  settings: "Ustawienia",
  search: "Szukaj",
};

const auth = {
  login: "Zaloguj się",
  register: "Zarejestruj",
  password: "Hasło",
  forgotPass: "Zapomniałeś hasła?",
  accountless: "Nie masz konta?",
  emailRequired: "Email jest wymagany",
  phoneNumberRequired: "Numer telefonu jest wymagany",
  badEmailFormat: "Niepoprawny format email",
  badPhoneFormat: "Niepoprawny format numeru telefonu",
  passwordRequired: "Hasło jest wymagane",
  firstNameRequired: "Imię jest wymagane",
  lastNameRequired: "Nazwisko jest wymagane",
  passMinVal: "Hasło powinno zawierać przynajmniej 6 znaków",
  passMaxVal: "Hasło może zawierać maksymalnie 18 znaków",
  passDiffErr: "Hasła nie są takie same",
  acceptRegulamin: "Zaakceptuj regulamin",
  password_confirmation: "potwierdzenie hasła",
  send_email: "Wyślij e-mail",
  passwordPlaceholder: "Wpisz hasło",
  passwordConfirmationPlaceholder: "Powtórz hasło",
  emailPlaceholder: "Wpisz adres e-mail",
  firstNamePlaceholder: "Wpisz imię",
  lastNamePlaceholder: "Wpisz nazwisko",
  phonePlaceholder: "Wpisz numer telefonu",
  resetPassword: "Resetuj hasło",
  setNewPassword: "Ustaw nowe hasło",
};

const contact = {
  nameAndSurname: "Imię i nazwisko",
  messageContent: "Treść wiadomości",
  yourEmail: "Twój email",
  regulationConsent:
    "Wyrażam zgodę na przetwarzanie moich danych osobowych podanych formularzu w celu uzyskania od powiedzi na zapytanie. Moja zgoda jest dobrowolna, a jednocześnie niezbędna do uzyskania odpowiedzi.",
};

const knowledge = {
  noInfo: "Brak informacji",
  loadMore: "Załaduj więcej",
  topics: "Kategorie",
  filterByTag: "Filtrowanie po tagach",
  readMore: "Czytaj więcej",
};

const myAccount = {
  myAccountHeader: "Moje konto",
  firstName: "Imię",
  lastName: "Nazwisko",
  updatedAccountData: "Zmieniono dane konta",
  updatedAvatar: "Zmieniono avatar",
  dataEditionError: "Nie udało się zaktualizować danych",
  aboutMe: "O mnie",
  courses: "Kursy i szkolenia",
  servicesAndPriceList: "Usługi i cennik",
};

const appointments = {
  appointmentsHistory: "Historia wizyt",
  plannedAppointments: "Planowane wizyty",
  moreDays: "więcej dni",
  today: "dzisiaj",
  notes: "Notatki",
  visibleOnlyForYou: "widoczne tylko dla ciebie",
  noNotes: "Brak notatek",
  startWriting: "Zacznij pisać...",
  fetchingError: "An error occurred while fetching appointment data.",
  recommendations: "Zalecenia",
  additionalKnowledgeBaseAcces: "Dodatkowy dostęp do bazy wiedzy",
  patient: "Pacjent",
  expert: "Specjalista",
  appointmentType: "Typ spotkania",
  appointmentTime: "Termin wizyty",
  changeAppointmentTime: "Zmień termin wizyty",
  appointmentPaymentStatus: "Status płatności za wizytę",
  unpaid: "Nieopłacona",
  payNow: "Opłać teraz",
  editRecommendations: "Edytuj zalecenia",
  cancelAppointment: "Anuluj wizytę",
  chatIsAvailable: "Czat jest już aktywny",
  chatWillBeAvailableAfterAppointment: "Czat będzie dostępny po odbyciu wizyty",
  chatWithPatient: "Czat ze pacjentem",
  chatWithExpert: "Czat ze specjalistą",
};

const questionnaireView = {
  header: "Ankieta",
  questionnaireSaved: "Ankieta zapisana",
  subHeader:
    "Wypełnij ankietę przed wizytą, żeby przekazać specjaliście więcej informacji",
};

const weekdays = {
  monday: "Poniedziałek",
  tuesday: "Wtorek",
  wednesday: "Środa",
  thursday: "Czwartek",
  friday: "Piątek",
  saturday: "Sobota",
  sunday: "Niedziela",
};

const questionnaire = {
  // questions
  problem_area_common:
    "Jakiego obszaru dotyczy problem? (zaznacz na rycinie człowieka)",
  problem_duration: "Jak długo trwa problem?",
  pain_scale:
    "Proszę ocenić ból w skali od 1 - 10, przy czym 1 – bardzo słabo, 10 – bardzo mocno",
  work_style: "Proszę ocenić swój styl pracy",
  steps: "Jak dużo kroków wykonuje Pani/Pan w ciągu dnia?",
  daily_activity: "Jak wygląda Pani/Pana aktywność na co dzień?",
  exercise: "Ćwiczy Pani/Pan?",
  exercise_frequency: "Jak często?",
  stress_scale: "Jak ocenia Pani swój poziom stresu w skali od 0 do 10?",
  drugs: "Czy przyjmuje Pan/Pani leki na stałe, jeśli tak to jakie?",
  // answers
  problem_area: "Obszar problemowy",
  sitting: "Siedzący tryb",
  standing: "Stojący tryb",
  mixed: "Mieszany",
  little: "Mało",
  average: "Średnio",
  lot: "Dużo",
  no: "Nie",
  light: "Lekko",
  hard: "Mocno",
  another: "Inne",
  cardiovascular_diseases: "Choroby układu krążenia",
  autoimmune_diseases: "Choroby autoimmunologiczne",
  "1_to_2_times_a_week": "1 do 2 razy w tygodniu",
  "3_to_4_times_a_week": "3 do 4 razy w tygodniu",
  "5_to_7_times_a_week": "5 do 7 razy w tygodniu",
  "1_to_4": "1 do 4",
  "5_to_7": "5 do 7",
  "8_to_10": "8 do 10",
};

const questionnaireDental = {
  // questions
  problem_area_dental:
    "Jakiego obszaru dotyczy problem ? (zaznacz na rycinie twarzy  – opcja, żeby można było zaznaczyć więcej niż jeden obszar i dodać notatkę)",
  problem_length: "Jak długo trwa problem?",
  fatigue_scale:
    "Proszę ocenić skalę zmęczenia problemem od 1 - 10, przy czym 1 – bardzo słabo, 10 – bardzo mocno",
  jaw_tension: "Czy czuje Pan/Pani napięcie w okolicy szczęki?",
  grinding_teeth: "Czy zgrzyta Pan/Pani zębami?",
  stiffness_in_the_jaw:
    "Jak odczuwa Pan/Pani sztywność poranną w okolicy szczęki?",
  tinnitus: "Czy ma Pan/Pani szumy uszne?",
  headaches: "Czy ma Pan/Pani bóle głowy, jeśli tak jak często?",
  visual_impairment: "Czy ma Pan/Pani zaburzenia pola widzenia?",
  chronic_diseases: "Czy ma Pani/Pan choroby przewlekłe?",
  permanent_drugs: "Czy przyjmuje Pan/Pani leki na stałe, jeśli tak to jakie?",
  painkillers: "Czy przyjmuje Pan/Pani leki przeciwbólowe?",
};

const questionnaireUrogynecological = {
  // questions
  problem: "Z jakim problemem się Pani zgłasza? (można zaznaczyć kilka opcji)",
  last_time_at_a_gynecologist: "Kiedy ostatni raz była Pani u ginekologa?",
  last_period: "Kiedy miała Pani ostatnią miesiączkę",
  liquids_during_the_day: "Jak dużo przyjmuje Pani płynów w ciągu dnia?",
  how_often_in_the_toilet_urinate:
    "Jak często chodzi Pani do toalety w ciągu dnia oddać mocz?",
  how_often_in_the_toilet_urinate_daily:
    "Jak często oddaje Pani mocz w ciągu doby?",
  urine_at_night: "Czy wstaje Pani w nocy oddać mocz?",
  daily_activity: "Jak wygląda Pani aktywność na co dzień?",
  exercises: "Ćwiczy Pani?",
  exercises_frequency: "Jak często?",
  stress_level: "Jak ocenia Pani swój poziom stresu w skali od 0 do 10?",
  chronic_diseases: "Czy ma Pani choroby przewlekłe?",
  urine_loss: "Czy gubi Pani mocz?",
  difficulties_in_defecation:
    "Czy ma Pani poczucie, że trudno jest Pani wypróżnić do końca pęcherz lub jelita?",
  soreness_at_sexual_intercourse: "Czy stosunki seksulane są dla Pani bolesne?",
  soreness_during_menstruation:
    "Czy miesiączki są bolesne w pierwszych dniach?",
  // "soreness_on_defecation": "Czy ma Pani poczucie, że trudno jest Pani wypróżnić do końca pęcherz lub jelita?",
  // answers
  preventive_visit: "Wizyta profilaktyczna",
  preparation_for_pregnancy: "Przygotowanie do ciąży",
  preparation_for_childbirth: "Przygotowanie do porodu",
  pregnancy_discomforts: "Dolegliwości ciążowe",
  painful_periods: "Bolesne miesiączki",
  painful_ovulation: "Bolesna owulacja",
  painful_intercourse: "Bolesne współżycia",
  dilation_of_the_rectus_abdominis_muscles: "Rozejście mięśni prostych brzucha",
  urinary_incontinence: "Nietrzymanie moczu",
  stool_and_gas_incontinence: "Nietrzymanie stolca i gazów",
  constipation: "Zaparcia",
  pelvic_pain: "Bóle miednicy",
  scar_therapy:
    "Terapia blizny (cc, epizjotomia-nacięcie/pęknięcie krocza itd.)",
  other_specify: "Inne (wpisz jakie)",
  not_applicable: "Nie dotyczy",
  less_than_a_liter: "Mniej niż litr",
  above_2l: "Powyżej 2l",
  above_3l: "Powyżej 3l",
  every_30_minutes: "Co 30 min",
  every_hour: "Co godzinę",
  every_2_hours: "Co 2 godziny",
  every_3_hours_and_more: "Co 3 godziny i więcej",
  other: "Inne",
  less_than_6_times: "Mniej niż 6 razy",
  "6_-_8_times": "6 – 8 razy",
  "9_-_12_times": "9 – 12 razy",
  more_than_12_times: "Więcej niż 12 razy",
  yes_how_many_times: "Tak (jeśli tak to ile razy)",
  no: "Nie",
  light: "Mała",
  medium: "Średnia",
  hard: "Duża",
  i_dont_exercise: "Nie ćwiczę",
  i_do_light_exercise_regularly: "Wykonuję regularnie lekkie treningi",
  i_regularly_do_medium: "Wykonuję regularnie średnio – ciężkie treningi",
  i_do_heavy_workouts_on_a_regular_basis:
    "Wykonuję regularnie ciężkie treningi",
  "1-2_times_a_week": "1 – 2 razy w tygodniu",
  "3-4_times_a_week": "3 – 4 razy w tygodniu",
  "5-7_times_a_week": "5 – 7 razy w tygodniu",
  yes_cardiovascular_diseases: "Tak (choroby układu krążenia)",
  yes_autoimmune_diseases: "Tak (choroby autoimmunologiczne)",
  yes_other: "Tak (inne)",
  yes: "Tak",
  yes_when_laughing_coughing_sneezing: "Tak podczas śmiechu, kaszlu, kichania",
  yes_when_jumping: "Tak podczas podskoków",
  yes_regardless_of_the_situation: "Tak, niezależnie od sytuacji",
  yes_when_I_feel_pressure_on_the_bladder: "Tak, gdy poczuję parcie na pęcherz",
  yes_at_the_beginning_at_the_entrance_to_the_vagina:
    "Tak, na początku, przy wejściu do pochwy",
  yes_deeper: "Tak, głębiej",
  i_dont_want_to_answer_that_question: "Nie chcę odpowiadać na to pytanie",
  yes_always: "Tak, od zawsze",
  yes_for_some_time: "Tak, od jakiegoś czasu",
  enter_value: "Wpisz wartość",
};

const inputs = {
  email: "Email",
  phone: "Numer telefonu",
  pesel: "PESEL",
  address: "Adres zamieszkania",
  zip: "Kod pocztowy",
  city: "Miasto",
  dateFrom: "Data od",
  dateTo: "Data do",
};

export default {
  ...common,
  ...auth,
  ...contact,
  ...knowledge,
  ...myAccount,
  ...appointments,
  ...weekdays,
  ...questionnaire,
  questionnaireView,
  ...questionnaireDental,
  ...questionnaireUrogynecological,
  buttons,
  inputs,
  homeView,
};

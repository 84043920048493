import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H3 } from "components/Typography";
import { Button } from "components/_form";

interface IPaymentFailedModal extends Pick<IModal, "isOpen" | "onCancelClick"> {
  onActionClick: () => void;
}

export const PaymentFailedModal = ({
  isOpen,
  onCancelClick,
  onActionClick,
}: IPaymentFailedModal) => {
  return (
    <Modal isOpen={isOpen} disableBackdropClick>
      <Flex p={3} width="100%" flexDirection="column">
        <H3 variant="h3" textAlign="center" color="primary">
          Płatność w tym momencie jest niemozliwa.
        </H3>
        <Flex mt={2} justifyContent="center" gap="20px">
          <Button
            variant="primary"
            bordered
            label="Opłać później"
            onClick={onCancelClick}
          />
          <Button variant="red" label="Ponów próbę" onClick={onActionClick} />
        </Flex>
      </Flex>
    </Modal>
  );
};

import React from "react";
import { Footer } from "layouts/components/Footer";
import { Navbar } from "./components/Navbar";
import { GuestContentWrapper, Wrapper } from "./components/Layout.styled";

export const GuestLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Wrapper>
      <Navbar isSidebarOpen={false} onMenuIconClick={() => {}} />
      <GuestContentWrapper>{children}</GuestContentWrapper>
      <Footer />
    </Wrapper>
  );
};

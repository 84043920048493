import { Box, Card, Line } from "components";
import { H2, H3 } from "components/Typography";
import { Select } from "components/_form";
import { useContext, useEffect } from "react";
import { TrixEditor } from "react-trix";
import { IResearchCardForm } from "types/researchCard";
import { InputWrapper } from "../AppointmentResearchCardView.styled";
import { ResearchCardContext } from "../context/ResearchCardContext";
import { CodesSelector } from "./CodesSelector";

interface ISection {
  data: {
    title: string;
    fields: TField[];
  };
  isCodeSelectorVisible?: boolean;
}

type TField = {
  title?: string;
  key: string;
};

export const Section = ({ data, isCodeSelectorVisible }: ISection) => {
  const { setValue, formValues } = useContext(ResearchCardContext);

  useEffect(() => {
    if (!!isCodeSelectorVisible) {
    }
  }, []);

  return (
    <Card>
      <H2 variant="h2" textAlign="center">
        {data.title}
      </H2>
      <Line />
      {!!isCodeSelectorVisible && (
        <Box my="30px">
          <H3 variant="h3" mb="10px">
            Kody ICD10:
          </H3>
          <CodesSelector />
        </Box>
      )}
      <InputWrapper>
        {data.fields.map((el, i) => {
          return (
            <Box key={i}>
              {!!el.title && (
                <H3 variant="h3" mb="10px">
                  {el.title}
                </H3>
              )}
              <TrixEditor
                mergeTags={[]}
                value={formValues[el.key as keyof IResearchCardForm]}
                onChange={(html) => {
                  setValue({
                    key: el.key as keyof IResearchCardForm,
                    value: html,
                  });
                }}
              />
            </Box>
          );
        })}
      </InputWrapper>
    </Card>
  );
};

import { Flex } from "components";
import { TextareaField } from "components/_form";
import { Dispatch, SetStateAction } from "react";
import { IRecommendation } from "types/recommendation";

interface IMedicalRecommendation {
  value: string;
  setRecommendation: Dispatch<
    SetStateAction<Omit<IRecommendation, "appointment_id">>
  >;
}

export const MedicalRecommendation = ({
  value,
  setRecommendation,
}: IMedicalRecommendation) => {
  const handleChange = (body: string) => {
    setRecommendation((prev) => {
      return {
        id: prev.id,
        body: body,
      };
    });
  };
  return (
    <Flex>
      <TextareaField
        label="Zalecenia:"
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </Flex>
  );
};

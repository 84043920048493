import { FC, useState } from "react";
import { Flex, Spinner } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "validation/authSchemas";
import { IResetPassword } from "types/forms/auth";
import { resetPassword } from "api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { H1, P } from "components/Typography/Typography";
import { Logo, StyledCard } from "./components/Auth.Styled";
import { ReactComponent as EmailIcon } from "assets/icons/user.svg";
import { ConfirmResetPasswrdModal } from "./components/ConfirmResetPasswrdModal";

export const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IResetPassword["user"]>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await resetPassword({ user: data });
      if (!response) return;
      setOpenConfirmModal(true);
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <ConfirmResetPasswrdModal
        isOpen={openConfirmModal}
        onCancelClick={() => setOpenConfirmModal(false)}
      />
      <Flex justifyContent="center" alignItems="center" width="100%">
        <StyledCard>
          <Logo />
          <Flex flexDirection="column" width="100%" gap="20px">
            <Flex flexDirection="column" width="100%">
              <H1 variant="h1" textAlign="center" color="primary">
                Resetowanie hasła
              </H1>
              <P variant="body" color="primary" textAlign="center">
                Aby zresetować hasło podaj adres e-mail, na który zostało
                założone konto. Następnie na podany adres wyślemy Ci maila z
                linkiem, który pozwoli Ci ustanowić nowe hasło.
              </P>
            </Flex>
            <form onSubmit={onSubmit}>
              <Flex flexDirection="column" width="100%" gap="20px">
                <TextField
                  required
                  label="E-mail:"
                  placeholder="Wpisz e-mail..."
                  icon={<EmailIcon />}
                  {...register("email")}
                  error={t(errors.email?.message || "")}
                />
                <Flex justifyContent="center">
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <Button
                      variant="secondary"
                      type="submit"
                      disabled={!formState.isValid}
                    >
                      {t("resetPassword")}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </form>
          </Flex>
        </StyledCard>
      </Flex>
    </>
  );
};

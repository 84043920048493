import { Box, Card } from "components";
import { H2, P } from "components/Typography";
import { Button } from "components/_form";
import { FC, useState } from "react";
import styled from "styled-components";
import { ReactComponent as XIcon } from "assets/icons/bx-x.svg";
import { deleteCourse } from "services/courses";
import { toast } from "react-toastify";
import { DeleteCourseModal } from "./DeleteCourseModal";
import { ICourse } from "types/courses";
import { MyAccountState } from "hooks/useMyAccount";

const StyledCard = styled(Card)`
  position: relative;
`;

const XButtonWrapper = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

interface Props {
  course: ICourse;
  myAccountState?: MyAccountState;
}

export const Course: FC<Props> = ({ course, myAccountState }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteCourse = async () => {
    await deleteCourse(course.id);
    myAccountState?.setUser(
      (prevUser) =>
        prevUser && {
          ...prevUser,
          profile: {
            ...prevUser.profile,
            courses: prevUser.profile.courses.filter(
              (searchCourse) => searchCourse.id !== course.id,
            ),
          },
        },
    );
    toast.success("Kurs został usunięty.");
  };

  return (
    <StyledCard p={4}>
      <XButtonWrapper>
        <Button
          textOnly
          variant="transparent"
          icon={<XIcon />}
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      </XButtonWrapper>
      <H2 variant="h2" color="coloured">
        {course.title}
      </H2>
      <P variant="body" color="grey">
        Data ukończenia: {course.receipt_date}
      </P>
      <P variant="body">{course.description}</P>
      <DeleteCourseModal
        isOpen={isModalOpen}
        onCancelClick={() => {
          setIsModalOpen(false);
        }}
        handleDelete={handleDeleteCourse}
      />
    </StyledCard>
  );
};

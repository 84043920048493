import { IOption, Select } from "components/_form/Select/Select";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getUsers } from "services/user";
import { IUser } from "types/user";
import { getUserFullName } from "utilities/appointment";

interface IExpertsOptions extends IOption {
  data: IUser;
}

export const ExpertSelect = () => {
  const { control, watch, setValue } = useFormContext();
  const [expertOptions, setExpertOptions] = useState<IExpertsOptions[]>([]);

  const serviceType = watch("serviceType");
  const expert = watch("expert");

  const fetchExperts = async () => {
    try {
      const queryFilters = {
        role: "expert",
        ...(serviceType && { service_ids: [serviceType.value] }),
      };
      const response = await getUsers(queryFilters);
      if (!response) return;
      const options = response.data.map((expert) => {
        return {
          value: expert.id.toString(),
          label: getUserFullName(expert),
          data: expert,
        };
      });
      setExpertOptions(options);
    } catch (err: any) {
    } finally {
    }
  };

  useEffect(() => {
    fetchExperts();
  }, [serviceType, expert]);

  return (
    <Controller
      control={control}
      name="expert"
      render={({ field: { value, onChange } }) => (
        <Select
          label="Specjalista"
          placeholder="Imię i Nazwisko (specjalizacja)"
          options={expertOptions}
          isMulti={false}
          selectedOptions={value}
          onChange={(val) => onChange(val)}
        />
      )}
    />
  );
};

import { Box } from "components";
import { Checkbox, TextField } from "components/_form";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useMyAccount } from "hooks/useMyAccount/useMyAccount";
import { TabPanel } from "components/TabPanel";
import { RegulationCheckboxes } from "./RegulationCheckboxes";
import { H2 } from "components/Typography";

const tabs = [
  { id: 0, name: "Dla siebie" },
  { id: 1, name: "Dla kogoś" },
];

export const PatientDataInputs = () => {
  const { t } = useTranslation();
  const [useUserData, setUseUserData] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const {
    formState: { errors },
    register,
    setValue,
    clearErrors,
  } = useFormContext();

  const { myAccountState } = useMyAccount();
  const { user } = myAccountState;

  useEffect(() => {
    switch (currentTab.id) {
      case 0:
        setUseUserData(true);
        break;
      case 1:
        setUseUserData(false);
        break;
    }
  }, [currentTab]);

  useEffect(() => {
    if (useUserData) {
      setValue("name", user?.profile.first_name);
      setValue("surname", user?.profile.last_name);
      setValue("email", user?.email);
      setValue("phone", user?.profile.phone_number);
    } else {
      setValue("name", "");
      setValue("surname", "");
      setValue("email", user?.email);
      setValue("phone", user?.profile.phone_number);
    }
    clearErrors();
  }, [useUserData, user]);

  return (
    <TabPanel tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}>
      <H2 variant="h2" textAlign="center">
        Dane pacjenta:
      </H2>
      <TextField
        label="Imię:"
        {...register("name")}
        error={t(errors.name?.message || "")}
        required
      />

      <TextField
        label="Nazwisko:"
        {...register("surname")}
        error={t(errors.surname?.message || "")}
        required
      />
      {currentTab.id === 1 && (
        <H2 variant="h2" textAlign="center">
          Twoje dane kontaktowe:
        </H2>
      )}

      <TextField
        label="E-mail:"
        {...register("email")}
        error={t(errors.email?.message || "")}
        required
      />

      <TextField
        type="phone"
        label="Numer telefonu:"
        {...register("phone")}
        error={t(errors.phone?.message || "")}
        required
      />

      <RegulationCheckboxes />
    </TabPanel>
  );
};

import { Box, Flex } from "components";
import { FieldLabel } from "components/_form";
import { useTranslation } from "react-i18next";
import { P } from "components/Typography/Typography";

import { ReactComponent as Jitsi } from "assets/icons/logo-jitsi.svg";
import { RadioInput } from "../../AppointmentFormView.styled";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { IService } from "types/service";

export const AppointmentTypeButtons = () => {
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const service = watch("serviceType")?.data as IService["data"];
  const anyOption =
    service?.can_in_person || service?.can_online || service?.can_telephone;

  useEffect(() => {
    if (!service) return;

    if (service.can_in_person) setValue("kind", "in_person");
    else if (service.can_online) setValue("kind", "onlie");
    else if (service.can_telephone) setValue("kind", "telephone");
    else setValue("kind", "");
  }, [service]);

  return !anyOption ? (
    <></>
  ) : (
    <Flex my={4} flexWrap="wrap" gap="10px">
      {service.can_in_person && (
        <Flex flexDirection="column">
          <Flex>
            <Box mr={2}>
              <RadioInput
                id="radioLocation"
                value="in_person"
                type="radio"
                {...register("kind")}
              />
            </Box>

            <FieldLabel htmlFor="radioLocation">Stacjonarnie</FieldLabel>
          </Flex>

          <P ml={3} variant="body" color="grey">
            ul. Antoniego Słonimskiego 26/U01, 50-304 Wrocław
          </P>
        </Flex>
      )}
      {service.can_online && (
        <Flex flexDirection="column">
          <Flex>
            <Box mr={2}>
              <RadioInput
                id="radioOnline"
                value="online"
                type="radio"
                {...register("kind")}
              />
            </Box>

            <FieldLabel htmlFor="radioOnline">Online</FieldLabel>
          </Flex>
          <Flex ml={3}>
            <Box mr={2}>
              <Jitsi width="20px" height="auto" />
            </Box>
            <P variant="body" color="grey">
              poprzez Jitsi Meeting
            </P>
          </Flex>
        </Flex>
      )}
      {service.can_telephone && (
        <Flex flexDirection="column">
          <Flex>
            <Box mr={2}>
              <RadioInput
                id="radioTelephone"
                value="telephone"
                type="radio"
                {...register("kind")}
              />
            </Box>

            <FieldLabel htmlFor="radioTelephone">Telefonicznie</FieldLabel>
          </Flex>
          <Flex ml={3}>
            <P variant="body" color="grey">
              Teleporada
            </P>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  phone_number: yup
    .string()
    .required("phoneNumberRequired")
    .matches(
      /^(?:(?:\+|00)?48)?([0-9]{4})[-. ]?([0-9]{5})$/,
      "badPhoneFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
  first_name: yup.string().required("firstNameRequired"),
  last_name: yup.string().required("lastNameRequired"),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
});

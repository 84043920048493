import { Flex, Modal } from "components";
import { IModal } from "components/Modal/Modal";
import { H2, H3 } from "components/Typography";
import { Button } from "components/_form";
import { useNavigate } from "react-router-dom";
import { TSurvey } from "types/service";
import { ReactComponent as GoodIcon } from "assets/icons/ico-good.svg";
import { IUser } from "types/user";
import { getSurveyArray } from "utilities/getSurveyArray";

interface ILocalAppointmentSummaryModal
  extends Pick<IModal, "isOpen" | "onCancelClick"> {
  surveyType?: TSurvey;
}

export const LocalAppointmentSummaryModal = ({
  isOpen,
  surveyType,
}: ILocalAppointmentSummaryModal) => {
  const navigate = useNavigate();
  const currentUser: IUser = JSON.parse(
    localStorage.getItem("currentUser") || "{}",
  );
  const surveys = getSurveyArray(currentUser, surveyType ?? "common");

  return (
    <Modal isOpen={isOpen} disableBackdropClick>
      <Flex width="100%" flexDirection="column" gap="20px" alignItems="center">
        <GoodIcon />
        <H2 variant="h2" textAlign="center" color="primary">
          Dziękujemy za umówienie wizyty.
        </H2>
        <H3 variant="h3" textAlign="center" color="primary">
          Pamiętaj, aby opłacić wizytę. Mozesz to zrobić w szczegółach wizyty,
          lub stacjonarnie w dniu wizyty.
        </H3>
        <Flex mt={2} justifyContent="center" gap="20px">
          {(currentUser.user_must_repeat_surveys || !surveys.length) && (
            <Button
              variant="accent"
              bordered
              label="Wypełnij ankietę"
              onClick={() => {
                if (!!surveyType) navigate(`/app/survey/${surveyType}`);
              }}
            />
          )}
          <Button
            variant="accent"
            label="Przejdź do listy wizyt"
            onClick={() => {
              navigate("/app/appointments");
            }}
          />
        </Flex>
      </Flex>
    </Modal>
  );
};

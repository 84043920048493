import { FC, useEffect, useState } from "react";
import { Flex } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { appointmentSchema } from "validation/appointment";
import appointmentImg from "assets/images/appointmentImg.png";
import { useTranslation } from "react-i18next";
import { H1 } from "components/Typography/Typography";
import { DotLine } from "components/DotLine";
import { IAppointmentFormData } from "types/forms/appointment";
import { createAppointment } from "services/appointment";
import { preparePayload } from "utilities/appointment";
import { AppointmentForm } from "./AppointmentForm";
import { Wrapper } from "./AppointmentFormView.styled";
import { IAppointmentExtended, TKind } from "types/appointment";
import { IPayment } from "types/pay";
import { createPayment } from "services/pay";
import { PaymentFailedModal } from "./PaymentFailedModal";
import { SectionHeader } from "components/SectionHeader";
import { LocalAppointmentSummaryModal } from "./LocalAppointmentSummaryModal";
import { toast } from "react-toastify";

const defaultValues = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  startDate: new Date(),
  regulationsAccepted: false,
  kind: "online" as TKind,
  first_visit: false,
};

export const AppointmentFormView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceType: serviceTypeParam, expert: expertParam } = useParams();
  const [instantPayment, setInstantPayment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openLocalSuccesModal, setOpenLocalSuccesModal] =
    useState<boolean>(false);
  const [appointmentData, setAppointmentData] =
    useState<IAppointmentExtended>();

  const methods = useForm<IAppointmentFormData>({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(appointmentSchema),
  });

  const handlePay = async (appointment: IAppointmentExtended) => {
    const payData: IPayment = {
      appointment_id: appointment.data.id,
      unit_price: appointment.data.first_visit
        ? appointment.data.service.first_time_price
        : appointment.data.service.price,
      user_id: appointment.data.patient.id,
      description: appointment.data.service.name,
    };

    try {
      const response = await createPayment(payData);

      if (response.status.statusCode === "SUCCESS") {
        localStorage.setItem("orderId", response.orderId);
        window.location.replace(response.redirectUri);
      } else {
      }
    } catch (e: any) {
      toast.error(e.error);
    }
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    setIsLoading(true);
    setError("");

    const reqPayload = preparePayload(data, currentUser.id);
    try {
      const response = await createAppointment(reqPayload);
      if (!response) return;
      setAppointmentData(response);
      if (instantPayment) {
        handlePay(response);
      } else {
        if (data.kind === "in_person") setOpenLocalSuccesModal(true);
        else navigate("/app/appointments");
      }
    } catch (err: any) {
      setError(err.error);
      toast.error(err.error);
    } finally {
      setIsLoading(false);
    }
  });

  const handleActionClick = () => {
    setOpenErrorModal(false);
    handlePay(appointmentData!);
  };

  const handleCancelClick = () => {
    setOpenErrorModal(false);
    navigate("/app/appointments");
  };

  return (
    <>
      <LocalAppointmentSummaryModal
        isOpen={openLocalSuccesModal}
        surveyType={appointmentData?.data.service.survey_type}
      />
      <PaymentFailedModal
        isOpen={openErrorModal}
        onActionClick={handleActionClick}
        onCancelClick={handleCancelClick}
      />
      <Wrapper justifyContent="center" flexWrap={["wrap", "nowrap"]}>
        <Flex flexDirection="column" height="100%" width="100%">
          <SectionHeader text="Umów wizytę" />
          <FormProvider {...methods}>
            <AppointmentForm
              serviceTypeParam={serviceTypeParam}
              expertParam={expertParam}
              isLoading={isLoading}
              onSubmit={onSubmit}
              setInstantPayment={setInstantPayment}
            />
          </FormProvider>
        </Flex>
      </Wrapper>
    </>
  );
};

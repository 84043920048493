import { getMe, getUser, updateUser } from "services/user";
import { useTranslation } from "react-i18next";
import { IAppointmentExtended } from "types/appointment";
import { getAppointments } from "services/appointment";
import { isAppointmentOld } from "utilities/appointment";
import { updateProfile } from "services/user";
import { IUpdateProfile, IUserForm } from "types/forms/user";
import { toast } from "react-toastify";
import { UseFormReturn } from "react-hook-form";
import { MyAccountState } from "./useMyAccount";
import { FetchHook } from "hooks/useFetch";

type MyAccountHandler = {
  fetchHook: FetchHook;
  myAccountState: MyAccountState;
  formHook: UseFormReturn<IUserForm>;
};

function getTryCatchWrapper({ setError, setIsLoading }: FetchHook) {
  return function tryCatchWrapper<T, P>(
    fetchHandler: (props?: P) => Promise<T>,
  ): (props?: P) => Promise<T | undefined> {
    const { t } = useTranslation();
    return async (props?: P) => {
      setError("");
      setIsLoading(true);
      try {
        return fetchHandler(props);
      } catch (err: any) {
        toast.error(err.message);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
  };
}

export const getHandleUpdateAvatar = ({
  myAccountState: { uploadedFile, setUploadedFile },
}: MyAccountHandler) => {
  const { t } = useTranslation();
  return async () => {
    if (!uploadedFile) return;
    const payloadData: IUpdateProfile = {
      profile: {
        avatar: uploadedFile,
      },
    };
    const profileResponse = await updateProfile(payloadData);
    setUploadedFile(undefined);
    if (profileResponse) {
      localStorage.setItem("currentUser", JSON.stringify(profileResponse));
      toast.success(t("updatedAvatar"));
    }
  };
};

export const getFetchUser = ({
  myAccountState: { setUser },
  formHook: { reset },
}: MyAccountHandler) => {
  return async () => {
    const userResponse = await getMe();
    const { data: user } = userResponse;
    const {
      phone_number,
      pesel,
      street_address,
      zip,
      city,
      about_me,
      interests,
    } = user.profile;
    const formData = {
      email: user?.email,
      phone_number,
      pesel,
      street_address,
      city,
      zip,
      about_me,
      interests,
      specialities: user?.specialities.map(({ name, id }) => ({
        value: String(id),
        label: name,
      })),
    };
    reset(formData);
    setUser(user);
  };
};

export const getFetchAppointments = ({
  myAccountState: { setAppointments, user },
}: MyAccountHandler) => {
  const { role } = JSON.parse(localStorage.getItem("currentUser") || "{}");
  return async () => {
    const userResponse = await getMe();
    const appointmentsResponse = await getAppointments();
    if (!appointmentsResponse.data.length || !userResponse) return;
    const allAppointmentsResponse: IAppointmentExtended["data"][] =
      await Promise.all(
        appointmentsResponse.data.map(async (appointment) => {
          const expert =
            role == "expert"
              ? userResponse
              : await getUser(appointment.expert.id);
          const patient =
            role == "user"
              ? userResponse
              : await getUser(appointment.patient.id);
          return {
            ...appointment,
            ...(expert && { expert: expert.data }),
            ...(patient && { patient: patient.data }),
          };
        }),
      );
    const appointments = allAppointmentsResponse.filter(
      (el) =>
        !isAppointmentOld(
          el.date_and_time_of_appointment,
          el.first_visit ? el.service.first_time_slots : el.service.slots,
        ),
    );
    setAppointments(appointments);
  };
};

export const getOnSubmit = ({
  myAccountState: { setIsSettingsMode, user },
}: MyAccountHandler) => {
  const { t } = useTranslation();
  return async (data?: IUserForm) => {
    if (!data) return;
    const {
      phone_number,
      zip,
      city,
      street_address,
      pesel,
      about_me,
      interests,
      courses_attributes,
      specialities,
    } = data;
    const payloadData: IUpdateProfile = {
      profile: {
        phone_number,
        pesel,
        street_address,
        city,
        zip,
        about_me,
        interests,
        courses_attributes,
      },
    };

    if (user?.role === "expert" && user?.id && specialities?.length > 0) {
      const userPayloadData = {
        user: {
          speciality_ids: specialities?.map(({ value }) => value) || [],
        },
      };

      await updateUser(user?.id, userPayloadData);
    }

    const profileResponse = await updateProfile(payloadData);
    if (profileResponse) {
      toast.success(t("updatedAccountData"));
      setIsSettingsMode(false);
    }
  };
};

export const getMyAccountHandlers = (myAccountHandler: MyAccountHandler) => {
  const {
    fetchHook,
    formHook: { handleSubmit },
  } = myAccountHandler;
  const tryCatchWrapper = getTryCatchWrapper(fetchHook);

  const onSubmit = handleSubmit(
    tryCatchWrapper<void, IUserForm>(getOnSubmit(myAccountHandler)),
  );
  const handleUpdateAvatar = tryCatchWrapper<void, undefined>(
    getHandleUpdateAvatar(myAccountHandler),
  );
  const fetchUser = tryCatchWrapper(getFetchUser(myAccountHandler));
  const fetchAppointments = tryCatchWrapper(
    getFetchAppointments(myAccountHandler),
  );
  return { onSubmit, handleUpdateAvatar, fetchUser, fetchAppointments };
};

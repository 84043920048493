import { ReactComponent as NewUserIcon } from "assets/icons/ico-user.svg";
import React from "react";
import styled from "styled-components";
import { theme } from "styles/theme";
import { ReactComponent as EditIcon } from "assets/icons/ico-notes.svg";
import { API_PHOTO_URL } from "constants/paths";

export interface AvatarI {
  size?: "small" | "medium" | "large";
  url?: string | Blob;
  color?: "light" | "dark";
  editButton?: boolean;
  onEdit?: (event: any) => void;
}

interface RootI {
  size: AvatarI["size"];
  color: NonNullable<AvatarI["color"]>;
  url?: AvatarI["url"];
}

const backgroundColorVariantsMap = {
  light: theme.palette.neutral.white,
  dark: theme.palette.primary.main,
};

const borderColorVariantsMap = {
  light: theme.palette.neutral.light,
  dark: theme.palette.primary.main,
};

const colorVariantMap = {
  light: theme.palette.primary.main,
  dark: theme.palette.neutral.white,
};

const Root = styled.div<RootI>`
  ${({ size, theme, color, url }) => `
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${
      (size === "small" && `32px`) ||
      (size === "medium" && `64px`) ||
      (size === "large" && `160px`)
    };
    width: ${
      (size === "small" && `32px`) ||
      (size === "medium" && `64px`) ||
      (size === "large" && `160px`)
    };
    padding: ${
      (size === "small" && `0`) ||
      (size === "medium" && `15px`) ||
      (size === "large" && `20px`)
    };
    border: ${size === "small" ? "1px" : "2px"} solid ${
    borderColorVariantsMap[color]
  };
    
    flex: 0 0 auto;
    border-radius: 50%;
    color: ${theme.palette.primary.main};
    background-color: ${backgroundColorVariantsMap[color]};

    .avatar {
      height: ${
        (size === "small" && `20px`) ||
        (size === "medium" && `48px`) ||
        (size === "large" && `120px`)
      };
      width: ${
        (size === "small" && `20px`) ||
        (size === "medium" && `48px`) ||
        (size === "large" && `120px`)
      };
      path {
      fill: ${colorVariantMap[color]};
    }
  }

    ${url && `background-image: url('${API_PHOTO_URL + "/" + url}');`}
    background-position: center;
    background-size: cover;
  `}
`;

const EditButton = styled.div`
  ${({ theme }) => `    
    background: ${theme.palette.error.main};
  `}
  width: 35px;
  height: 35px;
  border-radius: 30px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 51%;
  left: 51%;
  cursor: pointer;
  transform: rotate(294deg) translate(84px) rotate(-294deg);
`;

export const Avatar: React.FC<AvatarI> = ({
  size = "small",
  url,
  color = "light",
  editButton,
  onEdit,
}) => (
  <Root size={size} color={color} url={url}>
    {!url && <NewUserIcon className="avatar" />}
    {editButton && (
      <EditButton onClick={onEdit}>
        <EditIcon />
      </EditButton>
    )}
  </Root>
);

import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Flex } from "components";
import { ColouredBox } from "components/ColouredBox";
import { DateNavigationBox } from "./DateNavigationBox";
import { AppointmentTile } from "./AppointmentTile";
import { IAppointmentExtended } from "types/appointment";
import moment from "moment";
import { P } from "components/Typography";

export interface ITodayAppointments {
  currentDay: moment.Moment;
  setCurrentDay: Dispatch<SetStateAction<moment.Moment>>;
  appointments: IAppointmentExtended["data"][];
}

export const TodayAppointments = ({
  currentDay,
  setCurrentDay,
  appointments,
}: ITodayAppointments) => {
  const [sortedData, setSortedData] = useState<IAppointmentExtended["data"][]>(
    [],
  );

  useEffect(() => {
    const filteredData = appointments.filter((a) =>
      moment(a.date_and_time_of_appointment).isSame(currentDay, "day"),
    );

    setSortedData(
      filteredData.sort((a, b) =>
        moment(a.date_and_time_of_appointment).diff(
          b.date_and_time_of_appointment,
        ),
      ),
    );
  }, [appointments, currentDay]);

  return (
    <ColouredBox minWidth="330px" shadow height="100%">
      <Flex flexDirection="column" alignItems="center" p={15} width="100%">
        <DateNavigationBox
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
        />
        {sortedData.length > 0 ? (
          sortedData.map((a, k) => {
            return <AppointmentTile key={k} data={a} />;
          })
        ) : (
          <P variant="body" color="primary" mt={2}>
            Brak wizyt tego dnia
          </P>
        )}
      </Flex>
    </ColouredBox>
  );
};

import React, { useState } from "react";
import { SideBar } from "layouts/components/Sidebar";
import { Navbar } from "layouts/components/Navbar";
import { Content, ContentWrapper, Wrapper } from "./components/Layout.styled";
import { Footer } from "layouts/components/Footer";
import { CompleteDataModal } from "components/CompleteDataModal";
import { useWindowWidth } from "hooks/useWindowWidth";

export const AppLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <>
      <CompleteDataModal />
      <Wrapper id="mainWrapper">
        <Navbar
          isSidebarOpen={isSidebarOpen}
          onMenuIconClick={() => setIsSidebarOpen((prevState) => !prevState)}
        />
        <ContentWrapper>
          <SideBar />
          <Content>{children}</Content>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};
